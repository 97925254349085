import React from 'react';
import { render } from 'react-dom';
import Chart from './ChartS1';
import { getData } from "./utils"

import { TypeChooser } from "react-stockcharts/lib/helper";
import 'reactjs-popup/dist/index.css';
import file from './BTCUSDDAY.tsv';

//состояня отображают выбранный в меню пункт (value - 1 скрипт покупки)
class Stoosma extends React.Component {
	componentDidMount() {
		this.state = {value: ''};

		this.handleSubmit = this.handleSubmit.bind(this);

		getData(file).then(data => {
			this.setState({ data });
		})

	}

	  handleSubmit(event) {
		event.preventDefault();
		this.setState({value6: 'stoch', value7: 20, value10: 'sma', value11: 20, canvas: 700, canvas1: 410});
	  }


	render() {
		if (this.state === null) {
			return <div>Loading...</div>
		}
//есть ли сейчас уже покупка
let buy = 0;
let price = 0;
let price1 = 0;
let sdelki = 0;
let sdelkiminus = 0;
let pribyl = 0;

//условие 1
let a = 0;
let b = 0;
let a1 = 0;
let b1 = 0;
//условие 2
let a01 = 0;
let b01 = 0;

let a21 = 0;
let b21 = 0;

//массив со сделками
const sdelki1 = [];
const type = [];
const dats = [];

for (let i = 0; i < this.state.data.length; i++) {



	/* РЕЖИМ ПОКУПКИ */

	if (this.state.value6 === "stoch") {
		a1 = a;
		b1 = b;
		a = this.state.data[i].fullSTO.K;
		b = this.state.data[i].fullSTO.D;
	}
	
	if (this.state.value10 === "sma") {
		a21 = a01;
		b21 = b01;
		a01 = this.state.data[i].sma20;
		b01 = this.state.data[i].close;
	}
	
	if (buy === 0) {
		if (this.state.uslovie === undefined) {
			this.setState({uslovie: 2, canvas: 470, canvas1: 180});
		}

		if (b1 < 70 && b > 70 &&       a01 > a21) {
			buy = 1;
			sdelki = sdelki + 1;
			price = this.state.data[i].close;
			type[sdelki] = 0;
			sdelki1[sdelki] = price;
			price1 = +price + +this.state.value3;
			dats[sdelki] = this.state.data[i].date.toGMTString();
		}
	}


	
	/* РЕЖИМ ПРОДАЖИ */
	if (buy === 1) {

		if (b1 > 30 && b < 30 &&       a01 < a21) {
			buy = 0;
			pribyl = pribyl + (((this.state.data[i].close / price * 100 - 100) /100) * 1000)
				if ((((this.state.data[i].close / price * 100 - 100) /100) * 1000) < 0) {
					sdelkiminus = sdelkiminus + 1;
				}
			sdelki = sdelki + 1;
			price = this.state.data[i].close;
			type[sdelki] = 1;
			sdelki1[sdelki] = price;
			dats[sdelki] = this.state.data[i].date.toGMTString();
			price = 0;
		}

	}
	
	/* ----------------------------------------------------------------------------------------- */
}

		return (
			<div style={{backgroundColor:"#ffffff"}}>
			<TypeChooser>
				{type => <Chart type={type} data={this.state.data} value6={this.state.value6} value7={20} value10={this.state.value10} value11={20} value12={this.state.value12} value13={this.state.value13} value={this.state.value} value1={this.state.value1} value8={this.state.value8} value9={this.state.value9} value14={this.state.value14} value15={this.state.value15} canvas={this.state.canvas} canvas1={this.state.canvas1} buy={buy}/>}
			</TypeChooser>
			{localStorage.getItem('id') == null ? <h3 style={{color: "red"}}><a href='/create' >Зарегистрируйтесь</a>, чтобы протестировать стратегию.</h3> : null}
			{localStorage.getItem('id') != null ? <button onClick={this.handleSubmit}>Запустить проверку</button> : null}
<p></p>
			Статистика: <h2>При вложеннии по $1000 Прибыль составила: ${pribyl} (%{Math.floor(pribyl/10)})</h2>
			<p></p>
			Количество сделок: {sdelki}
			<p></p>
			Из них убыточных: {sdelkiminus}
			<p></p>
			  {sdelki1.map((e, index) => {
        return (
          <div key={index}>
            <h2>Сделка {index}: Тип: {type[index] < 1 ? "Покупка" : "Продажа"} Цена: ${sdelki1[index]}; Дата: {dats[index]}</h2>
            <hr />
          </div>
        );
      })}
			</div>
		)
	}
}



render(
	<Stoosma />,
	document.getElementById("root")
);

export default Stoosma;