import React from 'react'

import kukoin from './img/New-Project2.png'
import bitfin from './img/New-Project3.png'
import bybit from './img/New-Project4.png'
import okex from './img/New-Project5.png'
import binan from './img/New-Project6.png'
import chal from './img/challenge-1.png'
import inn from './img/innovation-1.png'
import shield from './img/shield-1.png'
import logo from './img/New-Project.png'
import stratbox from './img/pf-s73-eve-set-14-mockup-1.png'
import payments from './img/buy1w-2-e1684262574376.png'
import roket from './img/Group-281.png'
import vectorg from './img/Vector-14.png'
import vectorr from './img/Vector-13.png'
import crossr from './img/Group-121.png'
import check from './img/Group-123.png'
import 'bootstrap/dist/css/bootstrap.min.css'
import './css/post-10.css'
import './css/widget-nav-menu.min.css'
import './css/fontawesome.min.css'
import './css/frontend-lite.min.css'
import './css/frontend-lite.min1.css'
import './css/global.css'
import './css/post-2.css'
import './css/post-5.css'
import './css/post-195.css'
import './css/post-205.css'
import './css/responsive.css'
import './css/style.min.css'
import './css/swiper.min.css'
import './css/theme.min.css'

import './css/linearicons.css'
import './css/themify.css'
import './css/solid.min.css'
import './css/widget-icon-list.min.css'

function Market() {
    return (
<>
<div data-elementor-type="wp-page" data-elementor-id="2" className='elementor elementor-2' data-elementor-post-type="page">
	
	


		<section className='elementor-section elementor-top-section elementor-element elementor-element-7c2ad6d elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="7c2ad6d" data-element_type="section" id="contact-us" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
			<div className='elementor-container elementor-column-gap-default'>
				<div className='elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-095d340' data-id="095d340" data-element_type="column">
					<div className='elementor-widget-wrap elementor-element-populated'>
						<div className='elementor-element elementor-element-a3c9e3a elementor-widget elementor-widget-image' data-id="a3c9e3a" data-element_type="widget" data-widget_type="image.default">
							<div className='elementor-widget-container'>
								<img style={{display: 'block', margin: '85px 85px'}} loading="lazy" decoding="async" width="657" height="105" src="img/Frame-7.png" className='attachment-full size-full wp-image-532' alt="" srcSet='img/Frame-7.png 657w, img/Frame-7-300x48.png 300w' sizes="(max-width: 657px) 100vw, 657px" />
							</div>
						</div>

						<div className='elementor-element elementor-element-197ab35 elementor-widget elementor-widget-heading' data-id="197ab35" data-element_type="widget" id="buy-now" data-widget_type="heading.default">
							<div className='elementor-widget-container'>
								<h1 className='elementor-heading-title elementor-size-default'>Маркетплейс стратегий</h1>
							</div>
						</div>
						<section className='elementor-section elementor-inner-section elementor-element elementor-element-e14ac5e elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="e14ac5e" data-element_type="section">
							<div className='elementor-container elementor-column-gap-default'>
								<div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-f83b7e0' data-id="f83b7e0" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
									<div className='elementor-widget-wrap elementor-element-populated'>
										<div className='elementor-element elementor-element-c4667db elementor-widget elementor-widget-heading' data-id="c4667db" data-element_type="widget" data-widget_type="heading.default">
											<div className='elementor-widget-container'>
												<h3 className='elementor-heading-title elementor-size-default'>БОЛЬШЕ ПРИБЫЛИ</h3>
											</div>
										</div>
										<div className='elementor-element elementor-element-90e6e75 elementor-widget-divider--view-line elementor-widget elementor-widget-divider' data-id="90e6e75" data-element_type="widget" data-widget_type="divider.default">
											<div className='elementor-widget-container'>
												<div className='elementor-divider'>
													<span className='elementor-divider-separator'>
													</span>
												</div>
											</div>
										</div>
										<div className='elementor-element elementor-element-92503d8 elementor-widget elementor-widget-image' data-id="92503d8" data-element_type="widget" data-widget_type="image.default">
											<div className='elementor-widget-container'>
												<img loading="lazy" decoding="async" width="1263" height="655" src={stratbox} className='attachment-full size-full wp-image-1684' alt="" srcSet='img/pf-s73-eve-set-14-mockup-1.png 1263w, img/pf-s73-eve-set-14-mockup-1-300x156.png 300w, img/pf-s73-eve-set-14-mockup-1-1024x531.png 1024w, img/pf-s73-eve-set-14-mockup-1-768x398.png 768w' sizes="(max-width: 1263px) 100vw, 1263px" />
											</div>
										</div>
										<section className='elementor-section elementor-inner-section elementor-element elementor-element-9511012 elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="9511012" data-element_type="section">
											<div className='elementor-container elementor-column-gap-default'>
												<div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-a7fcc14' data-id="a7fcc14" data-element_type="column">
													<div className='elementor-widget-wrap elementor-element-populated'>
														<div className='elementor-element elementor-element-eb82a9b elementor-widget elementor-widget-heading' data-id="eb82a9b" data-element_type="widget" data-widget_type="heading.default">
															<div className='elementor-widget-container'>
																<div className='elementor-heading-title elementor-size-default'>$999</div>
															</div>
														</div>
													</div>
												</div>
												<div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-12e0a8b' data-id="12e0a8b" data-element_type="column">
													<div className='elementor-widget-wrap elementor-element-populated'>
														<div className='elementor-element elementor-element-efc8b2f elementor-widget elementor-widget-heading' data-id="efc8b2f" data-element_type="widget" data-widget_type="heading.default">
															<div className='elementor-widget-container'>
																<h3 className='elementor-heading-title elementor-size-default'>ПОЖИЗНЕННЫЙ ДОСТУП</h3>
															</div>
														</div>
													</div>
												</div>
											</div>
										</section>
										<div className='elementor-element elementor-element-a8eb358 elementor-widget elementor-widget-heading' data-id="a8eb358" data-element_type="widget" data-widget_type="heading.default">
											<div className='elementor-widget-container'>
												<h3 className='elementor-heading-title elementor-size-default'>Разблокируйте пожизненный доступ к стратегии с максимальной прибылью</h3>
											</div>
										</div>
										<div className='elementor-element elementor-element-1ea9428 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list' data-id="1ea9428" data-element_type="widget" data-widget_type="icon-list.default">
											<div className='elementor-widget-container'>
												<ul className='elementor-icon-list-items' style={{listStyleType: 'none'}}>
													<li className='elementor-icon-list-item'>
														<span className='elementor-icon-list-icon'>
															<i aria-hidden="true" className='fas fa-crown'></i>
														</span>
														<span className='elementor-icon-list-text'>Торговая пара: BTC-USDT</span>
													</li>
													<li className='elementor-icon-list-item'>
														<span className='elementor-icon-list-icon'>
															<i aria-hidden="true" className='fas fa-crown'></i>
														</span>
														<span className='elementor-icon-list-text'>Общее количество сделок: 19</span>
													</li>
													<li className='elementor-icon-list-item'>
														<span className='elementor-icon-list-icon'>
															<i aria-hidden="true" className='fas fa-crown'></i>
														</span>
														<span className='elementor-icon-list-text'>Количество убыточных сделок: 3</span>
													</li>
													<li className='elementor-icon-list-item'>
														<span className='elementor-icon-list-icon'>
															<i aria-hidden="true" className='fas fa-crown'></i>
														</span>
														<span className='elementor-icon-list-text'>Прибыль за весь период: 2270%</span>
													</li>
													<li className='elementor-icon-list-item'>
														<span className='elementor-icon-list-icon'>
															<i aria-hidden="true" className='fas fa-crown'></i>
														</span>
														<span className='elementor-icon-list-text'>Максимальная Прибыль за сделку: 1762%</span>
													</li>
													<li className='elementor-icon-list-item'>
														<span className='elementor-icon-list-icon'>
															<i aria-hidden="true" className='fas fa-crown'></i>
														</span>
														<span className='elementor-icon-list-text'>Средняя Доходность за сделку: 225%</span>
													</li>
													<li className='elementor-icon-list-item'>
														<span className='elementor-icon-list-icon'>
															<i aria-hidden="true" className='fas fa-crown'></i>
														</span>
														<span className='elementor-icon-list-text'>Средняя ежемесячная Доходность: 24%</span>
													</li>
												</ul>
											</div>
										</div>
										<div className='elementor-element elementor-element-8790ff0 elementor-align-justify elementor-widget elementor-widget-button' data-id="8790ff0" data-element_type="widget" data-widget_type="button.default">
											<div className='elementor-widget-container'>
												<div className='elementor-button-wrapper'>
													<a className='elementor-button elementor-button-link elementor-size-sm'>
														<span className='elementor-button-content-wrapper'>
															<span className='elementor-button-text tooltip-toggle'>Узнать больше</span>
															<span class="tooltip-text">Свяжитесь с администратором, чтобы узнать больше: @teleg.</span>
														</span>
													</a>
												</div>
											</div>
										</div>
										<div className='elementor-element elementor-element-85af4a3 elementor-widget elementor-widget-image' style={{textAlign: 'center'}} data-id="85af4a3" data-element_type="widget" data-widget_type="image.default">
											<div className='elementor-widget-container'>
												<img loading="lazy" decoding="async" width="201" height="43" src={payments} className='attachment-full size-full wp-image-1685' alt="" />
											</div>
										</div>
									</div>
								</div>
								<div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-b8add57' data-id="b8add57" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;gradient&quot;}">
									<div className='elementor-widget-wrap elementor-element-populated'>
										<div className='elementor-element elementor-element-ce31d9e elementor-widget elementor-widget-heading' data-id="ce31d9e" data-element_type="widget" data-widget_type="heading.default">
											<div className='elementor-widget-container'>
												<h3 className='elementor-heading-title elementor-size-default'>БОЛЬШЕ БЕЗОПАСНОСТИ</h3>
											</div>
										</div>
										<div className='elementor-element elementor-element-7a580e1 elementor-widget-divider--view-line elementor-widget elementor-widget-divider' data-id="7a580e1" data-element_type="widget" data-widget_type="divider.default">
											<div className='elementor-widget-container'>
												<div className='elementor-divider'>
													<span className='elementor-divider-separator'>
													</span>
												</div>
											</div>
										</div>
										<div className='elementor-element elementor-element-f1c7d88 elementor-widget elementor-widget-image' data-id="f1c7d88" data-element_type="widget" data-widget_type="image.default">
											<div className='elementor-widget-container'>
												<img loading="lazy" decoding="async" width="430" height="223" src="img/pf-s73-eve-set-14-mockup-3.png" className='attachment-full size-full wp-image-1686' alt="" srcset="img/pf-s73-eve-set-14-mockup-3.png 430w, img/pf-s73-eve-set-14-mockup-3-300x156.png 300w" sizes="(max-width: 430px) 100vw, 430px" />
											</div>
										</div>
										<section className='elementor-section elementor-inner-section elementor-element elementor-element-cad05c8 elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="cad05c8" data-element_type="section">
											<div className='elementor-container elementor-column-gap-default'>
												<div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-629424b' data-id="629424b" data-element_type="column">
													<div className='elementor-widget-wrap elementor-element-populated'>
														<div className='elementor-element elementor-element-f3bb58b elementor-widget elementor-widget-heading' data-id="f3bb58b" data-element_type="widget" data-widget_type="heading.default">
															<div className='elementor-widget-container'>
																<div className='elementor-heading-title elementor-size-default'>$999</div>
															</div>
														</div>
													</div>
												</div>
												<div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-6380fde' data-id="6380fde" data-element_type="column">
													<div className='elementor-widget-wrap elementor-element-populated'>
														<div className='elementor-element elementor-element-e84bdaf elementor-widget__width-initial elementor-hidden-mobile elementor-widget elementor-widget-heading' data-id="e84bdaf" data-element_type="widget" data-widget_type="heading.default">
															<div className='elementor-widget-container'>
																<h3 className='elementor-heading-title elementor-size-default'>ПОЖИЗНЕННЫЙ ДОСТУП</h3>
															</div>
														</div>
														<div className='elementor-element elementor-element-dbffea3 elementor-widget__width-initial elementor-hidden-desktop elementor-hidden-tablet elementor-widget elementor-widget-heading' data-id="dbffea3" data-element_type="widget" data-widget_type="heading.default">
															<div className='elementor-widget-container'>
																<h3 className='elementor-heading-title elementor-size-default'>LIFETIME ACCESS</h3>
															</div>
														</div>
													</div>
												</div>
											</div>
										</section>
										<div className='elementor-element elementor-element-748415f elementor-widget elementor-widget-heading' data-id="748415f" data-element_type="widget" data-widget_type="heading.default">
											<div className='elementor-widget-container'>
												<h3 className='elementor-heading-title elementor-size-default'>Разблокируйте пожизненный доступ к стратегии с максимальной безопасностью</h3>
											</div>
										</div>
										<div className='elementor-element elementor-element-a3649e7 elementor-align-left elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list' data-id="a3649e7" data-element_type="widget" data-widget_type="icon-list.default">
											<div className='elementor-widget-container'>
												<ul className='elementor-icon-list-items' style={{listStyleType: 'none'}}>
													<li className='elementor-icon-list-item'>
														<span className='elementor-icon-list-icon'>
															<i aria-hidden="true" className='fas fa-crown'></i>
														</span>
														<span className='elementor-icon-list-text'>Торговая пара: BTC-USDT</span>
													</li>
													<li className='elementor-icon-list-item'>
														<span className='elementor-icon-list-icon'>
															<i aria-hidden="true" className='fas fa-crown'></i>
														</span>
														<span className='elementor-icon-list-text'>Общее количество сделок: 13</span>
													</li>
													<li className='elementor-icon-list-item'>
														<span className='elementor-icon-list-icon'>
															<i aria-hidden="true" className='fas fa-crown'></i>
														</span>
														<span className='elementor-icon-list-text'>Количество убыточных сделок: 0</span>
													</li>
													<li className='elementor-icon-list-item'>
														<span className='elementor-icon-list-icon'>
															<i aria-hidden="true" className='fas fa-crown'></i>
														</span>
														<span className='elementor-icon-list-text'>Прибыль за весь период: 635%</span>
													</li>
													<li className='elementor-icon-list-item'>
														<span className='elementor-icon-list-icon'>
															<i aria-hidden="true" className='fas fa-crown'></i>
														</span>
														<span className='elementor-icon-list-text'>Максимальная Прибыль за сделку: 115%</span>
													</li>
													<li className='elementor-icon-list-item'>
														<span className='elementor-icon-list-icon'>
															<i aria-hidden="true" className='fas fa-crown'></i>
														</span>
														<span className='elementor-icon-list-text'>Средняя Доходность за сделку: 106%</span>
													</li>
													<li className='elementor-icon-list-item'>
														<span className='elementor-icon-list-icon'>
															<i aria-hidden="true" className='fas fa-crown'></i>
														</span>
														<span className='elementor-icon-list-text'>Средняя ежемесячная Доходность: 9%</span>
													</li>
												</ul>
											</div>
										</div>
										<div className='elementor-element elementor-element-2f3bcdf elementor-align-justify elementor-widget elementor-widget-button' data-id="2f3bcdf" data-element_type="widget" data-widget_type="button.default">
											<div className='elementor-widget-container'>
												<div className='elementor-button-wrapper'>
													<a className='elementor-button elementor-button-link elementor-size-sm'>
														<span className='elementor-button-content-wrapper'>
															<span className='elementor-button-text tooltip-toggle'>Узнать больше</span>
															<span class="tooltip-text">Свяжитесь с администратором, чтобы узнать больше: @teleg.</span>
														</span>
													</a>
												</div>
											</div>
										</div>
										<div className='elementor-element elementor-element-8311741 elementor-widget elementor-widget-image' style={{textAlign: 'center'}} data-id="8311741" data-element_type="widget" data-widget_type="image.default">
											<div className='elementor-widget-container'>
												<img loading="lazy" decoding="async" width="201" height="43" src={payments} className='attachment-full size-full wp-image-1685' alt="" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
					</div>
				</div>
			</div>
		</section>








</div>




</>
)
}
export default Market