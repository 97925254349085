import { tsvParse, csvParse } from  "d3-dsv";
import { timeParse } from "d3-time-format";

function parseData(parse) {
	let buy = 0;
	let price = 0;

	return function(d) {
		d.date = parse(d.date);
		d.open = +d.open;
		d.high = +d.high;
		d.low = +d.low;
		d.close = +d.close;
		d.volume = +d.volume;
		/*
if (d.close > 15000 && buy == 0) {
	buy = 1;
	price = d.close;
	console.log(price, d.date);
} else {
	if (buy == 1 && price < d.close) {
		buy = 0;
		price = 0;
		console.log(d.close, "sell", d.date);
	}
} */
		return d;
	};
}

//график дневной или с секундами
const parseDate = timeParse("%Y-%m-%d");
//const parseDate = timeParse("%Y-%m-%d %H:%M:%S");

export function getData(chartData) {
		const promiseMSFT = fetch(chartData)
		.then(response => response.text())
		.then(data => tsvParse(data, parseData(parseDate)))
//		.then(data => csvParse(data, parseData(parseDate)))
//		.then(data => {
//			data.sort((a, b) => {
//				return a.date.valueOf() - b.date.valueOf();
//			});
//			return data;
//		});
		return promiseMSFT;
}
