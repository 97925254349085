import React from "react";

import { VisibilityContext } from "@preshonyee/react-horizontal-scrolling-menu";

export function Card({
  onClick,
  selected,
  title,
  id,
  content,
  itemId
}: {
  disabled?: boolean;
  onClick: Function;
  selected: boolean;
  title: string;
  id: number;
  content: string;
  itemId: string;
}) {
  const visibility = React.useContext(VisibilityContext);

  const visible = visibility.isItemVisible(itemId);

  return (
    <div
      onClick={() => onClick(visibility)}
      onKeyDown={(ev) => {
        if (ev.code === "Enter") {
          onClick(visibility);
        }
      }}
      role="button"
      style={{
        border: "1px solid",
        display: "inline-block",
        margin: "0 10px",
        width: "160px",
        userSelect: "none"
      }}
      tabIndex={0}
      className="card"
    >
      <div>
      <div>{id}</div>
{/*         <div>{title}</div> */}
        <img style={{height: '100px', width: '160px', padding: '10px'}} src={content}></img>
{/*
        <div style={{ backgroundColor: visible ? "transparent" : "gray" }}>
          visible: {JSON.stringify(visible)}
        </div>
        <div>selected: {JSON.stringify(!!selected)}</div>
            */}
        </div>
      <div
        style={{
          backgroundColor: selected ? "green" : "bisque",
          height: "1px"
        }}
      />
     
    </div>
  );
}
