import React from 'react'
import { Link } from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.min.css'
import './css/post-10.css'
import './css/widget-nav-menu.min.css'
import './css/fontawesome.min.css'
import './css/frontend-lite.min.css'
import './css/frontend-lite.min1.css'
import './css/global.css'
import './css/post-2.css'
import './css/post-5.css'
import './css/post-195.css'
import './css/post-205.css'
import './css/responsive.css'
import './css/style.min.css'
import './css/swiper.min.css'
import './css/theme.min.css'

import './css/linearicons.css'
import './css/themify.css'
import './css/solid.min.css'
import './css/widget-icon-list.min.css'

function Strateg() {
    return (
<>
<div data-elementor-type="wp-page" data-elementor-id="2" className='elementor elementor-2' data-elementor-post-type="page">
	
	


		<section className='elementor-section elementor-top-section elementor-element elementor-element-7c2ad6d elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="7c2ad6d" data-element_type="section" id="contact-us" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
			<div className='elementor-container elementor-column-gap-default'>
				<div className='elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-095d340' data-id="095d340" data-element_type="column">
					<div className='elementor-widget-wrap elementor-element-populated'>
						<div className='elementor-element elementor-element-a3c9e3a elementor-widget elementor-widget-image' data-id="a3c9e3a" data-element_type="widget" data-widget_type="image.default">
							<div className='elementor-widget-container'>
								<img style={{display: 'block', margin: '85px 85px'}} loading="lazy" decoding="async" width="657" height="105" src="img/Frame-7.png" className='attachment-full size-full wp-image-532' alt="" srcSet='img/Frame-7.png 657w, img/Frame-7-300x48.png 300w' sizes="(max-width: 657px) 100vw, 657px" />
							</div>
						</div>

						<div className='elementor-element elementor-element-197ab35 elementor-widget elementor-widget-heading' data-id="197ab35" data-element_type="widget" id="buy-now" data-widget_type="heading.default">
							<div className='elementor-widget-container'>
								<h1 className='elementor-heading-title elementor-size-default'>Бесплатные стратегии</h1>
							</div>
						</div>
						<section className='elementor-section elementor-inner-section elementor-element elementor-element-e14ac5e elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="e14ac5e" data-element_type="section">
							<div className='elementor-container elementor-column-gap-default'>
								<div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-f83b7e0' data-id="f83b7e0" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
									<div className='elementor-widget-wrap elementor-element-populated'>
										<div className='elementor-element elementor-element-c4667db elementor-widget elementor-widget-heading' data-id="c4667db" data-element_type="widget" data-widget_type="heading.default">
											<div className='elementor-widget-container'>
												<h3 className='elementor-heading-title elementor-size-default'>Stochastic + SMA</h3>
											</div>
										</div>
										<div className='elementor-element elementor-element-90e6e75 elementor-widget-divider--view-line elementor-widget elementor-widget-divider' data-id="90e6e75" data-element_type="widget" data-widget_type="divider.default">
											<div className='elementor-widget-container'>
												<div className='elementor-divider'>
													<span className='elementor-divider-separator'>
													</span>
												</div>
											</div>
										</div>
										<div className='elementor-element elementor-element-92503d8 elementor-widget elementor-widget-image' data-id="92503d8" data-element_type="widget" data-widget_type="image.default">
											<div className='elementor-widget-container'>
											<iframe width="560" height="315" src="https://rutube.ru/play/embed/d352de7c90da1d916caa8c3d87c730c2" frameBorder="0" allow="clipboard-write; autoplay" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
											</div>
										</div>
										<section className='elementor-section elementor-inner-section elementor-element elementor-element-9511012 elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="9511012" data-element_type="section">
											<div className='elementor-container elementor-column-gap-default'>
												<div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-12e0a8b' data-id="12e0a8b" data-element_type="column">
													<div className='elementor-widget-wrap elementor-element-populated'>
														<div className='elementor-element elementor-element-efc8b2f elementor-widget elementor-widget-heading' data-id="efc8b2f" data-element_type="widget" data-widget_type="heading.default">
															<div className='elementor-widget-container'>
																<h3 className='elementor-heading-title elementor-size-default'>БЕСПЛАТНАЯ СТРАТЕГИЯ</h3>
															</div>
														</div>
													</div>
												</div>
											</div>
										</section>
										<div className='elementor-element elementor-element-1ea9428 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list' data-id="1ea9428" data-element_type="widget" data-widget_type="icon-list.default">
											<div className='elementor-widget-container'>
												<ul className='elementor-icon-list-items' style={{listStyleType: 'none'}}>
													<li className='elementor-icon-list-item'>
														<span className='elementor-icon-list-icon'>
															<i aria-hidden="true" className='fas fa-crown'></i>
														</span>
														<span className='elementor-icon-list-text'>Торговая пара: BTC-USDT</span>
													</li>
													<li className='elementor-icon-list-item'>
														<span className='elementor-icon-list-icon'>
															<i aria-hidden="true" className='fas fa-crown'></i>
														</span>
														<span className='elementor-icon-list-text'>Общее количество сделок: 69</span>
													</li>
													<li className='elementor-icon-list-item'>
														<span className='elementor-icon-list-icon'>
															<i aria-hidden="true" className='fas fa-crown'></i>
														</span>
														<span className='elementor-icon-list-text'>Количество убыточных сделок: 15</span>
													</li>
													<li className='elementor-icon-list-item'>
														<span className='elementor-icon-list-icon'>
															<i aria-hidden="true" className='fas fa-crown'></i>
														</span>
														<span className='elementor-icon-list-text'>Прибыль за весь период: 579%</span>
													</li>
													<li className='elementor-icon-list-item'>
														<span className='elementor-icon-list-icon'>
															<i aria-hidden="true" className='fas fa-crown'></i>
														</span>
														<span className='elementor-icon-list-text'>Максимальная Прибыль за сделку: 185%</span>
													</li>
													<li className='elementor-icon-list-item'>
														<span className='elementor-icon-list-icon'>
															<i aria-hidden="true" className='fas fa-crown'></i>
														</span>
														<span className='elementor-icon-list-text'>Средняя Доходность за сделку: 17%</span>
													</li>
													<li className='elementor-icon-list-item'>
														<span className='elementor-icon-list-icon'>
															<i aria-hidden="true" className='fas fa-crown'></i>
														</span>
														<span className='elementor-icon-list-text'>Средняя ежемесячная Доходность: 6%</span>
													</li>
												</ul>
											</div>
										</div>
										<div className='elementor-element elementor-element-8790ff0 elementor-align-justify elementor-widget elementor-widget-button' data-id="8790ff0" data-element_type="widget" data-widget_type="button.default">
											<div className='elementor-widget-container'>
												<div className='elementor-button-wrapper'>
													<a className='elementor-button elementor-button-link elementor-size-sm'>
														<span className='elementor-button-content-wrapper'>
															<Link className='elementor-button-text tooltip-toggle' style={{color: 'white'}} to='/stoosma'>Узнать больше</Link>
														</span>
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>

							</div>
						</section>
					</div>
				</div>
			</div>
		</section>








</div>




</>
)
}
export default Strateg