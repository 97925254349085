
import React from "react";
import PropTypes from "prop-types";

import { scaleTime } from "d3-scale";
import { format } from "d3-format";
import { timeFormat } from "d3-time-format";

import { ChartCanvas, Chart } from "react-stockcharts";
import {
	BarSeries,
	AreaSeries,
	CandlestickSeries,
	LineSeries,
	MACDSeries,
	BollingerSeries,
	RSISeries,
	StochasticSeries,
	StraightLine,
} from "react-stockcharts/lib/series";
import { XAxis, YAxis } from "react-stockcharts/lib/axes";
import {
	CrossHairCursor,
	CurrentCoordinate,
	EdgeIndicator,
	MouseCoordinateX,
	MouseCoordinateY,
	PriceCoordinate
} from "react-stockcharts/lib/coordinates";

import { discontinuousTimeScaleProvider } from "react-stockcharts/lib/scale";
import {
	OHLCTooltip,
	MovingAverageTooltip,
	MACDTooltip,
	BollingerBandTooltip,
	RSITooltip,
	SingleValueTooltip,
	StochasticTooltip,
} from "react-stockcharts/lib/tooltip";
import { ema, wma, sma, tma, macd, bollingerBand, rsi, atr, stochasticOscillator } from "react-stockcharts/lib/indicator";
import { fitWidth } from "react-stockcharts/lib/helper";
import algo from "react-stockcharts/lib/algorithm";
import {
	Label,
	Annotate,
	SvgPathAnnotation,
	buyPath,
	sellPath,
} from "react-stockcharts/lib/annotation";
import { last } from "react-stockcharts/lib/utils";

const stoAppearance = {
	stroke: Object.assign({},
		StochasticSeries.defaultProps.stroke)
};

const bbStroke = {
	top: "#964B00",
	middle: "#000000",
	bottom: "#964B00",
};
const bbFill = "#4682B4";

const macdAppearance = {
	stroke: {
		macd: "#FF0000",
		signal: "#00F300",
	},
	fill: {
		divergence: "#4682B4"
	},
};

const mouseEdgeAppearance = {
	textFill: "#542605",
	stroke: "#05233B",
	strokeOpacity: 1,
	strokeWidth: 3,
	arrowWidth: 5,
	fill: "#BCDEFA",
};

class CandleStickChartWithMA extends React.Component {
	render() {
		let { value7, value6, value10, value11, value12, value13, value, value1, value8, value9, value14, value15, canvas, canvas1 } = this.props;
		if (value7 == undefined) {
			value7 = 14;
		}
		const ema20 = ema()
			.options({
				windowSize: +value7, // optional will default to 10
//				sourcePath: "d.close", // optional will default to d.close as the source
			})
			.skipUndefined(true) // defaults to true
			.merge((d, c) => {d.ema20 = c;}) // Required, if not provided, log a error
			.accessor(d => d.ema20) // Required, if not provided, log an error during calculation
			.stroke("blue"); // Optional

		const sma20 = sma()
			.options({ windowSize: +value7 })
			.merge((d, c) => {d.sma20 = c;})
			.accessor(d => d.sma20);

		const sma50 = sma()
			.options({ windowSize: +value11 })
			.merge((d, c) => {d.sma50 = c;})
			.accessor(d => d.sma50);

		const sma90 = sma()
			.options({ windowSize: +value13 })
			.merge((d, c) => {d.sma90 = c;})
			.accessor(d => d.sma90);

		const wma20 = wma()
			.options({ windowSize: +value7 })
			.merge((d, c) => {d.wma20 = c;})
			.accessor(d => d.wma20);

		const wma50 = wma()
			.options({ windowSize: +value11 })
			.merge((d, c) => {d.wma50 = c;})
			.accessor(d => d.wma50);

		const wma90 = wma()
			.options({ windowSize: +value13 })
			.merge((d, c) => {d.wma90 = c;})
			.accessor(d => d.wma90);

		const tma20 = tma()
			.options({ windowSize: +value7 })
			.merge((d, c) => {d.tma20 = c;})
			.accessor(d => d.tma20);

		const tma50 = tma()
			.options({ windowSize: +value11 })
			.merge((d, c) => {d.tma50 = c;})
			.accessor(d => d.tma50);

		const tma90 = tma()
			.options({ windowSize: +value13 })
			.merge((d, c) => {d.tma90 = c;})
			.accessor(d => d.tma90);

		const ema50 = ema()
			.options({ windowSize: +value11 })
			.merge((d, c) => {d.ema50 = c;})
			.accessor(d => d.ema50);

		const ema90 = ema()
			.options({ windowSize: +value13 })
			.merge((d, c) => {d.ema90 = c;})
			.accessor(d => d.ema90);

		const macdCalculator = macd()
			.options({
				fast: 12,
				slow: 26,
				signal: 9,
			})
			.merge((d, c) => {d.macd = c;})
			.accessor(d => d.macd);

		const smaVolume50 = sma()
			.options({ windowSize: 20, sourcePath: "volume" })
			.merge((d, c) => {d.smaVolume50 = c;})
			.accessor(d => d.smaVolume50)
			.stroke("#4682B4")
			.fill("#4682B4");

		const bb = bollingerBand()
			.merge((d, c) => {d.bb = c;})
			.accessor(d => d.bb);

		const rsiCalculator = rsi()
			.options({ windowSize: +value7 })
			.merge((d, c) => {d.rsi = c;})
			.accessor(d => d.rsi);

		const atr14 = atr()
			.options({ windowSize: +value7 })
			.merge((d, c) => {d.atr14 = c;})
			.accessor(d => d.atr14);

		const fullSTO = stochasticOscillator()
			.options({ windowSize: +value7, kWindowSize: 3, dWindowSize: 4 })
			.merge((d, c) => {d.fullSTO = c;})
			.accessor(d => d.fullSTO);

	//стрелки BUY/SELL
		const buySell = algo()
		.windowSize(2)
		.accumulator(([prev, now]) => {
			const { ema20: prevShortTerm, ema50: prevLongTerm } = prev;
			const { ema20: nowShortTerm, ema50: nowLongTerm } = now;
//			if (prevShortTerm < prevLongTerm && nowShortTerm > nowLongTerm) return "LONG";
//			if (prevShortTerm > prevLongTerm && nowShortTerm < nowLongTerm) return "SHORT";
		})
		.merge((d, c) => { d.longShort = c; });

		const defaultAnnotationProps = {
			onClick: console.log.bind(console),
		};

		const longAnnotationProps = {
				...defaultAnnotationProps,
				y: ({ yScale, datum }) => yScale(datum.low),
				fill: "#006517",
				path: buyPath,
				tooltip: "Go long",
			};
	
		const shortAnnotationProps = {
				...defaultAnnotationProps,
				y: ({ yScale, datum }) => yScale(datum.high),
				fill: "#FF0000",
				path: sellPath,
				tooltip: "Go short",
			};


		const { type, data: initialData, width, ratio } = this.props;

		const calculatedData = buySell(ema20(wma20(wma50(wma90(ema50(ema90(sma20(sma50(sma90(tma20(tma50(tma90(macdCalculator(bb(rsiCalculator(atr14(fullSTO(smaVolume50(initialData)))))))))))))))))));
		const xScaleProvider = discontinuousTimeScaleProvider
			.inputDateAccessor(d => d.date);
		const {
			data,
			xScale,
			xAccessor,
			displayXAccessor,
		} = xScaleProvider(calculatedData);

		const start = xAccessor(last(data));
		const end = xAccessor(data[Math.max(0, data.length - 150)]);
		const xExtents = [start, end];

		return (
			<ChartCanvas height={canvas}
				width={width}
				ratio={ratio}
				margin={{ left: 70, right: 70, top: 10, bottom: 30 }}
				type={type}
				seriesName="MSFT"
				data={data}
				xScale={xScale}
				xAccessor={xAccessor}
				displayXAccessor={displayXAccessor}
				xExtents={xExtents}
			>
				<Chart id={1} height={400}
					yExtents={[d => [d.high, d.low]
						, sma20.accessor(), wma20.accessor(), tma20.accessor(), tma50.accessor(), ema20.accessor(), ema50.accessor(), bb.accessor()
]}
					padding={{ top: 10, bottom: 20 }}
				>
{/* tickStroke="#FFFFFF" - цвет для тёмной темы, для белой просто удалить этот параметр */}
					<XAxis axisAt="bottom" orient="bottom"/>
					<YAxis axisAt="right" orient="right" ticks={5}/>

{((value == "nizhe" || value == "vyshe") ? (<PriceCoordinate at="right" orient="right" price={+value1} stroke="#3490DC" strokeWidth={1} fill="#FFFFFF" textFill="#22292F" arrowWidth={7} strokeDasharray="ShortDash" displayFormat={format(".2f")}/>) : null )}
{((value8 == "nizhe1" || value8 == "vyshe1") ? (<PriceCoordinate at="right" orient="right" price={+value9} stroke="#3490DC" strokeWidth={1} fill="#FFFFFF" textFill="#22292F" arrowWidth={7} strokeDasharray="ShortDash" displayFormat={format(".2f")}/>) : null )}
{((value14 == "nizhe2" || value14 == "vyshe2") ? (<PriceCoordinate at="right" orient="right" price={+value15} stroke="#3490DC" strokeWidth={1} fill="#FFFFFF" textFill="#22292F" arrowWidth={7} strokeDasharray="ShortDash" displayFormat={format(".2f")}/>) : null )}
					<MouseCoordinateX
						at="bottom"
						orient="bottom"
//						displayFormat={timeFormat("%H:%M:%S")} />
						displayFormat={timeFormat("%Y-%m-%d")} />
					<MouseCoordinateY
						at="right"
						orient="right"
						displayFormat={format(".2f")} />
					<CandlestickSeries/>
{((value6 == "bb")	?	(<BollingerSeries yAccessor={d => d.bb} stroke={bbStroke} fill={bbFill}/>) : null )}
{((value6 == "sma")	?	(<LineSeries yAccessor={sma20.accessor()} stroke={sma20.stroke()}/>) : null )}
{((value10 == "sma")	?	(<LineSeries yAccessor={sma50.accessor()} stroke={sma50.stroke()}/>) : null )}
{((value12 == "sma")	?	(<LineSeries yAccessor={sma90.accessor()} stroke={sma90.stroke()}/>) : null )}
{((value6 == "wma")	?	(<LineSeries yAccessor={wma20.accessor()} stroke={wma20.stroke()}/>) : null )}
{((value10 == "wma")	?	(<LineSeries yAccessor={wma50.accessor()} stroke={wma50.stroke()}/>) : null )}
{((value12 == "wma")	?	(<LineSeries yAccessor={wma90.accessor()} stroke={wma90.stroke()}/>) : null )}
{((value6 == "tma")	?	(<LineSeries yAccessor={tma20.accessor()} stroke={tma20.stroke()}/>) : null )}
{((value10 == "tma")	?	(<LineSeries yAccessor={tma50.accessor()} stroke={tma50.stroke()}/>) : null )}
{((value12 == "tma")	?	(<LineSeries yAccessor={tma90.accessor()} stroke={tma90.stroke()}/>) : null )}
{((value6 == "ema")	?	(<LineSeries yAccessor={ema20.accessor()} stroke={ema20.stroke()}/>) : null )}
{((value10 == "ema")	?	(<LineSeries yAccessor={ema50.accessor()} stroke={ema50.stroke()}/>) : null )}
{((value12 == "ema")	?	(<LineSeries yAccessor={ema90.accessor()} stroke={ema90.stroke()}/>) : null )}
{/* точки на линиях при наведении на график */}
{((value6 == "sma")	?	(<CurrentCoordinate yAccessor={sma20.accessor()} fill={sma20.stroke()} />) : null )}
{((value10 == "sma")	?	(<CurrentCoordinate yAccessor={sma50.accessor()} fill={sma50.stroke()} />) : null )}
{((value12 == "sma")	?	(<CurrentCoordinate yAccessor={sma90.accessor()} fill={sma90.stroke()} />) : null )}
{((value6 == "wma")	?	(<CurrentCoordinate yAccessor={wma20.accessor()} fill={wma20.stroke()} />) : null )}
{((value10 == "wma")	?	(<CurrentCoordinate yAccessor={wma50.accessor()} fill={wma50.stroke()} />) : null )}
{((value12 == "wma")	?	(<CurrentCoordinate yAccessor={wma90.accessor()} fill={wma90.stroke()} />) : null )}
{((value6 == "tma")	?	(<CurrentCoordinate yAccessor={tma20.accessor()} fill={tma20.stroke()} />) : null )}
{((value10 == "tma")	?	(<CurrentCoordinate yAccessor={tma50.accessor()} fill={tma50.stroke()} />) : null )}
{((value12 == "tma")	?	(<CurrentCoordinate yAccessor={tma90.accessor()} fill={tma90.stroke()} />) : null )}
{((value6 == "ema")	?	(<CurrentCoordinate yAccessor={ema20.accessor()} fill={ema20.stroke()} />) : null )}
{((value10 == "ema")	?	(<CurrentCoordinate yAccessor={ema50.accessor()} fill={ema50.stroke()} />) : null )}
{((value12 == "ema")	?	(<CurrentCoordinate yAccessor={ema90.accessor()} fill={ema90.stroke()} />) : null )}
{/*EdgeIndicator - бегающий справа маркер, цены*/}
<EdgeIndicator itemType="last" orient="right" edgeAt="right"
						yAccessor={d => d.close} fill={d => d.close > d.open ? "#6BA583" : "#FF0000"}/>
					<OHLCTooltip origin={[-40, 0]} xDisplayFormat={timeFormat("%Y-%m-%d")} />
{/* 					<OHLCTooltip origin={[-40, 0]} xDisplayFormat={timeFormat("%Y-%m-%d %H:%M:%S")} />	*/}
{((value6 === "bb")	?	(<BollingerBandTooltip origin={[-38, 60]} yAccessor={d => d.bb} options={bb.options()} />) : null )}
{((value6 === "sma")	?	(<MovingAverageTooltip onClick={e => console.log(e)} origin={[-38, 15]} options={[{yAccessor: sma20.accessor(), type: "SMA", stroke: sma20.stroke(), windowSize: sma20.options().windowSize, echo: "some echo here", },]}/>) : null )}
{((value6 === "wma")	?	(<MovingAverageTooltip onClick={e => console.log(e)} origin={[-38, 15]}	options={[{yAccessor: wma20.accessor(), type: "WMA", stroke: wma20.stroke(), windowSize: wma20.options().windowSize, echo: "some echo here", },]}/>) : null )}
{((value6 === "ema")	?	(<MovingAverageTooltip onClick={e => console.log(e)} origin={[-38, 15]}	options={[{yAccessor: ema20.accessor(), type: "EMA", stroke: ema20.stroke(), windowSize: ema20.options().windowSize, echo: "some echo here", },]}/>) : null )}
{((value6 === "tma")	?	(<MovingAverageTooltip onClick={e => console.log(e)} origin={[-38, 15]}	options={[{yAccessor: tma20.accessor(), type: "TMA", stroke: tma20.stroke(), windowSize: tma20.options().windowSize, echo: "some echo here", },]}/>) : null )}
{((value10 === "ema")	?	(<MovingAverageTooltip onClick={e => console.log(e)} origin={[28, 15]}	options={[{yAccessor: ema50.accessor(), type: "EMA", stroke: ema50.stroke(), windowSize: ema50.options().windowSize, echo: "some echo here", },]}/>) : null )}
{((value10 === "sma")	?	(<MovingAverageTooltip onClick={e => console.log(e)} origin={[28, 15]}	options={[{yAccessor: sma50.accessor(), type: "SMA", stroke: sma50.stroke(), windowSize: sma50.options().windowSize, echo: "some echo here", },]}/>) : null )}
{((value10 === "tma")	?	(<MovingAverageTooltip onClick={e => console.log(e)} origin={[28, 15]}	options={[{yAccessor: tma50.accessor(), type: "TMA", stroke: tma50.stroke(), windowSize: tma50.options().windowSize, echo: "some echo here", },]}/>) : null )}
{((value10 === "wma")	?	(<MovingAverageTooltip onClick={e => console.log(e)} origin={[28, 15]}	options={[{yAccessor: wma50.accessor(), type: "WMA", stroke: wma50.stroke(), windowSize: wma50.options().windowSize, echo: "some echo here", },]}/>) : null )}
{((value12 === "ema")	?	(<MovingAverageTooltip onClick={e => console.log(e)} origin={[94, 15]}	options={[{yAccessor: ema90.accessor(), type: "EMA", stroke: ema90.stroke(), windowSize: ema90.options().windowSize, echo: "some echo here", },]}/>) : null )}
{((value12 === "sma")	?	(<MovingAverageTooltip onClick={e => console.log(e)} origin={[94, 15]}	options={[{yAccessor: sma90.accessor(), type: "SMA", stroke: sma90.stroke(), windowSize: sma90.options().windowSize, echo: "some echo here", },]}/>) : null )}
{((value12 === "tma")	?	(<MovingAverageTooltip onClick={e => console.log(e)} origin={[94, 15]}	options={[{yAccessor: tma90.accessor(), type: "TMA", stroke: tma90.stroke(), windowSize: tma90.options().windowSize, echo: "some echo here", },]}/>) : null )}
{((value12 === "wma")	?	(<MovingAverageTooltip onClick={e => console.log(e)} origin={[94, 15]}	options={[{yAccessor: wma90.accessor(), type: "WMA", stroke: wma90.stroke(), windowSize: wma90.options().windowSize, echo: "some echo here", },]}/>) : null )}
{/* 					<MovingAverageTooltip
						onClick={e => console.log(e)}
						origin={[-38, 15]}
						options={[
							{
								yAccessor: sma20.accessor(),
								type: "SMA",
								stroke: sma20.stroke(),
								windowSize: sma20.options().windowSize,
								echo: "some echo here",
							},
							{
								yAccessor: sma50.accessor(),
								type: "SMA",
								stroke: sma50.stroke(),
								windowSize: sma50.options().windowSize,
								echo: "some echo here",
							},
							{
								yAccessor: wma20.accessor(),
								type: "WMA",
								stroke: wma20.stroke(),
								windowSize: wma20.options().windowSize,
								echo: "some echo here",
							},
							{
								yAccessor: wma50.accessor(),
								type: "WMA",
								stroke: wma50.stroke(),
								windowSize: wma50.options().windowSize,
								echo: "some echo here",
							},
							{
								yAccessor: tma20.accessor(),
								type: "TMA",
								stroke: tma20.stroke(),
								windowSize: tma20.options().windowSize,
								echo: "some echo here",
							},
							{
								yAccessor: tma50.accessor(),
								type: "TMA",
								stroke: tma50.stroke(),
								windowSize: tma50.options().windowSize,
								echo: "some echo here",
							},
							{
								yAccessor: ema20.accessor(),
								type: "EMA",
								stroke: ema20.stroke(),
								windowSize: ema20.options().windowSize,
								echo: "some echo here",
							},
							{
								yAccessor: ema50.accessor(),
								type: "EMA",
								stroke: ema50.stroke(),
								windowSize: ema50.options().windowSize,
								echo: "some echo here",
							},
						]}
					/>*/}
					<Annotate with={SvgPathAnnotation} when={d => d.longShort === "LONG"}
						usingProps={longAnnotationProps} />
					<Annotate with={SvgPathAnnotation} when={d => d.longShort === "SHORT"}
						usingProps={shortAnnotationProps} />
				</Chart>
				<Chart id={2}
					yExtents={[d => d.volume
//						, smaVolume50.accessor()
]}
					height={150} origin={(w, h) => [0, h - canvas1]}
				>
					<YAxis axisAt="left" orient="left" ticks={5} tickFormat={format(".2s")}/>

					<MouseCoordinateX
						at="bottom"
						orient="bottom"
//						displayFormat={timeFormat("%H:%M:%S")} />
						displayFormat={timeFormat("%Y-%m-%d")} />

					<MouseCoordinateY
						at="left"
						orient="left"
						displayFormat={format(".4s")} />

					<BarSeries yAccessor={d => d.volume} fill={d => d.close > d.open ? "#6BA583" : "red"} />
{/*					<AreaSeries yAccessor={smaVolume50.accessor()} stroke={smaVolume50.stroke()} fill={smaVolume50.fill()}/>
					<CurrentCoordinate yAccessor={smaVolume50.accessor()} fill={smaVolume50.stroke()} /> */}
					<CurrentCoordinate yAccessor={d => d.volume} fill="#9B0A47" />
				</Chart>
				{((value6 === "macd" || value10 === "macd")	?	(<Chart id={3} height={150}
					yExtents={macdCalculator.accessor()}
					origin={(w, h) => [0, h - 240]} padding={{ top: 10, bottom: 10 }}
				>
					<XAxis axisAt="bottom" orient="bottom"/>
					<YAxis axisAt="right" orient="right" ticks={2}/>
					<MouseCoordinateX
						at="bottom"
						orient="bottom"
						displayFormat={timeFormat("%Y-%m-%d")}
						rectRadius={5}
						{...mouseEdgeAppearance}
					/>
					<MouseCoordinateY
						at="right"
						orient="right"
						displayFormat={format(".2f")}
						{...mouseEdgeAppearance}
					/>
					<MACDSeries yAccessor={d => d.macd} {...macdAppearance}/>
					<MACDTooltip origin={[-38, 15]} yAccessor={d => d.macd} options={macdCalculator.options()} appearance={macdAppearance}/>
				</Chart>) : null )}
				{((value6 === "rsi" || value6 === "stoch")	?	(<Chart id={3} height={150}
					yExtents={[0, 100]}
					origin={(w, h) => [0, h - 240]} padding={{ top: 10, bottom: 10 }}
				>
					<XAxis axisAt="bottom" orient="bottom"/>
					{((value6 === "rsi") ? (<YAxis axisAt="right" orient="right" tickValues={[30, 50, 70]}/>): null)}
					{((value6 === "stoch") ? (<YAxis axisAt="right" orient="right" tickValues={[20, 50, 80]}/>): null)}
					<MouseCoordinateX
						at="bottom"
						orient="bottom"
						displayFormat={timeFormat("%Y-%m-%d")} />
					<MouseCoordinateY
						at="right"
						orient="right"
						displayFormat={format(".2f")}
					/>
					{((value6 === "rsi") ? (<RSISeries yAccessor={d => d.rsi} />) : null)}
					{((value6 === "rsi") ? (<RSITooltip origin={[-38, 15]} yAccessor={d => d.rsi} options={rsiCalculator.options()}/>) : null)}
					{((value6 === "stoch") ? (<StochasticSeries yAccessor={d => d.fullSTO} {...stoAppearance}/>) : null)}
					{((value6 === "stoch") ? (<StochasticTooltip origin={[-38, 15]} yAccessor={d => d.fullSTO} options={fullSTO.options()} appearance={stoAppearance} label="Full STO"/>) : null)}
				</Chart>) : null )}
				{((value6 === "atr")	?	(<Chart id={3} height={150}
					yExtents={atr14.accessor()}
					origin={(w, h) => [0, h - 240]} padding={{ top: 10, bottom: 10 }}
				>
					<XAxis axisAt="bottom" orient="bottom"/>
					<YAxis axisAt="right" orient="right" ticks={2}/>
					<MouseCoordinateX
						at="bottom"
						orient="bottom"
						displayFormat={timeFormat("%Y-%m-%d")} />
					<MouseCoordinateY
						at="right"
						orient="right"
						displayFormat={format(".2f")}
					/>
					<LineSeries yAccessor={atr14.accessor()} stroke={atr14.stroke()}/>
					<SingleValueTooltip
						yAccessor={atr14.accessor()}
						yLabel={`ATR (${atr14.options().windowSize})`}
						yDisplayFormat={format(".2f")}
						/* valueStroke={atr14.stroke()} - optional prop */
						/* labelStroke="#4682B4" - optional prop */
						origin={[-40, 15]}/>
				</Chart>) : null )}
				<CrossHairCursor />
			</ChartCanvas>
		);
	}
}

CandleStickChartWithMA.propTypes = {
	data: PropTypes.array.isRequired,
	width: PropTypes.number.isRequired,
	ratio: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["svg", "hybrid"]).isRequired,
};

CandleStickChartWithMA.defaultProps = {
	type: "svg",
};
CandleStickChartWithMA = fitWidth(CandleStickChartWithMA);

export default CandleStickChartWithMA;