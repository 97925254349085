import React from 'react'
import {useState} from 'react'
import { BrowserRouter, Route, Link, Switch } from 'react-router-dom'
//import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Create from './Create'
import Login from './Login'
import Home from './Home'
import Read from './Read'
import Update from './Update'
import ChartComponent from './index1'
import Novice from './Novice'
import Master from './Master'
import Market from './Market'
import Strateg from './Strateg'
import Stoosma from './stoosma'
import Ok from './Ok'
import Errorpay from './Errorpay'
import Err404 from './Err404'

import kukoin from './img/New-Project2.png'
import bitfin from './img/New-Project3.png'
import bybit from './img/New-Project4.png'
import okex from './img/New-Project5.png'
import binan from './img/New-Project6.png'
import chal from './img/challenge-1.png'
import inn from './img/innovation-1.png'
import shield from './img/shield-1.png'
import stratbox from './img/pf-s73-eve-set-14-mockup-1.png'
import payments from './img/buy1w-2-e1684262574376.png'
import roket from './img/Group-281.png'
import vectorg from './img/Vector-14.png'
import vectorr from './img/Vector-13.png'
import crossr from './img/Group-121.png'
import check from './img/Group-123.png'
import tg from './img/tg.png'
import vk from './img/vk.png'
import logo from './img/New-Project.png'
import 'bootstrap/dist/css/bootstrap.min.css'

import './css/fontawesome.min.css'
import './css/frontend-lite.min.css'
import './css/frontend-lite.min1.css'
import './css/global.css'
import './css/elegant.css'
import './css/elementor-icons.min.css'
import './css/post-10.css'
import './css/solid.min.css'

import './css/themify.css'
import './css/widget-icon-list.min.css'
import './css/widget-loop-builder.min.css'
import './css/widget-nav-menu.min.css'
import './css/widget-styles.css'

//import './css/linearicons.css'
//import './css/post-2.css'
import './css/style.min.css'
import './css/theme.min.css'
import './css/post-5.css'
import './css/post-195.css'
import './css/post-205.css'
import './css/responsive.css'
import './css/swiper.min.css'


//import './css/ekiticons.css'

function logOut () {
	localStorage.removeItem("name");
	localStorage.removeItem("id");
	window.location.reload();
}

function App() {
const [tab1, setTab1] = useState(false);
const [tab2, setTab2] = useState(false);
const [tab3, setTab3] = useState(false);
const [tab4, setTab4] = useState(false);
const [tab5, setTab5] = useState(false);
const [truel, setTruel] = useState(true);
const [index, setIndex] = useState({ind:0});

if(index.ind == 0) {
	if (window.location.href !== 'http://smartex.site/') {
		setIndex({ind: 1});
		//	window.location.replace('http://smartex.site/root');
	}
}

function changeIndexPlus () {
	setIndex({ind: 1});
}

function changeIndexMinus () {
	setIndex({ind: 0});
}

function toggleMenu () {
	setTruel(!truel);
}

function toggleTab1 () {
	setTab1(!tab1);
}
function toggleTab2 () {
	setTab2(!tab2);
}
function toggleTab3 () {
	setTab3(!tab3);
}
function toggleTab4 () {
	setTab4(!tab4);
}
function toggleTab5 () {
	setTab5(!tab5);
}
    return (
        <BrowserRouter>
<div data-elementor-type="header" data-elementor-id="10" className='elementor elementor-10 elementor-location-header' data-elementor-post-type="elementor_library">
	<section className='elementor-section elementor-top-section elementor-element elementor-element-243f948 elementor-hidden-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="243f948" data-element_type="section">
		<div className='elementor-container elementor-column-gap-default'>
			<div className='elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-519b5e2' data-id="519b5e2" data-element_type="column">
		 	    <div className='elementor-widget-wrap elementor-element-populated'>
					<div className='elementor-element elementor-element-d660b07 elementor-widget-mobile__width-initial elementor-widget elementor-widget-image' data-id="d660b07" data-element_type="widget" data-widget_type="image.default">
				        <div className='elementor-widget-container'>
				            <a href="/">
							    <img width="110" height="114" src={logo} className='attachment-full size-full wp-image-651' alt="" />							
                            </a>
						</div>
				    </div>
				</div>
		    </div>
			<div className='elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-0eae127' data-id="0eae127" data-element_type="column">
			    <div className='elementor-widget-wrap elementor-element-populated'>
					<div className='elementor-element elementor-element-9546230 elementor-nav-menu__align-right elementor-nav-menu--stretch elementor-nav-menu--dropdown-tablet elementor-nav-menu__text-align-aside elementor-nav-menu--toggle elementor-nav-menu--burger elementor-widget elementor-widget-nav-menu' data-id="9546230" data-element_type="widget" data-widget_type="nav-menu.default">
				        <div className='elementor-widget-container'>
			                <nav className='elementor-nav-menu--main elementor-nav-menu__container elementor-nav-menu--layout-horizontal e--pointer-none'>
				                <ul id="menu-1-9546230" className='elementor-nav-menu'><li onClick={changeIndexMinus} className='menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-2 current_page_item menu-item-326'><Link to="/" className='elementor-item elementor-item-active' aria-current="page">Главная</Link></li>
                                    <li onClick={changeIndexPlus} className='menu-item menu-item-type-post_type menu-item-object-page menu-item-325'><Link className='elementor-item' to="/novice">Новичкам</Link></li>
                                    <li onClick={changeIndexPlus} className='menu-item menu-item-type-post_type menu-item-object-page menu-item-324'><Link className='elementor-item' to="/master">Профессионалам</Link></li>
                                    <li onClick={changeIndexPlus} className='menu-item menu-item-type-post_type menu-item-object-page menu-item-323'><Link className='elementor-item' to="/market">Маркетплейс</Link></li>
									<li onClick={changeIndexPlus} className='menu-item menu-item-type-post_type menu-item-object-page menu-item-323'><Link className='elementor-item' to="/strateg">Бесплатно</Link></li>
                                </ul>
                			</nav>
			            	<div onClick={toggleMenu} className={truel ? 'elementor-menu-toggle' : 'elementor-menu-toggle elementor-active'} role="button" tabIndex={0} aria-label="Menu Toggle" aria-expanded={truel ? 'false' : 'true'}>
		                        <i aria-hidden={truel ? 'true' : 'false'} role="presentation" className='elementor-menu-toggle__icon--open eicon-menu-bar'></i><i aria-hidden={truel ? 'true' : 'false'} role="presentation" className='elementor-menu-toggle__icon--close eicon-close'></i>			
                                    <span className='elementor-screen-only'>Menu</span>
		                    </div>
				                <nav className='elementor-nav-menu--dropdown elementor-nav-menu__container' aria-hidden={truel ? 'true' : 'false'}>
				                    <ul id="menu-2-9546230" className='elementor-nav-menu'><li onClick={changeIndexMinus} className='menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-2 current_page_item menu-item-326'><Link to="/" className='elementor-item elementor-item-active' tabIndex={-1} aria-current="page">Главная</Link></li>
										<li onClick={changeIndexPlus} className='menu-item menu-item-type-post_type menu-item-object-page menu-item-325'><Link className='elementor-item' tabIndex={-1} to="/novice">Новичкам</Link></li>
										<li onClick={changeIndexPlus} className='menu-item menu-item-type-post_type menu-item-object-page menu-item-324'><Link className='elementor-item' tabIndex={-1} to="/master">Профессионалам</Link></li>
										<li onClick={changeIndexPlus} className='menu-item menu-item-type-post_type menu-item-object-page menu-item-323'><Link className='elementor-item' tabIndex={-1} to="/market">Маркетплейс</Link></li>
										<li onClick={changeIndexPlus} className='menu-item menu-item-type-post_type menu-item-object-page menu-item-323'><Link className='elementor-item' tabIndex={-1} to="/strateg">Бесплатно</Link></li>
                                    </ul>
                                </nav>
				        </div>
				    </div>
				</div>
		    </div>
			{localStorage.getItem("name") ? <>
			<div className='elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-867e170' data-id="867e170" data-element_type="column">
				<div className='elementor-widget-wrap elementor-element-populated'>
					<div className='elementor-element elementor-element-0cb9ed5 elementor-widget elementor-widget-heading' data-id="0cb9ed5" data-element_type="widget" data-widget_type="heading.default">
						<div className='elementor-widget-container'>
						<span onClick={changeIndexPlus} className='elementor-heading-title elementor-size-default'><Link style={{color: '#FFFFFF'}} to={`/read/${localStorage.getItem("id")}`}>Инфо</Link></span>
							</div>
					</div>
				</div>
			</div>
			<div className='elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-867e170' data-id="867e170" data-element_type="column">
				<div className='elementor-widget-wrap elementor-element-populated'>
					<div className='elementor-element elementor-element-0cb9ed5 elementor-widget elementor-widget-heading' data-id="0cb9ed5" data-element_type="widget" data-widget_type="heading.default">
						<div className='elementor-widget-container'>
							<span className='elementor-heading-title elementor-size-default'><a style={{color: '#FFFFFF'}} onClick={logOut}>Выйти</a></span>
							</div>
					</div>
				</div>
			</div>
			</>
			: <>
			<div className='elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-867e170' data-id="867e170" data-element_type="column">
			    <div className='elementor-widget-wrap elementor-element-populated'>
					<div className='elementor-element elementor-element-0cb9ed5 elementor-widget elementor-widget-heading' data-id="0cb9ed5" data-element_type="widget" data-widget_type="heading.default">
			            <div className='elementor-widget-container'>
                            <span onClick={changeIndexPlus} className='elementor-heading-title elementor-size-default'><Link style={{color: '#FFFFFF'}} to="/create">Зарегистрироваться</Link></span>
                   		</div>
			        </div>
				</div>
		    </div>
			<div className='elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-867e170' data-id="867e170" data-element_type="column">
			    <div className='elementor-widget-wrap elementor-element-populated'>
					<div className='elementor-element elementor-element-0cb9ed5 elementor-widget elementor-widget-heading' data-id="0cb9ed5" data-element_type="widget" data-widget_type="heading.default">
			            <div className='elementor-widget-container'>
                            <span onClick={changeIndexPlus} className='elementor-heading-title elementor-size-default'><Link style={{color: '#FFFFFF'}} to="/login">Войти</Link></span>
                   		</div>
			        </div>
				</div>
		    </div>
			</>}
			<div className='elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-0478972' data-id="0478972" data-element_type="column">
			    <div className='elementor-widget-wrap elementor-element-populated'>
					<div className='elementor-element elementor-element-c72738d elementor-align-right elementor-widget elementor-widget-button' data-id="c72738d" data-element_type="widget" data-widget_type="button.default">
			            <div className='elementor-widget-container'>
			                <div onClick={changeIndexPlus} className='elementor-button-wrapper'>
			                    <Link className='elementor-button elementor-button-link elementor-size-sm' to="/main">
				                    <span className='elementor-button-content-wrapper'>
				                        <span className='elementor-button-text'>Начать</span>
		                            </span>
			                    </Link>
		                    </div>
			            </div>
			        </div>
			    </div>
		    </div>
		</div>
    </section>
</div>

<div>
<Switch>
    <Route path={"/main"}>
        <ChartComponent/>
    </Route>
    <Route path={'/home'}>
        <Home/>
    </Route>
    <Route path={'/novice'}>
        <Novice/>
    </Route>
	<Route path={'/master'}>
        <Master/>
    </Route>
	<Route path={'/market'}>
        <Market/>
    </Route>
	<Route path={'/strateg'}>
        <Strateg/>
    </Route>
	<Route path={'/stoosma'}>
        <Stoosma/>
    </Route>
    <Route path={'/create'}>
        <Create/>
    </Route>
    <Route path={'/login'}>
        <Login/>
    </Route>
	<Route path={'/ok'}>
        <Ok/>
    </Route>
	<Route path={'/error'}>
        <Errorpay/>
    </Route>
	<Route path={'/404'}>
        <Err404/>
    </Route>
    <Route path={'/update/:id'}>
        <Update/>
    </Route>
    <Route path={"/read/:id"}>
        <Read/>
    </Route>
</Switch>
{}
</div>
{index.ind == 0 ?
<>
	<div data-elementor-type="wp-page" data-elementor-id="2" className='elementor elementor-2' data-elementor-post-type="page">
	<section className='elementor-section elementor-top-section elementor-element elementor-element-97e8c04 elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="97e8c04" data-element_type="section" id="home" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
		<div className='elementor-background-overlay'></div>
		<div className='elementor-container elementor-column-gap-default'>
			<div className='elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4964962' data-id="4964962" data-element_type="column">
			    <div className='elementor-widget-wrap elementor-element-populated'>
					<div className='elementor-element elementor-element-49ff458 elementor-widget elementor-widget-heading' data-id="49ff458" data-element_type="widget" data-widget_type="heading.default">
        				<div className='elementor-widget-container'>
			                <h1 className='elementor-heading-title elementor-size-default'>Smartex - это платформа №1 для алгоритмической торговли. Не важно, новичок ты или профессионал трейдинга, у нас <span style={{background: '-webkit-linear-gradient(1830deg, #11D4DD 25%, #FC4FF6 71%) !important'}}>КАЖДЫЙ</span> сможет создать своего собственного торгового робота без знаний программирования, либо использовать уже готового и зарабатывать на нем</h1>		
                        </div>
    				</div>
	    			<div className='elementor-element elementor-element-5714fd3 elementor-widget__width-initial elementor-widget elementor-widget-heading' data-id="5714fd3" data-element_type="widget" data-widget_type="heading.default">
				        <div className='elementor-widget-container'>
			                <h1 className='elementor-heading-title elementor-size-default'>Использование стратегий алгоритмической торговли для получения <span style={{color: "#0FD5DD"}} >от 737% до 2270% ПРИБЫЛИ</span> с помощью собственного торгового робота.</h1>
                		</div>
			    	</div>
    				<section className='elementor-section elementor-inner-section elementor-element elementor-element-c786282 elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="c786282" data-element_type="section">
						<div className='elementor-container elementor-column-gap-default'>
        					<div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-9863c8a' data-id="9863c8a" data-element_type="column">
			                    <div className='elementor-widget-wrap elementor-element-populated'>
								    <div className='elementor-element elementor-element-da75cb8 elementor-align-right elementor-widget elementor-widget-button' data-id="da75cb8" data-element_type="widget" data-widget_type="button.default">
				                        <div className='elementor-widget-container'>
					                        <div onClick={changeIndexPlus} className='elementor-button-wrapper'>
			                                    <Link className='elementor-button elementor-button-link elementor-size-sm' to="/novice">
						                            <span className='elementor-button-content-wrapper'>
						                                <span className='elementor-button-text'>Новичкам</span>
		                                            </span>
					                            </Link>
		                                    </div>
				                        </div>
				                    </div>
					            </div>
		                    </div>
				            <div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-9172750' data-id="9172750" data-element_type="column">
			                    <div className='elementor-widget-wrap elementor-element-populated'>
								    <div className='elementor-element elementor-element-b1b11df elementor-align-left elementor-widget elementor-widget-button' data-id="b1b11df" data-element_type="widget" data-widget_type="button.default">
				                        <div className='elementor-widget-container'>
					                        <div className='elementor-button-wrapper'>
			                                    <Link onClick={changeIndexPlus} className='elementor-button elementor-button-link elementor-size-sm' to="/master">
						                            <span className='elementor-button-content-wrapper'>
						                                <span className='elementor-button-text'>Профессионалам</span>
		                                            </span>
					                            </Link>
		                                    </div>
				                        </div>
				                    </div>
					            </div>
		                    </div>
						</div>
             		</section>
				</div>
    		</div>
		</div>
	</section>
	<section className='elementor-section elementor-top-section elementor-element elementor-element-dde4f02 elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="dde4f02" data-element_type="section">
		<div className='elementor-container elementor-column-gap-default'>
			<div className='elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ef4a0a4' data-id="ef4a0a4" data-element_type="column">
		    	<div className='elementor-widget-wrap elementor-element-populated'>
					<div className='elementor-element elementor-element-54a391a elementor-widget elementor-widget-heading' data-id="54a391a" data-element_type="widget" data-widget_type="heading.default">
				        <div className='elementor-widget-container'>
			                <h3 className='elementor-heading-title elementor-size-default'>Нам доверяют <span style={{color: "#fff"}}>1000+</span> трейдеров из</h3>
                        </div>
				    </div>
				    <div className='elementor-element elementor-element-aba6c75 elementor-arrows-position-outside elementor-widget elementor-widget-image-carousel' data-id="aba6c75" data-element_type="widget" data-settings="{&quot;slides_to_show&quot;:&quot;4&quot;,&quot;navigation&quot;:&quot;arrows&quot;,&quot;image_spacing_custom&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:50,&quot;sizes&quot;:[]},&quot;slides_to_show_mobile&quot;:&quot;2&quot;,&quot;image_spacing_custom_mobile&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:10,&quot;sizes&quot;:[]},&quot;autoplay&quot;:&quot;yes&quot;,&quot;pause_on_hover&quot;:&quot;yes&quot;,&quot;pause_on_interaction&quot;:&quot;yes&quot;,&quot;autoplay_speed&quot;:5000,&quot;infinite&quot;:&quot;yes&quot;,&quot;speed&quot;:500,&quot;image_spacing_custom_tablet&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]}}" data-widget_type="image-carousel.default">
				        <div className='elementor-widget-container'>
			            	<div className='elementor-image-carousel-wrapper swiper' dir="ltr">
			                    <div className='elementor-image-carousel swiper-wrapper' aria-live="off">
								    <div className='swiper-slide' role="group" aria-roledescription="slide" aria-label="1 of 5"><figure className='swiper-slide-inner'><img decoding="async" className='swiper-slide-image' src={binan} alt="New Project(6)" /></figure></div><div className='swiper-slide' role="group" aria-roledescription="slide" aria-label="2 of 5"><figure className='swiper-slide-inner'><img decoding="async" className='swiper-slide-image' src={kukoin} alt="New Project(2)" /></figure></div><div className='swiper-slide' role="group" aria-roledescription="slide" aria-label="3 of 5"><figure className='swiper-slide-inner'><img decoding="async" className='swiper-slide-image' src={bitfin} alt="New Project(3)" /></figure></div><div className='swiper-slide' role="group" aria-roledescription="slide" aria-label="4 of 5"><figure className='swiper-slide-inner'><img decoding="async" className='swiper-slide-image' src={bybit} alt="New Project(4)" /></figure></div><div className='swiper-slide' role="group" aria-roledescription="slide" aria-label="5 of 5"><figure className='swiper-slide-inner'><img decoding="async" className='swiper-slide-image' src={okex} alt="New Project(5)" /></figure></div>
                                    </div>
									<div className='elementor-swiper-button elementor-swiper-button-prev' role="button" tabIndex={0}>
                     					<i aria-hidden="true" className=' arrow_carrot-left'></i>
                                    </div>
					                <div className='elementor-swiper-button elementor-swiper-button-next' role="button" tabIndex={0}>
						                <i aria-hidden="true" className=' arrow_carrot-right'></i>
                                    </div>
								</div>
				            </div>
				        </div>
					</div>
		        </div>
			</div>
		</section>
		<section className='elementor-section elementor-top-section elementor-element elementor-element-f6e9313 elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="f6e9313" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
			<div className='elementor-background-overlay'></div>
			<div className='elementor-container elementor-column-gap-default'>
				<div className='elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-a70ad99' data-id="a70ad99" data-element_type="column">
					<div className='elementor-widget-wrap elementor-element-populated'>
						<div className='elementor-element elementor-element-dcae5f2 elementor-widget elementor-widget-heading' data-id="dcae5f2" data-element_type="widget" data-widget_type="heading.default">
							<div className='elementor-widget-container'>
								<h2 className='elementor-heading-title elementor-size-default'>Особенности и Возможности</h2>
							</div>
						</div>
						<section className='elementor-section elementor-inner-section elementor-element elementor-element-5f0dabd elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="5f0dabd" data-element_type="section">
							<div className='elementor-container elementor-column-gap-default'>
								<div className='elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-9a20a72' data-id="9a20a72" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
									<div className='elementor-widget-wrap elementor-element-populated'>
										<div className='elementor-element elementor-element-6ecfa63 elementor-widget-mobile__width-initial elementor-widget elementor-widget-image' style={{textAlign: 'center'}} data-id="6ecfa63" data-element_type="widget" data-widget_type="image.default">
											<div className='elementor-widget-container'>
												<img loading="lazy" decoding="async" width="100" height="101" src={shield} className='attachment-full size-full wp-image-164' alt="" />
											</div>
										</div>
										<div className='elementor-element elementor-element-bce075b elementor-widget elementor-widget-heading' data-id="bce075b" data-element_type="widget" data-widget_type="heading.default">
											<div className='elementor-widget-container'>
												<h2 className='elementor-heading-title elementor-size-default'>Безопасно</h2>
											</div>
										</div>
										<div className='elementor-element elementor-element-65a370a elementor-widget elementor-widget-heading' data-id="65a370a" data-element_type="widget" data-widget_type="heading.default">
											<div className='elementor-widget-container'>
												<p className='elementor-heading-title elementor-size-default'>Все Ваши средства находятся в безопасности, так как все операции Вы проводите самостоятельно вне нашей платформы, роботы только дают Вам сигналы, в какой момент нужно купить или продать.</p>
											</div>
										</div>
									</div>
								</div>
								<div className='elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-ab2a416' data-id="ab2a416" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;gradient&quot;}">
									<div className='elementor-widget-wrap elementor-element-populated'>
										<div className='elementor-element elementor-element-d4c5750 elementor-widget-mobile__width-initial elementor-widget elementor-widget-image' style={{textAlign: 'center'}} data-id="d4c5750" data-element_type="widget" data-widget_type="image.default">
											<div className='elementor-widget-container'>
												<img loading="lazy" decoding="async" width="100" height="101" src={chal} className='attachment-full size-full wp-image-168' alt="" />
											</div>
										</div>
										<div className='elementor-element elementor-element-734eb66 elementor-widget elementor-widget-heading' data-id="734eb66" data-element_type="widget" data-widget_type="heading.default">
											<div className='elementor-widget-container'>
												<h2 className='elementor-heading-title elementor-size-default'>Легко</h2>
											</div>
										</div>
										<div className='elementor-element elementor-element-271fb68 elementor-widget elementor-widget-heading' data-id="271fb68" data-element_type="widget" data-widget_type="heading.default">
											<div className='elementor-widget-container'>
												<p className='elementor-heading-title elementor-size-default'>Попрощайтесь со сложностями криптовалютной торговли с помощью простой автоматизации. Наша удобная платформа и настраиваемые торговые параметры позволят Вам легко оптимизировать свои инвестиции, создав торгового робота без дополнительных знаний программирования.</p>
											</div>
										</div>
										<div className='elementor-element elementor-element-94bda3a elementor-align-center elementor-widget elementor-widget-button' data-id="94bda3a" data-element_type="widget" data-widget_type="button.default">
											<div className='elementor-widget-container'>
												<div className='elementor-button-wrapper'>
													<Link onClick={changeIndexPlus} className='elementor-button elementor-button-link elementor-size-sm' to="/main">
														<span className='elementor-button-content-wrapper'>
															<span className='elementor-button-text'>Начать</span>
														</span>
													</Link>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className='elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-90d2ad3' data-id="90d2ad3" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
									<div className='elementor-widget-wrap elementor-element-populated'>
										<div className='elementor-element elementor-element-40d9909 elementor-widget-mobile__width-initial elementor-widget elementor-widget-image' style={{textAlign: 'center'}} data-id="40d9909" data-element_type="widget" data-widget_type="image.default">
											<div className='elementor-widget-container' >
												<img loading="lazy" decoding="async" width="100" height="101" src={inn} className='attachment-full size-full wp-image-169' alt="" />
											</div>
										</div>
										<div className='elementor-element elementor-element-7926235 elementor-widget elementor-widget-heading' data-id="7926235" data-element_type="widget" data-widget_type="heading.default">
											<div className='elementor-widget-container'>
												<h2 className='elementor-heading-title elementor-size-default'>Прибыльно</h2>
											</div>
										</div>
										<div className='elementor-element elementor-element-3c56000 elementor-widget elementor-widget-heading' data-id="3c56000" data-element_type="widget" data-widget_type="heading.default">
											<div className='elementor-widget-container'>
												<p className='elementor-heading-title elementor-size-default'>Помимо инвестирования с помощью собственного торгового робота, Вы можете зарабатывать, выставив его на продажу на нашем Маркетплейсе и получая 30 000р с каждой продажи, либо предлагать другим зарегистрироваться с Вашим реферальным номером, тогда Вы будете получать 10% с их покупок.</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
					</div>
				</div>
			</div>
		</section>

		<section className='elementor-section elementor-top-section elementor-element elementor-element-7c2ad6d elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="7c2ad6d" data-element_type="section" id="contact-us" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
			<div className='elementor-container elementor-column-gap-default'>
				<div className='elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-095d340' data-id="095d340" data-element_type="column">
					<div className='elementor-widget-wrap elementor-element-populated'>
						<div className='elementor-element elementor-element-a3c9e3a elementor-widget elementor-widget-image' data-id="a3c9e3a" data-element_type="widget" data-widget_type="image.default">
							<div style={{textAlign: 'center', margin: '45px 45px'}} className='elementor-widget-container'>
								<img loading="lazy" decoding="async" width="657" height="105" src="/img/Frame-7.png" className='attachment-full size-full wp-image-532' alt="" srcSet='/img/Frame-7.png 657w, /img/Frame-7-300x48.png 300w' sizes="(max-width: 657px) 100vw, 657px" />
							</div>
						</div>
						{localStorage.getItem("name") ? null : 
						<section className='elementor-section elementor-inner-section elementor-element elementor-element-83d8ca5 elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="83d8ca5" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
							<div className='elementor-container elementor-column-gap-default'>
								<div className='elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-35bb662' data-id="35bb662" data-element_type="column">
									<div className='elementor-widget-wrap elementor-element-populated'>
										<div className='elementor-element elementor-element-6d81ddb elementor-widget elementor-widget-text-editor' data-id="6d81ddb" data-element_type="widget" data-widget_type="text-editor.default">
											<div className='elementor-widget-container'>
												<p>Готовы ли Вы вывести свой опыт криптовалютной торговли на новый уровень? Зарегистрируйтесь, чтобы начать оптимизировать свои инвестиции с помощью алгоритмических решений Smartex.</p>
											</div>
										</div>
										<div className='elementor-element elementor-element-9e52bf8 elementor-button-align-center elementor-widget elementor-widget-form' data-id="9e52bf8" data-element_type="widget" data-settings="{&quot;step_next_label&quot;:&quot;Next&quot;,&quot;step_previous_label&quot;:&quot;Previous&quot;,&quot;button_width&quot;:&quot;100&quot;,&quot;step_type&quot;:&quot;number_text&quot;,&quot;step_icon_shape&quot;:&quot;circle&quot;}" data-widget_type="form.default">
											<div className='elementor-widget-container'>
											<div className='elementor-field-group elementor-column elementor-field-type-submit elementor-col-100 e-form__buttons'>
															<button type="submit" className='elementor-button elementor-size-md'>
																<span >
																	<span className=' elementor-button-icon' href='/create'>
																	</span>
																	<span className='elementor-button-text'>Зарегистрироваться</span>
																</span>
															</button>
														</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
					}
						<div className='elementor-element elementor-element-197ab35 elementor-widget elementor-widget-heading' data-id="197ab35" data-element_type="widget" id="buy-now" data-widget_type="heading.default">
							<div className='elementor-widget-container'>
								<h1 className='elementor-heading-title elementor-size-default'>Самые популярные стратегии на Маркетплейсе</h1>
							</div>
						</div>
						<section className='elementor-section elementor-inner-section elementor-element elementor-element-e14ac5e elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="e14ac5e" data-element_type="section">
							<div className='elementor-container elementor-column-gap-default'>
								<div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-f83b7e0' data-id="f83b7e0" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
									<div className='elementor-widget-wrap elementor-element-populated'>
										<div className='elementor-element elementor-element-c4667db elementor-widget elementor-widget-heading' data-id="c4667db" data-element_type="widget" data-widget_type="heading.default">
											<div className='elementor-widget-container'>
												<h3 className='elementor-heading-title elementor-size-default'>БОЛЬШЕ ПРИБЫЛИ</h3>
											</div>
										</div>
										<div className='elementor-element elementor-element-90e6e75 elementor-widget-divider--view-line elementor-widget elementor-widget-divider' data-id="90e6e75" data-element_type="widget" data-widget_type="divider.default">
											<div className='elementor-widget-container'>
												<div className='elementor-divider'>
													<span className='elementor-divider-separator'>
													</span>
												</div>
											</div>
										</div>
										<div className='elementor-element elementor-element-92503d8 elementor-widget elementor-widget-image' data-id="92503d8" data-element_type="widget" data-widget_type="image.default">
											<div className='elementor-widget-container'>
												<img loading="lazy" decoding="async" width="1263" height="655" src={stratbox} className='attachment-full size-full wp-image-1684' alt="" srcSet='/img/pf-s73-eve-set-14-mockup-1.png 1263w, /img/pf-s73-eve-set-14-mockup-1-300x156.png 300w, /img/pf-s73-eve-set-14-mockup-1-1024x531.png 1024w, /img/pf-s73-eve-set-14-mockup-1-768x398.png 768w' sizes="(max-width: 1263px) 100vw, 1263px" />
											</div>
										</div>
										<section className='elementor-section elementor-inner-section elementor-element elementor-element-9511012 elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="9511012" data-element_type="section">
											<div className='elementor-container elementor-column-gap-default'>
												<div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-a7fcc14' data-id="a7fcc14" data-element_type="column">
													<div className='elementor-widget-wrap elementor-element-populated'>
														<div className='elementor-element elementor-element-eb82a9b elementor-widget elementor-widget-heading' data-id="eb82a9b" data-element_type="widget" data-widget_type="heading.default">
															<div className='elementor-widget-container'>
																<div className='elementor-heading-title elementor-size-default'>$999</div>
															</div>
														</div>
													</div>
												</div>
												<div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-12e0a8b' data-id="12e0a8b" data-element_type="column">
													<div className='elementor-widget-wrap elementor-element-populated'>
														<div className='elementor-element elementor-element-efc8b2f elementor-widget elementor-widget-heading' data-id="efc8b2f" data-element_type="widget" data-widget_type="heading.default">
															<div className='elementor-widget-container'>
																<h3 className='elementor-heading-title elementor-size-default'>ПОЖИЗНЕННЫЙ ДОСТУП</h3>
															</div>
														</div>
													</div>
												</div>
											</div>
										</section>
										<div className='elementor-element elementor-element-a8eb358 elementor-widget elementor-widget-heading' data-id="a8eb358" data-element_type="widget" data-widget_type="heading.default">
											<div className='elementor-widget-container'>
												<h3 className='elementor-heading-title elementor-size-default'>Разблокируйте пожизненный доступ к стратегии с максимальной прибылью</h3>
											</div>
										</div>
										<div className='elementor-element elementor-element-1ea9428 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list' data-id="1ea9428" data-element_type="widget" data-widget_type="icon-list.default">
											<div className='elementor-widget-container'>
												<ul className='elementor-icon-list-items' style={{listStyleType: 'none'}}>
													<li className='elementor-icon-list-item'>
														<span className='elementor-icon-list-icon'>
															<i aria-hidden="true" className='fas fa-crown'></i>
														</span>
														<span className='elementor-icon-list-text'>Торговая пара: BTC-USDT</span>
													</li>
													<li className='elementor-icon-list-item'>
														<span className='elementor-icon-list-icon'>
															<i aria-hidden="true" className='fas fa-crown'></i>
														</span>
														<span className='elementor-icon-list-text'>Общее количество сделок: 19</span>
													</li>
													<li className='elementor-icon-list-item'>
														<span className='elementor-icon-list-icon'>
															<i aria-hidden="true" className='fas fa-crown'></i>
														</span>
														<span className='elementor-icon-list-text'>Количество убыточных сделок: 3</span>
													</li>
													<li className='elementor-icon-list-item'>
														<span className='elementor-icon-list-icon'>
															<i aria-hidden="true" className='fas fa-crown'></i>
														</span>
														<span className='elementor-icon-list-text'>Прибыль за весь период: 2270%</span>
													</li>
													<li className='elementor-icon-list-item'>
														<span className='elementor-icon-list-icon'>
															<i aria-hidden="true" className='fas fa-crown'></i>
														</span>
														<span className='elementor-icon-list-text'>Максимальная Прибыль за сделку: 1762%</span>
													</li>
													<li className='elementor-icon-list-item'>
														<span className='elementor-icon-list-icon'>
															<i aria-hidden="true" className='fas fa-crown'></i>
														</span>
														<span className='elementor-icon-list-text'>Средняя Доходность за сделку: 225%</span>
													</li>
													<li className='elementor-icon-list-item'>
														<span className='elementor-icon-list-icon'>
															<i aria-hidden="true" className='fas fa-crown'></i>
														</span>
														<span className='elementor-icon-list-text'>Средняя ежемесячная Доходность: 24%</span>
													</li>
												</ul>
											</div>
										</div>
										<div className='elementor-element elementor-element-8790ff0 elementor-align-justify elementor-widget elementor-widget-button' data-id="8790ff0" data-element_type="widget" data-widget_type="button.default">
											<div className='elementor-widget-container'>
												<div className='elementor-button-wrapper'>
													<a className='elementor-button elementor-button-link elementor-size-sm'>
														<span className='elementor-button-content-wrapper'>
															<span className='elementor-button-text tooltip-toggle'>Узнать больше</span>
															<span class="tooltip-text">Свяжитесь с администратором, чтобы узнать больше: @teleg.</span>
														</span>
													</a>
												</div>
											</div>
										</div>
										<div className='elementor-element elementor-element-85af4a3 elementor-widget elementor-widget-image' style={{textAlign: 'center'}} data-id="85af4a3" data-element_type="widget" data-widget_type="image.default">
											<div className='elementor-widget-container'>
												<img loading="lazy" decoding="async" width="201" height="43" src={payments} className='attachment-full size-full wp-image-1685' alt="" />
											</div>
										</div>
									</div>
								</div>
								<div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-b8add57' data-id="b8add57" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;gradient&quot;}">
									<div className='elementor-widget-wrap elementor-element-populated'>
										<div className='elementor-element elementor-element-ce31d9e elementor-widget elementor-widget-heading' data-id="ce31d9e" data-element_type="widget" data-widget_type="heading.default">
											<div className='elementor-widget-container'>
												<h3 className='elementor-heading-title elementor-size-default'>БОЛЬШЕ БЕЗОПАСНОСТИ</h3>
											</div>
										</div>
										<div className='elementor-element elementor-element-7a580e1 elementor-widget-divider--view-line elementor-widget elementor-widget-divider' data-id="7a580e1" data-element_type="widget" data-widget_type="divider.default">
											<div className='elementor-widget-container'>
												<div className='elementor-divider'>
													<span className='elementor-divider-separator'>
													</span>
												</div>
											</div>
										</div>
										<div className='elementor-element elementor-element-f1c7d88 elementor-widget elementor-widget-image' data-id="f1c7d88" data-element_type="widget" data-widget_type="image.default">
											<div className='elementor-widget-container'>
												<img loading="lazy" decoding="async" width="430" height="223" src="/img/pf-s73-eve-set-14-mockup-3.png" className='attachment-full size-full wp-image-1686' alt="" srcset="/img/pf-s73-eve-set-14-mockup-3.png 430w, /img/pf-s73-eve-set-14-mockup-3-300x156.png 300w" sizes="(max-width: 430px) 100vw, 430px" />
											</div>
										</div>
										<section className='elementor-section elementor-inner-section elementor-element elementor-element-cad05c8 elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="cad05c8" data-element_type="section">
											<div className='elementor-container elementor-column-gap-default'>
												<div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-629424b' data-id="629424b" data-element_type="column">
													<div className='elementor-widget-wrap elementor-element-populated'>
														<div className='elementor-element elementor-element-f3bb58b elementor-widget elementor-widget-heading' data-id="f3bb58b" data-element_type="widget" data-widget_type="heading.default">
															<div className='elementor-widget-container'>
																<div className='elementor-heading-title elementor-size-default'>$999</div>
															</div>
														</div>
													</div>
												</div>
												<div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-6380fde' data-id="6380fde" data-element_type="column">
													<div className='elementor-widget-wrap elementor-element-populated'>
														<div className='elementor-element elementor-element-e84bdaf elementor-widget__width-initial elementor-hidden-mobile elementor-widget elementor-widget-heading' data-id="e84bdaf" data-element_type="widget" data-widget_type="heading.default">
															<div className='elementor-widget-container'>
																<h3 className='elementor-heading-title elementor-size-default'>ПОЖИЗНЕННЫЙ ДОСТУП</h3>
															</div>
														</div>
														<div className='elementor-element elementor-element-dbffea3 elementor-widget__width-initial elementor-hidden-desktop elementor-hidden-tablet elementor-widget elementor-widget-heading' data-id="dbffea3" data-element_type="widget" data-widget_type="heading.default">
															<div className='elementor-widget-container'>
																<h3 className='elementor-heading-title elementor-size-default'>LIFETIME ACCESS</h3>
															</div>
														</div>
													</div>
												</div>
											</div>
										</section>
										<div className='elementor-element elementor-element-748415f elementor-widget elementor-widget-heading' data-id="748415f" data-element_type="widget" data-widget_type="heading.default">
											<div className='elementor-widget-container'>
												<h3 className='elementor-heading-title elementor-size-default'>Разблокируйте пожизненный доступ к стратегии с максимальной безопасностью</h3>
											</div>
										</div>
										<div className='elementor-element elementor-element-a3649e7 elementor-align-left elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list' data-id="a3649e7" data-element_type="widget" data-widget_type="icon-list.default">
											<div className='elementor-widget-container'>
												<ul className='elementor-icon-list-items' style={{listStyleType: 'none'}}>
													<li className='elementor-icon-list-item'>
														<span className='elementor-icon-list-icon'>
															<i aria-hidden="true" className='fas fa-crown'></i>
														</span>
														<span className='elementor-icon-list-text'>Торговая пара: BTC-USDT</span>
													</li>
													<li className='elementor-icon-list-item'>
														<span className='elementor-icon-list-icon'>
															<i aria-hidden="true" className='fas fa-crown'></i>
														</span>
														<span className='elementor-icon-list-text'>Общее количество сделок: 14</span>
													</li>
													<li className='elementor-icon-list-item'>
														<span className='elementor-icon-list-icon'>
															<i aria-hidden="true" className='fas fa-crown'></i>
														</span>
														<span className='elementor-icon-list-text'>Количество убыточных сделок: 0</span>
													</li>
													<li className='elementor-icon-list-item'>
														<span className='elementor-icon-list-icon'>
															<i aria-hidden="true" className='fas fa-crown'></i>
														</span>
														<span className='elementor-icon-list-text'>Прибыль за весь период: 737%</span>
													</li>
													<li className='elementor-icon-list-item'>
														<span className='elementor-icon-list-icon'>
															<i aria-hidden="true" className='fas fa-crown'></i>
														</span>
														<span className='elementor-icon-list-text'>Максимальная Прибыль за сделку: 115%</span>
													</li>
													<li className='elementor-icon-list-item'>
														<span className='elementor-icon-list-icon'>
															<i aria-hidden="true" className='fas fa-crown'></i>
														</span>
														<span className='elementor-icon-list-text'>Средняя Доходность за сделку: 105%</span>
													</li>
													<li className='elementor-icon-list-item'>
														<span className='elementor-icon-list-icon'>
															<i aria-hidden="true" className='fas fa-crown'></i>
														</span>
														<span className='elementor-icon-list-text'>Средняя ежемесячная Доходность: 9%</span>
													</li>
												</ul>
											</div>
										</div>
										<div className='elementor-element elementor-element-2f3bcdf elementor-align-justify elementor-widget elementor-widget-button' data-id="2f3bcdf" data-element_type="widget" data-widget_type="button.default">
											<div className='elementor-widget-container'>
												<div className='elementor-button-wrapper'>
													<a className='elementor-button elementor-button-link elementor-size-sm'>
														<span className='elementor-button-content-wrapper'>
															<span className='elementor-button-text tooltip-toggle'>Узнать больше</span>
															<span class="tooltip-text">Свяжитесь с администратором, чтобы узнать больше: @teleg.</span>
														</span>
													</a>
												</div>
											</div>
										</div>
										<div className='elementor-element elementor-element-8311741 elementor-widget elementor-widget-image' style={{textAlign: 'center'}} data-id="8311741" data-element_type="widget" data-widget_type="image.default">
											<div className='elementor-widget-container'>
												<img loading="lazy" decoding="async" width="201" height="43" src={payments} className='attachment-full size-full wp-image-1685' alt="" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
					</div>
				</div>
			</div>
		</section>

		<section className='elementor-section elementor-top-section elementor-element elementor-element-893c776 elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="893c776" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
			<div className='elementor-background-overlay'></div>
				<div className='elementor-container elementor-column-gap-default'>
					<div className='elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-af805b7' data-id="af805b7" data-element_type="column">
						<div className='elementor-widget-wrap elementor-element-populated'>
							<section className='elementor-section elementor-inner-section elementor-element elementor-element-72761cc elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="72761cc" data-element_type="section">
								<div className='elementor-container elementor-column-gap-default'>
									<div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-1b044f5' data-id="1b044f5" data-element_type="column">
										<div className='elementor-widget-wrap elementor-element-populated'>
											<div className='elementor-element elementor-element-90eb214 elementor-widget elementor-widget-heading' data-id="90eb214" data-element_type="widget" data-widget_type="heading.default">
												<div className='elementor-widget-container'>
													<h2 className='elementor-heading-title elementor-size-default'>В чем преимущество</h2>
												</div>
											</div>
											<div className='elementor-element elementor-element-0eec64b elementor-widget elementor-widget-heading' data-id="0eec64b" data-element_type="widget" data-widget_type="heading.default">
												<div className='elementor-widget-container'>
													<h2 className='elementor-heading-title elementor-size-default'>Крипто?</h2>
												</div>
											</div>
										</div>
									</div>
									<div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-6f9a10a' data-id="6f9a10a" data-element_type="column">
										<div className='elementor-widget-wrap elementor-element-populated'>
											<section className='elementor-section elementor-inner-section elementor-element elementor-element-3358baf elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="3358baf" data-element_type="section">
												<div className='elementor-container elementor-column-gap-default'>
													<div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-2b9164d' data-id="2b9164d" data-element_type="column">
														<div className='elementor-widget-wrap elementor-element-populated'>
															<div className='elementor-element elementor-element-3d73ee5 elementor-widget elementor-widget-image' data-id="3d73ee5" data-element_type="widget" data-widget_type="image.default">
																<div className='elementor-widget-container'>
																	<img loading="lazy" decoding="async" width="41" height="42" src={crossr} className='attachment-full size-full wp-image-141' alt="" />
																</div>
															</div>
														</div>
													</div>
													<div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-0f79823' data-id="0f79823" data-element_type="column">
														<div className='elementor-widget-wrap elementor-element-populated'>
															<div className='elementor-element elementor-element-8afccb9 elementor-widget elementor-widget-heading' data-id="8afccb9" data-element_type="widget" data-widget_type="heading.default">
																<div className='elementor-widget-container'>
																	<h3 className='elementor-heading-title elementor-size-default'>Средняя медианная доходность недвижимости: 8,6% в год</h3>
																</div>
															</div>
															<div className='elementor-element elementor-element-0346a3a elementor-widget__width-initial elementor-widget elementor-widget-image' data-id="0346a3a" data-element_type="widget" data-widget_type="image.default">
																<div className='elementor-widget-container'>
																	<img loading="lazy" decoding="async" width="201" height="15" src={vectorr} className='attachment-full size-full wp-image-142' alt="" />
																</div>
															</div>
														</div>
													</div>
												</div>
											</section>
											<section className='elementor-section elementor-inner-section elementor-element elementor-element-bdf3443 elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="bdf3443" data-element_type="section">
												<div className='elementor-container elementor-column-gap-default'>
													<div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-ba19ad4' data-id="ba19ad4" data-element_type="column">
														<div className='elementor-widget-wrap elementor-element-populated'>
															<div className='elementor-element elementor-element-7da830f elementor-widget elementor-widget-image' data-id="7da830f" data-element_type="widget" data-widget_type="image.default">
																<div className='elementor-widget-container'>
																	<img loading="lazy" decoding="async" width="41" height="42" src={crossr} className='attachment-full size-full wp-image-141' alt="" />
																</div>
															</div>
														</div>
													</div>
													<div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-17cb11a' data-id="17cb11a" data-element_type="column">
														<div className='elementor-widget-wrap elementor-element-populated'>
															<div className='elementor-element elementor-element-804c9bd elementor-widget elementor-widget-heading' data-id="804c9bd" data-element_type="widget" data-widget_type="heading.default">
																<div className='elementor-widget-container'>
																	<h3 className='elementor-heading-title elementor-size-default'>Средняя медианная доходность на фондовом рынке: 10% в год</h3>
																</div>
															</div>
															<div className='elementor-element elementor-element-3c34975 elementor-widget__width-initial elementor-widget elementor-widget-image' data-id="3c34975" data-element_type="widget" data-widget_type="image.default">
																<div className='elementor-widget-container'>
																	<img loading="lazy" decoding="async" width="201" height="15" src={vectorr} className='attachment-full size-full wp-image-142' alt="" />
																</div>
															</div>
														</div>
													</div>
												</div>
											</section>
											<section className='elementor-section elementor-inner-section elementor-element elementor-element-f199b5c elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="f199b5c" data-element_type="section">
												<div className='elementor-container elementor-column-gap-default'>
													<div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-25ddf5f' data-id="25ddf5f" data-element_type="column">
														<div className='elementor-widget-wrap elementor-element-populated'>
															<div className='elementor-element elementor-element-b683d6c elementor-widget elementor-widget-image' data-id="b683d6c" data-element_type="widget" data-widget_type="image.default">
																<div className='elementor-widget-container'>
																	<img loading="lazy" decoding="async" width="46" height="46" src={check} className='attachment-full size-full wp-image-143' alt="" />
																</div>
															</div>
														</div>
													</div>
													<div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-c9c51ab' data-id="c9c51ab" data-element_type="column">
														<div className='elementor-widget-wrap elementor-element-populated'>
															<div className='elementor-element elementor-element-79b8240 elementor-widget elementor-widget-heading' data-id="79b8240" data-element_type="widget" data-widget_type="heading.default">
																<div className='elementor-widget-container'>
																	<h3 className='elementor-heading-title elementor-size-default'>Средняя медианная доходность на рынке криптовалют: 180% в год</h3>
																</div>
															</div>
															<div className='elementor-element elementor-element-4d91a3f elementor-widget__width-initial elementor-widget elementor-widget-image' data-id="4d91a3f" data-element_type="widget" data-widget_type="image.default">
																<div className='elementor-widget-container'>
																	<img loading="lazy" decoding="async" width="226" height="16" src={vectorg} className='attachment-full size-full wp-image-144' alt="" />
																</div>
															</div>
														</div>
													</div>
												</div>
											</section>
										</div>
									</div>
								</div>
							</section>
							<div className='elementor-element elementor-element-5b1dd2c elementor-widget-mobile__width-initial elementor-widget elementor-widget-image' data-id="5b1dd2c" data-element_type="widget" data-widget_type="image.default">
								<div className='elementor-widget-container'>
									<img loading="lazy" decoding="async" width="1526" height="803" src="/img/Group-119.png" className='attachment-full size-full wp-image-146' alt="" srcset="/img/Group-119.png 1526w, /img/Group-119-300x158.png 300w, /img/Group-119-1024x539.png 1024w, /img/Group-119-768x404.png 768w" sizes="(max-width: 1526px) 100vw, 1526px" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='elementor-section elementor-top-section elementor-element elementor-element-54b7be4 elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="54b7be4" data-element_type="section" id="about-us" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
				<div className='elementor-container elementor-column-gap-default'>
					<div className='elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-9c8e38e' data-id="9c8e38e" data-element_type="column">
						<div className='elementor-widget-wrap elementor-element-populated'>
							<div className='elementor-element elementor-element-7774a5f elementor-widget elementor-widget-image' data-id="7774a5f" data-element_type="widget" data-widget_type="image.default">
								<div className='elementor-widget-container'>
									<img loading="lazy" decoding="async" width="578" height="776" src="/img/Frame-1.png" className='attachment-full size-full wp-image-157' alt="" srcset="/img/Frame-1.png 578w, /img/Frame-1-223x300.png 223w" sizes="(max-width: 578px) 100vw, 578px" />
								</div>
							</div>
						</div>
					</div>
					<div className='elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-a6a6c54' data-id="a6a6c54" data-element_type="column">
						<div className='elementor-widget-wrap elementor-element-populated'>
							<div className='elementor-element elementor-element-81ad526 elementor-widget elementor-widget-heading' data-id="81ad526" data-element_type="widget" data-widget_type="heading.default">
								<div className='elementor-widget-container'>
									<h3 className='elementor-heading-title elementor-size-default'>Почему трейдеры выбирают Smartex?</h3>
								</div>
							</div>
							<div className='elementor-element elementor-element-35307bb elementor-widget-divider--view-line elementor-widget elementor-widget-divider' data-id="35307bb" data-element_type="widget" data-widget_type="divider.default">
								<div className='elementor-widget-container'>
									<div className='elementor-divider'>
										<span className='elementor-divider-separator'>
										</span>
									</div>
								</div>
							</div>
							<div className='elementor-element elementor-element-9846756 elementor-widget elementor-widget-heading' data-id="9846756" data-element_type="widget" data-widget_type="heading.default">
								<div className='elementor-widget-container'>
									<h2 className='elementor-heading-title elementor-size-default'>Расширение возможностей инвесторов с помощью алгоритмической торговли</h2>
								</div>
							</div>
							<section className='elementor-section elementor-inner-section elementor-element elementor-element-c40d181 elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="c40d181" data-element_type="section">
								<div className='elementor-container elementor-column-gap-default'>
									<div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-81ca593' data-id="81ca593" data-element_type="column">
										<div className='elementor-widget-wrap elementor-element-populated'>
											<div className='elementor-element elementor-element-ffab425 elementor-widget elementor-widget-image' data-id="ffab425" data-element_type="widget" data-widget_type="image.default">
												<div className='elementor-widget-container'>
													<img loading="lazy" decoding="async" width="37" height="37" src={roket} className='attachment-full size-full wp-image-158' alt="" />
												</div>
											</div>
										</div>
									</div>
									<div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-916c512' data-id="916c512" data-element_type="column">
										<div className='elementor-widget-wrap elementor-element-populated'>
											<div className='elementor-element elementor-element-c1eef42 elementor-widget elementor-widget-heading' data-id="c1eef42" data-element_type="widget" data-widget_type="heading.default">
												<div className='elementor-widget-container'>
													<h3 className='elementor-heading-title elementor-size-default'>Возможность создать свого робота на основе множества технических индикаторов, а не использовать непонятно какого робота без тестирования его прибыльности на всей истории.</h3>
												</div>
											</div>
										</div>
									</div>
								</div>
							</section>
							<section className='elementor-section elementor-inner-section elementor-element elementor-element-520a0d5 elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="520a0d5" data-element_type="section">
								<div className='elementor-container elementor-column-gap-default'>
									<div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-ed9a3e1' data-id="ed9a3e1" data-element_type="column">
										<div className='elementor-widget-wrap elementor-element-populated'>
											<div className='elementor-element elementor-element-2c60652 elementor-widget elementor-widget-image' data-id="2c60652" data-element_type="widget" data-widget_type="image.default">
												<div className='elementor-widget-container'>
													<img loading="lazy" decoding="async" width="37" height="37" src={roket} className='attachment-full size-full wp-image-158' alt="" />
												</div>
											</div>
										</div>
									</div>
									<div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-c34cc00' data-id="c34cc00" data-element_type="column">
										<div className='elementor-widget-wrap elementor-element-populated'>
											<div className='elementor-element elementor-element-047119d elementor-widget elementor-widget-heading' data-id="047119d" data-element_type="widget" data-widget_type="heading.default">
												<div className='elementor-widget-container'>
													<h3 className='elementor-heading-title elementor-size-default'>Возможность использовать готового робота с подробным тестом сделок на всей истории за несколько лет, а не сомнительного, работающиего по неизвестному алгоритму, который может быть доходным только в определенный период.</h3>
												</div>
											</div>
										</div>
									</div>
								</div>
							</section>
							<section className='elementor-section elementor-inner-section elementor-element elementor-element-e8a390b elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="e8a390b" data-element_type="section">
								<div className='elementor-container elementor-column-gap-default'>
									<div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-405f1c3' data-id="405f1c3" data-element_type="column">
										<div className='elementor-widget-wrap elementor-element-populated'>
											<div className='elementor-element elementor-element-1061717 elementor-widget elementor-widget-image' data-id="1061717" data-element_type="widget" data-widget_type="image.default">
												<div className='elementor-widget-container'>
													<img loading="lazy" decoding="async" width="37" height="37" src={roket} className='attachment-full size-full wp-image-158' alt="" />
												</div>
											</div>
										</div>
									</div>
									<div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-58ab4e1' data-id="58ab4e1" data-element_type="column">
										<div className='elementor-widget-wrap elementor-element-populated'>
											<div className='elementor-element elementor-element-281614c elementor-widget elementor-widget-heading' data-id="281614c" data-element_type="widget" data-widget_type="heading.default">
												<div className='elementor-widget-container'>
													<h3 className='elementor-heading-title elementor-size-default'>Возможность протестировать свою стратегию на графике с полной историей за несколько лет, а не за пару месяцев.</h3>
												</div>
											</div>
										</div>
									</div>
								</div>
							</section>
							<section className='elementor-section elementor-inner-section elementor-element elementor-element-e2080af elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="e2080af" data-element_type="section">
								<div className='elementor-container elementor-column-gap-default'>
									<div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-983f839' data-id="983f839" data-element_type="column">
										<div className='elementor-widget-wrap elementor-element-populated'>
											<div className='elementor-element elementor-element-fc70610 elementor-widget elementor-widget-image' data-id="fc70610" data-element_type="widget" data-widget_type="image.default">
												<div className='elementor-widget-container'>
													<img loading="lazy" decoding="async" width="37" height="37" src={roket} className='attachment-full size-full wp-image-158' alt="" />
												</div>
											</div>
										</div>
									</div>
									<div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-70424c2' data-id="70424c2" data-element_type="column">
										<div className='elementor-widget-wrap elementor-element-populated'>
											<div className='elementor-element elementor-element-12e9233 elementor-widget elementor-widget-heading' data-id="12e9233" data-element_type="widget" data-widget_type="heading.default">
												<div className='elementor-widget-container'>
													<h3 className='elementor-heading-title elementor-size-default'>Полная безопасность средств. Робот не торгует за Вас, а лишь дает точки покупки и продажи. Вы сами решаете, пользоваться ими или нет.</h3>
												</div>
											</div>
										</div>
									</div>
								</div>
							</section>
							<section className='elementor-section elementor-inner-section elementor-element elementor-element-8d43a7e elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="8d43a7e" data-element_type="section">
								<div className='elementor-container elementor-column-gap-default'>
									<div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-66b20a1' data-id="66b20a1" data-element_type="column">
										<div className='elementor-widget-wrap elementor-element-populated'>
											<div className='elementor-element elementor-element-782bd52 elementor-align-left elementor-mobile-align-right elementor-widget elementor-widget-button' data-id="782bd52" data-element_type="widget" data-widget_type="button.default">
												<div className='elementor-widget-container'>
													<div onClick={changeIndexPlus} className='elementor-button-wrapper'>
														<Link className='elementor-button elementor-button-link elementor-size-sm' to="/novice">
															<span className='elementor-button-content-wrapper'>
																<span className='elementor-button-text'>Новичкам</span>
															</span>
														</Link>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-c3193bb' data-id="c3193bb" data-element_type="column">
										<div className='elementor-widget-wrap elementor-element-populated'>
											<div className='elementor-element elementor-element-49353fe elementor-align-left elementor-widget elementor-widget-button' data-id="49353fe" data-element_type="widget" data-widget_type="button.default">
												<div className='elementor-widget-container'>
													<div onClick={changeIndexPlus} className='elementor-button-wrapper'>
														<Link className='elementor-button elementor-button-link elementor-size-sm' to="/master">
															<span className='elementor-button-content-wrapper'>
																<span className='elementor-button-text'>Профессионалам</span>
															</span>
														</Link>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</section>
						</div>
					</div>
				</div>
			</section>

			<section className='elementor-section elementor-top-section elementor-element elementor-element-1ef182e elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="1ef182e" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
				<div className='elementor-container elementor-column-gap-default'>
					<div className='elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-b84c016' data-id="b84c016" data-element_type="column">
						<div className='elementor-widget-wrap elementor-element-populated'>
							<div className='elementor-element elementor-element-219cfef elementor-widget elementor-widget-image' data-id="219cfef" data-element_type="widget" data-widget_type="image.default">
								<div className='elementor-widget-container'>
									<img loading="lazy" decoding="async" width="732" height="588" src="/img/Group-93.png" className='attachment-full size-full wp-image-177' alt="" srcset="/img/Group-93.png 732w, /img/Group-93-300x241.png 300w" sizes="(max-width: 732px) 100vw, 732px" />
								</div>
							</div>
						</div>
					</div>
					<div className='elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-54f6cc4' data-id="54f6cc4" data-element_type="column">
						<div className='elementor-widget-wrap elementor-element-populated'>
							<div className='elementor-element elementor-element-dacfb89 elementor-widget elementor-widget-heading' data-id="dacfb89" data-element_type="widget" data-widget_type="heading.default">
								<div className='elementor-widget-container'>
									<h3 className='elementor-heading-title elementor-size-default'>Алгоритмическая платформа Smartex</h3>
								</div>
							</div>
							<div className='elementor-element elementor-element-146b213 elementor-widget-divider--view-line elementor-widget elementor-widget-divider' data-id="146b213" data-element_type="widget" data-widget_type="divider.default">
								<div className='elementor-widget-container'>
									<div className='elementor-divider'>
										<span className='elementor-divider-separator'>
										</span>
									</div>
								</div>
							</div>
							<div className='elementor-element elementor-element-bbb914e elementor-widget elementor-widget-heading' data-id="bbb914e" data-element_type="widget" data-widget_type="heading.default">
								<div className='elementor-widget-container'>
									<h2 className='elementor-heading-title elementor-size-default'>Заработай на Маркетплейсе Smartex</h2>
								</div>
							</div>
							<div className='elementor-element elementor-element-a9e5be0 elementor-widget elementor-widget-heading' data-id="a9e5be0" data-element_type="widget" data-widget_type="heading.default">
								<div className='elementor-widget-container'>
									<p className='elementor-heading-title elementor-size-default'>Кроме создания и использования своего собственного робота, Вы можете зарабатывать, выставив его на продажу на нашем Маркетплейсе или получая пассивный доход с нашей реферальной программы!</p>
								</div>
							</div>
							<div className='elementor-element elementor-element-65eead9 elementor-widget elementor-widget-heading' data-id="65eead9" data-element_type="widget" data-widget_type="heading.default">
								<div className='elementor-widget-container'>
									<p className='elementor-heading-title elementor-size-default'>Мы создали платформу Smartex, чтобы предоставить максимально эффективное решение для автоматизации, которое упрощает торговлю криптовалютой, дает полный анализ стратегии на графике за несколько лет. Чтобы наши пользователи могли сосредоточиться на других своих делах и развлечениях, а об увеличении Ваших финансов и обеспечении финансово независимого будущего позаботимся мы.</p>
								</div>
							</div>
							<section className='elementor-section elementor-inner-section elementor-element elementor-element-63ebbcf elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="63ebbcf" data-element_type="section">
								<div className='elementor-container elementor-column-gap-default'>
									<div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-338231e' data-id="338231e" data-element_type="column">
										<div className='elementor-widget-wrap elementor-element-populated'>
											<div className='elementor-element elementor-element-6893467 elementor-align-left elementor-mobile-align-right elementor-widget elementor-widget-button' data-id="6893467" data-element_type="widget" data-widget_type="button.default">
												<div className='elementor-widget-container'>
													<div onClick={changeIndexPlus} className='elementor-button-wrapper'>
														<Link className='elementor-button elementor-button-link elementor-size-sm' to="/main">
															<span className='elementor-button-content-wrapper'>
																<span className='elementor-button-text'>Начать</span>
															</span>
														</Link>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</section>
						</div>
					</div>
				</div>
			</section>

			<section className='elementor-section elementor-top-section elementor-element elementor-element-337e113 elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="337e113" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;gradient&quot;}">
				<div className='elementor-container elementor-column-gap-default'>
					<div className='elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-28fbaa3' data-id="28fbaa3" data-element_type="column">
						<div className='elementor-widget-wrap elementor-element-populated'>
							<div className='elementor-element elementor-element-3bc497a elementor-widget elementor-widget-heading' data-id="3bc497a" data-element_type="widget" data-widget_type="heading.default">
								<div className='elementor-widget-container'>
									<h3 className='elementor-heading-title elementor-size-default'>Откройте для себя возможности алгоритмической торговли криптовалютами</h3>
								</div>
							</div>
							<div className='elementor-element elementor-element-6d1d6d0 elementor-widget elementor-widget-heading' data-id="6d1d6d0" data-element_type="widget" data-widget_type="heading.default">
								<div className='elementor-widget-container'>
									<h2 className='elementor-heading-title elementor-size-default'>Присоединяйся!</h2>
								</div>
							</div>
							<div className='elementor-element elementor-element-b3beb7f elementor-widget elementor-widget-heading' data-id="b3beb7f" data-element_type="widget" data-widget_type="heading.default">
								<div className='elementor-widget-container'>
									<p className='elementor-heading-title elementor-size-default'>К криптореволюции вместе со Smartex: зарегистрируйтесь сегодня, чтобы оптимизировать и протестировать свою торговую стратегию. Благодаря передовой технологии автоматизации и обновлениям в режиме реального времени добейтесь финансового успеха в динамичном мире торговли криптовалютой.</p>
								</div>
							</div>
							<section className='elementor-section elementor-inner-section elementor-element elementor-element-9919a67 elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="9919a67" data-element_type="section">
								<div className='elementor-container elementor-column-gap-default'>
									<div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-e7191c7' data-id="e7191c7" data-element_type="column">
										<div className='elementor-widget-wrap elementor-element-populated'>
											<div className='elementor-element elementor-element-81aa8bc elementor-align-left elementor-mobile-align-right elementor-widget elementor-widget-button' data-id="81aa8bc" data-element_type="widget" data-widget_type="button.default">
												<div className='elementor-widget-container'>
													<div onClick={changeIndexPlus} className='elementor-button-wrapper'>
														<Link className='elementor-button elementor-button-link elementor-size-sm' to="/create">
															<span className='elementor-button-content-wrapper'>
																<span className='elementor-button-text'>Зарегистрироваться</span>
															</span>
														</Link>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</section>
						</div>
					</div>
					<div className='elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-e21a26b' data-id="e21a26b" data-element_type="column">
						<div className='elementor-widget-wrap elementor-element-populated'>
							<div className='elementor-element elementor-element-ca90f1f elementor-widget elementor-widget-image' data-id="ca90f1f" data-element_type="widget" data-widget_type="image.default">
								<div className='elementor-widget-container'>
									<img loading="lazy" decoding="async" width="1195" height="685" src="/img/187-2-copy-2-1-2.png" className='attachment-full size-full wp-image-686' alt="" srcset="/img/187-2-copy-2-1-2.png 1195w, /img/187-2-copy-2-1-2-300x172.png 300w, /img/187-2-copy-2-1-2-1024x587.png 1024w, /img/187-2-copy-2-1-2-768x440.png 768w" sizes="(max-width: 1195px) 100vw, 1195px" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='elementor-section elementor-top-section elementor-element elementor-element-0775781 elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="0775781" data-element_type="section">
				<div className='elementor-container elementor-column-gap-default'>
					<div className='elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-a11128b' data-id="a11128b" data-element_type="column">
						<div className='elementor-widget-wrap elementor-element-populated'>
							<div className='elementor-element elementor-element-616c764 elementor-widget elementor-widget-heading' data-id="616c764" data-element_type="widget" data-widget_type="heading.default">
								<div className='elementor-widget-container'>
									<h2 className='elementor-heading-title elementor-size-default'>Часто Задаваемые Вопросы (ЧАВО)</h2>
								</div>
							</div>
							<div className='elementor-element elementor-element-d39a29f elementor-widget elementor-widget-toggle' data-id="d39a29f" data-element_type="widget" data-widget_type="toggle.default">
								<div className='elementor-widget-container'>
									<div className='elementor-toggle' style={{textAlign: "left"}}>
										<div className='elementor-toggle-item'>
											<div id="elementor-tab-title-2211" onClick={toggleTab1} className={tab1 ? 'elementor-tab-title elementor-active' : 'elementor-tab-title'} style={{fontWeight: '700', lineHeight: 1, margin: 0, padding: '15px', borderBottom: '1px solid #d5d8dc', cursor: 'pointer', outline: 'none'}} data-tab="1" role="button" aria-controls="elementor-tab-content-2211" aria-expanded={tab1 ? "true" : "false"}>
												<span className='elementor-toggle-icon elementor-toggle-icon-right' style={{display: 'inline-block', width: '1em', marginInlineStart: '-5px', width: '1em', height: '1em', float: 'right', textAlign: 'right'}} aria-hidden={tab1 ? 'false' : 'true'}>
													<span className='elementor-toggle-icon-closed' style={tab1 ? {display: 'none'} : {display: 'block'}}><i className=' ti-arrow-circle-down'></i></span>
													<span className='elementor-toggle-icon-opened' style={tab1 ? {display: 'block'} : {display: 'none'}}><i className='elementor-toggle-icon-opened  ti-arrow-circle-up'></i></span>
												</span>
												<a className='elementor-toggle-title' tabindex="0">Что мне нужно, чтобы начать?</a>
											</div>
											<div id="elementor-tab-content-2211" className={tab1 ? 'elementor-tab-content elementor-clearfix' : 'elementor-tab-content elementor-clearfix elementor-active'} style={{padding: '15px', borderBottom: '1px solid #d5d8dc'}} style={tab1 ? {display: 'block'} : {display: 'none'}} data-tab="1" role="region" aria-labelledby="elementor-tab-title-2211"><p>Учетная запись хотя бы на одной из следующих бирж с положительным балансом криптовалюты: Binance, OKX, Bybit, KuCoin, Bittrex, Coinbase, <a href="http://gate.io/">Gate.io</a>, Huobi, Kraken, Gemini, Deribit, Bitfinex, Bitstamp,<a href="http://crypto.com/">Crypto.com</a>.<br />Или криптовалютный кошелек USDT (TRC20). Например, <a href="https://www.tronlink.org/dlDetails/">TronLink</a> и той криптовалюты, на которую будет использовать Ваш торговый робот. Например, Bitcoin (BTC).</p></div>
											</div>
											<div className='elementor-toggle-item'>
												<div id="elementor-tab-title-2212" onClick={toggleTab2} className={tab2 ? 'elementor-tab-title elementor-active' : 'elementor-tab-title'} style={{fontWeight: '700', lineHeight: 1, margin: 0, padding: '15px', borderBottom: '1px solid #d5d8dc', cursor: 'pointer', outline: 'none'}} data-tab="2" role="button" aria-controls="elementor-tab-content-2212" aria-expanded={tab2 ? "true" : "false"}>
													<span className='elementor-toggle-icon elementor-toggle-icon-right' style={{display: 'inline-block', width: '1em', marginInlineStart: '-5px', width: '1em', height: '1em', float: 'right', textAlign: 'right'}} aria-hidden={tab2 ? 'false' : 'true'}>
														<span className='elementor-toggle-icon-closed'style={tab2 ? {display: 'none'} : {display: 'block'}}><i className=' ti-arrow-circle-down'></i></span>
														<span className='elementor-toggle-icon-opened'style={tab2 ? {display: 'block'} : {display: 'none'}}><i className='elementor-toggle-icon-opened  ti-arrow-circle-up'></i></span>
													</span>
													<a className='elementor-toggle-title' tabindex="0">Какова минимальная сумма для инвестирования?</a>
												</div>
												<div id="elementor-tab-content-2212" className={tab2 ? 'elementor-tab-content elementor-clearfix' : 'elementor-tab-content elementor-clearfix elementor-active'} style={{padding: '15px', borderBottom: '1px solid #d5d8dc'}} style={tab2 ? {display: 'block'} : {display: 'none'}} data-tab="2" role="region" aria-labelledby="elementor-tab-title-2212"><p>Минимальной суммы для инвестирования нет, вы можете начать с любой суммы, которую захотите. Тем не менее, мы рекомендуем вам инвестировать только то, чем вы готовы рискнуть.</p></div>
											</div>
											<div className='elementor-toggle-item'>
												<div id="elementor-tab-title-2213" onClick={toggleTab3} className={tab3 ? 'elementor-tab-title elementor-active' : 'elementor-tab-title'} style={{fontWeight: '700', lineHeight: 1, margin: 0, padding: '15px', borderBottom: '1px solid #d5d8dc', cursor: 'pointer', outline: 'none'}} data-tab="3" role="button" aria-controls="elementor-tab-content-2213" aria-expanded={tab3 ? "true" : "false"}>
													<span className='elementor-toggle-icon elementor-toggle-icon-right' style={{display: 'inline-block', width: '1em', marginInlineStart: '-5px', width: '1em', height: '1em', float: 'right', textAlign: 'right'}} aria-hidden={tab3 ? 'false' : 'true'}>
														<span className='elementor-toggle-icon-closed' style={tab3 ? {display: 'none'} : {display: 'block'}}><i className=' ti-arrow-circle-down'></i></span>
														<span className='elementor-toggle-icon-opened' style={tab3 ? {display: 'block'} : {display: 'none'}}><i className='elementor-toggle-icon-opened  ti-arrow-circle-up'></i></span>
													</span>
													<a className='elementor-toggle-title' tabindex="0">Если у меня возникнет проблема или вопрос, к кому я могу обратиться?</a>
												</div>
												<div id="elementor-tab-content-2213" className={tab3 ? 'elementor-tab-content elementor-clearfix' : 'elementor-tab-content elementor-clearfix elementor-active'} style={{padding: '15px', borderBottom: '1px solid #d5d8dc'}} style={tab3 ? {display: 'block'} : {display: 'none'}} data-tab="3" role="region" aria-labelledby="elementor-tab-title-2213"><p>Если у Вас возникнут какие-либо вопросы, Вы всегда можете их задать в нашем Телеграм чате.</p></div>
											</div>
											<div className='elementor-toggle-item'>
												<div id="elementor-tab-title-2216" onClick={toggleTab4} className={tab4 ? 'elementor-tab-title elementor-active' : 'elementor-tab-title'} style={{fontWeight: '700', lineHeight: 1, margin: 0, padding: '15px', borderBottom: '1px solid #d5d8dc', cursor: 'pointer', outline: 'none'}} data-tab="4" role="button" aria-controls="elementor-tab-content-2216" aria-expanded={tab4 ? "true" : "false"}>
													<span className='elementor-toggle-icon elementor-toggle-icon-right' style={{display: 'inline-block', width: '1em', marginInlineStart: '-5px', width: '1em', height: '1em', float: 'right', textAlign: 'right'}} aria-hidden={tab4 ? 'false' : 'true'}>
														<span className='elementor-toggle-icon-closed' style={tab4 ? {display: 'none'} : {display: 'block'}}><i className=' ti-arrow-circle-down'></i></span>
														<span className='elementor-toggle-icon-opened' style={tab4 ? {display: 'block'} : {display: 'none'}}><i className='elementor-toggle-icon-opened  ti-arrow-circle-up'></i></span>
													</span>
													<a className='elementor-toggle-title' tabindex="0">Как мне создать своего торгового робота?</a>
												</div>
												<div id="elementor-tab-content-2216" className={tab4 ? 'elementor-tab-content elementor-clearfix' : 'elementor-tab-content elementor-clearfix elementor-active'} style={{padding: '15px', borderBottom: '1px solid #d5d8dc'}} style={tab4 ? {display: 'block'} : {display: 'none'}} data-tab="5" role="region" aria-labelledby="elementor-tab-title-2216"><p>Если Вы новичок, то начните со страницы Новичкам, там пошагово разложены все этапы. Если Вы уже разбираетесь в инвестировании в криптовалюты, можете начать со страницы Профессионалам.</p></div>
											</div>
											<div className='elementor-toggle-item'>
												<div id="elementor-tab-title-2217" onClick={toggleTab5} className={tab5 ? 'elementor-tab-title elementor-active' : 'elementor-tab-title'} style={{fontWeight: '700', lineHeight: 1, margin: 0, padding: '15px', borderBottom: '1px solid #d5d8dc', cursor: 'pointer', outline: 'none'}} data-tab="6" role="button" aria-controls="elementor-tab-content-2217" aria-expanded={tab5 ? "true" : "false"}>
													<span className='elementor-toggle-icon elementor-toggle-icon-right' style={{display: 'inline-block', width: '1em', marginInlineStart: '-5px', width: '1em', height: '1em', float: 'right', textAlign: 'right'}} aria-hidden={tab5 ? 'false' : 'true'}>
														<span className='elementor-toggle-icon-closed' style={tab5 ? {display: 'none'} : {display: 'block'}}><i className=' ti-arrow-circle-down'></i></span>
														<span className='elementor-toggle-icon-opened' style={tab5 ? {display: 'block'} : {display: 'none'}}><i className='elementor-toggle-icon-opened  ti-arrow-circle-up'></i></span>
													</span>
													<a className='elementor-toggle-title' tabindex="0">Могу ли я заказать у Вас разработку индивидуального торгового робота?</a>
												</div>
												<div id="elementor-tab-content-2217" className={tab5 ? 'elementor-tab-content elementor-clearfix' : 'elementor-tab-content elementor-clearfix elementor-active'} style={{padding: '15px', borderBottom: '1px solid #d5d8dc'}} style={tab5 ? {display: 'block'} : {display: 'none'}} data-tab="7" role="region" aria-labelledby="elementor-tab-title-2217"><p>Да, Вы можете написать на по этому вопросу в Телеграм.</p></div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

</div>



<div data-elementor-type="footer" data-elementor-id="195" classList='elementor elementor-195 elementor-location-footer' data-elementor-post-type="elementor_library">
	<section classList='elementor-section elementor-top-section elementor-element elementor-element-477686a elementor-hidden-desctop elementor-hidden-tablet elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="477686a" data-element_type="section">
		<div classList='elementor-container elementor-column-gap-default'>
			<div classList='elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-90772f7' data-id="90772f7" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
				<div classList='elementor-widget-wrap elementor-element-populated'>
					<section classList='elementor-section elementor-inner-section elementor-element elementor-element-8ae67eb elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="8ae67eb" data-element_type="section">
						<div classList='elementor-container elementor-column-gap-default'>
							<div classList='elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-6dd66cc' data-id="6dd66cc" data-element_type="column">
								<div classList='elementor-widget-wrap elementor-element-populated'>
									<div classList='elementor-element elementor-element-8da5d3f elementor-icon-list--layout-inline elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list' data-id="8da5d3f" data-element_type="widget" data-widget_type="icon-list.default">
										<div classList='elementor-widget-container'>


										<section className='elementor-section elementor-top-section elementor-element elementor-element-243f948 elementor-hidden-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="243f948" data-element_type="section">
		<div className='elementor-container elementor-column-gap-default'>
			<div className='elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-519b5e2' data-id="519b5e2" data-element_type="column">
		 	    <div className='elementor-widget-wrap elementor-element-populated'>
					<div className='elementor-element elementor-element-d660b07 elementor-widget-mobile__width-initial elementor-widget elementor-widget-image' data-id="d660b07" data-element_type="widget" data-widget_type="image.default">
				        <div className='elementor-widget-container'>
				            <a href="/">
							    <img width="110" height="114" src={logo} className='attachment-full size-full wp-image-651' alt="" />							
                            </a>
						</div>
				    </div>
				</div>
		    </div>
			<div className='elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-0eae127' data-id="0eae127" data-element_type="column">
			    <div className='elementor-widget-wrap elementor-element-populated'>
					<div className='elementor-element elementor-element-9546230 elementor-nav-menu__align-right elementor-nav-menu--stretch elementor-nav-menu--dropdown-tablet elementor-nav-menu__text-align-aside elementor-nav-menu--toggle elementor-nav-menu--burger elementor-widget elementor-widget-nav-menu' data-id="9546230" data-element_type="widget" data-widget_type="nav-menu.default">
				        <div className='elementor-widget-container'>
							<ul style={{ display: 'flex', justifyContent: 'center'}} id="menu-1-9546230" className='elementor-nav-menu'>
								<li onClick={changeIndexMinus} style={{color: '#FFFFFF'}} className='menu-item menu-item-type-post_type menu-item-object-page menu-item-326'><Link className='elementor-item' to="/" >Главная</Link></li>
								<li onClick={changeIndexPlus} className='menu-item menu-item-type-post_type menu-item-object-page menu-item-325'><Link className='elementor-item' to="/novice">Новичкам</Link></li>
								<li onClick={changeIndexPlus} className='menu-item menu-item-type-post_type menu-item-object-page menu-item-324'><Link className='elementor-item' to="/master">Профессионалам</Link></li>
								<li onClick={changeIndexPlus} className='menu-item menu-item-type-post_type menu-item-object-page menu-item-323'><Link className='elementor-item' to="/market">Маркетплейс</Link></li>
								<li onClick={changeIndexPlus} className='menu-item menu-item-type-post_type menu-item-object-page menu-item-322'><Link className='elementor-item' to="/strateg">Бесплатно</Link></li>
							</ul>
				        </div>
				    </div>
				</div>
		    </div>

		</div>
    </section>

{/* 
											<ul style={{justifyContent: 'center', display: 'flex'}} classList='elementor-icon-list-items elementor-inline-items'>
												<li classList='elementor-icon-list-item elementor-inline-item'>
													<a href="/root">
														<span style={{color: '#FFFFFF', transition: '0.3s'}} classList='elementor-icon-list-text'>Главная</span>
													</a>
												</li>
												<li classList='elementor-icon-list-item elementor-inline-item'>
													<a href="/about-us/">
														<span classList='elementor-icon-list-text'>Новичкам</span>
													</a>
												</li>
												<li classList='elementor-icon-list-item elementor-inline-item'>
													<a href="/leave-us-a-review/">
														<span classList='elementor-icon-list-text'>Профессионалам</span>
													</a>
												</li>
												<li classList='elementor-icon-list-item elementor-inline-item'>
													<a href="/leave-us-a-review/">
														<span classList='elementor-icon-list-text'>Маркетплейс</span>
													</a>
												</li>
												<li classList='elementor-icon-list-item elementor-inline-item'>
													<a href="/contact-us/">
														<span classList='elementor-icon-list-text'>Регистрация</span>
													</a>
												</li>
											</ul>
											*/}
										</div>
									</div>
								</div>
							</div>

						</div>
					</section>
{/*
				<section classList=''"elementor-section elementor-inner-section elementor-element elementor-element-e27e103 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="e27e103" data-element_type="section">
						<div classList=''"elementor-container elementor-column-gap-default">
					<div classList=''"elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-ca8882d" data-id="ca8882d" data-element_type="column">
			<div classList=''"elementor-widget-wrap elementor-element-populated">
								<div classList=''"elementor-element elementor-element-52e53a8 elementor-widget elementor-widget-heading" data-id="52e53a8" data-element_type="widget" data-widget_type="heading.default">
				<div classList=''"elementor-widget-container">
			<p classList=''"elementor-heading-title elementor-size-default">@2024 Crypto All rights reserved.</p>		</div>
				</div>
					</div>
		</div>
				<div classList=''"elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-dc7df50" data-id="dc7df50" data-element_type="column">
			<div classList=''"elementor-widget-wrap elementor-element-populated">
								<div classList=''"elementor-element elementor-element-bbde6e6 elementor-widget elementor-widget-image" data-id="bbde6e6" data-element_type="widget" data-widget_type="image.default">
				<div classList=''"elementor-widget-container">
																<a href="https://apexbot.ai">
							<img width="110" height="114" src="img/New-Project.png" classList=''"attachment-full size-full wp-image-651" alt="" loading="lazy" />								</a>
															</div>
				</div>
					</div>
		</div>
				<div classList=''"elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-0540ee3" data-id="0540ee3" data-element_type="column">
			<div classList=''"elementor-widget-wrap elementor-element-populated">
								<div classList=''"elementor-element elementor-element-c8cdb96 elementor-icon-list--layout-inline elementor-align-right elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="c8cdb96" data-element_type="widget" data-widget_type="icon-list.default">
				<div classList=''"elementor-widget-container">
					<ul classList=''"elementor-icon-list-items elementor-inline-items">
							<li classList=''"elementor-icon-list-item elementor-inline-item">
											<a href="https://www.wealthyleads.com/wl-terms" target="_blank">

											<span classList=''"elementor-icon-list-text">Terms of service</span>
											</a>
									</li>
								<li classList=''"elementor-icon-list-item elementor-inline-item">
											<a href="https://www.wealthyleads.com/wl-privacy" target="_blank">

											<span classList=''"elementor-icon-list-text">Privacy Policy</span>
											</a>
									</li>
						</ul>
				</div>
				</div>
					</div>
		</div>
							</div>
		</section>
					</div>
		</div>
							</div>
		</section>
				<section classList=''"elementor-section elementor-top-section elementor-element elementor-element-477686a elementor-hidden-desktop elementor-hidden-tablet elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="477686a" data-element_type="section">
						<div classList=''"elementor-container elementor-column-gap-default">
					<div classList=''"elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-90772f7" data-id="90772f7" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
			<div classList=''"elementor-widget-wrap elementor-element-populated">
								<section classList=''"elementor-section elementor-inner-section elementor-element elementor-element-8ae67eb elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="8ae67eb" data-element_type="section">
						<div classList=''"elementor-container elementor-column-gap-default">
					<div classList=''"elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-6dd66cc" data-id="6dd66cc" data-element_type="column">
			<div classList=''"elementor-widget-wrap elementor-element-populated">
								<div classList=''"elementor-element elementor-element-39775f7 elementor-widget-mobile__width-initial elementor-widget elementor-widget-image" data-id="39775f7" data-element_type="widget" data-widget_type="image.default">
				<div classList=''"elementor-widget-container">
																<a href="https://apexbot.ai">
							<img width="110" height="114" src="img/New-Project.png" classList=''"attachment-full size-full wp-image-651" alt="" loading="lazy" />								</a>
															</div>
				</div>
					</div>
		</div>
				<div classList=''"elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-8b837d2" data-id="8b837d2" data-element_type="column">
			<div classList=''"elementor-widget-wrap elementor-element-populated">
								<div classList=''"elementor-element elementor-element-c3de738 elementor-icon-list--layout-inline elementor-mobile-align-center elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="c3de738" data-element_type="widget" data-widget_type="icon-list.default">
				<div classList=''"elementor-widget-container">
					<ul classList=''"elementor-icon-list-items elementor-inline-items">
							<li classList=''"elementor-icon-list-item elementor-inline-item">
											<a href="https://apexbot.ai">

											<span classList=''"elementor-icon-list-text">Home</span>
											</a>
									</li>
								<li classList=''"elementor-icon-list-item elementor-inline-item">
											<a href="/about-us/">

											<span classList=''"elementor-icon-list-text">About Us</span>
											</a>
									</li>
								<li classList=''"elementor-icon-list-item elementor-inline-item">
											<a href="/leave-us-a-review/">

											<span classList=''"elementor-icon-list-text">Leave us a Review</span>
											</a>
									</li>
								<li classList=''"elementor-icon-list-item elementor-inline-item">
											<a href="/contact-us/">

											<span classList=''"elementor-icon-list-text">Contact Us</span>
											</a>
									</li>
								<li classList=''"elementor-icon-list-item elementor-inline-item">
											<a href="https://www.wealthyleads.com/apex-bot-member-dashboard1702667651304">

											<span classList=''"elementor-icon-list-text">Member Login</span>
											</a>
									</li>
						</ul>
				</div>
				</div>
					</div>
		</div>
				<div classList=''"elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-8f359b8" data-id="8f359b8" data-element_type="column">
			<div classList=''"elementor-widget-wrap elementor-element-populated">
								<div classList=''"elementor-element elementor-element-b6f7abf elementor-align-right elementor-mobile-align-center elementor-widget elementor-widget-button" data-id="b6f7abf" data-element_type="widget" data-widget_type="button.default">
				<div classList=''"elementor-widget-container">
					<div classList=''"elementor-button-wrapper">
			<a classList=''"elementor-button elementor-button-link elementor-size-sm" href="/contact-us/">
						<span classList=''"elementor-button-content-wrapper">
						<span classList=''"elementor-button-text">Contact Us</span>
		</span>
					</a>
		</div>
				</div>
				</div>
					</div>
		</div>
							</div>
		</section>
				<section classList=''"elementor-section elementor-inner-section elementor-element elementor-element-5ac9a0c elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="5ac9a0c" data-element_type="section">
						<div classList=''"elementor-container elementor-column-gap-default">
					<div classList=''"elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-e51a6e1" data-id="e51a6e1" data-element_type="column">
			<div classList=''"elementor-widget-wrap elementor-element-populated">
								<div classList=''"elementor-element elementor-element-ea6442a elementor-widget elementor-widget-heading" data-id="ea6442a" data-element_type="widget" data-widget_type="heading.default">
				<div classList=''"elementor-widget-container">
			<p classList=''"elementor-heading-title elementor-size-default">@2023 Crypto All rights reserved.</p>		</div>
				</div>
					</div>
		</div>
				<div classList=''"elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-05037ea" data-id="05037ea" data-element_type="column">
			<div classList=''"elementor-widget-wrap elementor-element-populated">
								<div classList=''"elementor-element elementor-element-5ce4fcc elementor-icon-list--layout-inline elementor-align-right elementor-mobile-align-center elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="5ce4fcc" data-element_type="widget" data-widget_type="icon-list.default">
				<div classList=''"elementor-widget-container">
					<ul classList=''"elementor-icon-list-items elementor-inline-items">
							<li classList=''"elementor-icon-list-item elementor-inline-item">
											<a href="https://www.wealthyleads.com/wl-terms" target="_blank">

											<span classList=''"elementor-icon-list-text">Terms of service</span>
											</a>
									</li>
								<li classList=''"elementor-icon-list-item elementor-inline-item">
											<a href="https://www.wealthyleads.com/wl-privacy" target="_blank">

											<span classList=''"elementor-icon-list-text">Privacy Policy</span>
											</a>
									</li>
						</ul>
				</div>
				</div>
					</div>
		</div>
							</div>
		</section>
							 */}					
				</div>
			</div>
		</div>
	</section>
	<div classList="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-e51a6e1" data-id="e51a6e1" data-element_type="column">
		<div classList="elementor-widget-wrap elementor-element-populated">
			<div classList="elementor-element elementor-element-ea6442a elementor-widget elementor-widget-heading" data-id="ea6442a" data-element_type="widget" data-widget_type="heading.default">
				<div style={{display: 'flex', justifyContent: 'center'}} classList="elementor-widget-container">
				<a href='https://vk.com/bus_invest'><img style={{width: "150px", justifyContent: 'left', margin: '15px'}} src={vk}></img></a>	<a href='https://t.me/+TnkKgXanxZQs4p8c'><img style={{width: "150px", justifyContent: 'right', margin: '15px'}} src={tg}></img></a></div>
				<div style={{display: 'flex', justifyContent: 'center'}} classList="elementor-widget-container">
					<p style={{color: '#FFFFFF'}} classList="elementor-heading-title elementor-size-default">@2024 Crypto All rights reserved.</p>		</div>
				</div>
			</div>
		</div>

</div>
</>
: null}
			</BrowserRouter>
  )}

export default App