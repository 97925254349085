import axios from 'axios';
import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';

function Login() {
    let history = useHistory();
    const [data, setData] = useState([]);
    const [inputData, setInputData] = useState({
        imya: '',
        pass: ''
        })
    let connect = 0;
    useEffect(()=> {
        axios.get('http://smartex.site/api/users/')
            .then(res => setData(res.data))
            .catch(err => console.log(err))
            }, [])
    const handleSubmit = (event) => {
        event.preventDefault();
                axios.get('http://smartex.site/api/users/')
                    .then(res => setData(res.data))
                    .catch(err => console.log(err))
                    .then (console.log(data))
                    .then(data.map((d, i)=> (d.name == inputData.imya && d.password == inputData.pass ? (history.push(`/`), connect = d.id, localStorage.setItem("name", inputData.imya), localStorage.setItem("id", d.id), location.reload()) : null)))
                    .then(connect == 0 ? alert("Такой пользователь не найден!") : null)
                                    }
                                    const [isCaptchaSuccessful, setIsCaptchaSuccess] = React.useState(false)

  function onChange(value) {
    setIsCaptchaSuccess(true)
//    console.log("captcha value: ", value);
  }
  return (
    <div className='d-flex w-100 vh-100 justify-content-center align-items-center'>
        <div className='w-50 border bg-secondary text-white p-5'>
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="name">Имя:</label>
                          <input type="text" name='name' className='form-control'     onChange={e => setInputData({...inputData, imya: e.target.value})}/>
                          </div> 
                             <div>
                                <label htmlFor="password">Пароль:</label>
                                  <input type="password" name='password' className='form-control'     onChange={e => setInputData({...inputData, pass: e.target.value})}/>
                              </div> 
                             <br />
{/*                             <ReCAPTCHA
          sitekey={"6LfgujIpAAAAAFxt3OIp0l_k18Y4FoiRrVR1bquU"}
//          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          onChange={onChange}
          />    */}
          <button className='btn btn-info'
          //</form> disabled={!isCaptchaSuccessful}
          >Войти</button>
                             </form>
                             </div>
                             </div>  )}
export default Login