import React from "react";

import { VisibilityContext } from "@preshonyee/react-horizontal-scrolling-menu";

function Arrow({
  children,
  onClick
}: {
  children: React.ReactNode;
  onClick: VoidFunction;
}) {
  return (
    <button
      onClick={onClick}
      style={{
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        right: "1%",
        userSelect: "none"
      }}
    >
      {children}
    </button>
  );
}

export function LeftArrow() {
  const {
    isFirstItemVisible,
    scrollPrev,
    initComplete
  } = React.useContext(VisibilityContext);

  


  return (
    <Arrow  onClick={() => scrollPrev()}>
      Left
    </Arrow>
  );
}

export function RightArrow() {
  const { isLastItemVisible, scrollNext} = React.useContext(
    VisibilityContext
  );

 

  return (
    <Arrow  onClick={() => scrollNext()}>
      Right
    </Arrow>
  );
}
