import React from 'react';
import { render } from 'react-dom';
import Chart from './Chart';
import { getData } from "./utils"
import axios from 'axios';
import {Link} from 'react-router-dom';

import { TypeChooser } from "react-stockcharts/lib/helper";
import {dannye} from './test.json';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { element } from 'prop-types';
//import file from './bitfinex_xbtusd_1m.csv';
import file from './BTCUSDDAY1.tsv';
import file1 from './BTCUSDDAY.tsv';
import users from './db.json'

//состояня отображают выбранный в меню пункт (value - 1 скрипт покупки)
class ChartComponent extends React.Component {
	componentDidMount() {
		this.state = {value: '',
						uslovie1: 0,
						uslovie2: 0,
						uslovie3: 0,
						//числовое значение в 1 скрипте покупки
						value1: 0,
						//1 скрипт продажи
						value2: '',
						//числовое значение в 1 скрипте продажи
						value3: 0,
						//хранимые значение сейчас не используется
						value4: 'saveclose',
						value5: 1,
						//1 индикатор
						value6: '',
						//числовое значение 1 индикатора
						value7: 10,
						//2 скрипт покупки
						value8: "",
						//числовое значение во 2 скрипте покупки
						value9: 0,
						//2 индикатор
						value10: "",
						//числовое значение 2 индикатора
						value11: 0,
						//3 индикатор
						value12: "",
						//числовое значение 3 индикатора
						value13: 0,
						//3 скрипт покупки
						value14: "",
						//числовое значение в 3 скрипте покупки
						value15: 0,
						//сколько условий (при открытии + индикатора)
						uslovie: 1,
						canvas: 400,
						canvas1: 150,
						coin: 0,
						time: 0};
		//биндим this, чтобы можно было использовать стейт внутри других функций
		this.handleChange = this.handleChange.bind(this);
		this.handleChange1 = this.handleChange1.bind(this);
		this.handleChange2 = this.handleChange2.bind(this);
		this.handleChange3 = this.handleChange3.bind(this);
		this.handleChange4 = this.handleChange4.bind(this);
		this.handleChange5 = this.handleChange5.bind(this);
		this.handleChange6 = this.handleChange6.bind(this);
		this.handleChange7 = this.handleChange7.bind(this);
		this.handleChange8 = this.handleChange8.bind(this);
		this.handleChange9 = this.handleChange9.bind(this);
		this.handleChange10 = this.handleChange10.bind(this);
		this.handleChange11 = this.handleChange11.bind(this);
		this.handleChange12 = this.handleChange12.bind(this);
		this.handleChange13 = this.handleChange13.bind(this);
		this.handleChange14 = this.handleChange14.bind(this);
		this.handleChange15 = this.handleChange15.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleSubmit1 = this.handleSubmit1.bind(this);
		this.handleSubmit2 = this.handleSubmit2.bind(this);
		this.handleSubmit3 = this.handleSubmit3.bind(this);
		this.handleSubmit4 = this.handleSubmit4.bind(this);
		this.handleSubmit5 = this.handleSubmit5.bind(this);
		this.handleSubmit6 = this.handleSubmit6.bind(this);
		this.handleSubmit7 = this.handleSubmit7.bind(this);
//		this.saveMe = this.saveMe.bind(this);
		this.payMe = this.payMe.bind(this);
		this.activMe = this.activMe.bind(this);
//		this.lookMe = this.lookMe.bind(this);

//чтобы можно было использовать стейт внутри вложенных функций
const that = this;

async function status() {
	//проверяем не вышел ли пользователь
	if(localStorage.getItem("id") != null) {
		let payment = '';
		let coin = 0;
		let time = 0;
		let name = '';
		axios.get('http://smartex.site/api/user/'+localStorage.getItem("id"))
		.then(res => that.setState(res.data))
	//		.then(function (response) { {self.setState({time: response.data.time}), 	(response.data.coin > 0 && response.data.time != 0) ? ((response.data.time > new Date().valueOf()) ? (getData(file1).then(data => {that.setState({data}, {coin: response.data.coin, time: response.data.time}) })): (axios.put(`http://smartex.site/api/user/${localStorage.getItem("id")}/edit`, {id: localStorage.getItem("id"), name: response.data.name, coin: response.data.coin - 1, time: 0, payment: response.data.payment}, getData(file).then(data => {that.setState({ data }),	that.setState({coin: response.data.coin - 1, time: 0}) })	))) : (getData(file).then(data => {that.setState({ data }), that.setState({coin: response.data.coin, time: 0})})) 	} })
	//		.then( setTimeout(() => { console.log(self.state) }, 3000)		)
	//		.then((response.data.coin > 0 && response.data.time != 0) ? ((response.data.time > new Date().valueOf()) ? (getData(file1).then(data => {that.setState({data}, {coin: response.data.coin, time: response.data.time}) })): (axios.put(`http://smartex.site/api/user/${localStorage.getItem("id")}/edit`, {id: localStorage.getItem("id"), name: response.data.name, coin: response.data.coin - 1, time: 0, payment: response.data.payment}, getData(file).then(data => {that.setState({ data }),	that.setState({coin: response.data.coin - 1, time: 0}) })	))) : (getData(file).then(data => {that.setState({ data }), that.setState({coin: response.data.coin, time: 0})})) )
			.catch(function (error) {
				console.log(error);
			 });
	//		console.log(time, new Date(time).valueOf(), new Date().valueOf());
		//проверяем, куплены ли коины
			 coin = that.state.coin;
			 time = that.state.time;
		if (coin > 0 && time != 0) {
			//проверяем, не истекло ли время покупки
			if (new Date(time).valueOf() > new Date().valueOf()) {
				getData(file1).then(data => {
					that.setState({ data });
					that.setState({coin: coin, time: time});
				})
			} else {
				//если истекло время, обнуляем коины
				axios.put(`http://smartex.site/api/user/${localStorage.getItem("id")}/edit`, {id: localStorage.getItem("id"), name: that.state.name, email: that.state.email, password: that.state.password, coin: coin - 1, time: 0, ref: that.state.ref, payment: that.state.payment});
				getData(file).then(data => {
					that.setState({ data });
					that.setState({coin: coin - 1, time: 0});
				})
			}
		} else {
			getData(file).then(data => {
				that.setState({ data });
				that.setState({coin: coin, time: 0});
			})
		}

	} else {
		getData(file).then(data => {
			that.setState({ data });
			that.setState({time: 0, coin: 0});
		})
	}
  }
  (async function() {
	await status();
  })();


  setTimeout(() => { 
	if (this.state.time > 0) {
		if (this.state.time > new Date().valueOf()) {
			getData(file1).then(data => {
				that.setState({ data });
			})
		} else {
			//если истекло время, обнуляем коины
			axios.put(`http://smartex.site/api/user/${localStorage.getItem("id")}/edit`, {id: localStorage.getItem("id"), name: this.state.name, email: this.state.email, password: this.state.password, coin: this.state.coin - 1, time: 0, ref: this.state.ref, payment: this.state.payment});
			getData(file).then(data => {
				that.setState({ data });
				that.setState({coin: this.state.coin - 1, time: 0});
			})
		}
	}
}, 2000);
}

	  handleChange(event) {
		this.setState({value: event.target.value});
	  }
	  handleChange1(event) {
		this.setState({value1: event.target.value});
	  }
	  handleChange2(event) {
		this.setState({value2: event.target.value});
	  }
	  handleChange3(event) {
		this.setState({value3: event.target.value});
	  }
	  handleChange4(event) {
		this.setState({value4: event.target.value});
	  }
	  handleChange5(event) {
		this.setState({value5: event.target.value});
	  }
	  handleChange6(event) {
		if (event.target.value === "macd" || event.target.value === "rsi" || event.target.value === "atr" || event.target.value === "stoch") {
			this.setState({value6: event.target.value, canvas: 700, canvas1: 410});
		} else {
			this.setState({value6: event.target.value, canvas: 470, canvas1: 180});
		}
	  }
	  handleChange7(event) {
		this.setState({value7: event.target.value});
	  }
	  handleChange8(event) {
		this.setState({value8: event.target.value});
	  }
	  handleChange9(event) {
		this.setState({value9: event.target.value});
	  }
	  handleChange10(event) {
		if (this.state.value6 === "macd" || this.state.value6 === "rsi" || this.state.value6 === "atr" || this.state.value6 === "stoch") {
			this.setState({value10: event.target.value, canvas: 700, canvas1: 410});
		} else {
			this.setState({value10: event.target.value, canvas: 470, canvas1: 180});
		}
	  }
	  handleChange11(event) {
		this.setState({value11: event.target.value});
	  }
	  handleChange12(event) {
		if (this.state.value6 === "macd" || this.state.value6 === "rsi" || this.state.value6 === "atr" || this.state.value6 === "stoch") {
			this.setState({value12: event.target.value, canvas: 700, canvas1: 410});
		} else {
			this.setState({value12: event.target.value, canvas: 470, canvas1: 180});
		}
	  }
	  handleChange13(event) {
		this.setState({value13: event.target.value});
	  }
	  handleChange14(event) {
		this.setState({value14: event.target.value});
	  }
	  handleChange15(event) {
		this.setState({value15: event.target.value});
	  }
	  handleSubmit(event) {
		alert('Ваш выбор: ' + this.state.value + this.state.value1);
		event.preventDefault();
	  }
	  handleSubmit1(event) {
		alert('Ваш выбор: ' + this.state.value3 + this.state.value2);
		event.preventDefault();
	  }
	  handleSubmit2(event) {
		alert('Ваш выбор: ' + this.state.value4 + this.state.value5);
		event.preventDefault();
	  }
	  handleSubmit3(event) {
		alert('Ваш выбор: ' + this.state.value6 + this.state.value7);
		event.preventDefault();
	  }
	  handleSubmit4(event) {
		alert('Ваш выбор: ' + this.state.value8 + this.state.value9);
		event.preventDefault();
	  }
	  handleSubmit5(event) {
		alert('Ваш выбор: ' + this.state.value10 + this.state.value11);
		event.preventDefault();
	  }
	  handleSubmit6(event) {
		alert('Ваш выбор: ' + this.state.value12 + this.state.value13);
		event.preventDefault();
	  }
	  handleSubmit7(event) {
		alert('Ваш выбор: ' + this.state.value14 + this.state.value15);
		event.preventDefault();
	  }

	  payMe() {
		const url = 'https://api.cryptocloud.plus/v2/invoice/create';
		const headers = {
			'Authorization': 'Token eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1dWlkIjoiTVRnd05EWT0iLCJ0eXBlIjoicHJvamVjdCIsInYiOiI4ZGU2NTBmN2RmZTYxYTRlNTc4YjQ2MmE4NWZhZTk3MDA2MjVlNTJhZTZjYjYxOTM5M2Y2M2FjMDJmODg3ZjAyIiwiZXhwIjo4ODEwNDIxODc5N30.qFKqzGrrGfryDuu0sBV4_AQh3PV2RcwjgfvRvW1UHsQ',
			'Content-Type': 'application/json'
		};
		const data = {
			amount: 10,
			shop_id: '2gHwYS8pCDX50sx4',
			currency: 'USD'
		};
		axios.post(url, data, { headers })
			.then(response => {
				console.log('Success:', response.data);
				axios.put(`http://smartex.site/api/user/${localStorage.getItem("id")}/edit`, {id: localStorage.getItem("id"), name: this.state.name, email: this.state.email, password: this.state.password, coin: this.state.coin, ref: this.state.ref, payment: (this.state.payment + "," + response.data.result.uuid)});
				window.open(`${response.data.result.link}`);
				location.reload();
			})
			.catch(error => {
				console.error('Fail:', error);
			});
	  }

	activMe() {
		axios.put(`http://smartex.site/api/user/${localStorage.getItem("id")}/edit`, {id: localStorage.getItem("id"), name: this.state.name, email: this.state.email, password: this.state.password, coin: this.state.coin, time: new Date().valueOf()+600000, ref: this.state.ref, payment: this.state.payment});
		location.reload();
	}
	
/*
	  saveMe() {
			axios.get(`http://smartex.site/api/user/${localStorage.getItem("id")}/edit`)
				.then(res => {
					let d = res.data.save;
					console.log(d[1]);
					for (let i = 1; i < 11; i++) {
						if (d[i].one == 0) {
							d[i].one = 1;
							d[i].ind = this.state.value6;
							d[i].indcount = this.state.value7;
							d[i].ind1 = this.state.value10;
							d[i].ind1count = this.state.value11;
							d[i].ind2 = this.state.value12;
							d[i].ind2count = this.state.value13;
							d[i].buy = this.state.value;
							d[i].buycount = this.state.value1;
							d[i].buy1 = this.state.value8;
							d[i].buy1count = this.state.value9;
							d[i].buy2 = this.state.value14;
							d[i].buy2count = this.state.value15;
							d[i].sell = this.state.value2;
							d[i].sellcount = this.state.value3;
							break;
						}
					}
					axios.patch('http://localhost:3001/users/'+localStorage.getItem("id"), {save: d});
//					for (let i = 1; i < 11; i++) {
						//if (d[i].one > 0) {
//							axios.patch('http://localhost:3001/users/'+localStorage.getItem("id"), {save.${i}.one: 1});
//						}
//					break;
//					}
				})
	  }
*/
	render() {
		if (this.state === null) {
			return <div>Loading...</div>
		};

//есть ли сейчас уже покупка
let buy = 0;

let price = 0;
let price1 = 0;
let sdelki = 0;
let sdelkiminus = 0;
let pribyl = 0;
let savemaxclose = 0;
let savemaxopen = 0;
let savemaxhigh = 0;
let savemaxlow = 0;
let uslovie = 1;
//условие 1
let a = 0;
let b = 0;
let a1 = 0;
let b1 = 0;
//условие 2
let a01 = 0;
let b01 = 0;
let a11 = 0;
let b11 = 0;
//условие 3
let a02 = 0;
let b02 = 0;
let a21 = 0;
let b21 = 0;
//продажа
let c = 0;
let c1 = 0;
let c01 = 0;
let c11 = 0;
let c02 = 0;
let c21 = 0;
let bba = 0;
let bbb = 0;
let bba1 = 0;
let bbb1 = 0;
//массив со сделками
let sdelki1 = [];
let type = [];
let dats = [];

if (this.state.data == undefined) {
	return <div>Loading...</div>
//	location.reload();
}
for (let i = 0; i < this.state.data.length; i++) {
	//1
	if (this.state.value === "nizhe" || this.state.value === "vyshe") {
		a = this.state.value1;
		b = this.state.data[i].close;
		c1 = c;
		c = this.state.data[i].ema20;
	}
	if (this.state.value === "emaup" || this.state.value === "emadown") {
		a = this.state.data[i].ema20;
		b = this.state.data[i].close;
		c1 = c;
		c = this.state.data[i].ema20;
	}
	if (this.state.value === "wmadown") {
		a = this.state.data[i].wma20;
		b = this.state.data[i].close;
	}
	if (this.state.value === "smadown") {
		a = this.state.data[i].sma20;
		b = this.state.data[i].close;
	}
	if (this.state.value === "tmadown") {
		a = this.state.data[i].tma20;
		b = this.state.data[i].close;
	}
	if (this.state.value === "emacross") {
		a1 = a;
		b1 = b;
		a = this.state.data[i].ema50;
		b = this.state.data[i].ema20;
	}
	if (this.state.value === "macdup") {
		a1 = a;
		b1 = b;
		a = this.state.data[i].macd.macd;
		b = this.state.data[i].macd.signal;
	}
	if (this.state.value === "stochup") {
		a1 = a;
		b1 = b;
		a = this.state.data[i].fullSTO.K;
		b = this.state.data[i].fullSTO.D;
	}
	if (this.state.value === "stochleveldwn") {
		a1 = this.state.value1;
		b1 = b;
		a = this.state.value1;
		b = this.state.data[i].fullSTO.D;
		if (this.state.value1 === undefined) {
			a = 20;
			a1 = 20;
		}
	}
	if (this.state.value === "stochlevelup") {
		a1 = a;
		b1 = this.state.value1;
		a = this.state.data[i].fullSTO.D;
		b = this.state.value1;
		if (this.state.value1 === undefined) {
			b = 80;
			b1 = 80;
		}
	}
	if (this.state.value === "rsileveldwn") {
		a1 = this.state.value1;
		b1 = b;
		a = this.state.value1;
		b = this.state.data[i].rsi;
		if (this.state.value1 === undefined) {
			a1 = 30;
			a = 30;
		}
	}
	if (this.state.value === "atrleveldwn") {
		a1 = this.state.value1;
		b1 = b;
		a = this.state.value1;
		b = this.state.data[i].atr14;
		if (this.state.value1 === undefined) {
			a1 = 580;
			a = 580;
		}
	}
	if (this.state.value === "bbreturnup" && buy === 0) {
		a1 = a;
		b1 = b;
		a = this.state.data[i].close;
		b = this.state.data[i].bb?.bottom;
	}
	//2
	if (this.state.value8 === "nizhe1" || this.state.value8 === "vyshe1") {
		a01 = this.state.value9;
		b01 = this.state.data[i].close;
	}
	if (this.state.value8 === "diverdown") {
		a01 = this.state.value9;
		b01 = this.state.data[i].macd.divergence;
	}
	if (this.state.value8 === "emaup1" || this.state.value8 === "emadown1") {
		a01 = this.state.data[i].ema50;
		b01 = this.state.data[i].close;
		c11 = c01;
		c01 = this.state.data[i].ema50;
	}
	if (this.state.value8 === "wmadown1") {
		a01 = this.state.data[i].wma50;
		b01 = this.state.data[i].close;
	}
	if (this.state.value8 === "smadown1") {
		a01 = this.state.data[i].sma50;
		b01 = this.state.data[i].close;
	}
	if (this.state.value8 === "tmadown1") {
		a01 = this.state.data[i].tma50;
		b01 = this.state.data[i].close;
	}
	if (this.state.value8 === "emacross1") {
		a11 = a01;
		b11 = b01;
		a01 = this.state.data[i].ema20;
		b01 = this.state.data[i].ema50;
		c11 = c01;
		c01 = this.state.data[i].ema50;
	}
	if (this.state.value8 === "emacrosswma") {
		a11 = a01;
		b11 = b01;
		a01 = this.state.data[i].ema20;
		b01 = this.state.data[i].wma50;
	}
	if (this.state.value8 === "emacrosssma") {
		a11 = a01;
		b11 = b01;
		a01 = this.state.data[i].ema20;
		b01 = this.state.data[i].sma50;
	}
	if (this.state.value8 === "emacrosstma") {
		a11 = a01;
		b11 = b01;
		a01 = this.state.data[i].ema20;
		b01 = this.state.data[i].tma50;
	}
	if (this.state.value8 === "wmacrossema") {
		a11 = a01;
		b11 = b01;
		a01 = this.state.data[i].wma20;
		b01 = this.state.data[i].ema50;
	}
	if (this.state.value8 === "wmacrosssma") {
		a11 = a01;
		b11 = b01;
		a01 = this.state.data[i].wma20;
		b01 = this.state.data[i].sma50;
	}
	if (this.state.value8 === "wmacrosstma") {
		a11 = a01;
		b11 = b01;
		a01 = this.state.data[i].wma20;
		b01 = this.state.data[i].tma50;
	}
	if (this.state.value8 === "wmacross1") {
		a11 = a01;
		b11 = b01;
		a01 = this.state.data[i].wma20;
		b01 = this.state.data[i].wma50;
	}
	if (this.state.value8 === "smacrossema") {
		a11 = a01;
		b11 = b01;
		a01 = this.state.data[i].sma20;
		b01 = this.state.data[i].ema50;
	}
	if (this.state.value8 === "smacrosstma") {
		a11 = a01;
		b11 = b01;
		a01 = this.state.data[i].sma20;
		b01 = this.state.data[i].tma50;
	}
	if (this.state.value8 === "smacrosswma") {
		a11 = a01;
		b11 = b01;
		a01 = this.state.data[i].sma20;
		b01 = this.state.data[i].wma50;
	}
	if (this.state.value8 === "smacross1") {
		a11 = a01;
		b11 = b01;
		a01 = this.state.data[i].sma20;
		b01 = this.state.data[i].sma50;
	}
	if (this.state.value8 === "tmacrossema") {
		a11 = a01;
		b11 = b01;
		a01 = this.state.data[i].tma20;
		b01 = this.state.data[i].ema50;
	}
	if (this.state.value8 === "tmacrosssma") {
		a11 = a01;
		b11 = b01;
		a01 = this.state.data[i].tma20;
		b01 = this.state.data[i].sma50;
	}
	if (this.state.value8 === "tmacrosswma") {
		a11 = a01;
		b11 = b01;
		a01 = this.state.data[i].tma20;
		b01 = this.state.data[i].wma50;
	}
	if (this.state.value8 === "tmacross1") {
		a11 = a01;
		b11 = b01;
		a01 = this.state.data[i].tma20;
		b01 = this.state.data[i].tma50;
	}
	//3
	if (this.state.value14 === "nizhe2" || this.state.value14 === "vyshe2") {
		a02 = this.state.value15;
		b02 = this.state.data[i].close;
		//допы для моей страты
		c21 = c02;
		c02 = this.state.data[i].ema90;
	}
	if (this.state.value14 === "emaup2" || this.state.value14 === "emadown2") {
		a02 = this.state.data[i].ema90;
		b02 = this.state.data[i].close;
		c21 = c02;
		c02 = this.state.data[i].ema90;
	}
	if (this.state.value14 === "wmadown2") {
		a02 = this.state.data[i].wma90;
		b02 = this.state.data[i].close;
	}
	if (this.state.value14 === "smadown2") {
		a02 = this.state.data[i].sma90;
		b02 = this.state.data[i].close;
	}
	if (this.state.value14 === "tmadown2") {
		a02 = this.state.data[i].tma90;
		b02 = this.state.data[i].close;
	}
	if (this.state.value14 === "emacross2") {
		a21 = a02;
		b21 = b02;
		a02 = this.state.data[i].ema50;
		b02 = this.state.data[i].ema90;
	}
	if (this.state.value14 === "emacrosswma") {
		a21 = a02;
		b21 = b02;
		a02 = this.state.data[i].ema50;
		b02 = this.state.data[i].wma90;
	}
	if (this.state.value14 === "emacrosssma") {
		a21 = a02;
		b21 = b02;
		a02 = this.state.data[i].ema50;
		b02 = this.state.data[i].sma90;
	}
	if (this.state.value14 === "emacrosstma") {
		a21 = a02;
		b21 = b02;
		a02 = this.state.data[i].ema50;
		b02 = this.state.data[i].tma90;
	}
	if (this.state.value14 === "wmacrossema") {
		a21 = a02;
		b21 = b02;
		a02 = this.state.data[i].wma50;
		b02 = this.state.data[i].ema90;
	}
	if (this.state.value14 === "wmacrosssma") {
		a21 = a02;
		b21 = b02;
		a02 = this.state.data[i].wma50;
		b02 = this.state.data[i].sma90;
	}
	if (this.state.value14 === "wmacrosstma") {
		a21 = a02;
		b21 = b02;
		a02 = this.state.data[i].wma50;
		b02 = this.state.data[i].tma90;
	}
	if (this.state.value14 === "wmacross2") {
		a21 = a02;
		b21 = b02;
		a02 = this.state.data[i].wma50;
		b02 = this.state.data[i].wma90;
	}
	if (this.state.value14 === "smacrossema") {
		a21 = a02;
		b21 = b02;
		a02 = this.state.data[i].sma50;
		b02 = this.state.data[i].ema90;
	}
	if (this.state.value14 === "smacrosstma") {
		a21 = a02;
		b21 = b02;
		a02 = this.state.data[i].sma50;
		b02 = this.state.data[i].tma90;
	}
	if (this.state.value14 === "smacrosswma") {
		a21 = a02;
		b21 = b02;
		a02 = this.state.data[i].sma50;
		b02 = this.state.data[i].wma90;
	}
	if (this.state.value14 === "smacross2") {
		a21 = a02;
		b21 = b02;
		a02 = this.state.data[i].sma50;
		b02 = this.state.data[i].sma90;
	}
	if (this.state.value14 === "tmacrossema") {
		a21 = a02;
		b21 = b02;
		a02 = this.state.data[i].tma50;
		b02 = this.state.data[i].ema90;
	}
	if (this.state.value14 === "tmacrosssma") {
		a21 = a02;
		b21 = b02;
		a02 = this.state.data[i].tma50;
		b02 = this.state.data[i].sma90;
	}
	if (this.state.value14 === "tmacrosswma") {
		a21 = a02;
		b21 = b02;
		a02 = this.state.data[i].tma50;
		b02 = this.state.data[i].wma90;
	}
	if (this.state.value14 === "tmacross2") {
		a21 = a02;
		b21 = b02;
		a02 = this.state.data[i].tma50;
		b02 = this.state.data[i].tma90;
	}
	/* РЕЖИМ ПОКУПКИ */
	
	
	
	
	
	if (buy === 0) {
		if (this.state.uslovie === undefined) {
			this.setState({uslovie: 1, canvas: 470, canvas1: 180});
		}
	
		if (this.state.uslovie === 1) {
			if (this.state.value === "nizhe" || this.state.value === "emadown" || this.state.value === "smadown" || this.state.value === "tmadown" || this.state.value === "wmadown") {
				if (a > b) {
					buy = 1;
					sdelki = sdelki + 1;
					price = this.state.data[i].close;
					type[sdelki] = 0;
					sdelki1[sdelki] = price;
					price1 = +price + +this.state.value3;
					dats[sdelki] = this.state.data[i].date.toGMTString();
				};
			}
			if (this.state.value === "vyshe" || this.state.value === "emaup") {
				if (a < b) {
					buy = 1;
					sdelki = sdelki + 1;
					price = this.state.data[i].close;
					type[sdelki] = 0;
					sdelki1[sdelki] = price;
					price1 = +price + +this.state.value3;
					dats[sdelki] = this.state.data[i].date.toGMTString();
				};
			}
			if (this.state.value === "macdup" || this.state.value === "stochup" || this.state.value === "stochleveldwn" || this.state.value === "stochlevelup" || this.state.value === "bbreturnup" || this.state.value === "rsileveldwn" || this.state.value === "atrleveldwn") {
				if (a > b && a1 < b1) {
					buy = 1;
					sdelki = sdelki + 1;
					price = this.state.data[i].close;
					type[sdelki] = 0;
					sdelki1[sdelki] = price;
					price1 = +price + +this.state.value3;
					dats[sdelki] = this.state.data[i].date.toGMTString();
				};
			}
		}
//2 условия
		if (this.state.uslovie === 2) {
			if (this.state.value === "vyshe" || this.state.value === "emaup") {
				if  (this.state.value8 === "vyshe1" || this.state.value8 === "emaup1") {
					if (a < b && a01 < b01) {
						buy = 1;
						sdelki = sdelki + 1;
						price = this.state.data[i].close;
						type[sdelki] = 0;
						sdelki1[sdelki] = price;
						price1 = +price + +this.state.value3;
						dats[sdelki] = this.state.data[i].date.toGMTString();
					}
				}
			}
			if (this.state.value === "vyshe" || this.state.value === "emaup") {
				if (this.state.value8 === "emacross1" || this.state.value8 === "smacross1" || this.state.value8 === "tmacross1" || this.state.value8 === "wmacross1" || this.state.value8 === "emacrosswma" || this.state.value8 === "emacrosssma" || this.state.value8 === "emacrosstma" || this.state.value8 === "wmacrossema" || this.state.value8 === "wmacrosssma" || this.state.value8 === "wmacrosstma" || this.state.value8 === "smacrossema" || this.state.value8 === "smacrosstma" || this.state.value8 === "smacrosswma" || this.state.value8 === "tmacrossema" || this.state.value8 === "tmacrosssma" || this.state.value8 === "tmacrosswma") {
					if (a < b && a01 > b01 && a11 < b11) {
						buy = 1;
						sdelki = sdelki + 1;
						price = this.state.data[i].close;
						type[sdelki] = 0;
						sdelki1[sdelki] = price;
						price1 = +price + +this.state.value3;
						dats[sdelki] = this.state.data[i].date.toGMTString();
					}
				}
			}
			if (this.state.value === "vyshe" || this.state.value === "emaup") {
				if (this.state.value8 === "nizhe1" || this.state.value8 === "emadown1" || this.state.value8 === "smadown1" || this.state.value8 === "tmadown1" || this.state.value8 === "wmadown1" || this.state.value8 === "diverdown") {
					if (a < b && a01 > b01) {
						buy = 1;
						sdelki = sdelki + 1;
						price = this.state.data[i].close;
						type[sdelki] = 0;
						sdelki1[sdelki] = price;
						price1 = +price + +this.state.value3;
						dats[sdelki] = this.state.data[i].date.toGMTString();
					}
				}
			}
			if (this.state.value === "emacross" || this.state.value === "macdup" || this.state.value === "stochup") {
				if (this.state.value8 === "vyshe1" || this.state.value8 === "emaup1") {
					if (a > b && a1 < b1 && a01 < b01) {
						buy = 1;
						sdelki = sdelki + 1;
						price = this.state.data[i].close;
						type[sdelki] = 0;
						sdelki1[sdelki] = price;
						price1 = +price + +this.state.value3;
						dats[sdelki] = this.state.data[i].date.toGMTString();
					}
				}
			}
			if (this.state.value === "emacross" || this.state.value === "macdup" || this.state.value === "stochup") {
				if (this.state.value8 === "emacross1" || this.state.value8 === "smacross1" || this.state.value8 === "tmacross1" || this.state.value8 === "wmacross1" || this.state.value8 === "emacrosswma" || this.state.value8 === "emacrosssma" || this.state.value8 === "emacrosstma" || this.state.value8 === "wmacrossema" || this.state.value8 === "wmacrosssma" || this.state.value8 === "wmacrosstma" || this.state.value8 === "smacrossema" || this.state.value8 === "smacrosstma" || this.state.value8 === "smacrosswma" || this.state.value8 === "tmacrossema" || this.state.value8 === "tmacrosssma" || this.state.value8 === "tmacrosswma") {
					if (a > b && a1 < b1 && a01 > b01 && a11 < b11) {
						buy = 1;
						sdelki = sdelki + 1;
						price = this.state.data[i].close;
						type[sdelki] = 0;
						sdelki1[sdelki] = price;
						price1 = +price + +this.state.value3;
						dats[sdelki] = this.state.data[i].date.toGMTString();
				}
				}
			}
			if (this.state.value === "emacross" || this.state.value === "macdup" || this.state.value === "stochup" || this.state.value === "stochleveldwn" || this.state.value === "stochlevelup" || this.state.value === "bbreturnup" || this.state.value === "rsileveldwn" || this.state.value === "atrleveldwn") {
				if (this.state.value8 === "nizhe1" || this.state.value8 === "emadown1" || this.state.value8 === "smadown1" || this.state.value8 === "tmadown1" || this.state.value8 === "wmadown1" || this.state.value8 === "diverdown") {
					if (a > b && a1 < b1 && a01 > b01) {
						buy = 1;
						sdelki = sdelki + 1;
						price = this.state.data[i].close;
						type[sdelki] = 0;
						sdelki1[sdelki] = price;
						price1 = +price + +this.state.value3;
						dats[sdelki] = this.state.data[i].date.toGMTString();
				}
				}
			}
			if (this.state.value === "nizhe" || this.state.value === "emadown" || this.state.value === "smadown" || this.state.value === "tmadown" || this.state.value === "wmadown") {
				if (this.state.value8 === "vyshe1" || this.state.value8 === "emaup1") {
					if (a > b && a01 < b01) {
							buy = 1;
							sdelki = sdelki + 1;
							price = this.state.data[i].close;
							type[sdelki] = 0;
							sdelki1[sdelki] = price;
							price1 = +price + +this.state.value3;
							dats[sdelki] = this.state.data[i].date.toGMTString();
					}
				}
			}
			if (this.state.value === "nizhe" || this.state.value === "emadown" || this.state.value === "smadown" || this.state.value === "tmadown" || this.state.value === "wmadown") {
				if (this.state.value8 === "emacross1" || this.state.value8 === "smacross1" || this.state.value8 === "tmacross1" || this.state.value8 === "wmacross1" || this.state.value8 === "emacrosswma" || this.state.value8 === "emacrosssma" || this.state.value8 === "emacrosstma" || this.state.value8 === "wmacrossema" || this.state.value8 === "wmacrosssma" || this.state.value8 === "wmacrosstma" || this.state.value8 === "smacrossema" || this.state.value8 === "smacrosstma" || this.state.value8 === "smacrosswma" || this.state.value8 === "tmacrossema" || this.state.value8 === "tmacrosssma" || this.state.value8 === "tmacrosswma") {
					if (a > b && a01 > b01 && a11 < b11) {
						buy = 1;
						sdelki = sdelki + 1;
						price = this.state.data[i].close;
						type[sdelki] = 0;
						sdelki1[sdelki] = price;
						price1 = +price + +this.state.value3;
						dats[sdelki] = this.state.data[i].date.toGMTString();
					}
				}
			}
			if (this.state.value === "nizhe" || this.state.value === "emadown" || this.state.value === "smadown" || this.state.value === "tmadown" || this.state.value === "wmadown") {
				if (this.state.value8 === "nizhe1" || this.state.value8 === "emadown1" || this.state.value8 === "smadown1" || this.state.value8 === "tmadown1" || this.state.value8 === "wmadown1" || this.state.value8 === "diverdown") {
					if (a > b && a01 > b01) {
						buy = 1;
						sdelki = sdelki + 1;
						price = this.state.data[i].close;
						type[sdelki] = 0;
						sdelki1[sdelki] = price;
						price1 = +price + +this.state.value3;
						dats[sdelki] = this.state.data[i].date.toGMTString();
					}
				}
			}
		}
	
		if (this.state.uslovie === 3) {
			if (this.state.value === "vyshe" || this.state.value === "emaup") {
				if  (this.state.value8 === "vyshe1" || this.state.value8 === "emaup1") {
					if (this.state.value14 === "vyshe2" || this.state.value14 === "emaup2") {
						if (a < b && a01 < b01 && a02 < b02) {
							buy = 1;
							sdelki = sdelki + 1;
							price = this.state.data[i].close;
							type[sdelki] = 0;
							sdelki1[sdelki] = price;
							price1 = +price + +this.state.value3;
							dats[sdelki] = this.state.data[i].date.toGMTString();
						}
					}
				}
			}
			if (this.state.value === "vyshe" || this.state.value === "emaup") {
				if  (this.state.value8 === "vyshe1" || this.state.value8 === "emaup1") {
					if (this.state.value14 === "emacross2" || this.state.value14 === "smacross2" || this.state.value14 === "tmacross2" || this.state.value14 === "wmacross2" || this.state.value14 === "emacrosswma" || this.state.value14 === "emacrosssma" || this.state.value14 === "emacrosstma" || this.state.value14 === "wmacrossema" || this.state.value14 === "wmacrosssma" || this.state.value14 === "wmacrosstma" || this.state.value14 === "smacrossema" || this.state.value14 === "smacrosstma" || this.state.value14 === "smacrosswma" || this.state.value14 === "tmacrossema" || this.state.value14 === "tmacrosssma" || this.state.value14 === "tmacrosswma") {
						if (a < b && a01 < b01 && a02 > b02 && a21 < b21) {
							buy = 1;
							sdelki = sdelki + 1;
							price = this.state.data[i].close;
							type[sdelki] = 0;
							sdelki1[sdelki] = price;
							price1 = +price + +this.state.value3;
							dats[sdelki] = this.state.data[i].date.toGMTString();
						}
					}
				}
			}
			if (this.state.value === "vyshe" || this.state.value === "emaup") {
				if  (this.state.value8 === "vyshe1" || this.state.value8 === "emaup1") {
					if (this.state.value14 === "nizhe2" || this.state.value14 === "emadown2" || this.state.value14 === "smadown2" || this.state.value14 === "tmadown2" || this.state.value14 === "wmadown2") {
						if (a < b && a01 < b01 && a02 > b02) {
							buy = 1;
							sdelki = sdelki + 1;
							price = this.state.data[i].close;
							type[sdelki] = 0;
							sdelki1[sdelki] = price;
							price1 = +price + +this.state.value3;
							dats[sdelki] = this.state.data[i].date.toGMTString();
						}
					}
				}
			}
			if (this.state.value === "vyshe" || this.state.value === "emaup") {
				if (this.state.value8 === "emacross1" || this.state.value8 === "smacross1" || this.state.value8 === "tmacross1" || this.state.value8 === "wmacross1" || this.state.value8 === "emacrosswma" || this.state.value8 === "emacrosssma" || this.state.value8 === "emacrosstma" || this.state.value8 === "wmacrossema" || this.state.value8 === "wmacrosssma" || this.state.value8 === "wmacrosstma" || this.state.value8 === "smacrossema" || this.state.value8 === "smacrosstma" || this.state.value8 === "smacrosswma" || this.state.value8 === "tmacrossema" || this.state.value8 === "tmacrosssma" || this.state.value8 === "tmacrosswma") {
					if (this.state.value14 === "vyshe2" || this.state.value14 === "emaup2") {
						if (a < b && a01 > b01 && a11 < b11 && a02 < b02) {
							buy = 1;
							sdelki = sdelki + 1;
							price = this.state.data[i].close;
							type[sdelki] = 0;
							sdelki1[sdelki] = price;
							price1 = +price + +this.state.value3;
							dats[sdelki] = this.state.data[i].date.toGMTString();
						}
					}
				}
			}
			if (this.state.value === "vyshe" || this.state.value === "emaup") {
				if (this.state.value8 === "emacross1" || this.state.value8 === "smacross1" || this.state.value8 === "tmacross1" || this.state.value8 === "wmacross1" || this.state.value8 === "emacrosswma" || this.state.value8 === "emacrosssma" || this.state.value8 === "emacrosstma" || this.state.value8 === "wmacrossema" || this.state.value8 === "wmacrosssma" || this.state.value8 === "wmacrosstma" || this.state.value8 === "smacrossema" || this.state.value8 === "smacrosstma" || this.state.value8 === "smacrosswma" || this.state.value8 === "tmacrossema" || this.state.value8 === "tmacrosssma" || this.state.value8 === "tmacrosswma") {
					if (this.state.value14 === "emacross2" || this.state.value14 === "smacross2" || this.state.value14 === "tmacross2" || this.state.value14 === "wmacross2" || this.state.value14 === "emacrosswma" || this.state.value14 === "emacrosssma" || this.state.value14 === "emacrosstma" || this.state.value14 === "wmacrossema" || this.state.value14 === "wmacrosssma" || this.state.value14 === "wmacrosstma" || this.state.value14 === "smacrossema" || this.state.value14 === "smacrosstma" || this.state.value14 === "smacrosswma" || this.state.value14 === "tmacrossema" || this.state.value14 === "tmacrosssma" || this.state.value14 === "tmacrosswma") {
						if (a < b && a01 > b01 && a11 < b11 && a02 > b02 && a21 < b21) {
							buy = 1;
							sdelki = sdelki + 1;
							price = this.state.data[i].close;
							type[sdelki] = 0;
							sdelki1[sdelki] = price;
							price1 = +price + +this.state.value3;
							dats[sdelki] = this.state.data[i].date.toGMTString();
						}
					}
				}
			}
			if (this.state.value === "vyshe" || this.state.value === "emaup") {
				if (this.state.value8 === "emacross1" || this.state.value8 === "smacross1" || this.state.value8 === "tmacross1" || this.state.value8 === "wmacross1" || this.state.value8 === "emacrosswma" || this.state.value8 === "emacrosssma" || this.state.value8 === "emacrosstma" || this.state.value8 === "wmacrossema" || this.state.value8 === "wmacrosssma" || this.state.value8 === "wmacrosstma" || this.state.value8 === "smacrossema" || this.state.value8 === "smacrosstma" || this.state.value8 === "smacrosswma" || this.state.value8 === "tmacrossema" || this.state.value8 === "tmacrosssma" || this.state.value8 === "tmacrosswma") {
					if (this.state.value14 === "nizhe2" || this.state.value14 === "emadown2" || this.state.value14 === "smadown2" || this.state.value14 === "tmadown2" || this.state.value14 === "wmadown2") {
						if (a < b && a01 > b01 && a11 < b11 && a02 > b02) {
							buy = 1;
							sdelki = sdelki + 1;
							price = this.state.data[i].close;
							type[sdelki] = 0;
							sdelki1[sdelki] = price;
							price1 = +price + +this.state.value3;
							dats[sdelki] = this.state.data[i].date.toGMTString();
						}
					}
				}
			}
			if (this.state.value === "vyshe" || this.state.value === "emaup") {
				if (this.state.value8 === "nizhe1" || this.state.value8 === "emadown1" || this.state.value8 === "smadown1" || this.state.value8 === "tmadown1" || this.state.value8 === "wmadown1" || this.state.value8 === "diverdown") {
					if (this.state.value14 === "vyshe2" || this.state.value14 === "emaup2") {
						if (a < b && a01 > b01 && a02 < b02) {
							buy = 1;
							sdelki = sdelki + 1;
							price = this.state.data[i].close;
							type[sdelki] = 0;
							sdelki1[sdelki] = price;
							price1 = +price + +this.state.value3;
							dats[sdelki] = this.state.data[i].date.toGMTString();
						}
					}
				}
			}
			if (this.state.value === "vyshe" || this.state.value === "emaup") {
				if (this.state.value8 === "nizhe1" || this.state.value8 === "emadown1" || this.state.value8 === "smadown1" || this.state.value8 === "tmadown1" || this.state.value8 === "wmadown1" || this.state.value8 === "diverdown") {
					if (this.state.value14 === "emacross2" || this.state.value14 === "smacross2" || this.state.value14 === "tmacross2" || this.state.value14 === "wmacross2" || this.state.value14 === "emacrosswma" || this.state.value14 === "emacrosssma" || this.state.value14 === "emacrosstma" || this.state.value14 === "wmacrossema" || this.state.value14 === "wmacrosssma" || this.state.value14 === "wmacrosstma" || this.state.value14 === "smacrossema" || this.state.value14 === "smacrosstma" || this.state.value14 === "smacrosswma" || this.state.value14 === "tmacrossema" || this.state.value14 === "tmacrosssma" || this.state.value14 === "tmacrosswma") {
						if (a < b && a01 > b01 && a02 > b02 && a21 < b21) {
							buy = 1;
							sdelki = sdelki + 1;
							price = this.state.data[i].close;
							type[sdelki] = 0;
							sdelki1[sdelki] = price;
							price1 = +price + +this.state.value3;
							dats[sdelki] = this.state.data[i].date.toGMTString();
						}
					}
				}
			}
			if (this.state.value === "vyshe" || this.state.value === "emaup") {
				if (this.state.value8 === "nizhe1" || this.state.value8 === "emadown1" || this.state.value8 === "smadown1" || this.state.value8 === "tmadown1" || this.state.value8 === "wmadown1" || this.state.value8 === "diverdown") {
					if (this.state.value14 === "nizhe2" || this.state.value14 === "emadown2" || this.state.value14 === "smadown2" || this.state.value14 === "tmadown2" || this.state.value14 === "wmadown2") {
						if (a < b && a01 > b01 && a02 > b02) {
							buy = 1;
							sdelki = sdelki + 1;
							price = this.state.data[i].close;
							type[sdelki] = 0;
							sdelki1[sdelki] = price;
							price1 = +price + +this.state.value3;
							dats[sdelki] = this.state.data[i].date.toGMTString();
						}
					}
				}
			}
			if (this.state.value === "emacross" || this.state.value === "macdup" || this.state.value === "stochup" || this.state.value === "stochleveldwn" || this.state.value === "stochlevelup" || this.state.value === "bbreturnup" || this.state.value === "rsileveldwn" || this.state.value === "atrleveldwn") {
				if (this.state.value8 === "vyshe1" || this.state.value8 === "emaup1") {
					if (this.state.value14 === "vyshe2" || this.state.value14 === "emaup2") {
						if (a > b && a1 < b1 && a01 < b01 && a02 < b02) {
							buy = 1;
							sdelki = sdelki + 1;
							price = this.state.data[i].close;
							type[sdelki] = 0;
							sdelki1[sdelki] = price;
							price1 = +price + +this.state.value3;
							dats[sdelki] = this.state.data[i].date.toGMTString();
						}
					}
				}
			}
			if (this.state.value === "emacross" || this.state.value === "macdup" || this.state.value === "stochup" || this.state.value === "stochleveldwn" || this.state.value === "stochlevelup" || this.state.value === "bbreturnup" || this.state.value === "rsileveldwn" || this.state.value === "atrleveldwn") {
				if (this.state.value8 === "vyshe1" || this.state.value8 === "emaup1") {
					if (this.state.value14 === "emacross2" || this.state.value14 === "smacross2" || this.state.value14 === "tmacross2" || this.state.value14 === "wmacross2" || this.state.value14 === "emacrosswma" || this.state.value14 === "emacrosssma" || this.state.value14 === "emacrosstma" || this.state.value14 === "wmacrossema" || this.state.value14 === "wmacrosssma" || this.state.value14 === "wmacrosstma" || this.state.value14 === "smacrossema" || this.state.value14 === "smacrosstma" || this.state.value14 === "smacrosswma" || this.state.value14 === "tmacrossema" || this.state.value14 === "tmacrosssma" || this.state.value14 === "tmacrosswma") {
						if (a > b && a1 < b1 && a01 < b01 && a02 > b02 && a21 < b21) {
							buy = 1;
							sdelki = sdelki + 1;
							price = this.state.data[i].close;
							type[sdelki] = 0;
							sdelki1[sdelki] = price;
							price1 = +price + +this.state.value3;
							dats[sdelki] = this.state.data[i].date.toGMTString();
						}
					}
				}
			}
			if (this.state.value === "emacross" || this.state.value === "macdup" || this.state.value === "stochup" || this.state.value === "stochleveldwn" || this.state.value === "stochlevelup" || this.state.value === "bbreturnup" || this.state.value === "rsileveldwn" || this.state.value === "atrleveldwn") {
				if (this.state.value8 === "vyshe1" || this.state.value8 === "emaup1") {
					if (this.state.value14 === "nizhe2" || this.state.value14 === "emadown2" || this.state.value14 === "smadown2" || this.state.value14 === "tmadown2" || this.state.value14 === "wmadown2") {
						if (a > b && a1 < b1 && a01 < b01 && a02 > b02) {
							buy = 1;
							sdelki = sdelki + 1;
							price = this.state.data[i].close;
							type[sdelki] = 0;
							sdelki1[sdelki] = price;
							price1 = +price + +this.state.value3;
							dats[sdelki] = this.state.data[i].date.toGMTString();
						}
					}
				}
			}
			if (this.state.value === "emacross" || this.state.value === "macdup" || this.state.value === "stochup" || this.state.value === "stochleveldwn" || this.state.value === "stochlevelup" || this.state.value === "bbreturnup" || this.state.value === "rsileveldwn" || this.state.value === "atrleveldwn") {
				if (this.state.value8 === "emacross1" || this.state.value8 === "smacross1" || this.state.value8 === "tmacross1" || this.state.value8 === "wmacross1" || this.state.value8 === "emacrosswma" || this.state.value8 === "emacrosssma" || this.state.value8 === "emacrosstma" || this.state.value8 === "wmacrossema" || this.state.value8 === "wmacrosssma" || this.state.value8 === "wmacrosstma" || this.state.value8 === "smacrossema" || this.state.value8 === "smacrosstma" || this.state.value8 === "smacrosswma" || this.state.value8 === "tmacrossema" || this.state.value8 === "tmacrosssma" || this.state.value8 === "tmacrosswma") {
					if (this.state.value14 === "vyshe2" || this.state.value14 === "emaup2") {
						if (a > b && a1 < b1 && a01 > b01 && a11 < b11 && a02 < b02) {
							buy = 1;
							sdelki = sdelki + 1;
							price = this.state.data[i].close;
							type[sdelki] = 0;
							sdelki1[sdelki] = price;
							price1 = +price + +this.state.value3;
							dats[sdelki] = this.state.data[i].date.toGMTString();
						}
					}
				}
			}
			if (this.state.value === "emacross" || this.state.value === "macdup" || this.state.value === "stochup" || this.state.value === "stochleveldwn" || this.state.value === "stochlevelup" || this.state.value === "bbreturnup" || this.state.value === "rsileveldwn" || this.state.value === "atrleveldwn") {
				if (this.state.value8 === "emacross1" || this.state.value8 === "smacross1" || this.state.value8 === "tmacross1" || this.state.value8 === "wmacross1" || this.state.value8 === "emacrosswma" || this.state.value8 === "emacrosssma" || this.state.value8 === "emacrosstma" || this.state.value8 === "wmacrossema" || this.state.value8 === "wmacrosssma" || this.state.value8 === "wmacrosstma" || this.state.value8 === "smacrossema" || this.state.value8 === "smacrosstma" || this.state.value8 === "smacrosswma" || this.state.value8 === "tmacrossema" || this.state.value8 === "tmacrosssma" || this.state.value8 === "tmacrosswma") {
					if (this.state.value14 === "emacross2" || this.state.value14 === "smacross2" || this.state.value14 === "tmacross2" || this.state.value14 === "wmacross2" || this.state.value14 === "emacrosswma" || this.state.value14 === "emacrosssma" || this.state.value14 === "emacrosstma" || this.state.value14 === "wmacrossema" || this.state.value14 === "wmacrosssma" || this.state.value14 === "wmacrosstma" || this.state.value14 === "smacrossema" || this.state.value14 === "smacrosstma" || this.state.value14 === "smacrosswma" || this.state.value14 === "tmacrossema" || this.state.value14 === "tmacrosssma" || this.state.value14 === "tmacrosswma") {
						if (a > b && a1 < b1 && a01 > b01 && a11 < b11 && a02 > b02 && a21 < b21) {
							buy = 1;
							sdelki = sdelki + 1;
							price = this.state.data[i].close;
							type[sdelki] = 0;
							sdelki1[sdelki] = price;
							price1 = +price + +this.state.value3;
							dats[sdelki] = this.state.data[i].date.toGMTString();
						}
					}
				}
			}
			if (this.state.value === "emacross" || this.state.value === "macdup" || this.state.value === "stochup" || this.state.value === "stochleveldwn" || this.state.value === "stochlevelup" || this.state.value === "bbreturnup" || this.state.value === "rsileveldwn" || this.state.value === "atrleveldwn") {
				if (this.state.value8 === "emacross1" || this.state.value8 === "smacross1" || this.state.value8 === "tmacross1" || this.state.value8 === "wmacross1" || this.state.value8 === "emacrosswma" || this.state.value8 === "emacrosssma" || this.state.value8 === "emacrosstma" || this.state.value8 === "wmacrossema" || this.state.value8 === "wmacrosssma" || this.state.value8 === "wmacrosstma" || this.state.value8 === "smacrossema" || this.state.value8 === "smacrosstma" || this.state.value8 === "smacrosswma" || this.state.value8 === "tmacrossema" || this.state.value8 === "tmacrosssma" || this.state.value8 === "tmacrosswma") {
					if (this.state.value14 === "nizhe2" || this.state.value14 === "emadown2" || this.state.value14 === "smadown2" || this.state.value14 === "tmadown2" || this.state.value14 === "wmadown2") {
						if (a > b && a1 < b1 && a01 > b01 && a11 < b11 && a02 > b02) {
							buy = 1;
							sdelki = sdelki + 1;
							price = this.state.data[i].close;
							type[sdelki] = 0;
							sdelki1[sdelki] = price;
							price1 = +price + +this.state.value3;
							dats[sdelki] = this.state.data[i].date.toGMTString();
						}
					}
				}
			}
			if (this.state.value === "emacross" || this.state.value === "macdup" || this.state.value === "stochup" || this.state.value === "stochleveldwn" || this.state.value === "stochlevelup" || this.state.value === "bbreturnup" || this.state.value === "rsileveldwn" || this.state.value === "atrleveldwn") {
				if (this.state.value8 === "nizhe1" || this.state.value8 === "emadown1" || this.state.value8 === "smadown1" || this.state.value8 === "tmadown1" || this.state.value8 === "wmadown1" || this.state.value8 === "diverdown") {
					if (this.state.value14 === "vyshe2" || this.state.value14 === "emaup2") {
						if (a > b && a1 < b1 && a01 > b01 && a02 < b02) {
							buy = 1;
							sdelki = sdelki + 1;
							price = this.state.data[i].close;
							type[sdelki] = 0;
							sdelki1[sdelki] = price;
							price1 = +price + +this.state.value3;
							dats[sdelki] = this.state.data[i].date.toGMTString();
						}
					}
				}
			}
			if (this.state.value === "emacross" || this.state.value === "macdup" || this.state.value === "stochup" || this.state.value === "stochleveldwn" || this.state.value === "stochlevelup" || this.state.value === "bbreturnup" || this.state.value === "rsileveldwn" || this.state.value === "atrleveldwn") {
				if (this.state.value8 === "nizhe1" || this.state.value8 === "emadown1" || this.state.value8 === "smadown1" || this.state.value8 === "tmadown1" || this.state.value8 === "wmadown1" || this.state.value8 === "diverdown") {
					if (this.state.value14 === "emacross2" || this.state.value14 === "smacross2" || this.state.value14 === "tmacross2" || this.state.value14 === "wmacross2" || this.state.value14 === "emacrosswma" || this.state.value14 === "emacrosssma" || this.state.value14 === "emacrosstma" || this.state.value14 === "wmacrossema" || this.state.value14 === "wmacrosssma" || this.state.value14 === "wmacrosstma" || this.state.value14 === "smacrossema" || this.state.value14 === "smacrosstma" || this.state.value14 === "smacrosswma" || this.state.value14 === "tmacrossema" || this.state.value14 === "tmacrosssma" || this.state.value14 === "tmacrosswma") {
						if (a > b && a1 < b1 && a01 > b01 && a02 > b02 && a21 < b21) {
							buy = 1;
							sdelki = sdelki + 1;
							price = this.state.data[i].close;
							type[sdelki] = 0;
							sdelki1[sdelki] = price;
							price1 = +price + +this.state.value3;
							dats[sdelki] = this.state.data[i].date.toGMTString();
						}
					}
				}
			}
			if (this.state.value === "emacross" || this.state.value === "macdup" || this.state.value === "stochup" || this.state.value === "stochleveldwn" || this.state.value === "stochlevelup" || this.state.value === "bbreturnup" || this.state.value === "rsileveldwn" || this.state.value === "atrleveldwn") {
				if (this.state.value8 === "nizhe1" || this.state.value8 === "emadown1" || this.state.value8 === "smadown1" || this.state.value8 === "tmadown1" || this.state.value8 === "wmadown1" || this.state.value8 === "diverdown") {
					if (this.state.value14 === "nizhe2" || this.state.value14 === "emadown2" || this.state.value14 === "smadown2" || this.state.value14 === "tmadown2" || this.state.value14 === "wmadown2") {
						if (a > b && a1 < b1 && a01 > b01 && a02 > b02) {
							buy = 1;
							sdelki = sdelki + 1;
							price = this.state.data[i].close;
							type[sdelki] = 0;
							sdelki1[sdelki] = price;
							price1 = +price + +this.state.value3;
							dats[sdelki] = this.state.data[i].date.toGMTString();
						}
					}
				}
			}
			if (this.state.value === "nizhe" || this.state.value === "emadown" || this.state.value === "smadown" || this.state.value === "tmadown" || this.state.value === "wmadown") {
				if (this.state.value8 === "vyshe1" || this.state.value8 === "emaup1") {
					if (this.state.value14 === "vyshe2" || this.state.value14 === "emaup2") {
						if (a > b && a01 < b01 && a02 < b02) {
							buy = 1;
							sdelki = sdelki + 1;
							price = this.state.data[i].close;
							type[sdelki] = 0;
							sdelki1[sdelki] = price;
							price1 = +price + +this.state.value3;
							dats[sdelki] = this.state.data[i].date.toGMTString();
						}
					}
				}
			}
			if (this.state.value === "nizhe" || this.state.value === "emadown" || this.state.value === "smadown" || this.state.value === "tmadown" || this.state.value === "wmadown") {
				if (this.state.value8 === "vyshe1" || this.state.value8 === "emaup1") {
					if (this.state.value14 === "emacross2" || this.state.value14 === "smacross2" || this.state.value14 === "tmacross2" || this.state.value14 === "wmacross2" || this.state.value14 === "emacrosswma" || this.state.value14 === "emacrosssma" || this.state.value14 === "emacrosstma" || this.state.value14 === "wmacrossema" || this.state.value14 === "wmacrosssma" || this.state.value14 === "wmacrosstma" || this.state.value14 === "smacrossema" || this.state.value14 === "smacrosstma" || this.state.value14 === "smacrosswma" || this.state.value14 === "tmacrossema" || this.state.value14 === "tmacrosssma" || this.state.value14 === "tmacrosswma") {
						if (a > b && a01 < b01 && a02 > b02 && a21 < b21) {
							buy = 1;
							sdelki = sdelki + 1;
							price = this.state.data[i].close;
							type[sdelki] = 0;
							sdelki1[sdelki] = price;
							price1 = +price + +this.state.value3;
							dats[sdelki] = this.state.data[i].date.toGMTString();
						}
					}
				}
			}
			if (this.state.value === "nizhe" || this.state.value === "emadown" || this.state.value === "smadown" || this.state.value === "tmadown" || this.state.value === "wmadown") {
				if (this.state.value8 === "vyshe1" || this.state.value8 === "emaup1") {
					if (this.state.value14 === "nizhe2" || this.state.value14 === "emadown2" || this.state.value14 === "smadown2" || this.state.value14 === "tmadown2" || this.state.value14 === "wmadown2") {
						if (a > b && a01 < b01 && a02 > b02) {
							buy = 1;
							sdelki = sdelki + 1;
							price = this.state.data[i].close;
							type[sdelki] = 0;
							sdelki1[sdelki] = price;
							price1 = +price + +this.state.value3;
							dats[sdelki] = this.state.data[i].date.toGMTString();
						}
					}
				}
			}
			if (this.state.value === "nizhe" || this.state.value === "emadown" || this.state.value === "smadown" || this.state.value === "tmadown" || this.state.value === "wmadown") {
				if (this.state.value8 === "emacross1" || this.state.value8 === "smacross1" || this.state.value8 === "tmacross1" || this.state.value8 === "wmacross1" || this.state.value8 === "emacrosswma" || this.state.value8 === "emacrosssma" || this.state.value8 === "emacrosstma" || this.state.value8 === "wmacrossema" || this.state.value8 === "wmacrosssma" || this.state.value8 === "wmacrosstma" || this.state.value8 === "smacrossema" || this.state.value8 === "smacrosstma" || this.state.value8 === "smacrosswma" || this.state.value8 === "tmacrossema" || this.state.value8 === "tmacrosssma" || this.state.value8 === "tmacrosswma") {
					if (this.state.value14 === "vyshe2" || this.state.value14 === "emaup2") {
						if (a > b && a01 > b01 && a11 < b11 && a02 < b02) {
							buy = 1;
							sdelki = sdelki + 1;
							price = this.state.data[i].close;
							type[sdelki] = 0;
							sdelki1[sdelki] = price;
							price1 = +price + +this.state.value3;
							dats[sdelki] = this.state.data[i].date.toGMTString();
						}
					}
				}
			}
			if (this.state.value === "nizhe" || this.state.value === "emadown" || this.state.value === "smadown" || this.state.value === "tmadown" || this.state.value === "wmadown") {
				if (this.state.value8 === "emacross1" || this.state.value8 === "smacross1" || this.state.value8 === "tmacross1" || this.state.value8 === "wmacross1" || this.state.value8 === "emacrosswma" || this.state.value8 === "emacrosssma" || this.state.value8 === "emacrosstma" || this.state.value8 === "wmacrossema" || this.state.value8 === "wmacrosssma" || this.state.value8 === "wmacrosstma" || this.state.value8 === "smacrossema" || this.state.value8 === "smacrosstma" || this.state.value8 === "smacrosswma" || this.state.value8 === "tmacrossema" || this.state.value8 === "tmacrosssma" || this.state.value8 === "tmacrosswma") {
					if (this.state.value14 === "emacross2" || this.state.value14 === "smacross2" || this.state.value14 === "tmacross2" || this.state.value14 === "wmacross2" || this.state.value14 === "emacrosswma" || this.state.value14 === "emacrosssma" || this.state.value14 === "emacrosstma" || this.state.value14 === "wmacrossema" || this.state.value14 === "wmacrosssma" || this.state.value14 === "wmacrosstma" || this.state.value14 === "smacrossema" || this.state.value14 === "smacrosstma" || this.state.value14 === "smacrosswma" || this.state.value14 === "tmacrossema" || this.state.value14 === "tmacrosssma" || this.state.value14 === "tmacrosswma") {
						if (a > b && a01 > b01 && a11 < b11 && a02 > b02 && a21 < b21) {
							buy = 1;
							sdelki = sdelki + 1;
							price = this.state.data[i].close;
							type[sdelki] = 0;
							sdelki1[sdelki] = price;
							price1 = +price + +this.state.value3;
							dats[sdelki] = this.state.data[i].date.toGMTString();
						}
					}
				}
			}
			if (this.state.value === "nizhe" || this.state.value === "emadown" || this.state.value === "smadown" || this.state.value === "tmadown" || this.state.value === "wmadown") {
				if (this.state.value8 === "emacross1" || this.state.value8 === "smacross1" || this.state.value8 === "tmacross1" || this.state.value8 === "wmacross1" || this.state.value8 === "emacrosswma" || this.state.value8 === "emacrosssma" || this.state.value8 === "emacrosstma" || this.state.value8 === "wmacrossema" || this.state.value8 === "wmacrosssma" || this.state.value8 === "wmacrosstma" || this.state.value8 === "smacrossema" || this.state.value8 === "smacrosstma" || this.state.value8 === "smacrosswma" || this.state.value8 === "tmacrossema" || this.state.value8 === "tmacrosssma" || this.state.value8 === "tmacrosswma") {
					if (this.state.value14 === "nizhe2" || this.state.value14 === "emadown2" || this.state.value14 === "smadown2" || this.state.value14 === "tmadown2" || this.state.value14 === "wmadown2") {
						if (a > b && a01 > b01 && a11 < b11 && a02 > b02) {
							buy = 1;
							sdelki = sdelki + 1;
							price = this.state.data[i].close;
							type[sdelki] = 0;
							sdelki1[sdelki] = price;
							price1 = +price + +this.state.value3;
							dats[sdelki] = this.state.data[i].date.toGMTString();
						}
					}
				}
			}
			if (this.state.value === "nizhe" || this.state.value === "emadown" || this.state.value === "smadown" || this.state.value === "tmadown" || this.state.value === "wmadown") {
				if (this.state.value8 === "nizhe1" || this.state.value8 === "emadown1" || this.state.value8 === "smadown1" || this.state.value8 === "tmadown1" || this.state.value8 === "wmadown1" || this.state.value8 === "diverdown") {
					if (this.state.value14 === "vyshe2" || this.state.value14 === "emaup2") {
						if (a > b && a01 > b01 && a02 < b02) {
							buy = 1;
							sdelki = sdelki + 1;
							price = this.state.data[i].close;
							type[sdelki] = 0;
							sdelki1[sdelki] = price;
							price1 = +price + +this.state.value3;
							dats[sdelki] = this.state.data[i].date.toGMTString();
						}
					}
				}
			}
			if (this.state.value === "nizhe" || this.state.value === "emadown" || this.state.value === "smadown" || this.state.value === "tmadown" || this.state.value === "wmadown") {
				if (this.state.value8 === "nizhe1" || this.state.value8 === "emadown1" || this.state.value8 === "smadown1" || this.state.value8 === "tmadown1" || this.state.value8 === "wmadown1" || this.state.value8 === "diverdown") {
					if (this.state.value14 === "emacross2" || this.state.value14 === "smacross2" || this.state.value14 === "tmacross2" || this.state.value14 === "wmacross2" || this.state.value14 === "emacrosswma" || this.state.value14 === "emacrosssma" || this.state.value14 === "emacrosstma" || this.state.value14 === "wmacrossema" || this.state.value14 === "wmacrosssma" || this.state.value14 === "wmacrosstma" || this.state.value14 === "smacrossema" || this.state.value14 === "smacrosstma" || this.state.value14 === "smacrosswma" || this.state.value14 === "tmacrossema" || this.state.value14 === "tmacrosssma" || this.state.value14 === "tmacrosswma") {
						if (a > b && a01 > b01 && a02 > b02 && a21 < b21) {
							buy = 1;
							sdelki = sdelki + 1;
							price = this.state.data[i].close;
							type[sdelki] = 0;
							sdelki1[sdelki] = price;
							price1 = +price + +this.state.value3;
							dats[sdelki] = this.state.data[i].date.toGMTString();
						}
					}
				}
			}
			if (this.state.value === "nizhe" || this.state.value === "emadown" || this.state.value === "smadown" || this.state.value === "tmadown" || this.state.value === "wmadown") {
				if (this.state.value8 === "nizhe1" || this.state.value8 === "emadown1" || this.state.value8 === "smadown1" || this.state.value8 === "tmadown1" || this.state.value8 === "wmadown1" || this.state.value8 === "diverdown") {
					if (this.state.value14 === "nizhe2" || this.state.value14 === "emadown2" || this.state.value14 === "smadown2" || this.state.value14 === "tmadown2" || this.state.value14 === "wmadown2") {
						if (a > b && a01 > b01 && a02 > b02) {
							buy = 1;
							sdelki = sdelki + 1;
							price = this.state.data[i].close;
							type[sdelki] = 0;
							sdelki1[sdelki] = price;
							price1 = +price + +this.state.value3;
							dats[sdelki] = this.state.data[i].date.toGMTString();
						}
					}
				}
			}
		}
	}
	
	
	/* РЕЖИМ ПРОДАЖИ */
	if (buy > 0) {
	/*	---------- УСЛОВИЯ --------------------	*/
		/* Цена покупки ниже цены закрытия */	
	if  (this.state.value2 === "price") {
		if (price < this.state.data[i].close) {
			buy = 0;
			pribyl = pribyl + (((this.state.data[i].close / price * 100 - 100) /100) * 1000)
				if ((((this.state.data[i].close / price * 100 - 100) /100) * 1000) < 0) {
					sdelkiminus = sdelkiminus + 1;
				}
			sdelki = sdelki + 1;
			price = this.state.data[i].close;
			type[sdelki] = 1;
			sdelki1[sdelki] = price;
			dats[sdelki] = this.state.data[i].date.toGMTString();
			price = 0;
			savemaxclose = 0;
			savemaxopen = 0;
			savemaxhigh = 0;
			savemaxlow = 0;
		}
	}
		/* Цена покупки +значение ниже цены закрытия */
	if  (this.state.value2 === "price1") {
		if (price1 < this.state.data[i].close) {
			buy = 0;
			pribyl = pribyl + (((this.state.data[i].close / price * 100 - 100) /100) * 1000)
				if ((((this.state.data[i].close / price * 100 - 100) /100) * 1000) < 0) {
					sdelkiminus = sdelkiminus + 1;
				}
			sdelki = sdelki + 1;
			price = this.state.data[i].close;
			type[sdelki] = 1;
			sdelki1[sdelki] = price;
			dats[sdelki] = this.state.data[i].date.toGMTString();
			price = 0;
			savemaxclose = 0;
			savemaxopen = 0;
			savemaxhigh = 0;
			savemaxlow = 0;
		}
	}
		/* Цена покупки *значение ниже цены закрытия */
	if  (this.state.value2 === "umnprice") {
		if (+price * +this.state.value3 < this.state.data[i].close) {
			buy = 0;
			pribyl = pribyl + (((this.state.data[i].close / price * 100 - 100) /100) * 1000)
				if ((((this.state.data[i].close / price * 100 - 100) /100) * 1000) < 0) {
					sdelkiminus = sdelkiminus + 1;
				}
			sdelki = sdelki + 1;
			price = this.state.data[i].close;
			type[sdelki] = 1;
			sdelki1[sdelki] = price;
			dats[sdelki] = this.state.data[i].date.toGMTString();
			price = 0;
			savemaxclose = 0;
			savemaxopen = 0;
			savemaxhigh = 0;
			savemaxlow = 0;
		}
	}
	/* Цена вернулась в корридор сверху */
	if  (this.state.value2 === "bbreturndwn") {
		bba1 = bba;
		bbb1 = bbb;
		bba = this.state.data[i].close;
		bbb = this.state.data[i].bb.top;
		if (bba < bbb && bba1 > bbb1) {
			buy = 0;
			pribyl = pribyl + (((this.state.data[i].close / price * 100 - 100) /100) * 1000)
				if ((((this.state.data[i].close / price * 100 - 100) /100) * 1000) < 0) {
					sdelkiminus = sdelkiminus + 1;
				}
			sdelki = sdelki + 1;
			price = this.state.data[i].close;
			type[sdelki] = 1;
			sdelki1[sdelki] = price;
			dats[sdelki] = this.state.data[i].date.toGMTString();
			price = 0;
			savemaxclose = 0;
			savemaxopen = 0;
			savemaxhigh = 0;
			savemaxlow = 0;
		}
	}
	/* RSI выше верхнего уровня */
	if  (this.state.value2 === "rsilevelup") {
		bba1 = this.state.value3;
		bbb1 = bbb;
		bba = this.state.value3;
		bbb = this.state.data[i].rsi;
			if (this.state.value3 === undefined) {
				bba = 70;
				bba1 = 70;
			}
		if (bba < bbb && bba1 > bbb1) {
			buy = 0;
			pribyl = pribyl + (((this.state.data[i].close / price * 100 - 100) /100) * 1000)
				if ((((this.state.data[i].close / price * 100 - 100) /100) * 1000) < 0) {
					sdelkiminus = sdelkiminus + 1;
				}
			sdelki = sdelki + 1;
			price = this.state.data[i].close;
			type[sdelki] = 1;
			sdelki1[sdelki] = price;
			dats[sdelki] = this.state.data[i].date.toGMTString();
			price = 0;
			savemaxclose = 0;
			savemaxopen = 0;
			savemaxhigh = 0;
			savemaxlow = 0;
		}
	}
	/* ATR опустился ниже уровня */
	if  (this.state.value2 === "atrreturndwn") {
		bba1 = this.state.value3;
		bbb1 = bbb;
		bba = this.state.value3;
		bbb = this.state.data[i].atr14;
			if (this.state.value3 === undefined) {
				bba = 1000;
				bba1 = 1000;
			}
			if (bba > bbb && bba1 < bbb1 && buy == 2) {
				buy = 0;
				pribyl = pribyl + (((this.state.data[i].close / price * 100 - 100) /100) * 1000)
					if ((((this.state.data[i].close / price * 100 - 100) /100) * 1000) < 0) {
						sdelkiminus = sdelkiminus + 1;
					}
				sdelki = sdelki + 1;
				price = this.state.data[i].close;
				type[sdelki] = 1;
				sdelki1[sdelki] = price;
				dats[sdelki] = this.state.data[i].date.toGMTString();
				price = 0;
				savemaxclose = 0;
				savemaxopen = 0;
				savemaxhigh = 0;
				savemaxlow = 0;
			 }
		if (bba < bbb && bba1 > bbb1 && buy === 1) {
			buy = 2;
		}
	}
	/*
	a1 = this.state.value1;
	b1 = b;
	a = this.state.value1;
	b = this.state.data[i].atr14;
	*/
	if  (this.state.value2 === "crossema" || this.state.value2 === "crosssma" || this.state.value2 === "crosstma" || this.state.value2 === "crosswma" || this.state.value2 === "crosswmaema" || this.state.value2 === "crosssmaema" || this.state.value2 === "crosstmaema" || this.state.value2 === "crossemawma" || this.state.value2 === "crosssmawma" || this.state.value2 === "crosstmawma" || this.state.value2 === "crossemasma" || this.state.value2 === "crosstmasma" || this.state.value2 === "crosswmasma" || this.state.value2 === "crossematma" || this.state.value2 === "crosssmatma" || this.state.value2 === "crosswmatma" || this.state.value2 === "macddown" || this.state.value2 === "stochdown") {
		if (a < b && a1 > b1) {
			buy = 0;
			pribyl = pribyl + (((this.state.data[i].close / price * 100 - 100) /100) * 1000)
				if ((((this.state.data[i].close / price * 100 - 100) /100) * 1000) < 0) {
					sdelkiminus = sdelkiminus + 1;
				}
			sdelki = sdelki + 1;
			price = this.state.data[i].close;
			type[sdelki] = 1;
			sdelki1[sdelki] = price;
			dats[sdelki] = this.state.data[i].date.toGMTString();
			price = 0;
			savemaxclose = 0;
			savemaxopen = 0;
			savemaxhigh = 0;
			savemaxlow = 0;
		}
		if (a01 < b01 && a11 > b11) {
			buy = 0;
			pribyl = pribyl + (((this.state.data[i].close / price * 100 - 100) /100) * 1000)
				if ((((this.state.data[i].close / price * 100 - 100) /100) * 1000) < 0) {
					sdelkiminus = sdelkiminus + 1;
				}
			sdelki = sdelki + 1;
			price = this.state.data[i].close;
			type[sdelki] = 1;
			sdelki1[sdelki] = price;
			dats[sdelki] = this.state.data[i].date.toGMTString();
			price = 0;
			savemaxclose = 0;
			savemaxopen = 0;
			savemaxhigh = 0;
			savemaxlow = 0;
		}
		if (a02 < b02 && a21 > b21) {
			buy = 0;
			pribyl = pribyl + (((this.state.data[i].close / price * 100 - 100) /100) * 1000)
				if ((((this.state.data[i].close / price * 100 - 100) /100) * 1000) < 0) {
					sdelkiminus = sdelkiminus + 1;
				}
			sdelki = sdelki + 1;
			price = this.state.data[i].close;
			type[sdelki] = 1;
			sdelki1[sdelki] = price;
			dats[sdelki] = this.state.data[i].date.toGMTString();
			price = 0;
			savemaxclose = 0;
			savemaxopen = 0;
			savemaxhigh = 0;
			savemaxlow = 0;
		}
	}
	if  (this.state.value2 === "crossema3") {
		if (c < c02 && c1 > c21) {
			buy = 0;
			pribyl = pribyl + (((this.state.data[i].close / price * 100 - 100) /100) * 1000)
				if ((((this.state.data[i].close / price * 100 - 100) /100) * 1000) < 0) {
					sdelkiminus = sdelkiminus + 1;
				}
			sdelki = sdelki + 1;
			price = this.state.data[i].close;
			type[sdelki] = 1;
			sdelki1[sdelki] = price;
			dats[sdelki] = this.state.data[i].date.toGMTString();
			price = 0;
			savemaxclose = 0;
			savemaxopen = 0;
			savemaxhigh = 0;
			savemaxlow = 0;
		}
	}
	}
	
	/* ----------------------------------------------------------------------------------------- */
}

		return (
			<div style={{backgroundColor:"#ffffff"}}>
			<TypeChooser>
				{type => <Chart type={type} data={this.state.data} value6={this.state.value6} value7={this.state.value7} value10={this.state.value10} value11={this.state.value11} value12={this.state.value12} value13={this.state.value13} value={this.state.value} value1={this.state.value1} value8={this.state.value8} value9={this.state.value9} value14={this.state.value14} value15={this.state.value15} canvas={this.state.canvas} canvas1={this.state.canvas1} />}
			</TypeChooser>
			{this.state.time > 0 ? <h3 style={{color: 'red'}}>Активирован полный график на 10 минут</h3>: null}
			Индикатор
			<Popup trigger=
                {<button> 1 </button>}
                position="right center">
      <form onSubmit={this.handleSubmit3}>
        <label>
          <select value={this.state.value6} onChange={this.handleChange6}>
		  	<option selected="true" disabled="disabled" >Выберите индикатор</option>
			<option value="ema">EMA</option>
			<option value="sma">SMA</option>
			<option value="tma">TMA</option>
			<option value="wma">WMA</option>
			<option value="atr">ATR</option>
			<option value="macd">MACD</option>
			<option value="rsi">RSI</option>
			<option value="bb">Bollinger Band</option>
			<option value="stoch">Stochastic Oscillator</option>
          </select>
        </label>
		<input type="number" value={this.state.value7} onChange={this.handleChange7}/>
      </form>
            </Popup>
{(this.state.uslovie > 1) ? <Popup trigger=
                {<button> 2 </button>}
                position="right center">
      <form onSubmit={this.handleSubmit5}>
        <label>
          <select value={this.state.value10} onChange={this.handleChange10}>
		  	<option selected="true" disabled="disabled" >Выберите индикатор</option>
			<option value="ema">EMA</option>
			<option value="sma">SMA</option>
			<option value="tma">TMA</option>
			<option value="wma">WMA</option>
          </select>
        </label>
		<input type="number" value={this.state.value11} onChange={this.handleChange11}/>
      </form>
            </Popup> : null}
{(this.state.uslovie > 2) ? <Popup trigger=
                {<button> 3 </button>}
                position="right center">
      <form onSubmit={this.handleSubmit6}>
        <label>
          <select value={this.state.value12} onChange={this.handleChange12}>
		  	<option selected="true" disabled="disabled" >Выберите индикатор</option>
			<option value="ema">EMA</option>
			<option value="sma">SMA</option>
			<option value="tma">TMA</option>
			<option value="wma">WMA</option>
          </select>
        </label>
		<input type="number" value={this.state.value13} onChange={this.handleChange13}/>
      </form>
            </Popup> : null}
			{(this.state.uslovie < 3) ? <button onClick={() => {this.setState({uslovie: this.state.uslovie + 1})}}> + </button>: null}
			{(this.state.uslovie > 1) ? <button onClick={() => {this.setState({uslovie: this.state.uslovie - 1})}}> - </button>: null}
			
			<button onClick={() => {location.reload()}}> Сбросить </button>
			<p></p>
			Скрипт покупки:
		  <Popup trigger=
                {<button> 1 </button>}
                position="right center">
      <form onSubmit={this.handleSubmit}>
        <label>
          <select value={this.state.value} onChange={this.handleChange}>
		  	<option selected="true" disabled="disabled" >Выберите условие</option>
			<option value="nizhe">Закрытие ниже отметки</option>
			<option value="vyshe">Закрытие выше отметки</option>
			{(this.state.value6 === "ema" && this.state.value7 > 0) ? <option value="emaup">Закрытие выше EMA</option> : null}
			{(this.state.value6 === "ema" && this.state.value7 > 0) ? <option value="emadown">Закрытие ниже EMA</option> : null}
			{(this.state.value6 === "wma" && this.state.value7 > 0) ? <option value="wmadown">Закрытие ниже WMA</option> : null}
			{(this.state.value6 === "sma" && this.state.value7 > 0) ? <option value="smadown">Закрытие ниже SMA</option> : null}
			{(this.state.value6 === "tma" && this.state.value7 > 0) ? <option value="tmadown">Закрытие ниже TMA</option> : null}
			{(this.state.value10 === "ema" && this.state.value6 === "ema" && this.state.value11 > 0 && this.state.value7 > 0) ? <option value="emacross">EMA2 пересекает EMA1</option> : null}
			{(this.state.value6 === "macd") ? <option value="macdup">MACD пересекает Signal</option> : null}
			{(this.state.value6 === "stoch") ? <option value="stochup">KStoch пересекает DStoch вверх</option> : null}
			{(this.state.value6 === "stoch") ? <option value="stochleveldwn">KStoch и DStoch пересекает нижний уровень</option> : null}
			{(this.state.value6 === "stoch") ? <option value="stochlevelup">KStoch и DStoch пересекает верхний уровень</option> : null}
			{(this.state.value6 === "bb") ? <option value="bbreturnup">Цена вернулась в корридор снизу</option> : null}
			{(this.state.value6 === "rsi") ? <option value="rsileveldwn">RSI ниже уровня</option> : null}
			{(this.state.value6 === "atr") ? <option value="atrleveldwn">ATR ниже уровня</option> : null}
			{/*
			<option value="opnizhe">Открытие ниже отметки</option>
			<option value="hinizhe">Хай ниже отметки</option>
			<option value="lonizhe">Лоу ниже отметки</option>
			<option value="opvyshe">Открытие выше отметки</option>
			<option value="hivyshe">Хай выше отметки</option>
			<option value="lovyshe">Лоу выше отметки</option>
            <option value="ravno">Закрытие рвно отметке</option>
            <option value="opravno">Открытие рвно отметке</option>
            <option value="hiravno">Хай рвно отметке</option>
            <option value="loravno">Лоу рвно отметке</option>
			<option value="downsave">Закрытие ниже хранимого</option>
			<option value="downotmetsave">Закрытие ниже хранимого/отметку</option>
			*/}
          </select>
        </label>
		<input type="number" value={this.state.value1} onChange={this.handleChange1}/>
      </form>
	  </Popup>

{(this.state.uslovie > 1) ? <Popup trigger= 
 {<button> 2 </button>}
 position="right center">
      <form onSubmit={this.handleSubmit4}>
        <label>
          <select value={this.state.value8} onChange={this.handleChange8}>
		  	<option selected="true" disabled="disabled" >Выберите условие</option>
			<option value="nizhe1">Закрытие ниже отметки</option>
			<option value="vyshe1">Закрытие выше отметки</option>
			{(this.state.value10 === "ema") ? <option value="emaup1">Закрытие выше EMA</option> : null}
			{(this.state.value10 === "ema") ? <option value="emadown1">Закрытие ниже EMA</option> : null}
			{(this.state.value10 === "sma" && this.state.value11 > 0) ? <option value="smadown1">Закрытие ниже SMA</option> : null}
			{(this.state.value10 === "tma" && this.state.value11 > 0) ? <option value="tmadown1">Закрытие ниже TMA</option> : null}
			{(this.state.value10 === "wma" && this.state.value11 > 0) ? <option value="wmadown1">Закрытие ниже WMA</option> : null}
			{(this.state.value10 === "ema" && this.state.value6 === "ema" && this.state.value11 > 0 && this.state.value7 > 0) ? <option value="emacross1">EMA1 пересекает EMA2</option> : null}
			{(this.state.value10 === "wma" && this.state.value6 === "ema" && this.state.value11 > 0 && this.state.value7 > 0) ? <option value="emacrosswma">EMA пересекает WMA</option> : null}
			{(this.state.value10 === "sma" && this.state.value6 === "ema" && this.state.value11 > 0 && this.state.value7 > 0) ? <option value="emacrosssma">EMA пересекает SMA</option> : null}
			{(this.state.value10 === "tma" && this.state.value6 === "ema" && this.state.value11 > 0 && this.state.value7 > 0) ? <option value="emacrosstma">EMA пересекает TMA</option> : null}
			{(this.state.value10 === "ema" && this.state.value6 === "wma" && this.state.value11 > 0 && this.state.value7 > 0) ? <option value="wmacrossema">WMA пересекает EMA</option> : null}
			{(this.state.value10 === "sma" && this.state.value6 === "wma" && this.state.value11 > 0 && this.state.value7 > 0) ? <option value="wmacrosssma">WMA пересекает SMA</option> : null}
			{(this.state.value10 === "tma" && this.state.value6 === "wma" && this.state.value11 > 0 && this.state.value7 > 0) ? <option value="wmacrosstma">WMA пересекает TMA</option> : null}
			{(this.state.value10 === "wma" && this.state.value6 === "wma" && this.state.value11 > 0 && this.state.value7 > 0) ? <option value="wmacross1">WMA1 пересекает WMA2</option> : null}
			{(this.state.value10 === "ema" && this.state.value6 === "sma" && this.state.value11 > 0 && this.state.value7 > 0) ? <option value="smacrossema">SMA пересекает EMA</option> : null}
			{(this.state.value10 === "tma" && this.state.value6 === "sma" && this.state.value11 > 0 && this.state.value7 > 0) ? <option value="smacrosstma">SMA пересекает TMA</option> : null}
			{(this.state.value10 === "wma" && this.state.value6 === "sma" && this.state.value11 > 0 && this.state.value7 > 0) ? <option value="smacrosswma">SMA пересекает WMA</option> : null}
			{(this.state.value10 === "sma" && this.state.value6 === "sma" && this.state.value11 > 0 && this.state.value7 > 0) ? <option value="smacross1">SMA1 пересекает SMA2</option> : null}
			{(this.state.value10 === "ema" && this.state.value6 === "tma" && this.state.value11 > 0 && this.state.value7 > 0) ? <option value="tmacrossema">TMA пересекает EMA</option> : null}
			{(this.state.value10 === "sma" && this.state.value6 === "tma" && this.state.value11 > 0 && this.state.value7 > 0) ? <option value="tmacrosssma">TMA пересекает SMA</option> : null}
			{(this.state.value10 === "wma" && this.state.value6 === "tma" && this.state.value11 > 0 && this.state.value7 > 0) ? <option value="tmacrosswma">TMA пересекает WMA</option> : null}
			{(this.state.value10 === "tma" && this.state.value6 === "tma" && this.state.value11 > 0 && this.state.value7 > 0) ? <option value="tmacross1">TMA1 пересекает TMA2</option> : null}
			{(this.state.value6 === "macd") ? <option value="diverdown">Divergence меньше значения</option> : null}
          </select>
        </label>
		<input type="number" value={this.state.value9} onChange={this.handleChange9}/>
      </form>
            </Popup> : null}
			{(this.state.uslovie === 3) ? <Popup trigger= 
 {<button> 3 </button>}
 position="right center">
      <form onSubmit={this.handleSubmit7}>
        <label>
          <select value={this.state.value14} onChange={this.handleChange14}>
		  	<option selected="true" disabled="disabled" >Выберите условие</option>
			<option value="nizhe2">Закрытие ниже отметки</option>
			<option value="vyshe2">Закрытие выше отметки</option>
			{(this.state.value12 === "ema" && this.state.value13 > 0) ? <option value="emaup2">Закрытие выше EMA</option> : null}
			{(this.state.value12 === "ema" && this.state.value13 > 0) ? <option value="emadown2">Закрытие ниже EMA</option> : null}
			{(this.state.value12 === "sma" && this.state.value13 > 0) ? <option value="smadown2">Закрытие ниже SMA</option> : null}
			{(this.state.value12 === "tma" && this.state.value13 > 0) ? <option value="tmadown2">Закрытие ниже TMA</option> : null}
			{(this.state.value12 === "wma" && this.state.value13 > 0) ? <option value="wmadown2">Закрытие ниже WMA</option> : null}
			{(this.state.value12 === "ema" && this.state.value10 === "ema" && this.state.value13 > 0 && this.state.value11 > 0) ? <option value="emacross2">EMA2 пересекает EMA3</option> : null}
			{(this.state.value12 === "wma" && this.state.value10 === "ema" && this.state.value13 > 0 && this.state.value11 > 0) ? <option value="emacrosswma">EMA пересекает WMA</option> : null}
			{(this.state.value12 === "sma" && this.state.value10 === "ema" && this.state.value13 > 0 && this.state.value11 > 0) ? <option value="emacrosssma">EMA пересекает SMA</option> : null}
			{(this.state.value12 === "tma" && this.state.value10 === "ema" && this.state.value13 > 0 && this.state.value11 > 0) ? <option value="emacrosstma">EMA пересекает TMA</option> : null}
			{(this.state.value12 === "ema" && this.state.value10 === "wma" && this.state.value13 > 0 && this.state.value11 > 0) ? <option value="wmacrossema">WMA пересекает EMA</option> : null}
			{(this.state.value12 === "sma" && this.state.value10 === "wma" && this.state.value13 > 0 && this.state.value11 > 0) ? <option value="wmacrosssma">WMA пересекает SMA</option> : null}
			{(this.state.value12 === "tma" && this.state.value10 === "wma" && this.state.value13 > 0 && this.state.value11 > 0) ? <option value="wmacrosstma">WMA пересекает TMA</option> : null}
			{(this.state.value12 === "wma" && this.state.value10 === "wma" && this.state.value13 > 0 && this.state.value11 > 0) ? <option value="wmacross2">WMA2 пересекает WMA3</option> : null}
			{(this.state.value12 === "ema" && this.state.value10 === "sma" && this.state.value13 > 0 && this.state.value11 > 0) ? <option value="smacrossema">SMA пересекает EMA</option> : null}
			{(this.state.value12 === "tma" && this.state.value10 === "sma" && this.state.value13 > 0 && this.state.value11 > 0) ? <option value="smacrosstma">SMA пересекает TMA</option> : null}
			{(this.state.value12 === "wma" && this.state.value10 === "sma" && this.state.value13 > 0 && this.state.value11 > 0) ? <option value="smacrosswma">SMA пересекает WMA</option> : null}
			{(this.state.value12 === "sma" && this.state.value10 === "sma" && this.state.value13 > 0 && this.state.value11 > 0) ? <option value="smacross2">SMA2 пересекает SMA3</option> : null}
			{(this.state.value12 === "ema" && this.state.value10 === "tma" && this.state.value13 > 0 && this.state.value11 > 0) ? <option value="tmacrossema">TMA пересекает EMA</option> : null}
			{(this.state.value12 === "sma" && this.state.value10 === "tma" && this.state.value13 > 0 && this.state.value11 > 0) ? <option value="tmacrosssma">TMA пересекает SMA</option> : null}
			{(this.state.value12 === "wma" && this.state.value10 === "tma" && this.state.value13 > 0 && this.state.value11 > 0) ? <option value="tmacrosswma">TMA пересекает WMA</option> : null}
			{(this.state.value12 === "tma" && this.state.value10 === "tma" && this.state.value13 > 0 && this.state.value11 > 0) ? <option value="tmacross2">TMA2 пересекает TMA3</option> : null}
          </select>
        </label>
		<input type="number" value={this.state.value15} onChange={this.handleChange15}/>
      </form>
            </Popup> : null}
	  	{/* 			Храним:
			<Popup trigger=
                {<button> + </button>}
                position="right center">
      <form onSubmit={this.handleSubmit2}>
        <label>
          <select value={this.state.value4} onChange={this.handleChange4}>
		  	<option selected="true" disabled="disabled" >Выберите условие</option>
		  	<option value="closevysh">Максимальное закрытие</option>
			<option value="opvysh">Максимальное открытие</option>
			<option value="hivysh">Максимум Хай</option>
			<option value="lovysh">Максимальный Лоу</option>
			<option value="closevyshplus">Максимальное закрытие +значение</option>
			<option value="opvyshplus">Максимальное открытие +значение</option>
			<option value="hivyshplus">Максимальный Хай +значение</option>
			<option value="lovyshplus">Максимальный Лоу +значение</option>
			<option value="closevyshumn">Максимальное закрытие *значение</option>
			<option value="opvyshumn">Максимальное открытие *значение</option>
			<option value="hivyshumn">Максимальный Хай *значение</option>
			<option value="lovyshumn">Максимальный Лоу *значение</option>
			<option value="closevyshdel">Максимальное закрытие /значение</option>
			<option value="opvyshdel">Максимальное открытие /значение</option>
			<option value="hivyshdel">Максимальный Хай /значение</option>
			<option value="lovyshdel">Максимальный Лоу /значение</option>
          </select>
        </label>
		<input type="number" value={this.state.value5} onChange={this.handleChange5}/>
        <input type="submit" value="Подтвердить" />
      </form> 
            </Popup>
			*/}
			<p></p>
			Скрипт продажи:
			<Popup trigger=
                {<button> 1 </button>}
                position="right center">
      <form onSubmit={this.handleSubmit1}>
	  <label>
          <select value={this.state.value2} onChange={this.handleChange2}>
		  	<option selected="true" disabled="disabled" >Выберите условие</option>
		  	<option value="price">Закрытие выше покупки</option>
            <option value="price1">Закрытие выше покупки +значение</option>
			<option value="umnprice">Закрытие выше покупки *значение</option>
			{(this.state.value14 === "emacross2" || this.state.value8 === "emacross1") ? <option value="crossema">EMA пересекает обратно EMA</option> : null}
			{(this.state.value14 === "smacross2" || this.state.value8 === "smacross1") ? <option value="crosssma">SMA пересекает обратно SMA</option> : null}
			{(this.state.value14 === "tmacross2" || this.state.value8 === "tmacross1") ? <option value="crosstma">TMA пересекает обратно TMA</option> : null}
			{(this.state.value14 === "wmacross2" || this.state.value8 === "wmacross1") ? <option value="crosswma">WMA пересекает обратно WMA</option> : null}
			{(this.state.value6 === "ema" && this.state.value10 === "ema" && this.state.value12 === "ema") ? <option value="crossema3">EMA1 пересекает обратно EMA3</option> : null}
			{(this.state.value14 === "emacrosswma" || this.state.value8 === "emacrosswma") ? <option value="crosswmaema">WMA пересекает обратно EMA</option> : null}
			{(this.state.value14 === "emacrosssma" || this.state.value8 === "emacrosssma") ? <option value="crosssmaema">SMA пересекает обратно EMA</option> : null}
			{(this.state.value14 === "emacrosstma" || this.state.value8 === "emacrosstma") ? <option value="crosstmaema">TMA пересекает обратно EMA</option> : null}
			{(this.state.value14 === "wmacrossema" || this.state.value8 === "wmacrossema") ? <option value="crossemawma">EMA пересекает обратно WMA</option> : null}
			{(this.state.value14 === "wmacrosssma" || this.state.value8 === "wmacrosssma") ? <option value="crosssmawma">SMA пересекает обратно WMA</option> : null}
			{(this.state.value14 === "wmacrosstma" || this.state.value8 === "wmacrosstma") ? <option value="crosstmawma">TMA пересекает обратно WMA</option> : null}
			{(this.state.value14 === "smacrossema" || this.state.value8 === "smacrossema") ? <option value="crossemasma">EMA пересекает обратно SMA</option> : null}
			{(this.state.value14 === "smacrosstma" || this.state.value8 === "smacrosstma") ? <option value="crosstmasma">TMA пересекает обратно SMA</option> : null}
			{(this.state.value14 === "smacrosswma" || this.state.value8 === "smacrosswma") ? <option value="crosswmasma">WMA пересекает обратно SMA</option> : null}
			{(this.state.value14 === "tmacrossema" || this.state.value8 === "tmacrossema") ? <option value="crossematma">EMA пересекает обратно TMA</option> : null}
			{(this.state.value14 === "tmacrosssma" || this.state.value8 === "tmacrosssma") ? <option value="crosssmatma">SMA пересекает обратно TMA</option> : null}
			{(this.state.value14 === "tmacrosswma" || this.state.value8 === "tmacrosswma") ? <option value="crosswmatma">WMA пересекает обратно TMA</option> : null}
			{(this.state.value === "macdup") ? <option value="macddown">Signal пересекает MACD</option> : null}
			{(this.state.value6 === "stoch") ? <option value="stochdown">KStoch пересекает DStoch вниз</option> : null}
			{(this.state.value6 === "bb") ? <option value="bbreturndwn">Цена вернулась в корридор сверху</option> : null}
			{(this.state.value6 === "rsi") ? <option value="rsilevelup">RSI выше верхнего уровня</option> : null}
			{(this.state.value6 === "atr") ? <option value="atrreturndwn">ATR вернулся ниже уровня</option> : null}
          </select>
        </label>
		<input type="number" value={this.state.value3} onChange={this.handleChange3}/>
      </form>
            </Popup>
			<p></p>
			{pribyl > 17000 && sdelki > 4 ? <h2>Поздравляю, отличный результат! Вы можете оставить заявку на размещение робота на продажу.</h2> : null}
			<p></p>
			<p></p>
			{//sdelki > 0 ? <button onClick={this.saveMe}>Сохранить скрипт себе?</button> : null}
	}
			<p></p>
			Статистика: <h2>При вложеннии по $1000 Прибыль составила: ${pribyl} (%{Math.floor(pribyl/10)})</h2>
			<p></p>
			Количество сделок: {sdelki}
			<p></p>
			Из них убыточных: {sdelkiminus}
			<p></p>
			  {sdelki1.map((e, index) => {
        return (
          <div key={index}>
            <h2>Сделка {index}: Тип: {type[index] < 1 ? "Покупка" : "Продажа"} Цена: ${sdelki1[index]}; Дата: {dats[index]}</h2>
            <hr />
          </div>
        );
      })}
	  			{this.state.time == 0 && this.state.coin > 0 && pribyl > 0 && localStorage.getItem('id') !== null ? <><button onClick={this.activMe}>Активировать</button> <h3> полный график за 1 SmartCoin на 10 мин, чтобы протестировать робота на всей истории.</h3> </>: null}
	  			{localStorage.getItem('id') == null ? <h3 style={{color: "red"}}><Link to='/create' >Зарегистрируйтесь</Link>, чтобы протестировать робота на полном графике со всей историей.</h3> : null}
	  			{this.state.coin == 0 && pribyl > 0 && localStorage.getItem('id') !== null ? <><h3>Чтобы протестировать робота на полном графике со всей историей, приобретите SmartCoin.</h3><p></p><button onClick={this.payMe}>Приобрести</button></> : null}
				  {console.log(this.state.coin)}
			</div>
		)
/*
		function showList () {
            const courses = document.querySelector(".courses");
			console.log (courses);
			if (courses.style.display == "block") {
      courses.style.display = "none";
            } else {
                courses.style.display = "block";
            }
        }
*/

	}
}



render(
	<ChartComponent />,
	document.getElementById("root")
);

export default ChartComponent;