import axios from 'axios'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useParams, useHistory } from 'react-router-dom'
//import { useNavigate, useParams } from 'react-router-dom'

function Read() {
        let history = useHistory();
        const {id} = useParams()
//        const navigate = useNavigate()
//console.log(id, localStorage.getItem("name"));
if (id == null) {
    history.push('/')
}
{/*
axios.get('http://smartex.site/api/users/').then(function(response){
    console.log(response.data);
})

axios.get('http://localhost:3001/users/'+id)
.then(res => {
    if (res.data.name != localStorage.getItem("name")) {
        history.push('/root')
    }
})
*/}
        const [user, setUser] = useState([])
    useEffect(() => {
                axios.get('http://smartex.site/api/users/'+id)
                        .then(res => setUser(res.data))
                                .catch(err => console.log(err))
                                },
                                 []);

if (localStorage.getItem("name")) {
    null;
} else {
    location.replace('http://smartex.site')
}

function payMe() {
    const url = 'https://api.cryptocloud.plus/v2/invoice/create';
    const headers = {
        'Authorization': 'Token eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1dWlkIjoiTVRnd05EWT0iLCJ0eXBlIjoicHJvamVjdCIsInYiOiI4ZGU2NTBmN2RmZTYxYTRlNTc4YjQ2MmE4NWZhZTk3MDA2MjVlNTJhZTZjYjYxOTM5M2Y2M2FjMDJmODg3ZjAyIiwiZXhwIjo4ODEwNDIxODc5N30.qFKqzGrrGfryDuu0sBV4_AQh3PV2RcwjgfvRvW1UHsQ',
        'Content-Type': 'application/json'
    };
    const data = {
        amount: 10,
        shop_id: '2gHwYS8pCDX50sx4',
        currency: 'USD'
    };
    axios.post(url, data, { headers })
        .then(response => {
            console.log('Success:', response.data);
            axios.put(`http://smartex.site/api/user/${localStorage.getItem("id")}/edit`, {id: localStorage.getItem("id"), name: user.name, email: user.email, password: user.password, coin: user.coin, ref: user.ref, payment: (user.payment + "," + response.data.result.uuid)});
//            window.open(`${response.data.result.link}`);
            location.replace(`${response.data.result.link}`);
        })
        .catch(error => {
            console.error('Fail:', error);
        });
};

  return (<>
{user.name == localStorage.getItem("name") ? 
<div className='d-flex w-100 vh-100 justify-content-center align-items-center'>
<div className='w-50 border bg-secondary text-white p-5'>
    <h3>Информация</h3>
    <div className=' text-white'>
{/*                     <p>{user.id}</p>    */}
        <span>Имя: {user.name}</span>
        <p><span>Email: {user.email}</span></p>
        <p><span>SmartCoins: {user.coin} </span><button onClick={payMe}>Приобрести</button></p>
        <p><span>Ваш реферальный номер: {'R-0000'+user.id}</span></p>
{// user.save[1].one == 1 ? <p>{user.save[1].ind}, {user.save[1].indcount}</p> : null }
}
{// user.save[2].one == 2 ? <p>{user.save[2].one}, {user.save[2].ind}</p> : null }
}
        <a href="http://smartex.site" className='btn btn-primary'>На главную</a>
        </div>
         </div>    </div>
          : null
}        
</>                      )}
export default Read