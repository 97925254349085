import React from 'react'

function Errorpay() {
    return (
<>
<div data-elementor-type="wp-page" data-elementor-id="2" className='elementor elementor-2' data-elementor-post-type="page">
	<section className='elementor-section elementor-top-section elementor-element elementor-element-97e8c04 elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="97e8c04" data-element_type="section" id="home" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
		<div className='elementor-background-overlay'></div>
		<div className='elementor-container elementor-column-gap-default'>
			<div className='elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4964962' data-id="4964962" data-element_type="column">
			    <div className='elementor-widget-wrap elementor-element-populated'>
					<div className='elementor-element elementor-element-49ff458 elementor-widget elementor-widget-heading' data-id="49ff458" data-element_type="widget" data-widget_type="heading.default">
        				<div className='elementor-widget-container'>
			                <h1 className='elementor-heading-title elementor-size-default'>Ошибка оплаты! Обратитесь к администратору.</h1>		
                        </div>
    				</div>
				</div>
    		</div>
		</div>
	</section>
</div>
</>
)
}
export default Errorpay