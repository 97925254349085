import React from 'react';
import {Link} from 'react-router-dom';

function Novice() {
  return (
<>
<div className='elementor-background-overlay'>
</div>
<div className='elementor-container elementor-column-gap-default'>
	<div className='elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4964962' data-id="4964962" data-element_type="column">
	    <div className='elementor-widget-wrap elementor-element-populated'>
			<div className='elementor-element elementor-element-49ff458 elementor-widget elementor-widget-heading' data-id="49ff458" data-element_type="widget" data-widget_type="heading.default">
       			<div className='elementor-widget-container'>
		            <h1 style={{textAlign: 'center'}} className='elementor-heading-title elementor-size-default'>Доступная информация для новичков. Пошагово собрали основные моменты:</h1>		
                </div>
   			</div>
		</div>
	</div>					
</div>

<section classList='elementor-section elementor-top-section elementor-element elementor-element-2f623fe elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="2f623fe" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
	<div classList='elementor-background-overlay'></div>
	<div classList='elementor-container elementor-column-gap-default'>
		<div classList='elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-24c056d' data-id="24c056d" data-element_type="column">
			<div classList='elementor-widget-wrap elementor-element-populated'>
				<div classList='elementor-element elementor-element-3d7f17f elementor-widget__width-initial elementor-widget elementor-widget-heading' data-id="3d7f17f" data-element_type="widget" data-widget_type="heading.default">
					<div classList='elementor-widget-container'>
						<h1 style={{textAlign: 'center', color: '#FFFFFF', fontWeight: '700', fontSize: '58px'}}classList='elementor-heading-title elementor-size-default'>1. Что такое <span style={{background: 'linear-gradient(1830deg, #11D4DD 25%, #FC4FF6 71%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent'}}>ТРЕЙДИНГ?</span></h1>
					</div>
				</div>
				<div classList='elementor-element elementor-element-ac1c8e8 elementor-widget elementor-widget-video' data-id="ac1c8e8" data-element_type="widget" data-settings="{&quot;youtube_url&quot;:&quot;https:\/\/youtu.be\/86cvSqm2oTI&quot;,&quot;video_type&quot;:&quot;youtube&quot;}" data-widget_type="video.default">
					<div classList='elementor-widget-container' style={{overflow: 'hidden', transform: 'translateZ(0)'}}>
						<div classList='elementor-wrapper elementor-open-inline' style={{aspectRatio: 'var(--video-aspect-ratio)', display: 'block'}}>
{/*	                                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/86cvSqm2oTI?si=fvY0mQTtj1auLyTM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>   */}
{/*							<iframe style={{display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '920', height: '630px'}} classList='elementor-video' frameborder="0" allowfullscreen="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="How We Are Making Over $800 Weekly With Crypto Bots" width="1120" height="360" src="https://www.youtube.com/embed/e6GxZhiKeCI?si=mUGZuSK8wSSroZiF?controls=0&amp;rel=0&amp;playsinline=0&amp;modestbranding=0&amp;autoplay=0&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fapexbot.ai&amp;widgetid=1" id="widget2" data-gtm-yt-inspected-6="true"></iframe>	*/}
							<iframe style={{display: 'block', marginLeft: 'auto', marginRight: 'auto'}} width="720" height="405" src="https://rutube.ru/play/embed/130ed4108ebf9850b65ca5368052866c" frameBorder="0" allow="clipboard-write; autoplay" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
						</div>
					</div>
				</div>
				<section className='elementor-section elementor-top-section elementor-element elementor-element-97e8c04 elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="97e8c04" data-element_type="section" id="home" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
					<div className='elementor-container elementor-column-gap-default'>
						<div className='elementor-element elementor-element-49ff458 elementor-widget elementor-widget-heading' data-id="49ff458" data-element_type="widget" data-widget_type="heading.default">
							<div className='elementor-widget-container'>
								<h1 style={{textAlign: 'center', color: 'red'}} className='elementor-heading-title elementor-size-default'>Ручной трейдинг занимает уйму времени, которое уходит на слежение за индикаторами и выявление точек покупки. Так же, человек находится в постоянном стрессе из-за колебаний цен.</h1>
								<h1 style={{textAlign: 'center', color: 'blue'}} className='elementor-heading-title elementor-size-default'>Наша платформа берет на себя работу по отслеживанию точек покупки и продажи, освобождая Ваше время и избавляя от лишнего стресса.</h1>
							</div>
						</div>
					</div>
				</section>
				
				<div classList='elementor-element elementor-element-3d7f17f elementor-widget__width-initial elementor-widget elementor-widget-heading' data-id="3d7f17f" data-element_type="widget" data-widget_type="heading.default">	
					<div classList='elementor-widget-container'>
						<h1 style={{textAlign: 'center', color: '#FFFFFF', fontWeight: '700', fontSize: '58px'}}classList='elementor-heading-title elementor-size-default'>2. Что такое <span style={{background: 'linear-gradient(1830deg, #11D4DD 25%, #FC4FF6 71%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent'}}>АЛГОТРЕЙДИНГ?</span></h1>
					</div>
				</div>
				<div classList='elementor-element elementor-element-ac1c8e8 elementor-widget elementor-widget-video' data-id="ac1c8e8" data-element_type="widget" data-settings="{&quot;youtube_url&quot;:&quot;https:\/\/youtu.be\/86cvSqm2oTI&quot;,&quot;video_type&quot;:&quot;youtube&quot;}" data-widget_type="video.default">
					<div classList='elementor-widget-container' style={{overflow: 'hidden', transform: 'translateZ(0)'}}>
						<div classList='elementor-wrapper elementor-open-inline' style={{aspectRatio: 'var(--video-aspect-ratio)', display: 'block'}}>
{/*							<iframe style={{display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '920', height: '630px'}} classList='elementor-video' frameborder="0" allowfullscreen="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="How We Are Making Over $800 Weekly With Crypto Bots" width="1120" height="360" src="https://www.youtube.com/embed/JluTQl6Cnk0?si=rnn0kYpCXX6wKULC?controls=0&amp;rel=0&amp;playsinline=0&amp;modestbranding=0&amp;autoplay=0&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fapexbot.ai&amp;widgetid=1" id="widget2" data-gtm-yt-inspected-6="true"></iframe>	*/}
							<iframe style={{display: 'block', marginLeft: 'auto', marginRight: 'auto'}} width="720" height="405" src="https://rutube.ru/play/embed/cd110695e30851610e50e25c267a46a4" frameBorder="0" allow="clipboard-write; autoplay" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
						</div>
					</div>
				</div>
				<section className='elementor-section elementor-top-section elementor-element elementor-element-97e8c04 elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="97e8c04" data-element_type="section" id="home" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
					<div className='elementor-container elementor-column-gap-default'>
						<div className='elementor-element elementor-element-49ff458 elementor-widget elementor-widget-heading' data-id="49ff458" data-element_type="widget" data-widget_type="heading.default">
							<div className='elementor-widget-container'>
								<h1 style={{textAlign: 'center', color: 'red'}} className='elementor-heading-title elementor-size-default'>Алготрейдинг требует наличия навыков:
									1.Количественного анализа
									2.Программирования
									3.Трейдинга или знания финансовых рынков
									4.Идеи для стратегии
									<p></p>Так же, необходимо наличие:
									1.Программного инструмента
									2.Количественных исторических данных
									3.Серверной инфраструктуры</h1>
								<h1 style={{textAlign: 'center', color: 'blue'}} className='elementor-heading-title elementor-size-default'>Мы предусмотрели все это на нашей платформе и все программные ресурсы и исторические данные включили в оболочку сайта. Навыки программирования Вам не потребуются, мы так же взяли это на себя.</h1>
							</div>
						</div>
					</div>
				</section>

				<div classList='elementor-element elementor-element-3d7f17f elementor-widget__width-initial elementor-widget elementor-widget-heading' data-id="3d7f17f" data-element_type="widget" data-widget_type="heading.default">
					<div classList='elementor-widget-container'>
						<h1 style={{textAlign: 'center', color: '#FFFFFF', fontWeight: '700', fontSize: '58px'}}classList='elementor-heading-title elementor-size-default'>3. Свечной анализ <span style={{background: 'linear-gradient(1830deg, #11D4DD 25%, #FC4FF6 71%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent'}}>ЯПОНСКИЕ СВЕЧИ</span></h1>
					</div>
				</div>
				<div classList='elementor-element elementor-element-ac1c8e8 elementor-widget elementor-widget-video' data-id="ac1c8e8" data-element_type="widget" data-settings="{&quot;youtube_url&quot;:&quot;https:\/\/youtu.be\/86cvSqm2oTI&quot;,&quot;video_type&quot;:&quot;youtube&quot;}" data-widget_type="video.default">
					<div classList='elementor-widget-container' style={{overflow: 'hidden', transform: 'translateZ(0)'}}>
						<div classList='elementor-wrapper elementor-open-inline' style={{aspectRatio: 'var(--video-aspect-ratio)', display: 'block'}}>
{/*							<iframe style={{display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '920', height: '630px'}} classList='elementor-video' frameborder="0" allowfullscreen="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="How We Are Making Over $800 Weekly With Crypto Bots" width="1120" height="360" src="https://www.youtube.com/embed/kofVHSp3XQI?si=roou9pW2ygHMO0EQ?controls=0&amp;rel=0&amp;playsinline=0&amp;modestbranding=0&amp;autoplay=0&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fapexbot.ai&amp;widgetid=1" id="widget2" data-gtm-yt-inspected-6="true"></iframe>	*/}
							<iframe style={{display: 'block', marginLeft: 'auto', marginRight: 'auto'}} width="720" height="405" src="https://rutube.ru/play/embed/5f612aa96840df98c4beff431f837a61" frameBorder="0" allow="clipboard-write; autoplay" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
						</div>
					</div>
				</div>
				<section className='elementor-section elementor-top-section elementor-element elementor-element-97e8c04 elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="97e8c04" data-element_type="section" id="home" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
					<div className='elementor-container elementor-column-gap-default'>
						<div className='elementor-element elementor-element-49ff458 elementor-widget elementor-widget-heading' data-id="49ff458" data-element_type="widget" data-widget_type="heading.default">
							<div className='elementor-widget-container'>
								<h1 style={{textAlign: 'center', color: 'blue'}} className='elementor-heading-title elementor-size-default'>Ролик описывает базовые техники свечного анализа.</h1>
							</div>
						</div>
					</div>
				</section>

				<div classList='elementor-element elementor-element-3d7f17f elementor-widget__width-initial elementor-widget elementor-widget-heading' data-id="3d7f17f" data-element_type="widget" data-widget_type="heading.default">
					<div classList='elementor-widget-container'>
						<h1 style={{textAlign: 'center', color: '#FFFFFF', fontWeight: '700', fontSize: '58px'}}classList='elementor-heading-title elementor-size-default'>4. Популярные торговые <span style={{background: 'linear-gradient(1830deg, #11D4DD 25%, #FC4FF6 71%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent'}}>ИНДИКАТОРЫ</span></h1>
					</div>
				</div>
				<div classList='elementor-element elementor-element-ac1c8e8 elementor-widget elementor-widget-video' data-id="ac1c8e8" data-element_type="widget" data-settings="{&quot;youtube_url&quot;:&quot;https:\/\/youtu.be\/86cvSqm2oTI&quot;,&quot;video_type&quot;:&quot;youtube&quot;}" data-widget_type="video.default">
					<div classList='elementor-widget-container' style={{overflow: 'hidden', transform: 'translateZ(0)'}}>
						<div classList='elementor-wrapper elementor-open-inline' style={{aspectRatio: 'var(--video-aspect-ratio)', display: 'block'}}>
{/*							<iframe style={{display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '920', height: '630px'}} classList='elementor-video' frameborder="0" allowfullscreen="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="How We Are Making Over $800 Weekly With Crypto Bots" width="1120" height="360" src="https://www.youtube.com/embed/M00I8lsXgsM?si=gwarMpxG11yp_VJS?controls=0&amp;rel=0&amp;playsinline=0&amp;modestbranding=0&amp;autoplay=0&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fapexbot.ai&amp;widgetid=1" id="widget2" data-gtm-yt-inspected-6="true"></iframe>	*/}
							<iframe style={{display: 'block', marginLeft: 'auto', marginRight: 'auto'}} width="720" height="405" src="https://rutube.ru/play/embed/df69666363a83bf7c96e89806d46c58a" frameBorder="0" allow="clipboard-write; autoplay" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
						</div>
					</div>
				</div>
				<section className='elementor-section elementor-top-section elementor-element elementor-element-97e8c04 elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="97e8c04" data-element_type="section" id="home" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
					<div className='elementor-container elementor-column-gap-default'>
						<div className='elementor-element elementor-element-49ff458 elementor-widget elementor-widget-heading' data-id="49ff458" data-element_type="widget" data-widget_type="heading.default">
							<div className='elementor-widget-container'>
								<h1 style={{textAlign: 'center', color: 'blue'}} className='elementor-heading-title elementor-size-default'>Ролик описывает самые популярные торговые индикаторы, чтобы понять основы трейдинга и суметь создать идею Вашей собственной торговой стратегии для робота.</h1>
							</div>
						</div>
					</div>
				</section>

				<div classList='elementor-element elementor-element-3d7f17f elementor-widget__width-initial elementor-widget elementor-widget-heading' data-id="3d7f17f" data-element_type="widget" data-widget_type="heading.default">
					<div classList='elementor-widget-container'>
						<h1 style={{textAlign: 'center', color: '#FFFFFF', fontWeight: '700', fontSize: '58px'}}classList='elementor-heading-title elementor-size-default'>5. Создание и пополнение <span style={{background: 'linear-gradient(1830deg, #11D4DD 25%, #FC4FF6 71%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent'}}>КРИПТОКОШЕЛЬКА</span></h1>
					</div>
				</div>
				<div classList='elementor-element elementor-element-ac1c8e8 elementor-widget elementor-widget-video' data-id="ac1c8e8" data-element_type="widget" data-settings="{&quot;youtube_url&quot;:&quot;https:\/\/youtu.be\/86cvSqm2oTI&quot;,&quot;video_type&quot;:&quot;youtube&quot;}" data-widget_type="video.default">
					<div classList='elementor-widget-container' style={{overflow: 'hidden', transform: 'translateZ(0)'}}>
						<div classList='elementor-wrapper elementor-open-inline' style={{aspectRatio: 'var(--video-aspect-ratio)', display: 'block'}}>
{/*							<iframe style={{display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '920', height: '630px'}} classList='elementor-video' frameborder="0" allowfullscreen="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="How We Are Making Over $800 Weekly With Crypto Bots" width="1120" height="360" src="https://www.youtube.com/embed/3noJ25i7FRs?si=dF4nQiuSv7nIKuKd?controls=0&amp;rel=0&amp;playsinline=0&amp;modestbranding=0&amp;autoplay=0&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fapexbot.ai&amp;widgetid=1" id="widget2" data-gtm-yt-inspected-6="true"></iframe>	*/}
							<iframe style={{display: 'block', marginLeft: 'auto', marginRight: 'auto'}} width="720" height="405" src="https://rutube.ru/play/embed/ba919f4dca088414bde9ede89f910ec6" frameBorder="0" allow="clipboard-write; autoplay" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
						</div>
					</div>
				</div>
				<section className='elementor-section elementor-top-section elementor-element elementor-element-97e8c04 elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="97e8c04" data-element_type="section" id="home" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
					<div className='elementor-container elementor-column-gap-default'>
						<div className='elementor-element elementor-element-49ff458 elementor-widget elementor-widget-heading' data-id="49ff458" data-element_type="widget" data-widget_type="heading.default">
							<div className='elementor-widget-container'>
								<h1 style={{textAlign: 'center', color: 'blue'}} className='elementor-heading-title elementor-size-default'>Криптокошелек <a style={{textDecoration: 'none'}} href='https://www.tronlink.org'>TronLink</a>. <a style={{textDecoration: 'none'}} href='https://chrome.google.com/webstore/detail/tronlink/ibnejdfjmmkpcnlpebklmnkoeoihofec'>Расширение для Google Chrome</a>. Обменник <a style={{textDecoration: 'none'}} href='https://onemoment.cc?ref=21c17999-ef1e-4fd6-874a-afc81b80b094'>OneMoment</a>.</h1>
							</div>
						</div>
					</div>
				</section>

				<div classList='elementor-element elementor-element-3d7f17f elementor-widget__width-initial elementor-widget elementor-widget-heading' data-id="3d7f17f" data-element_type="widget" data-widget_type="heading.default">
					<div classList='elementor-widget-container'>
						<h1 style={{textAlign: 'center', color: '#FFFFFF', fontWeight: '700', fontSize: '58px'}}classList='elementor-heading-title elementor-size-default'>6. Тестирование торгового <span style={{background: 'linear-gradient(1830deg, #11D4DD 25%, #FC4FF6 71%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent'}}>РОБОТА</span></h1>
					</div>
				</div>
				<div classList='elementor-element elementor-element-ac1c8e8 elementor-widget elementor-widget-video' data-id="ac1c8e8" data-element_type="widget" data-settings="{&quot;youtube_url&quot;:&quot;https:\/\/youtu.be\/86cvSqm2oTI&quot;,&quot;video_type&quot;:&quot;youtube&quot;}" data-widget_type="video.default">
					<div classList='elementor-widget-container' style={{overflow: 'hidden', transform: 'translateZ(0)'}}>
						<div classList='elementor-wrapper elementor-open-inline' style={{aspectRatio: 'var(--video-aspect-ratio)', display: 'block'}}>
						<iframe style={{display: 'block', marginLeft: 'auto', marginRight: 'auto'}} width="720" height="405" src="https://rutube.ru/play/embed/a700e8ad5816c9ac63522ea4785f497a" frameBorder="0" allow="clipboard-write; autoplay" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
						</div>
					</div>
				</div>
				<section className='elementor-section elementor-top-section elementor-element elementor-element-97e8c04 elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="97e8c04" data-element_type="section" id="home" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
					<div className='elementor-container elementor-column-gap-default'>
						<div className='elementor-element elementor-element-49ff458 elementor-widget elementor-widget-heading' data-id="49ff458" data-element_type="widget" data-widget_type="heading.default">
							<div className='elementor-widget-container'>
								<h1 style={{textAlign: 'center', color: 'blue'}} className='elementor-heading-title elementor-size-default'>Описали процесс активации и тестирования торгового робота на полном историческом графике.</h1>
							</div>
						</div>
					</div>
				</section>
{/*
				<div classList='elementor-element elementor-element-3d7f17f elementor-widget__width-initial elementor-widget elementor-widget-heading' data-id="3d7f17f" data-element_type="widget" data-widget_type="heading.default">
					<div classList='elementor-widget-container'>
						<h1 style={{textAlign: 'center', color: '#FFFFFF', fontWeight: '700', fontSize: '58px'}}classList='elementor-heading-title elementor-size-default'>5. Нюансы пополнения <span style={{background: 'linear-gradient(1830deg, #11D4DD 25%, #FC4FF6 71%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent'}}>КРИПТОКОШЕЛЬКА</span></h1>
					</div>
				</div>
				<div classList='elementor-element elementor-element-ac1c8e8 elementor-widget elementor-widget-video' data-id="ac1c8e8" data-element_type="widget" data-settings="{&quot;youtube_url&quot;:&quot;https:\/\/youtu.be\/86cvSqm2oTI&quot;,&quot;video_type&quot;:&quot;youtube&quot;}" data-widget_type="video.default">
					<div classList='elementor-widget-container' style={{overflow: 'hidden', transform: 'translateZ(0)'}}>
						<div classList='elementor-wrapper elementor-open-inline' style={{aspectRatio: 'var(--video-aspect-ratio)', display: 'block'}}>
							<iframe style={{display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '920', height: '630px'}} classList='elementor-video' frameborder="0" allowfullscreen="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="How We Are Making Over $800 Weekly With Crypto Bots" width="1120" height="360" src="https://www.youtube.com/embed/aGl6ZpGQD00?si=nhlONMTi_GuCawLv?controls=0&amp;rel=0&amp;playsinline=0&amp;modestbranding=0&amp;autoplay=0&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fapexbot.ai&amp;widgetid=1" id="widget2" data-gtm-yt-inspected-6="true"></iframe>  
						</div>
					</div>
				</div>
*/}



				<div className='elementor-element elementor-element-5714fd3 elementor-widget__width-initial elementor-widget elementor-widget-heading' data-id="5714fd3" data-element_type="widget" data-widget_type="heading.default">
					<div className='elementor-widget-container'>
						<p></p>
						<h1 style={{textAlign: 'center'}} className='elementor-heading-title elementor-size-default'>Если Вы прошли все 6 шагов, можете Начать пользоваться платформой или перейти в раздел Профессионалам для обучения использованию торговых индикаторов.</h1>
					</div>
				</div>
				<section className='elementor-section elementor-inner-section elementor-element elementor-element-c786282 elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="c786282" data-element_type="section">
					<div className='elementor-container elementor-column-gap-default'>
						<div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-9863c8a' data-id="9863c8a" data-element_type="column">
							<div className='elementor-widget-wrap elementor-element-populated'>
								<div className='elementor-element elementor-element-da75cb8 elementor-align-right elementor-widget elementor-widget-button' data-id="da75cb8" data-element_type="widget" data-widget_type="button.default">
									<div className='elementor-widget-container'>
										<div className='elementor-button-wrapper'>
											<Link style={{textDecoration: 'none'}} className='elementor-button elementor-button-link elementor-size-sm' to="/main">
												<span className='elementor-button-content-wrapper'>
													<span style={{padding: '12px 24px', fontSize: '18px', color: "#000000", backgroundColor: '#0FD5DD', borderStyle: 'solid', borderWidth: '2px 2px 2px 2px', borderColor: '#0FD5DD', borderRadius: '25px 25px 25px 25px'}} className='elementor-button-text'>Начать</span>
												</span>
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-9172750' data-id="9172750" data-element_type="column">
							<div className='elementor-widget-wrap elementor-element-populated'>
								<div className='elementor-element elementor-element-b1b11df elementor-align-left elementor-widget elementor-widget-button' data-id="b1b11df" data-element_type="widget" data-widget_type="button.default">
									<div className='elementor-widget-container'>
										<div className='elementor-button-wrapper'>
											<Link style={{textDecoration: 'none'}} className='elementor-button elementor-button-link elementor-size-sm' to="/master">
												<span className='elementor-button-content-wrapper'>
													<span style={{padding: '12px 24px', fontSize: '18px', color: "#FFFFFF", backgroundColor: '#0FD5DD00', borderStyle: 'solid', borderWidth: '2px 2px 2px 2px', borderColor: '#F710FF', borderRadius: '25px 25px 25px 25px'}} className='elementor-button-text'>Профессионалам</span>
												</span>
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>



			</div>
		</div>
	</div>
</section>
</>
)}
export default Novice