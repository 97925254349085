import React from 'react';
import {useState, useContext} from 'react';
import {ScrollMenu, VisibilityContext} from '@preshonyee/react-horizontal-scrolling-menu';
//import { useNavigate } from 'react-router-dom';
import styled from 'styled-jss';
import { LeftArrow, RightArrow } from "./arrows.tsx";
import { Card } from "./card.tsx";
import Styler from "stylefire";
import { animate } from "popmotion";
import { OptionsWrapper, OptionItem } from "./optionsElements.tsx";

const NoScrollbar = styled('div')({
    '& .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar': {
      display: 'none',
    },
    // NOTE: also need to set on parent:
    // display: 'flex' and position: 'relative'
    '& .react-horizontal-scrolling-menu--wrapper': {
      height: '100%',
    },
  
    '& .react-horizontal-scrolling-menu--scroll-container': {
      height: 'initial',
      scrollbarWidth: 'none',
      '-ms-overflow-style': 'none',
      overflowY: 'auto',
      flexDirection: 'column',
    },
  });

/*
  function UpArrow() {
    const { initComplete, isFirstItemVisible, scrollPrev } =
      useContext(VisibilityContext);
    // NOTE initComplete is a hack for  prevent blinking on init
    // Can get visibility of item only after it's rendered
  
    return (
      <Arrow
        disabled={!initComplete || (initComplete && isFirstItemVisible)}
        onClick={() => scrollPrev(undefined, undefined, 'end')}
        testId="up-arrow"
      >
        Up
      </Arrow>
    );
  }
  
  function DownArrow() {
    const { initComplete, isLastItemVisible, scrollNext } =
      useContext(VisibilityContext);
  
    return (
      <Arrow
        disabled={initComplete && isLastItemVisible}
        onClick={() => scrollNext(undefined, undefined, 'start')}
        testId="down-arrow"
      >
        Down
      </Arrow>
    );
  }
  
  function Arrow({
    children,
    disabled,
    onClick,
    className,
    testId,
  }) {
    return (
      <ArrowButton
        disabled={disabled}
        onClick={onClick}
        className={'arrow' + `-${className}`}
        data-testid={testId}
      >
        {children}
      </ArrowButton>
    );
  }
  const ArrowButton = styled('button')({
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: '2px',
    opacity: (props) => (props.disabled ? '0' : '1'),
    userSelect: 'none',
    borderRadius: '6px',
    borderWidth: '1px',
  });
  
  function Card({
    onClick,
    selected,
    title,
    itemId,
  }) {
    const visibility = useContext(VisibilityContext);
  
    const visible =
      !visibility.initComplete ||
      (visibility.initComplete && visibility.isItemVisible(itemId));
  
    return (
      <CardBody
        data-cy={itemId}
        onClick={() => onClick(visibility)}
        onKeyDown={(ev) => {
          ev.code === 'Enter' && onClick(visibility);
        }}
        data-testid="card"
        role="button"
        tabIndex={0}
        className="card"
        visible={visible}
        selected={selected}
      >
        <div className="header">
          <div>{title}</div>
          <div className="visible">visible: {JSON.stringify(visible)}</div>
          <div className="selected">selected: {JSON.stringify(!!selected)}</div>
        </div>
        <div className="background" />
      </CardBody>
    );
  }
  const CardBody = styled('div')({
    border: '1px solid',
    display: 'inline-block',
    margin: '0 10px',
    width: '160px',
    userSelect: 'none',
    borderRadius: '8px',
    overflow: 'hidden',
  
    '& .header': {
      backgroundColor: 'white',
    },
  
    '& .visible': {
      backgroundColor: (props) => (props.visible ? 'transparent' : 'gray'),
    },
  
    '& .background': {
      backgroundColor: (props) => (props.selected ? 'green' : 'bisque'),
      height: '125px',
    },
  });
  
  const getId = (index) => `${'test'}${index}`;
  
  const getItems = () =>
    Array(10)
      .fill(0)
      .map((_, ind) => ({ id: getId(ind) }));
  */

      const elemPrefix = "st";
      const getId = (index) => `${elemPrefix}${index}`;
      
      const getItems = () =>
      [
        {id: 0, title: 'ATR', content: 'https://trading-expert.ru/wp-content/uploads/2023/03/Indikator-ATR-1-850x477.png'},
        {id: 1, title: 'Bollonger', content: 'https://i.ytimg.com/vi/l_Bxphx310U/maxresdefault.jpg'},
        {id: 2, title: 'EMA', content: 'https://i.pinimg.com/originals/26/62/ba/2662baa256f5b7874d66de4f8cccb1c7.jpg'},
        {id: 3, title: 'MACD', content: 'https://i.pinimg.com/originals/9d/b7/7f/9db77f908cb0a27d115b950f24c9ac4a.jpg'},
        {id: 4, title: 'RSI', content: 'https://grin4.com/files/images/5d131d18e5ffb9ae16c64bd8c5e21950a0d3130b5ea65a7efa894a1871ee3fb9.png'},
        {id: 5, title: 'SMA', content: 'https://sun6-20.userapi.com/1QGJ2avWp8G0ft0NnPWIG99tXZpIlCFE5nsZcQ/pTKfvZPYjSM.jpg'},
        {id: 6, title: 'Stoch', content: 'https://xm.investing.vn/home/wp-content/uploads/2021/04/stochastic-oscillator-indicator-structure.jpg'},
        {id: 7, title: 'TMA', content: 'https://i.ytimg.com/vi/f17lKwZJbwA/maxresdefault.jpg'},
        {id: 8, title: 'WMA', content: 'https://sli24.ru/wp-content/uploads/0/d/0/0d0f5ce734b27c57c68d49eef53d95b0.jpeg'},
        {id: 9, title: 'Without', content: 'https://i.ytimg.com/vi/7BWcyFvt_HQ/maxresdefault.jpg'},
];

        Array(10)
          .fill(0)
          .map((_, ind) => ({ id: getId(ind) }));

function Master() {
/*
    const [items] = useState(() => getItems());
    const [selected, setSelected] = useState([]);
  
    const isItemSelected = (id) =>
      !!selected.find((el) => el === id);
  
    const handleItemClick = (itemId) => {
      const itemSelected = isItemSelected(itemId);
  
      setSelected((currentSelected) =>
        itemSelected
          ? currentSelected.filter((el) => el !== itemId)
          : currentSelected.concat(itemId)
      );
    };
*/
const [items] = useState(getItems);
const [selected, setSelected] = useState([]);
const [duration, setDuration] = useState(500);
const [ease, setEase] = useState("linear");
const [customAnimation, setCustomAnimation] = useState(true);

function onWheel(
  apiObj,
  ev
) {
  const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;
  if (isThouchpad) {
    ev.stopPropagation();
    return;
  }
  if (ev.deltaY < 0) {
    // NOTE: for transitions
    apiObj.scrollNext(undefined, undefined, undefined, { duration });
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev(undefined, undefined, undefined, { duration });
  }
}

const isItemSelected = (id) =>
  !!selected.find((el) => el === id);

// NOTE: for select items
const handleItemClick = (itemId) => ({
  getItemById
}) => {
//  const itemSelected = isItemSelected(itemId);
//  console.log("clicked Item ", itemId);
  document.getElementById('0').style.cssText="display: none;";
  document.getElementById('1').style.cssText="display: none;";
  document.getElementById('2').style.cssText="display: none;";
  document.getElementById('3').style.cssText="display: none;";
  document.getElementById('4').style.cssText="display: none;";
  document.getElementById('5').style.cssText="display: none;";
  document.getElementById('6').style.cssText="display: none;";
  document.getElementById('7').style.cssText="display: none;";
  document.getElementById('8').style.cssText="display: none;";
  document.getElementById('9').style.cssText="display: none;";
  document.getElementById(`${itemId}`).style.cssText="display: block;";
//  setSelected((currentSelected) =>
//    itemSelected
//      ? currentSelected.filter((el) => el !== itemId)
//      : currentSelected.concat(itemId)
//  );
};

const nextInd = (itemId) => ({
  getItemById
}) => {
  document.getElementById('0').style.cssText="display: none;";
  document.getElementById('1').style.cssText="display: none;";
  document.getElementById('2').style.cssText="display: none;";
  document.getElementById('3').style.cssText="display: none;";
  document.getElementById('4').style.cssText="display: none;";
  document.getElementById('5').style.cssText="display: none;";
  document.getElementById('6').style.cssText="display: none;";
  document.getElementById('7').style.cssText="display: none;";
  document.getElementById('8').style.cssText="display: none;";
  document.getElementById('9').style.cssText="display: none;";
  document.getElementById(`${itemId+1}`).style.cssText="display: block;";
  window.scrollTo({top: 0, behavior: 'smooth'});
};

return (
<>
<ScrollMenu
            LeftArrow={LeftArrow}
            RightArrow={RightArrow}
            onWheel={onWheel}
            transitionDuration={duration} // NOTE: for transitions
            transitionEase={easingFunctions[ease]}
            transitionBehavior={customAnimation ? scrollBehavior : undefined}
          >
            {items.map(({ id, content }) => (
              <Card
                title={id}
                itemId={id} // NOTE: itemId is required for track items
                key={id}
                content={content}
                onClick={handleItemClick(id)}
                selected={isItemSelected(id)}
              />
            ))}
          </ScrollMenu>
{/*
          <OptionsWrapper>
            <OptionItem label="Duration">
              <input
                value={duration}
                onChange={(ev) => setDuration(+ev.target.value)}
              />
            </OptionItem>
            <OptionItem label="Ease">
              <select
                name="ease"
                id="ease"
                value={ease}
                onChange={(ev) => setEase(ev.target.value)}
              >
                {Object.entries(easingFunctions).map(([name, fn]) => (
                  <option value={name} key={name}>
                    {name}
                  </option>
                ))}
              </select>
            </OptionItem>
            <OptionItem label="Custom animation">
              <input
                checked={customAnimation}
                onChange={(ev) => setCustomAnimation(ev.target.checked)}
                type="checkbox"
                style={{ width: "20px", height: "20px" }}
              />
            </OptionItem>
          </OptionsWrapper>
*/}

<section className='elementor-section elementor-top-section elementor-element elementor-element-97e8c04 elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="97e8c04" data-element_type="section" id="home" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
		<div className='elementor-background-overlay'></div>
		<div className='elementor-container elementor-column-gap-default'>
			<div className='elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4964962' data-id="4964962" data-element_type="column">
			    <div className='elementor-widget-wrap elementor-element-populated'>

   
                <section id='0' style={{display: 'block'}} className='elementor-section elementor-top-section elementor-element elementor-element-1ef182e elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="1ef182e" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
				<div className='elementor-container elementor-column-gap-default'>
					<div className='elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-b84c016' data-id="b84c016" data-element_type="column">
						<div className='elementor-widget-wrap elementor-element-populated'>
							<div className='elementor-element elementor-element-219cfef elementor-widget elementor-widget-image' data-id="219cfef" data-element_type="widget" data-widget_type="image.default">
								<div className='elementor-widget-container'>
									<img loading="lazy" decoding="async" width="732" height="588" src="img/Group-93.png" className='attachment-full size-full wp-image-177' alt="" srcset="img/Group-93.png 732w, img/Group-93-300x241.png 300w" sizes="(max-width: 732px) 100vw, 732px" />
								</div>
							</div>
						</div>
					</div>
					<div className='elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-54f6cc4' data-id="54f6cc4" data-element_type="column">
						<div className='elementor-widget-wrap elementor-element-populated'>
							<div className='elementor-element elementor-element-bbb914e elementor-widget elementor-widget-heading' data-id="bbb914e" data-element_type="widget" data-widget_type="heading.default">
								<div className='elementor-widget-container'>
									<h2 className='elementor-heading-title elementor-size-default'>Индикатор ATR</h2>
								</div>
							</div>
							<div className='elementor-element elementor-element-65eead9 elementor-widget elementor-widget-heading' data-id="65eead9" data-element_type="widget" data-widget_type="heading.default">
								<div className='elementor-widget-container'>
                <iframe width="720" height="405" src="https://rutube.ru/play/embed/21fb44b7fdbd637d6b66fcdd285f5206" frameBorder="0" allow="clipboard-write; autoplay" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
									<p className='elementor-heading-title elementor-size-default'>Своим появлением индикатор ATR обязан Уэллсу Уайлдеру, разработавшему множество инструментов технического анализа, считающихся в настоящее время классическими. Алгоритм его расчета и методика применения были опубликованы в 1978 году в книге "Новые концепции технических торговых систем".</p>
                  <p className='elementor-heading-title elementor-size-default'>Автор называет ATR измерителем волатильности и говорит, что он отражает диапазон возможного изменения цен актива на выбранном временном интервале. Отсюда, кстати, и пошло название инструмента: ATR является аббревиатурой от Average True Range (в русском варианте - средний истинный диапазон).</p>
                  <p className='elementor-heading-title elementor-size-default'>О том, что он действительно выполняет заявленную функцию, свидетельствует формула индикатора ATR. Алгоритм расчета достаточно прост и состоит всего из двух шагов:</p>
                    <ol>
                      <li className='elementor-heading-title elementor-size-default'>Расчет истинного диапазона (True Range, TR). Для этого находится наибольшее из трех значений:
                        <ul>
                          <li>разность между максимальной и минимальной ценами актива на текущем (выбранном) периоде ценового графика;</li>
                          <li>разность между максимумом цены текущего (выбранного бара) и ценой закрытия предыдущего;</li>
                          <li>разность между ценой закрытия предыдущего ценового бара и минимальной ценой текущего (выбранного).</li>
                        </ul>
                      </li>
                    <li className='elementor-heading-title elementor-size-default'>Усреднение полученных значений на заданном интервале (собственно, вычисление ATR). Это реализуется путем расчета по ним скользящей средней (в оригинале - простой, то есть использующей стандартную форму нахождения среднего арифметического).</li>
                  </ol>
                  <p className='elementor-heading-title elementor-size-default'>Расчет TR выглядит несколько нерациональным, ведь истинный диапазон каждого торгового периода можно принимать равным разности между максимальной и минимальной ценами. Однако предложенный автором вариант охватывает и ситуации появления гэпов. На очень волатильных графиках с продолжительным перерывом между торговыми периодами могут образовываться гэпы (промежутки между ценами закрытия прошлого периода и открытия текущего). При этом они необязательно будут заполнены на протяжении текущего периода. Именно для этих ситуаций в расчет введены две дополнительные разницы.</p>
                  <p className='elementor-heading-title elementor-size-default'><span style={{color: 'blue'}}><strong><i>Интересный факт!</i></strong></span> Эта идея появилась благодаря тому, что индикатор изначально использовался для анализа товарно-сырьевых рынков, волатильность которых в период его разработки была значительно выше волатильности фондового.</p>
                  <p className='elementor-heading-title elementor-size-default'>Усреднение полученных значений диапазонов цен с помощью скользящей средней (Moving Average) применено, чтобы инструмент измерял не размах движения котировок, а его среднее значение, то есть волатильность, с некоторым приближением.</p>
                  <p className='elementor-heading-title elementor-size-default'><span style={{color: 'blue'}}><strong><i>На заметку!</i></strong></span> Сегодня существуют модификации ATR, которые используют не простую скользящую среднюю, а другие варианты - экспоненциальную, линейно взвешенную. Это позволяет сделать приближенные к текущему периоду графика значения более весомыми. Есть даже варианты, которые рассчитывают волатильность в ее классическом определении, то есть по среднеквадратичному (стандартному) отклонению ценовых минимумов и максимумов.</p>
                  <h3 className='elementor-heading-title elementor-size-default'>Что показывает и чего не показывает ATR-индикатор</h3>
                  <p className='elementor-heading-title elementor-size-default'>Поскольку инструмент представляет собой индикатор волатильности, ATR отражает только один показатель - активность цены за заданный временной интервал. По его линии можно судить только о волатильности актива. Это означает, что данные индикатора показывают возможный диапазон движения цен на текущем периоде ценового графика.</p>
                  <p className='elementor-heading-title elementor-size-default'>Индикатор ATR не показывает:
                    <ul>
                      <li>Направление тренда. Хотя иногда направление его линии и совпадает с направлением движения ценового графика, судить по ней о господствующей на рынке тенденции невозможно.</li>
                      <li>Силу тренда. Нередко бывает так, что тренд продолжает развиваться, а линия индикатора уже прошла максимум и снижается.</li>
                      <li>Области перекупленности и перепроданности. Индикатор рассчитывается в абсолютных, а не относительных единицах, поэтому, хотя его и относят к осцилляторам, зон насыщения (перекупленности или перепроданности) он не формирует. Не может он их формировать и по другой причине - направление движения цены он не отражает.</li>
                    </ul>
                  </p>
                  <p className='elementor-heading-title elementor-size-default'>В результате ATR не относят к сигнальным индикаторам - по его показаниям практически невозможно определять точки входа и выхода. Хотя он может оказать в решении этой задачи очень серьезную помощь.</p>
                  <h3 className='elementor-heading-title elementor-size-default'>ATR индикатор: как пользоваться</h3>
                  <p className='elementor-heading-title elementor-size-default'>В трейдинге ATR нашел применение именно как индикатор флэтовых и трендовых движений. Он часто используется в этом качестве непосредственно в торговых системах (TC) и для разработки других индикаторов. Рассматривают ситуации:
                    <ul>
                      <li>ATR на минимальных значениях. Волатильность низкая, малый диапазон движения цен, скорее всего, флэт.</li>
                      <li>ATR растет. Волатильность, а значит, активность торгов повышается, возможны сильные движения и зарождение тренда.</li>
                      <li>ATR прошел максимум. Волатильность начинает снижаться, что говорит о потере интереса трейдеров к торгам. Возможен переход во флэт или к коррекции</li>
                    </ul>
                  </p>
                  <img style={{height: '300px'}} src='https://www.finam.ru/Images/u/newsonline/202210/011.jpeg'></img>
                  <p className='elementor-heading-title elementor-size-default'>Однако трактовать таким образом его показания не лучший вариант - понятия минимумов и максимумов неоднозначны, особенно если принимать во внимание расчет показаний индикатора в абсолютных единицах.</p>
                  <img style={{height: '300px'}} src='https://www.finam.ru/Images/u/newsonline/202210/02.jpeg'></img>
                  <p className='elementor-heading-title elementor-size-default'>Нередко трейдеры пытаются справиться с этой проблемой, размечая в окне индикатора некоторый граничный уровень. Значения ATR ниже него говорят о низкой волатильности и, естественно, отсутствии активности на рынке. Всплеск волатильности выше порогового значения рассматривается как предупреждение о возрастающей активности и возможностях поиска моментов для заключения сделок.</p>
                  <p className='elementor-heading-title elementor-size-default'>Однако и этот вариант сложно считать удачным. Он подходит только для активов, которые слабо реагируют на изменения обстановки на рынках. Для остальных же такие перемены могут означать значительный рост или уменьшение волатильности даже во время боковых движений. Поэтому опытные трейдеры предлагают использовать не статический уровень, а динамический, например, скользящую среднюю с периодом больше периода усреднения ATR.</p>
                  <img style={{height: '300px'}} src='https://www.finam.ru/Images/u/newsonline/202210/03.jpeg'></img>
                  <p className='elementor-heading-title elementor-size-default'>В этом случае о растущей активности на рынке говорят, когда его линия находится выше MA, а о ее снижении - при расположении линии ATR под скользящей средней.</p>
                  <p className='elementor-heading-title elementor-size-default'><span style={{color: 'blue'}}><strong><i>На заметку!</i></strong></span> За счет подбора соотношения периодов усреднения ATR и "центральной" скользящей средней можно получить высокую чувствительность индикатора к изменениям волатильности. В этом случае будет много лишних пересечений. Не несущих полезной информации, но их можно фильтровать другими инструментами.</p>
                  <p className='elementor-heading-title elementor-size-default'>Еще один более практичный вариант применения ATR - размещение стоп-приказов. Логично предполагать, что от цены открытия свечи смещение максимума или минимума на уровень, превышающий ATR, маловероятно. Например, трейдер покупает акции условной компании ABC по 100 рублей за штуку на открытии свечи. Показания ATR составляют 4 рубля. Соответственно, если выставить стоп-лосс на уровне 96 рублей (цена открытия и покупки - ATR), он не будет сорван рыночным шумом.</p>
                  <p className='elementor-heading-title elementor-size-default'>На практике при расчете уровня стоп-лосс используют значение ATR с коэффициентом от 2 до 4. Это еще больше снижает вероятность срабатывания ордера от случайных движений цены, но увеличивает риски инвестора.</p>
                  <p className='elementor-heading-title elementor-size-default'><span style={{color: 'blue'}}><strong><i>На заметку!</i></strong></span> Аналогичным образом можно рассуждать и при выставлении ордеров тейк-профит. Например, размещение его на расстоянии ATR от цены открытия свечи, на которой открыта позиция в лонг, практически гарантирует получение прибыли (пусть и небольшой). Это хороший вариант для скальпинга. В расчете на получение прибыли от сильного движения ордер можно смещать за счет использования дополнительных коэффициентов.</p>
                  <p className='elementor-heading-title elementor-size-default'>Такие рассуждения легли в принцип построения ценовых каналов на базе ATR. Используется та же идея, что в полосах Боллинджера - ось канала представляет собой скользящую среднюю, а границы располагаются от нее на расстоянии от 1 до 4 ATR. Вариант реализации такого канала получил названия канала Кельтнера.</p>
                  <p className='elementor-heading-title elementor-size-default'>Таким образом, индикатор волатильности ATR, хоть и не является сигнальным инструментом технического анализа, но несет значительный объем полезной информации. Его в комбинации с другими индикаторами можно использовать для идентификации удачных точек входа, а также расстановки ордеров для ограничения убытков и фиксации прибыли.</p>
                  <iframe width="720" height="405" src="https://rutube.ru/play/embed/52ec7fd579cad3570dcbadefaac23a0a" frameBorder="0" allow="clipboard-write; autoplay" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
								</div>
							</div>
							<section className='elementor-section elementor-inner-section elementor-element elementor-element-63ebbcf elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="63ebbcf" data-element_type="section">
								<div className='elementor-container elementor-column-gap-default'>
									<div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-338231e' data-id="338231e" data-element_type="column">
										<div className='elementor-widget-wrap elementor-element-populated'>
											<div className='elementor-element elementor-element-6893467 elementor-align-left elementor-mobile-align-right elementor-widget elementor-widget-button' data-id="6893467" data-element_type="widget" data-widget_type="button.default">
												<div className='elementor-widget-container'>
													<div className='elementor-button-wrapper'>
															<span className='elementor-button elementor-button-link elementor-size-sm'>
																<button style={{}} onClick={nextInd(0)} className='elementor-button-text'>Следующий</button>
															</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</section>
						</div>
					</div>
				</div>
			</section>

      <section id='1' style={{display: 'none'}} className='elementor-section elementor-top-section elementor-element elementor-element-1ef182e elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="1ef182e" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
				<div className='elementor-container elementor-column-gap-default'>
					<div className='elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-b84c016' data-id="b84c016" data-element_type="column">
						<div className='elementor-widget-wrap elementor-element-populated'>
							<div className='elementor-element elementor-element-219cfef elementor-widget elementor-widget-image' data-id="219cfef" data-element_type="widget" data-widget_type="image.default">
								<div className='elementor-widget-container'>
									<img loading="lazy" decoding="async" width="732" height="588" src="img/Group-93.png" className='attachment-full size-full wp-image-177' alt="" srcset="img/Group-93.png 732w, img/Group-93-300x241.png 300w" sizes="(max-width: 732px) 100vw, 732px" />
								</div>
							</div>
						</div>
					</div>
					<div className='elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-54f6cc4' data-id="54f6cc4" data-element_type="column">
						<div className='elementor-widget-wrap elementor-element-populated'>
							<div className='elementor-element elementor-element-bbb914e elementor-widget elementor-widget-heading' data-id="bbb914e" data-element_type="widget" data-widget_type="heading.default">
								<div className='elementor-widget-container'>
									<h2 className='elementor-heading-title elementor-size-default'>Индикатор полосы Боллинджера</h2>
								</div>
							</div>
							<div className='elementor-element elementor-element-65eead9 elementor-widget elementor-widget-heading' data-id="65eead9" data-element_type="widget" data-widget_type="heading.default">
								<div className='elementor-widget-container'>
                <iframe width="720" height="405" src="https://rutube.ru/play/embed/dfd0650975e243751ce64419c19058dc" frameBorder="0" allow="clipboard-write; autoplay" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
									<p className='elementor-heading-title elementor-size-default'>Bollinger Bands (BB, полосы, линии или ленты Боллинджера) в теории технического анализа относят к трендовым, задача которых - определение направления и силы рыночной тенденции. Однако его функциональность несколько шире.</p>
                  <p className='elementor-heading-title elementor-size-default'>В Bollinger Bands объединены два широко используемых технических инструмента:</p>
                    <ol>
                      <li className='elementor-heading-title elementor-size-default'>Скользящая средняя (медианная линия индикатора), которая используется как фильтр шумов на ценовом графике и отображает направление тренда.</li>
                      <li className='elementor-heading-title elementor-size-default'>Стандартное (среднеквадратичное) отклонение (Standard Deviation), на основании расчета которого строятся верхняя и нижняя границы линии. Основное назначение - отображение текущей волатильности котировок актива.</li>
                    </ol>
                  <p className='elementor-heading-title elementor-size-default'>В результате полосы Боллинджера выглядят как ценовой канал переменной ширины, у которого расстояние от средней линии до границ и между ними зависит от волатильности графика. Таким образом, индикатор не только отражает текущий тренд, но и адаптирует ширину канала к рыночной активности.</p>
                  <p className='elementor-heading-title elementor-size-default'><span style={{color: 'blue'}}><strong><i>Интересный факт!</i></strong></span> Индикатор назван по имени его создателя - Джона Боллинджера. На разработку этой системы у него ушло почти 7 лет - с 1984-го по 1991 год. Инструмент получил всеобщее признание в сообществе трейдеров и инвесторов и принес автору мировую известность. В 1996 году заслуги Джона Боллинджера были отмечены престижным званием лучшего разработчика программного обеспечения для технического анализа рынков.</p>
                  <img style={{height: '300px'}} src='https://www.finam.ru/Images/u/newsonline/202209/tg_image_1811330930.jpeg'></img>
                  <p className='elementor-heading-title elementor-size-default'>Использование BB позволяет одновременно оценить направление тренда и потенциал движения цены. Это открывает широкие возможности для детального анализа рынков:
                    <ul>
                      <li>оценки текущего уровня волатильности актива;</li>
                      <li>идентификации тренда и флэта, прогнозирования моментов начала и завершения направленного движения котировок;</li>
                      <li>прогнозирования вероятности появления и развития импульса на ценовых графиках;</li>
                      <li>расчета целей движения, на которых размещаются приказы на фиксацию прибыли и ограничение убытков;</li>
                      <li>получения достоверных торговых сигналов на открытие позиций и завершение сделок. </li>
                    </ul>
                  </p>
                  <p className='elementor-heading-title elementor-size-default'>Благодаря универсальности инструмент часто применяют аналитические агентства и службы крупных рыночных игроков - банков, хедж-фондов, страховых, брокерских компаний и пр. </p>
                  <p className='elementor-heading-title elementor-size-default'>Фактически Bollinger Bands можно рассматривать как самодостаточную торговую систему. При этом полосы Боллинджера легко комбинируются с другими техническими инструментами, за счет чего вероятность прибыльной торговли существенно увеличивается.</p>
                  <h3 className='elementor-heading-title elementor-size-default'>Настройки индикатора</h3>
                  <p className='elementor-heading-title elementor-size-default'>Bollinger Bands есть в качестве стандартного индикатора практически во всех торговых терминалах. Возможности настройки на платформах несколько различаются, и в максимальном варианте включают следующие параметры.</p>
                  <p className='elementor-heading-title elementor-size-default'><strong><i>Период</i></strong></p>
                  <p className='elementor-heading-title elementor-size-default'>Период расчета N (длина индикатора, окно наблюдения) - целое число баров ценового графика, на котором строится индикатор. Используется в расчете осевой линии канала (скользящей средней) и стандартного отклонения цены. С увеличением этого параметра улучшается фильтрующая способность индикатора, сокращается количество ложных сигналов. При этом, как и в любых инструментах с применением MA, растет запаздывание.</p>
                  <p className='elementor-heading-title elementor-size-default'>Автор рекомендует использовать на фондовом рынке период 20, что отражает на дневном таймфрейме поведение цен на интервале в месяц (в среднем в месяце 20-22 рабочих дня). Значения для трейдинга на дневных графиках можно варьировать в диапазоне от 12 до 24. Инвесторы считают, что для долгосрочной торговли лучше подойдут периоды, отражающие число торговых и календарных дней в году - 240 и 365. В целом же при построении торговой системы трейдер подбирает значение таким образом, чтобы обеспечить оптимальное соотношение между фильтрующей способностью и чувствительностью индикатора к движениям котировок.</p>
                  <p className='elementor-heading-title elementor-size-default'><strong><i>Сдвиг</i></strong></p>
                  <p className='elementor-heading-title elementor-size-default'>Сдвиг - целое количество баров, на которые смещаются линии индикатора относительно графика цены. Если задана положительная величина, рассчитанные значения BB опережают появление следующей свечи на графике котировок. Такой подход удобен при подаче лимитных заявок и расстановке ордеров Stop Loss и Take Profit. В то же время сдвиг вносит некоторую погрешность в определении моментов входа в рынок и закрытия сделок в реальном времени. </p>
                  <p className='elementor-heading-title elementor-size-default'>Большинство трейдеров не использует этот параметр (оставляют его равным 0), но некоторые работают со сдвигом в 1 бар.</p>
                  <p className='elementor-heading-title elementor-size-default'><strong><i>Цена актива</i></strong></p>                  
                  <p className='elementor-heading-title elementor-size-default'>Как правило, полосы Боллинджера строятся по ценам закрытия свечей графика котировок. Некоторые торговые платформы вообще не предоставляют возможности изменять этот параметр. В более продвинутых можно, помимо Close, использовать весть набор ценовых последовательностей - цены открытия, максимальные и минимальные, средние, взвешенные и т.д. В некоторых случаях это дает повышение эффективности индикатора из-за учета экстремумов.</p>
                  <p className='elementor-heading-title elementor-size-default'><strong><i>Тип MA</i></strong></p>
                  <p className='elementor-heading-title elementor-size-default'>Классические Bollinger Bands строятся на простой скользящей средней (SMA). Она имеет хорошие показатели в качестве фильтра, но приводит к максимальному среди всего этого семейства инструментов запаздыванию. В части торговых терминалов возможен выбор типа скользящей средней. Трейдеры утверждают, что использование экспоненциальной (EMA) или линейно-взвешенной (LWMA) улучшает качество сигналов без потери аналитических возможностей полос Боллинджера.</p>
                  <p className='elementor-heading-title elementor-size-default'><strong><i>Коэффициент отклонения</i></strong></p>
                  <p className='elementor-heading-title elementor-size-default'>Коэффициент отклонения - параметр, который отвечает за ширину канала. Трейдер может менять его в любых пределах. Для фондового рынка на дневных графиках автор рекомендует использовать коэффициент 2, для рынков и активов с высокой волатильностью подойдут значения от 3 до 5. Интересные результаты дает использование в качестве коэффициентов чисел последовательности Фибоначчи.</p>
                  <p className='elementor-heading-title elementor-size-default'>Следует отметить, что при уменьшении коэффициента отклонения увеличивается чувствительность BB к ценовым импульсам, однако появляется большее число сигналов, которые трудно однозначно интерпретировать. </p>
                  <p className='elementor-heading-title elementor-size-default'><span style={{color: 'blue'}}><strong><i>Практическое замечание!</i></strong></span> Bollinger Bands - универсальный индикатор, одинаково хорошо работающий на всех таймфреймах, от минутного до годового. Как и в случае с MA, период индикатора нужно увеличивать при переходе на младший таймфрейм, поскольку зашумленность таких графиков значительно возрастает.</p>
                  <h3 className='elementor-heading-title elementor-size-default'>Как пользоваться индикатором полосы Боллинджера в торговле</h3>
                  <p className='elementor-heading-title elementor-size-default'>Торговля по полосам Боллинджера внутри дня и на более длительных интервалах, вплоть до нескольких лет для долгосрочного инвестирования, требует прежде всего понимания того, как работают эти индикаторы. Их характерное поведение соответствует различным фазам рынка:</p>
                  <ol>
                    <li className='elementor-heading-title elementor-size-default'><strong><i>В фазе консолидации (на флэте).</i></strong> Осевая линия имеет нулевой или околонулевой наклон. Границы канала расположены параллельно ей или сближаются (направлены к медианной MA), при этом уменьшается уровень рыночного шума. Цена в основном находится внутри канала, изредка наблюдаются всплески с ложными пробоями верхней или нижней границы. В такой конфигурации индикатора трейдер может применять стратегии флэтовой торговли на отскоках от линий поддержки/сопротивления, в качестве которых выступают нижняя/верхняя линии BB соответственно.</li>
                    <li className='elementor-heading-title elementor-size-default'><strong><i>Направленное движение (зарождающийся и развитый тренд).</i></strong> Ситуация на рынке отражается значительным наклоном средней линии полос Боллинджера. На начальном этапе наблюдается расширение канала. В трендовом движении чаще происходят пробои границ канала, при этом основной линией поддержки/сопротивления выступает ось канала, а противоположной границы цены практически не достигают. Чем сильнее тренд, тем чаще пробивается граница, а откаты за нее становятся реже (такой режим называют "прогулкой по полосе").</li>
                    <li className='elementor-heading-title elementor-size-default'><strong><i>Завершение тренда.</i></strong> По мере исчерпания потенциала движения наклон медианы уменьшается, начинается сужение канала. Нередко при этом наблюдается пробой осевой MA, который можно считать подтверждением перехода к фазе консолидации. </li>
                  </ol>
                  <img style={{height: '300px'}} src='https://www.finam.ru/Images/u/newsonline/202209/tg_image_1272921374.jpeg'></img>
                  <h3 className='elementor-heading-title elementor-size-default'>Сигналы полос Боллинджера</h3>
                  <p className='elementor-heading-title elementor-size-default'>Стратегии и торговые системы, использующие Bollinger Bands, ориентированы прежде всего на сигналы, которые легко интерпретировать. Их при работе с этим индикатором более чем достаточно:</p>
                  <ol>
                    <li className='elementor-heading-title elementor-size-default'>Во флэте (при малом наклоне медианной линии) источниками сигналов являются верхняя и нижняя границы канала. При их касании генерируется сигнал на открытие в противоположном направлении.</li>
                    <li className='elementor-heading-title elementor-size-default'>Пробой линии (верхней или нижней) с одновременным стартом расширения канала рассматривается как сигнал начала трендового движения. Его направление совпадает с направлением пробойного импульса.</li>
                    <li className='elementor-heading-title elementor-size-default'>Во время направленного движения основной источник сигнала - осевая MA. Отскок от нее как от линии поддержки/сопротивления рассматривается как сигнал на вход в сделку в направлении движения скользящей средней.</li>
                    <li className='elementor-heading-title elementor-size-default'>После начала сужения канала касание или пробой границ выступает сигналом на открытие позиций против тренда, поскольку говорит о его завершении, коррекции или вероятном развороте цены.</li>
                  </ol>
                  <p className='elementor-heading-title elementor-size-default'>В своей книге "Боллинджер о полосах Боллинджера" автор предлагает рассматривать в качестве надежных сигналов так называемые W- и M-образные модели. Каждая из них состоит из двух локальных экстремумов. Первый формируется за границами канала, второй - в его пределах. </p>
                  <img style={{height: '300px'}} src='https://www.finam.ru/Images/u/newsonline/202209/tg_image_2834363225.jpeg'></img>
                  <p className='elementor-heading-title elementor-size-default'>Воспринимать каждую такую модель следует как дивергенцию: W-образную как бычью, M-образную как медвежью.</p>
                  <p className='elementor-heading-title elementor-size-default'><span style={{color: 'blue'}}><strong><i>Практическое замечание!</i></strong></span> Для формирования достоверных сигналов особенно важно правильно задать параметры индикатора, прежде всего период и коэффициент отклонения. На практике их подбирают, исходя из "правила 95%". В этом случае BB настраивают так, чтобы 95% времени котировки находились в пределах канала.</p>
                  <iframe width="720" height="405" src="https://rutube.ru/play/embed/68332b9152900b0c6340522d31d6dcd9" frameBorder="0" allow="clipboard-write; autoplay" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
								</div>
							</div>
							<section className='elementor-section elementor-inner-section elementor-element elementor-element-63ebbcf elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="63ebbcf" data-element_type="section">
								<div className='elementor-container elementor-column-gap-default'>
									<div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-338231e' data-id="338231e" data-element_type="column">
										<div className='elementor-widget-wrap elementor-element-populated'>
											<div className='elementor-element elementor-element-6893467 elementor-align-left elementor-mobile-align-right elementor-widget elementor-widget-button' data-id="6893467" data-element_type="widget" data-widget_type="button.default">
												<div className='elementor-widget-container'>
													<div className='elementor-button-wrapper'>
                            <span className='elementor-button elementor-button-link elementor-size-sm'>
															<button style={{}} onClick={nextInd(1)} className='elementor-button-text'>Следующий</button>
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</section>
						</div>
					</div>
				</div>
			</section>

      <section id='2' style={{display: 'none'}} className='elementor-section elementor-top-section elementor-element elementor-element-1ef182e elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="1ef182e" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
				<div className='elementor-container elementor-column-gap-default'>
					<div className='elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-b84c016' data-id="b84c016" data-element_type="column">
						<div className='elementor-widget-wrap elementor-element-populated'>
							<div className='elementor-element elementor-element-219cfef elementor-widget elementor-widget-image' data-id="219cfef" data-element_type="widget" data-widget_type="image.default">
								<div className='elementor-widget-container'>
									<img loading="lazy" decoding="async" width="732" height="588" src="img/Group-93.png" className='attachment-full size-full wp-image-177' alt="" srcset="img/Group-93.png 732w, img/Group-93-300x241.png 300w" sizes="(max-width: 732px) 100vw, 732px" />
								</div>
							</div>
						</div>
					</div>
					<div className='elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-54f6cc4' data-id="54f6cc4" data-element_type="column">
						<div className='elementor-widget-wrap elementor-element-populated'>
							<div className='elementor-element elementor-element-bbb914e elementor-widget elementor-widget-heading' data-id="bbb914e" data-element_type="widget" data-widget_type="heading.default">
								<div className='elementor-widget-container'>
									<h2 className='elementor-heading-title elementor-size-default'>Скользящие средние (Moving Average)</h2>
								</div>
							</div>
              <iframe width="720" height="405" src="https://rutube.ru/play/embed/51856155161259d9e2a71b0d1e302cba" frameBorder="0" allow="clipboard-write; autoplay" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
							<div className='elementor-element elementor-element-65eead9 elementor-widget elementor-widget-heading' data-id="65eead9" data-element_type="widget" data-widget_type="heading.default">
								<div className='elementor-widget-container'>
                  <p className='elementor-heading-title elementor-size-default'>Скользящие средние (Moving Average, MA) — это дополнительные линии на графике цены актива. Внешне они повторяют график цены, но с небольшим запозданием и более гладко, без колебаний. Традиционная трактовка скользящих средних говорит, что, если цена выше скользящей средней, то рынок находится в фазе роста. Если цена ниже скользящей средней, то тенденция считается падающей.</p>
                  <h3 className='elementor-heading-title elementor-size-default'>Simple Moving Average (SMA)</h3>
                  <p className='elementor-heading-title elementor-size-default'>Скользящие средние показывают среднюю цену актива за установленный вами период. Например, скользящая средняя с длиной 20 показывает среднюю цену актива за предыдущие 20 свечей.</p>
                  <p className='elementor-heading-title elementor-size-default'><strong><i>Расчет простой скользящей средней (SMA)</i></strong></p>
                  <p className='elementor-heading-title elementor-size-default'>SMA = (цена 1 + цена 2 +… + цена N) / N, где N — это количество временных отрезков, за который считается средняя, например, дней или часов. Цена 1 берется за текущий временной отрезок, а последующие — за прошлые временные отрезки.</p>
                  <p className='elementor-heading-title elementor-size-default'>Например, рассчитаем значение SMA на определенную дату. Возьмем временной отрезок в три дня: сегодня акция X стоит 10 ₽, вчера — 11 ₽, позавчера — 15 ₽. Тогда сегодня SMA составит: (10 + 11 + 15) / 3 = 12 ₽.</p>
                  <p className='elementor-heading-title elementor-size-default'><strong><i>Применение скользящей средней в трейдинге</i></strong>
                    <ul>
                      <li>В торговых стратегиях – комбинируя с другими индикаторами.</li>
                      <li>С целью определения текущего тренда.</li>
                      <li>Для нахождения мест установки Stop-Loss.</li>
                      <li>Для получения торговых сигналов после пересечения линий скользящих средних.</li>
                      <li>В качестве зон поддержки/сопротивления.</li>
                    </ul>
                  </p>
                  <p className='elementor-heading-title elementor-size-default'>Уровни поддержки и сопротивления — это цены, при достижении которых актив с высокой вероятностью временно изменяет направление движения цены на противоположное. Линия скользящей средней — как раз такой уровень.
                    <ul>
                      <li>Когда цена актива находится над скользящей средней и опускается к ней, вероятность возобновления роста выше, чем вероятность пробития линии вниз. Значит, актив можно покупать.</li>
                      <li>Когда цена актива находится под скользящей средней и поднимается к ней, вероятность возобновления падения выше, чем вероятность пробития линии вверх. Значит, актив можно продавать.</li>
                    </ul>
                  </p>
                  <img style={{height: '400px'}} src='https://cdn.bcs-express.ru/article-image/44066'></img>
                  <p></p>
                  <p className='elementor-heading-title elementor-size-default'><strong><i>Пересечение цены и скользящей средней</i></strong></p>
                  <p className='elementor-heading-title elementor-size-default'>��сновной принцип анализа этого трендового индикатора — рассмотрение положения ценового графика относительно средней линии. В период, когда цена находится выше средней, текущая ситуация лучше ожиданий, а значит — на рынке преобладают бычьи настроения. И наоборот, если цена опускается ниже линии скользящей средней — это сигнал того, что ожидания рынка не оправдались и на рынке господствуют медведи. Таким образом, пересечение MA с ценой может стать сигналом к совершению сделки: пересечение цены снизу вверх дает сигнал на покупку, сверху вниз — на продажу.</p>
                  <img style={{height: '350px'}} src='https://cdn.bcs-express.ru/article-image/44070'></img>
                  <p></p>
                  <p className='elementor-heading-title elementor-size-default'><strong><i>Пересечение скользящих средних</i></strong></p>
                  <p className='elementor-heading-title elementor-size-default'>Если рынок демонстрирует сильную волатильность, то пересечение двух и более скользящих средних с разными периодами больше подходит для анализа. Принцип получения торгового сигнала от пересечения двух средних аналогичен принципу пересечения скользящей с ценовым графиком, с той лишь разницей, что вместо ценового графика в данном случае выступает вторая скользящая средняя с меньшим параметром N.</p>
                  <p className='elementor-heading-title elementor-size-default'>Таким образом, сигналом на покупку будет являться пересечение медленной скользящей средней, то есть с большим N, снизу вверх быстрой скользящей, а на продажу — пересечение сверху вниз. И в этом случае не стоит забывать, что цена должна находиться в тренде.</p>
                  <img style={{height: '400px'}} src='https://kbrobots.ru/wp-content/uploads/2019/10/Signal_MA_03.jpg'></img>
                  <p></p>

                  <h3 className='elementor-heading-title elementor-size-default'>Exponential Moving Average (EMA)</h3>
									<p className='elementor-heading-title elementor-size-default'>Экспоненциальное скользящее среднее – вычисление скользящего среднего по формуле, учитывающей предыдущее значение индикатора и текущую цену. Влияние каждой предыдущей цены убывает экспоненциально с его отдаленностью от текущей цены, что и отражено в термине «экспоненциальное скользящее среднее».</p>
                  <p className='elementor-heading-title elementor-size-default'>Экспоненциальные скользящие придают большее значение свежим ценам, которые важнее для дальнейшего развития тренда. Из‑за этого экспоненциальные средние быстрее реагируют на падения и взлеты цены и чаще используются инвесторами.</p>
                  <p className='elementor-heading-title elementor-size-default'><strong><i>Расчет экспоненциальной скользящей средней (EMA)</i></strong></p>
                  <p className='elementor-heading-title elementor-size-default'>EMA в конкретной точке графика = (цена × вес цены) + (EMA в предыдущей точке графика × (1 − вес цены)). Вес цены = 2 / (N + 1), где N — это количество временных отрезков, за который считается средняя, например, дней или часов.</p>
                  <p className='elementor-heading-title elementor-size-default'>Например, рассчитаем значение EMA для той же акции X. Временной отрезок составляет три дня, поэтому вес цены будет равен: 2 / (3 + 1) = 0,5.</p>
                  <p className='elementor-heading-title elementor-size-default'>Вес цены позволяет снизить влияние предыдущих цен на последние значения EMA. Из‑за этого при одинаковых ценах простая скользящая средняя на сегодня составила 12 ₽, а экспоненциальная — 11,5 ₽, что ближе к последней цене актива.</p>
                  <p className='elementor-heading-title elementor-size-default'><strong><i>Главные особенности ЕМА</i></strong>
                    <ul>
                      <li>Настройка обеспечивает большее число сигналов, повышается чувствительность к изменениям цены.</li>
                      <li>Все сигналы нужно фильтровать, так как повышается и число ложных срабатываний.</li>
                      <li>У ЕМА точность все равно выше в сравнении с SMA с меньшим периодом, к примеру (даже при условии уменьшения значения параметра и повышения чувствительности и числа сигналов, SMA не дает такого результата, как ЕМА).</li>
                    </ul>
                  </p>

                  <h3 className='elementor-heading-title elementor-size-default'>Triangular Moving Average (TMA)</h3>
                  <p className='elementor-heading-title elementor-size-default'>Треугольная скользящая средняя. TMA является трендовым индикатором. Он получил всеобщее признание трейдов по всему миру и его стали широко применять во многих стратегиях, которые популярны и по сей день. Наиболее часто используется в виде канала TMA.</p>
                  <p className='elementor-heading-title elementor-size-default'>Это индикатор, следующий за ценой. Он является запаздывающим, так как всегда несколько отстает от текущей цены. Расчет TMA похож на расчет простой скользящей средней, но с дополнительным сглаживанием.</p>
                  <p className='elementor-heading-title elementor-size-default'>Рассмотрим рисунок валютной пары USDJPY таймфрейма m5, на котором представлены для сравнения сразу два индикатора Triangular Moving Average(TMA) и Simple Moving Average(SMA) с одинаковыми периодами 21. На графике SMA обозначена цифрой 1, а TMA обозначена цифрой 2. Хорошо видно, что SMA при движении бывает довольно угловата, в то время как TMA выглядит гладкой и плавной линией. Треугольная MA похожа на экспоненциальное и взвешенное MA, отличаясь лишь способом взвешивания. Использовать TMA можно точно так же, как и простое скользящее среднее, например, определять с его помощью тренд.</p>
                  <img style={{height: '400px'}} src='https://fx-wiki.ru/images/6/6e/04.02.02.png'></img>
                  <p className='elementor-heading-title elementor-size-default'>Рассмотрим следующий рисунок. На нем изображена Triangular MA на графике валютной пары USDJPY M5. Как мы видим, цена сначала пересекла мувинг снизу вверх и стала двигаться значительно выше него - это явный признак восходящего тренда. Также стоит обратить внимание на направление мувинга, которое явно направлено вверх, это еще один фактор подтверждающий восходящий тренд. Причем, чем больше наклонен мувинг вверх или вниз, тем о большей силе тренда это говорит. Далее на графике TMA стала двигаться значительно с меньшим наклоном, что говорит нам о том, что силы тренда, вероятно, на исходе.</p>
                  <img style={{height: '400px'}} src='https://fx-wiki.ru/images/b/b0/04.02.03.png'></img>
                  <p className='elementor-heading-title elementor-size-default'>Индикатор можно применять на любых таймфреймах и инструментах. На более старших таймфреймах такой сигнал на вход, как подход к уровню поддержки или сопротивления канала имеет большую силу, чем, например, на М1 или М5. С другой стороны, данный индикатор очень популярен в скальперских стратегиях, направленных на работу как раз на таймфреймах м1 или м5. Обусловлено это тем, что на рынке по статистике 70% времени флэт и лишь 30% времени длится тренд. Поэтому цена часто на маленьких таймфреймах во время флэта бьется об уровни, рисуемые данным индикатором, и отскакивает.</p>
                  <p className='elementor-heading-title elementor-size-default'><strong><i>Расчет треугольной скользящей средней (TMA)</i></strong></p>
                  <img src='https://fx-wiki.ru/images/d/df/04.02.05.png'></img>
                  <p className='elementor-heading-title elementor-size-default'>SMA— простое скользящее среднее</p>
                  <p className='elementor-heading-title elementor-size-default'>N — число периодов для скользящего среднего</p>
                  <p className='elementor-heading-title elementor-size-default'>Что касается расчета индикатора, то сравним его с расчетом простой, экспоненциальной и взвешенной МА. Экспоненциальная и взвешенная МА присваивают больший вес самым крайним значениям соответствующего ряда. У обычной МА все значения ряда имеют одинаковый авторитет. У Треугольного МА больший вес присваивается данным из средней части ценового ряда.</p>
                  <p className='elementor-heading-title elementor-size-default'><strong><i>Особенности TMA</i></strong>
                    <ul>
                      <li>Давно известный факт, что обычная SMA, довольно сильно уступает TMA. Преимущество треугольной скользящей средней заключается в том, что линия, отображаемая на графике, выглядит более сглаженной и волнообразной, а скорость реакции по сравнению с обычной скользящей средней значительно выше.</li>
                      <li>Главный её недостаток, как и у других MA, заключается в том что, он двигается по инерции ценового движения, всвязи с чем сигалы TMA запаздывающие.</li>
                    </ul>
                  </p>

                  <h3 className='elementor-heading-title elementor-size-default'>Linear Weighted Moving Average (WMA)</h3>
                  <p className='elementor-heading-title elementor-size-default'>Чтобы иметь объективное представление о динамике рынка, была разработана линейно-взвешенная скользящая. С ее помощью удалось придать значимости более поздним ценам и снизить влияние ранних. Принцип в том, что суммируются цены за промежуток, установленный трейдером, и последнему значению стоимости дают больший удельный вес, а ранней цене — меньший. Делится полученное число на сумму значимостей.</p>
                  <p className='elementor-heading-title elementor-size-default'><strong><i>Расчет взвешенной скользящей средней (WMA)</i></strong></p>
                  <p className='elementor-heading-title elementor-size-default'>Если за основу были взяты 5 значений, то формула будет выглядеть следующим образом: (5Р1+4Р2+3Р3+2Р4+1Р5)/(5+4+3+2+1), где Р обозначает цену, цифра перед буквой — удельный вес, число после Р — порядковый номер стоимости от конца к началу периода.</p>
                  <p className='elementor-heading-title elementor-size-default'><strong><i>Особенности WMA</i></strong>
                    <ul>
                      <li>Запаздывание сигнала меньше, чем в простой скользящей;</li>
                      <li>Все сигналы нужно фильтровать, так как повышается и число ложных срабатываний.</li>
                      <li>Показывает трейдеру более выгодные моменты для входа на рынок, по сравнению с простой скользящей, но менее выгодные по сравнению с экспоненциальной.</li>
                      <iframe width="720" height="405" src="https://rutube.ru/play/embed/301b6a56eb7c0c37193d20d778053e46" frameBorder="0" allow="clipboard-write; autoplay" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
                    </ul>
                  </p>
								</div>
							</div>
							<section className='elementor-section elementor-inner-section elementor-element elementor-element-63ebbcf elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="63ebbcf" data-element_type="section">
								<div className='elementor-container elementor-column-gap-default'>
									<div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-338231e' data-id="338231e" data-element_type="column">
										<div className='elementor-widget-wrap elementor-element-populated'>
											<div className='elementor-element elementor-element-6893467 elementor-align-left elementor-mobile-align-right elementor-widget elementor-widget-button' data-id="6893467" data-element_type="widget" data-widget_type="button.default">
												<div className='elementor-widget-container'>
													<div className='elementor-button-wrapper'>
                            <span className='elementor-button elementor-button-link elementor-size-sm'>
															<button style={{}} onClick={nextInd(2)} className='elementor-button-text'>Следующий</button>
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</section>
						</div>
					</div>
				</div>
			</section>

      <section id='3' style={{display: 'none'}} className='elementor-section elementor-top-section elementor-element elementor-element-1ef182e elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="1ef182e" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
				<div className='elementor-container elementor-column-gap-default'>
					<div className='elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-b84c016' data-id="b84c016" data-element_type="column">
						<div className='elementor-widget-wrap elementor-element-populated'>
							<div className='elementor-element elementor-element-219cfef elementor-widget elementor-widget-image' data-id="219cfef" data-element_type="widget" data-widget_type="image.default">
								<div className='elementor-widget-container'>
									<img loading="lazy" decoding="async" width="732" height="588" src="img/Group-93.png" className='attachment-full size-full wp-image-177' alt="" srcset="img/Group-93.png 732w, img/Group-93-300x241.png 300w" sizes="(max-width: 732px) 100vw, 732px" />
								</div>
							</div>
						</div>
					</div>
					<div className='elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-54f6cc4' data-id="54f6cc4" data-element_type="column">
						<div className='elementor-widget-wrap elementor-element-populated'>
							<div className='elementor-element elementor-element-bbb914e elementor-widget elementor-widget-heading' data-id="bbb914e" data-element_type="widget" data-widget_type="heading.default">
								<div className='elementor-widget-container'>
									<h2 className='elementor-heading-title elementor-size-default'>Индикатор Moving Averages Convergence/Divergence Trading Method (MACD)</h2>
								</div>
							</div>
							<div className='elementor-element elementor-element-65eead9 elementor-widget elementor-widget-heading' data-id="65eead9" data-element_type="widget" data-widget_type="heading.default">
								<div className='elementor-widget-container'>
                  <iframe width="720" height="405" src="https://rutube.ru/play/embed/f60ceaa5d593c1262f186626251e016e" frameBorder="0" allow="clipboard-write; autoplay" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
									<p className='elementor-heading-title elementor-size-default'>Индикатор MACD - метод торговли на схождении/расхождении скользящих средних. Представляет собой эффективный инструмент технического анализа, базирующийся на нескольких скользящих средних (Moving Averages). Фактически он представляет собой компиляцию нескольких индикаторов, за счет чего приобретает свойства законченной аналитической и (или) торговой системы.</p>
                  <p className='elementor-heading-title elementor-size-default'>Благодаря базе, которую составляют только MA, инструмент стабильно работает на всех рынках, активах и таймфреймах. Наиболее часто он применяется на фондовом рынке, для которого и был разработан в 1979 году Джеральдом Аппелем (автор описал его в книге "Технический анализ. Эффективные инструменты для активного инвестора").</p>
                  <p className='elementor-heading-title elementor-size-default'>MACD широко используется профессиональными трейдерами и инвесторами при построении торговой системы. Так, хорошо известный Билл Вильямс использовал его при создании собственной теории торгового хаоса. Применяют его для волновой разметки и сторонники теории Эллиотта.</p>
                  <p className='elementor-heading-title elementor-size-default'>Бесспорна польза инструмента также для тех, кто делает в торговле первые шаги. Он поможет получить информацию о текущем состоянии рынка в виде удобных диаграмм. Линии и (или) гистограммы инструмента позволяют быстро определить:
                    <ul>
                      <li className='elementor-heading-title elementor-size-default'>направление господствующей ценовой тенденции (тренда);</li>
                      <li className='elementor-heading-title elementor-size-default'>моменты смены тренда и (или) начала коррекционного движения;</li>
                      <li className='elementor-heading-title elementor-size-default'>волатильность торгового инструмента в текущий период времени.</li>
                    </ul>
                  </p>
                  <p className='elementor-heading-title elementor-size-default'>При этом показания отличаются достаточно высокой точностью, могут использоваться и как источник, и как фильтр торговых сигналов.</p>
                  <h3 className='elementor-heading-title elementor-size-default'>Как рассчитывается и строится индикатор MACD</h3>
                  <p className='elementor-heading-title elementor-size-default'>В классическом варианте индикатор состоит из двух линий:</p>
                  <ol>
                    <li className='elementor-heading-title elementor-size-default'>Основной, которая рассчитывается как разность между двумя скользящими средними: быстрой EMA с меньшим периодом и медленной - с большим.</li>
                    <li className='elementor-heading-title elementor-size-default'>Сигнальной. Эта линия представляет собой простую скользящую среднюю (SMA), которая сглаживает основную.</li>
                  </ol>
                  <p className='elementor-heading-title elementor-size-default'>График строится не поверх ценового, а в отдельном окне.</p>
                  <p className='elementor-heading-title elementor-size-default'><span style={{color: 'blue'}}><strong><i>На заметку!</i></strong></span> MACD в трейдинге имеет и альтернативные варианты представления. Например, в MACD Histogram кроме основной и сигнальной линий строится гистограмма, наглядно отображающая разность между ними. В некоторых торговых терминалах вместо основной линии также строят гистограмму. Некоторые трейдеры модифицируют индикатор, добавляя столбцам индикатора цвет.</p>
                  <img style={{height: '300px'}} src='https://www.finam.ru/Images/u/newsonline/202211/j1.jpeg'></img>
                  <h3 className='elementor-heading-title elementor-size-default'>Что показывает индикатор</h3>
                  <p className='elementor-heading-title elementor-size-default'>Фактически инструмент является графическим представлением одной из хорошо известных трендовых методик - взаимодействия двух МА (средних). В основе ответа на вопрос, как работает MACD, лежат два утверждения:</p>
                    <ol>
                      <li>Цена всегда стремится вернуться к некоторому равновесному состоянию (среднему значению).</li>
                      <li>Движение цены от положения равновесия определяет ценовой импульс.</li>
                    </ol>
                  <p className='elementor-heading-title elementor-size-default'>Соответственно, при построении индикатора подразумевают, что:
                    <ul>
                      <li className='elementor-heading-title elementor-size-default'>Равновесное (среднее) состояние за выбранный интервал времени отражает медленная EMA.</li>
                      <li className='elementor-heading-title elementor-size-default'>Быстрая EMA соответствует состоянию рынка (цене актива) в текущий момент. Ее используют вместо самого графика котировок, чтобы отфильтровать рыночный шум.</li>
                      <li className='elementor-heading-title elementor-size-default'>Если линии расходятся, действует ценовой импульс.</li>
                      <li className='elementor-heading-title elementor-size-default'>Без воздействия импульса быстрая EMA всегда стремится к медленной.</li>
                      <li className='elementor-heading-title elementor-size-default'>Чем дольше длится расхождение линий и чем больше разность между ними, тем больше вероятность обратного движения.</li>
                      <li className='elementor-heading-title elementor-size-default'>При схождении быстрой и медленной скользящих средних состояние рынка близко к равновесному. Следующее движение котировок будет определять новый импульс.</li>
                    </ul>
                  </p>
                  <p className='elementor-heading-title elementor-size-default'>Таким образом, для анализа рыночной ситуации и определения моментов открытия и закрытия позиции пользователь MACD должен обращать внимание на характерные точки и отрезки графика:
                    <ul>
                      <li className='elementor-heading-title elementor-size-default'>локальные максимумы и минимумы основной линии;</li>
                      <li className='elementor-heading-title elementor-size-default'>пересечения основной и сигнальной линий;</li>
                      <li className='elementor-heading-title elementor-size-default'>моменты перехода линий индикатора через ноль.</li>
                    </ul>
                  </p>
                  <h3 className='elementor-heading-title elementor-size-default'>Индикатор MACD: как пользоваться инструментом</h3>
                  <p className='elementor-heading-title elementor-size-default'>Применение MACD будет эффективным, если участник торгов правильно выберет параметры индикатора и будет знать, как интерпретировать его поведение.</p>
                  <h3 className='elementor-heading-title elementor-size-default'>Настройки индикатора MACD</h3>
                  <p className='elementor-heading-title elementor-size-default'>В настройках индикатора задаются четыре параметра:
                    <ul>
                      <li className='elementor-heading-title elementor-size-default'>Периоды быстрой и медленной EMA для расчета основной линии.</li>
                      <li className='elementor-heading-title elementor-size-default'>Период SMA для расчета сигнальной линии.</li>
                      <li className='elementor-heading-title elementor-size-default'>Цена актива, по которой ведется расчет.</li>
                    </ul>
                  </p>
                  <p className='elementor-heading-title elementor-size-default'>В авторской реализации предлагается использовать следующие параметры: периоды 12 и 26 для быстрой и медленной скользящей средних соответственно, 9 - для сигнальной линии. Вести расчет нужно по ценам закрытия. Считается, что такой набор оптимален для работы на фондовом рынке на дневных графиках.</p>
                  <p className='elementor-heading-title elementor-size-default'><span style={{color: 'blue'}}><strong><i>На заметку!</i></strong></span> Образцовые авторские настройки хорошо работают на бычьем рынке. Несколько позже появился считающийся оптимальным набор параметров для работы с ценными бумагами на медвежьем рынке. Настройка индикатора в этом случае: периоды EMA 8 и 17, сигнальной SMA 5, применяется к типичным ценам (Typical Price = (High + Low + Close) / 3).</p>
                  <p className='elementor-heading-title elementor-size-default'>Значение для настройки имеет и таймфрейм, на котором используется индикатор. Предложенные автором параметры хорошо показывают себя на дневных графиках при долгосрочной торговле (инвестициях). В этом случае более 90% рыночного шума фильтруются ценовыми барами, поэтому достоверность показаний индикатора считается достаточно высокой.</p>
                  <p className='elementor-heading-title elementor-size-default'>При переходе на младшие таймфреймы влияние шума становится более явным, поэтому пользователю приходится корректировать параметры индикатора. При этом нужно учитывать следующее:
                    <ul>
                      <li className='elementor-heading-title elementor-size-default'>Для улучшения фильтрующей способности MACD приходится увеличивать периоды EMA, по которым рассчитывается основная линия.</li>
                      <li className='elementor-heading-title elementor-size-default'>С увеличением периодов растет задержка в формировании характерных точек на графике индикатора относительно ценового чарта.</li>
                      <li className='elementor-heading-title elementor-size-default'>Чтобы уменьшить запаздывание, нужно уменьшать периоды скользящих средних.</li>
                    </ul>
                  </p>
                  <p className='elementor-heading-title elementor-size-default'>Как же настроить MACD при таких противоречивых требованиях? Эксперты советуют использовать тестеры стратегий и аналогичные симуляторы, входящие в состав торговых платформ. Лучшие результаты дает оптимизация параметров в условиях, приближенных к реальной торговле, то есть на демосчете.</p>
                  <p className='elementor-heading-title elementor-size-default'><span style={{color: 'blue'}}><strong><i>Важно!</i></strong></span> На других рынках также потребуется изменение настроек индикатора MACD, поскольку все они отличаются волатильностью и зашумленностью ценовых графиков. Например, для внутридневной торговли на Форекс или криптовалютном рынке стандартные настройки плохо работают даже на часовых графиках. Здесь требуются лучшие фильтрующие способности и одновременно минимально возможный временной лаг. Некоторые трейдеры выходят из этого положения, используя в качестве базы для расчета основной линии другие варианты МА, например, линейно-взвешенную или скользящую среднюю Хала.</p>
                  <h3 className='elementor-heading-title elementor-size-default'>Применение MACD в торговле</h3>
                  <p className='elementor-heading-title elementor-size-default'>На рынке трейдеры и инвесторы пользуются двумя главными особенностями индикатора:</p>
                  <ol>
                    <li className='elementor-heading-title elementor-size-default'>За счет скользящих средних MACD может работать как трендовый индикатор.</li>
                    <li className='elementor-heading-title elementor-size-default'>Поскольку алгоритм базируется на вычислении разностей, индикатор приобретает основное свойство осцилляторов - способность генерировать опережающие сигналы.</li>
                  </ol>
                  <h3 className='elementor-heading-title elementor-size-default'>MACD в идентификации тренда</h3>
                  <p className='elementor-heading-title elementor-size-default'>Принято считать, что индикатор MACD позволяет идентифицировать направление и силу тренда:
                    <ul>
                      <li className='elementor-heading-title elementor-size-default'>Если основная линия находится в положительной области, тренд считают восходящим.</li>
                      <li className='elementor-heading-title elementor-size-default'>При расположении основной линии ниже нулевого уровня говорят о нисходящем тренде.</li>
                      <li className='elementor-heading-title elementor-size-default'>Пересечение основной линией уровня нуля считают моментом смены тренда.</li>
                    </ul>
                  </p>
                  <p className='elementor-heading-title elementor-size-default'><span style={{color: 'blue'}}><strong><i>Важно!</i></strong></span> В большинстве случаев это работает. Но возможен и такой вариант: в реальности смены тренда не происходит, а MACD переходит через ноль. Такая ситуация возможна при некоторых паузах или незначительных коррекциях в сильных трендовых движениях. Причиной становится некорректность определения тренда только по взаимному расположению двух скользящих средних.</p>
                  <img style={{height: '300px'}} src='https://www.finam.ru/Images/u/newsonline/202211/j2.jpeg'></img>
                  <p className='elementor-heading-title elementor-size-default'>О силе движения судят по высоте столбцов гистограммы или расстоянию от основной линии до нулевого уровня. Эта же величина с достаточной степенью достоверности отражает и волатильность актива в текущий момент времени.</p>
                  <h3 className='elementor-heading-title elementor-size-default'>Торговые сигналы на локальных максимумах или минимумах</h3>
                  <p className='elementor-heading-title elementor-size-default'>Достижение основной линией индикатора MACD локального экстремума можно рассматривать как появление торгового сигнала:
                    <ul>
                      <li className='elementor-heading-title elementor-size-default'>Локальный максимум в положительной области говорит о вероятном дальнейшем ослаблении восходящего тренда.</li>
                      <li className='elementor-heading-title elementor-size-default'>Локальный минимум в отрицательной области свидетельствует о возможном ослаблении нисходящей тенденции.</li>
                      <li className='elementor-heading-title elementor-size-default'>Локальный минимум в положительной области и локальный максимум в отрицательной говорят о накоплении потенциала для продолжения движения в направлении тренда.</li>
                    </ul>
                  </p>
                  <img style={{height: '300px'}} src='https://www.finam.ru/Images/u/newsonline/202211/j3.jpeg'></img>
                  <p className='elementor-heading-title elementor-size-default'>В консервативной торговле первые два варианта рассматривают как сигнал на закрытие позиций или перемещение стоп-приказов ближе к текущему уровню цен. В агрессивной торговле их используют для раннего входа в сделку. Последний вариант интерпретируют как сигнал на открытие позиций в направлении тренда или увеличение их объемов.</p>
                  <p className='elementor-heading-title elementor-size-default'>При работе с высоковолатильными инструментами из-за зашумленности графика частота появления таких сигналов достаточно высока, поэтому для выделения наиболее достоверных требуется дополнительная фильтрация. В качестве фильтра выступает сигнальная линия индикатора.</p>
                  <p className='elementor-heading-title elementor-size-default'>При ее применении торговые сигналы появляются в моменты пересечения основной и сигнальной линий. Автор индикатора предлагает считать его основным. Основное достоинство такого сигнала - появление со значительным опережением по сравнению с пересечением MA. Однако от моментов появления экстремумов на графике MACD появляется существенное запаздывание. Оно тем больше, чем больше период сигнальной SMA.</p>
                  <p className='elementor-heading-title elementor-size-default'><span style={{color: 'blue'}}><strong><i>На заметку!</i></strong></span> На активах с невысокой волатильностью сигнал пересечения может использоваться как наиболее достоверный при построении торговых систем. С ростом волатильности частота сигналов повышается - возможно, потребуется дополнительная фильтрация.</p>
                  <h3 className='elementor-heading-title elementor-size-default'>Пересечение графика индикатора с нулевым уровнем</h3>
                  <p className='elementor-heading-title elementor-size-default'>Момент перехода основной линии индикатора через ноль принято считать самым сильным сигналом, который может предложить стратегия MACD. Он соответствует пересечению скользящих средних и говорит о том, что цены вернулись к равновесному состоянию.</p>
                  <p className='elementor-heading-title elementor-size-default'>Само пересечение как сигнал на вход в рынок не рассматривается. Трейдеру необходимо дождаться завершения следующей свечи, которая определит ценовой импульс. В полученном направлении и открывается сделка.</p>
                  <p className='elementor-heading-title elementor-size-default'>Основной недостаток этого сигнала - значительное запаздывание по отношению к моменту разворота тренда или начала коррекции.</p>
                  <h3 className='elementor-heading-title elementor-size-default'>Дивергенция и конвергенция</h3>
                  <p className='elementor-heading-title elementor-size-default'>Считается, что MACD является лучшим индикатором для применения теории дивергенций и конвергенций с ценовым графиком. Именно на нем проявляются схождения и расхождения всех видов и типов. Поскольку на волатильных активах число таких сигналов велико, их используют только с дополнительным подтверждением. В качестве подтверждающих сигналов могут служить формирование следующих экстремумов индикатора, пересечение основной и сигнальной линий, переход MACD через ноль после образования дивергенций.</p>
                  <p className='elementor-heading-title elementor-size-default'>Благодаря проявлению в MACD одновременно свойств трендового индикатора и осциллятора, его легко комбинировать с другими инструментами технического анализа. Например, стратегия MACD RSI или MACD Stochastic предполагает использование MACD для идентификации тренда и фильтрации сигналов. В связке с трендовыми индикаторами, такими как полосы Боллинджера, он становится источником опережающих сигналов.</p>
                  <p className='elementor-heading-title elementor-size-default'>Таким образом, MACD достаточно простой и эффективный инструмент для технического анализа рынков. Он отлично зарекомендовал себя в работе с ценными бумагами и может широко применяться даже новичками, причем без изменения базовых настроек.</p>
                  <iframe width="720" height="405" src="https://rutube.ru/play/embed/a855492ef02cf09140fe1e9bc5f64cf2" frameBorder="0" allow="clipboard-write; autoplay" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
								</div>
							</div>
							<section className='elementor-section elementor-inner-section elementor-element elementor-element-63ebbcf elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="63ebbcf" data-element_type="section">
								<div className='elementor-container elementor-column-gap-default'>
									<div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-338231e' data-id="338231e" data-element_type="column">
										<div className='elementor-widget-wrap elementor-element-populated'>
											<div className='elementor-element elementor-element-6893467 elementor-align-left elementor-mobile-align-right elementor-widget elementor-widget-button' data-id="6893467" data-element_type="widget" data-widget_type="button.default">
												<div className='elementor-widget-container'>
													<div className='elementor-button-wrapper'>
                            <span className='elementor-button elementor-button-link elementor-size-sm'>
															<button style={{}} onClick={nextInd(3)} className='elementor-button-text'>Следующий</button>
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</section>
						</div>
					</div>
				</div>
			</section>

      <section id='4' style={{display: 'none'}} className='elementor-section elementor-top-section elementor-element elementor-element-1ef182e elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="1ef182e" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
				<div className='elementor-container elementor-column-gap-default'>
					<div className='elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-b84c016' data-id="b84c016" data-element_type="column">
						<div className='elementor-widget-wrap elementor-element-populated'>
							<div className='elementor-element elementor-element-219cfef elementor-widget elementor-widget-image' data-id="219cfef" data-element_type="widget" data-widget_type="image.default">
								<div className='elementor-widget-container'>
									<img loading="lazy" decoding="async" width="732" height="588" src="img/Group-93.png" className='attachment-full size-full wp-image-177' alt="" srcset="img/Group-93.png 732w, img/Group-93-300x241.png 300w" sizes="(max-width: 732px) 100vw, 732px" />
								</div>
							</div>
						</div>
					</div>
					<div className='elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-54f6cc4' data-id="54f6cc4" data-element_type="column">
						<div className='elementor-widget-wrap elementor-element-populated'>
							<div className='elementor-element elementor-element-bbb914e elementor-widget elementor-widget-heading' data-id="bbb914e" data-element_type="widget" data-widget_type="heading.default">
								<div className='elementor-widget-container'>
									<h2 className='elementor-heading-title elementor-size-default'>Индикатор Relative Strength Index (RSI)</h2>
								</div>
							</div>
							<div className='elementor-element elementor-element-65eead9 elementor-widget elementor-widget-heading' data-id="65eead9" data-element_type="widget" data-widget_type="heading.default">
								<div className='elementor-widget-container'>
                  <iframe width="720" height="405" src="https://rutube.ru/play/embed/07d53d8dab6c275510d60a7b9e9d5c77" frameBorder="0" allow="clipboard-write; autoplay" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
									<p className='elementor-heading-title elementor-size-default'>Индекс относительной силы - классический осциллятор, который строится на основании расчета относительной скорости изменения котировок актива на заданном периоде. Инструмент считается универсальным и с успехом применяется на любых финансовых рынках - фондовом, валютном, срочном, товарно-сырьевом и т. д. Он позволяет проанализировать движение цены в прошлом и дать прогнозы поведения рынка в будущем. На основании такого анализа участники торгов выявляют периоды тренда и флэта, получают сигналы на вход в рынок и закрытие позиций. Из-за простого и понятного представления информации использовать его могут не только опытные трейдеры и инвесторы, но и новички.</p>
                  <p className='elementor-heading-title elementor-size-default'>Как и многие классические осцилляторы, RSI изменяется в диапазоне 0–100%. Чтобы его рассчитать, определяют абсолютный прирост и падение цен за заданный период, а затем находят отношение между ними.</p>
                  <img style={{height: '300px'}} src='https://www.finam.ru/Images/u/newsonline/202209/tg_image_2897437867.jpeg'></img>
                  <p className='elementor-heading-title elementor-size-default'>Что показывает в этом случае индикатор? Он содержит массу полезной информации:
                    <ul>
                      <li className='elementor-heading-title elementor-size-default'>направление и скорость движения ценовых графиков (направление и сила тренда);</li>
                      <li className='elementor-heading-title elementor-size-default'>потенциальные точки разворота тенденций;</li>
                      <li className='elementor-heading-title elementor-size-default'>нахождение цен активов в областях перекупленности или перепроданности. </li>
                    </ul>
                  </p>
                  <p className='elementor-heading-title elementor-size-default'><span style={{color: 'blue'}}><strong><i>Дополнительная информация!</i></strong></span> О том, что график котировок находится в области перекупленности/перепроданности, говорят, когда на рынке наблюдается полное доминирование покупателей или продавцов соответственно. Считается, что в такие моменты участники торгов постепенно теряют интерес к активным покупкам/продажам, что в результате приводит к переходу в фазу консолидации, а затем слому тенденции (или по меньшей мере коррекции). Для обозначения таких зон на графики осцилляторов наносят их границы - уровни перекупленности/перепроданности.</p>
                  <p className='elementor-heading-title elementor-size-default'>Одно из главных достоинств RSI - опережающая (по сравнению с трендовыми индикаторами) реакция на изменение направления движения ценового графика. В этом же кроется и его главный недостаток - из-за высокой чувствительности он может генерировать ложные сигналы.</p>
                  <img style={{height: '300px'}} src='https://www.finam.ru/Images/u/newsonline/202209/tg_image_2464762080.jpeg'></img>
                  <h3 className='elementor-heading-title elementor-size-default'>Настройки индикатора RSI</h3>
                  <p className='elementor-heading-title elementor-size-default'>В большинстве торговых платформ для настройки RSI задают два параметра:
                    <ul>
                      <li>Период N (длина, окно наблюдения). Определяет количество баров ценового графика, на котором производится расчет индикатора.</li>
                      <li>Цена актива. Указывает одну из характерных цен. Именно по ней производится расчет.</li>
                    </ul>
                  </p>  
                  <p className='elementor-heading-title elementor-size-default'><span style={{color: 'blue'}}><strong><i>На заметку!</i></strong></span> Цена в настройках присутствует далеко не во всех торговых терминалах. Классический RSI считают по разнице цен закрытия (Close) баров. Если используется именно такой алгоритм, цену в настройки разработчики могут и не выносить.</p>
                  <p className='elementor-heading-title elementor-size-default'>Для трейдера важнейшая задача - определить оптимальный для торговой системы период. При этом следует учитывать несколько факторов:
                    <ul>
                      <li className='elementor-heading-title elementor-size-default'>Чувствительность индикатора. Чем меньше период, тем больше сигналов будет генерировать RSI. При этом часть из них оказывается ложной, поскольку появляются из-за влияния рыночного шума. Соответственно, при выборе периода обязательно обращать внимание на волатильность актива и зашумленность графика.
                      <img style={{height: '300px'}} src='https://www.finam.ru/Images/u/newsonline/202209/tg_image_1139163029.jpeg'></img>
                      </li>
                      <li className='elementor-heading-title elementor-size-default'>Таймфрейм (ТФ). Ценовые бары имеют замечательное свойство - фильтровать шум. Причем чем больше таймфрейм, тем меньше шума остается на графиках. Соответственно, на старших ТФ период индикатора можно уменьшать, на младших - обязательно увеличивать. </li>
                    </ul>
                  </p>
                  <p className='elementor-heading-title elementor-size-default'><span style={{color: 'blue'}}><strong><i>Практическое замечание!</i></strong></span> Современные торговые платформы позволяют работать не только с классическим вариантом RSI, но и модифицировать его. Так, для уменьшения количества ложных сигналов нередко применяют сглаживающие фильтры, например, скользящие средние или полосы Боллинджера (перелинковка на статьи "Как использовать скользящие средние в торговле на фондовом рынке" и "Полосы Боллинджера: что нужно знать об этом инструменте и как правильно его использовать") непосредственно для линий индикатора. Кроме того, сигналы таких модифицированных инструментов анализа проще интерпретировать.</p>
                  <p className='elementor-heading-title elementor-size-default'>Оптимальный период RSI для ТС трейдер определяет опытным путем. Для старта подбора можно воспользоваться рекомендованными значениями. Так, автор индикатора советует работать с N = 14, сегодня распространение получили периоды 9 и 25. Нередко также строят индикаторы с длиной 5 и 21.</p>
                  <p className='elementor-heading-title elementor-size-default'><span style={{color: 'blue'}}><strong><i>Практический совет!</i></strong></span> При определении лучшего периода можно использовать "правило 95%". Оно состоит в том, что длину RSI выбирают так, чтобы 95% экстремумов находились между уровнями перекупленности и перепроданности. В этом случае оставшиеся 5% будут формировать крайне надежные сигналы, хотя частота их будет невысока. </p>
                  <h3 className='elementor-heading-title elementor-size-default'>Уровни перекупленности/перепроданности</h3>
                  <p className='elementor-heading-title elementor-size-default'>Некоторые трейдеры не относят эти значения к настройкам самого индикатора RSI. Однако их роль, пожалуй, не менее важна, чем точное значение периода. Именно эти уровни задают границы зон перекупленности/перепроданности, которые позволяют:
                    <ul>
                      <li>получать при их пересечении основной линией индикатора сигналы на открытие и закрытие позиций;</li>
                      <li>определять направление и продолжительность трендов.</li>
                    </ul>
                  </p>
                  <p className='elementor-heading-title elementor-size-default'>В оригинале автор использовал универсальный подход с уровнями 30% (граница зоны перепроданности) и 70% (для зоны перекупленности). Однако практика показывает, что такие параметры хороши только для спокойных рынков с высокими показателями ликвидности. На более волатильных и зашумленных графиках требуется некоторая коррекция:</p>
                  <ol>
                    <li>Так, при росте активов хорошо показывают себя уровни 40% и 80%. Первый служит сигналом выхода из зоны перепроданности, второй - входа в зону перекупленности.</li>
                    <li>Аналогично на падающем рынке рассматривают уровни 60% (выход из зоны перекупленности) и 20% (вход в зону перепроданности).</li>
                  </ol>
                  <h3 className='elementor-heading-title elementor-size-default'>Как пользоваться индикатором RSI в торговле</h3>
                  <p className='elementor-heading-title elementor-size-default'>В трейдинге используют целый набор сигналов от индикатора RSI. Каждый из них имеет свои особенности - частоту, достоверность и пр.</p>
                  <h3 className='elementor-heading-title elementor-size-default'>Выход из зоны перекупленности/перепроданности</h3>
                  <p className='elementor-heading-title elementor-size-default'>Это, пожалуй, самый известный сигнал RSI и зачастую единственный, применение которого осваивают новички. Происходит так из-за его высокой достоверности и простоты интерпретации. Появляется сигнал на вход в рынок, когда линия индикатора покидает соответствующую зону.</p>
                  <p className='elementor-heading-title elementor-size-default'>Основан метод на предположениях, что в зонах перекупленности и перепроданности:
                    <ul>
                      <li className='elementor-heading-title elementor-size-default'>преобладает предложение или спрос;</li>
                      <li className='elementor-heading-title elementor-size-default'>цена смещена от равновесной.</li>
                    </ul>
                  </p>
                  <p className='elementor-heading-title elementor-size-default'>В этом случае движение к паритету между покупателями и продавцами, а цены актива к равновесной означает либо смену существующей тенденции, либо коррекцию. Начало процесса на графике индикатора и есть переход основной линии через границу зоны перекупленности/перепроданности.</p>
                  <img style={{height: '300px'}} src='https://www.finam.ru/Images/u/newsonline/202209/tg_image_3653120509.jpeg'></img>
                  <p className='elementor-heading-title elementor-size-default'>Для закрытия позиций используют:
                    <ul>
                      <li className='elementor-heading-title elementor-size-default'>ордер Take Profit (фиксация прибыли);</li>
                      <li className='elementor-heading-title elementor-size-default'>ордер Stop Loss, который перемещают вручную при движении цены, или Trailing Stop, смещающийся автоматически;</li>
                      <li className='elementor-heading-title elementor-size-default'>появление сигнала противоположного направления.</li>
                    </ul>
                  </p>
                  <p className='elementor-heading-title elementor-size-default'><span style={{color: 'blue'}}><strong><i>Внимание!</i></strong></span> Любые сигналы против направления движения цены, пока она находится в зоне перекупленности/перепроданности, игнорируют. Причина - цена может оставаться там сколько угодно; в это время либо продолжается тенденция, либо наблюдается флэт.</p>
                  <p className='elementor-heading-title elementor-size-default'>Уровни RSI могут не только генерировать сигналы, но и помочь идентифицировать тренд. Так, практика показывает, что при правильном выборе периода индикатора на нисходящем тренде его линия может несколько раз касаться уровня 20%, но никогда не возвращается к 80%. Аналогичная картина при восходящей тенденции - наблюдаются многочисленные касания уровня 80%, но никогда - возврат к 20%.</p>
                  <p className='elementor-heading-title elementor-size-default'>Таким образом, если после выхода из зоны перекупленности (пересечение уровня 60% сверху вниз) линия индикатора доходит до 20%, значит, имеет место мощный медвежий тренд. И наоборот, достижение уровня 80% после пересечения 40% снизу вверх говорит об устойчивой бычьей тенденции.</p>
                  <p className='elementor-heading-title elementor-size-default'><span style={{color: 'blue'}}><strong><i>На заметку!</i></strong></span> При идентификации трендов хорошо работает и уровень 50%. Большинство коррекций от уровней 20% и 80% заканчиваются именно на нем - он становится для линии индикатора уровнем сопротивления или поддержки.</p>
                  <h3 className='elementor-heading-title elementor-size-default'>Пробой уровней и линий поддержки/сопротивления</h3>
                  <p className='elementor-heading-title elementor-size-default'>Стратегии торговли по уровням или линиям поддержки или сопротивления работают и на индикаторе RSI. Такие графические построения хорошо коррелируют с аналогичными графическими фигурами непосредственно на ценовом графике.</p>
                  <img style={{height: '300px'}} src='https://www.finam.ru/Images/u/newsonline/202209/tg_image_1194329446.jpeg'></img>
                  <p className='elementor-heading-title elementor-size-default'>Ценность таких построений - отнюдь не в формировании сигнала. На графике хорошо видно, что пробой линии поддержки на RSI произошел значительно раньше, чем на ценовом чарте. Считать этот пробой моментом входа в короткую сделку было бы ошибкой - цена еще некоторое время продолжает тенденцию. Однако как предупреждение о скором окончании тренда и подготовке к развороту такой сигнал вполне работает.</p>
                  <p className='elementor-heading-title elementor-size-default'><span style={{color: 'blue'}}><strong><i>Внимание!</i></strong></span> Совместно на графиках цены и индикатора можно рассматривать и другие графические паттерны, например "Голову и плечи", флаги и треугольники. Все построения в окне RSI дают опережающие сигналы по сравнению с аналогичными на графике котировок.</p>
                  <h3 className='elementor-heading-title elementor-size-default'>Дивергенции</h3>
                  <p className='elementor-heading-title elementor-size-default'>По мнению автора индикатора Уэллса Уайлдера, наиболее достоверный и мощный сигнал, который формирует RSI - дивергенция с графиком цены. Для новичков будет вполне достаточно, если они научатся различать на графиках классические медвежью и бычью дивергенции.</p>
                  <p className='elementor-heading-title elementor-size-default'>Медвежья дивергенция формируется, когда:</p>
                  <ol>
                    <li className='elementor-heading-title elementor-size-default'>На графике цены есть несколько (два и более) повышающихся максимумов.</li>
                    <li className='elementor-heading-title elementor-size-default'>На графике RSI каждый следующий экстремум, соответствующий пику на котировках, либо находится на уровне предыдущего, либо вообще оказывается ниже.</li>
                  </ol>
                  <p className='elementor-heading-title elementor-size-default'>Если соединить экстремумы обоих графиков линиями, они покажут расхождение - дивергенцию.</p>
                  <img style={{height: '300px'}} src='https://www.finam.ru/Images/u/newsonline/202209/tg_image_3329014914.jpeg'></img>
                  <p className='elementor-heading-title elementor-size-default'>Такая картина говорит о высокой вероятности снижения цены в ближайшем будущем, поэтому дивергенцию называют медвежьей.</p>
                  <p className='elementor-heading-title elementor-size-default'>Аналогичным образом рассматривают бычью дивергенцию (точнее, конвергенцию - схождение). Отличие в том, что на графике цены формируется ряд понижающихся минимумов, а экстремумы осциллятора RSI либо остаются на одном уровне, либо повышаются. Картина указывает на возможный рост цены.</p>
                  <p className='elementor-heading-title elementor-size-default'>Считается, что дивергенции - универсальный сигнал, который хорошо отрабатывается и валютными парами на Форексе, и котировками акций на фондовом рынке, и такими сложно поддающимися техническому анализу активами, как криптовалюта.</p>
                  <p className='elementor-heading-title elementor-size-default'>В целом индикатор RSI заслуженно считают одним из лучших осцилляторов для построения торговых систем на финансовых рынках. Он пригодится не только трейдерам, извлекающим быструю спекулятивную прибыль. Все принципы работы с ним могут использовать и долгосрочные инвесторы. Он позволит найти лучший момент для покупки отобранных в портфель активов и своевременно закрыть сделку с максимальной прибылью или минимальным убытком.</p>
                  <iframe width="720" height="405" src="https://rutube.ru/play/embed/8a30e71b4c86f52698d71eea0a7bc27a" frameBorder="0" allow="clipboard-write; autoplay" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
								</div>
							</div>
							<section className='elementor-section elementor-inner-section elementor-element elementor-element-63ebbcf elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="63ebbcf" data-element_type="section">
								<div className='elementor-container elementor-column-gap-default'>
									<div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-338231e' data-id="338231e" data-element_type="column">
										<div className='elementor-widget-wrap elementor-element-populated'>
											<div className='elementor-element elementor-element-6893467 elementor-align-left elementor-mobile-align-right elementor-widget elementor-widget-button' data-id="6893467" data-element_type="widget" data-widget_type="button.default">
												<div className='elementor-widget-container'>
													<div className='elementor-button-wrapper'>
                            <span className='elementor-button elementor-button-link elementor-size-sm'>
															<button style={{}} onClick={nextInd(4)} className='elementor-button-text'>Следующий</button>
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</section>
						</div>
					</div>
				</div>
			</section>

      <section id='5' style={{display: 'none'}} className='elementor-section elementor-top-section elementor-element elementor-element-1ef182e elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="1ef182e" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
				<div className='elementor-container elementor-column-gap-default'>
					<div className='elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-b84c016' data-id="b84c016" data-element_type="column">
						<div className='elementor-widget-wrap elementor-element-populated'>
							<div className='elementor-element elementor-element-219cfef elementor-widget elementor-widget-image' data-id="219cfef" data-element_type="widget" data-widget_type="image.default">
								<div className='elementor-widget-container'>
									<img loading="lazy" decoding="async" width="732" height="588" src="img/Group-93.png" className='attachment-full size-full wp-image-177' alt="" srcset="img/Group-93.png 732w, img/Group-93-300x241.png 300w" sizes="(max-width: 732px) 100vw, 732px" />
								</div>
							</div>
						</div>
					</div>
					<div className='elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-54f6cc4' data-id="54f6cc4" data-element_type="column">
						<div className='elementor-widget-wrap elementor-element-populated'>
							<div className='elementor-element elementor-element-bbb914e elementor-widget elementor-widget-heading' data-id="bbb914e" data-element_type="widget" data-widget_type="heading.default">
								<div className='elementor-widget-container'>
									<h2 className='elementor-heading-title elementor-size-default'>Скользящие средние (Moving Average)</h2>
								</div>
							</div>
							<div className='elementor-element elementor-element-65eead9 elementor-widget elementor-widget-heading' data-id="65eead9" data-element_type="widget" data-widget_type="heading.default">
								<div className='elementor-widget-container'>
                  <p className='elementor-heading-title elementor-size-default'>Скользящие средние (Moving Average, MA) — это дополнительные линии на графике цены актива. Внешне они повторяют график цены, но с небольшим запозданием и более гладко, без колебаний. Традиционная трактовка скользящих средних говорит, что, если цена выше скользящей средней, то рынок находится в фазе роста. Если цена ниже скользящей средней, то тенденция считается падающей.</p>
                  <h3 className='elementor-heading-title elementor-size-default'>Simple Moving Average (SMA)</h3>
                  <p className='elementor-heading-title elementor-size-default'>Скользящие средние показывают среднюю цену актива за установленный вами период. Например, скользящая средняя с длиной 20 показывает среднюю цену актива за предыдущие 20 свечей.</p>
                  <p className='elementor-heading-title elementor-size-default'><strong><i>Расчет простой скользящей средней (SMA)</i></strong></p>
                  <p className='elementor-heading-title elementor-size-default'>SMA = (цена 1 + цена 2 +… + цена N) / N, где N — это количество временных отрезков, за который считается средняя, например, дней или часов. Цена 1 берется за текущий временной отрезок, а последующие — за прошлые временные отрезки.</p>
                  <p className='elementor-heading-title elementor-size-default'>Например, рассчитаем значение SMA на определенную дату. Возьмем временной отрезок в три дня: сегодня акция X стоит 10 ₽, вчера — 11 ₽, позавчера — 15 ₽. Тогда сегодня SMA составит: (10 + 11 + 15) / 3 = 12 ₽.</p>
                  <p className='elementor-heading-title elementor-size-default'><strong><i>Применение скользящей средней в трейдинге</i></strong>
                    <ul>
                      <li>В торговых стратегиях – комбинируя с другими индикаторами.</li>
                      <li>С целью определения текущего тренда.</li>
                      <li>Для нахождения мест установки Stop-Loss.</li>
                      <li>Для получения торговых сигналов после пересечения линий скользящих средних.</li>
                      <li>В качестве зон поддержки/сопротивления.</li>
                    </ul>
                  </p>
                  <p className='elementor-heading-title elementor-size-default'>Уровни поддержки и сопротивления — это цены, при достижении которых актив с высокой вероятностью временно изменяет направление движения цены на противоположное. Линия скользящей средней — как раз такой уровень.
                    <ul>
                      <li>Когда цена актива находится над скользящей средней и опускается к ней, вероятность возобновления роста выше, чем вероятность пробития линии вниз. Значит, актив можно покупать.</li>
                      <li>Когда цена актива находится под скользящей средней и поднимается к ней, вероятность возобновления падения выше, чем вероятность пробития линии вверх. Значит, актив можно продавать.</li>
                    </ul>
                  </p>
                  <img style={{height: '400px'}} src='https://cdn.bcs-express.ru/article-image/44066'></img>
                  <p></p>
                  <p className='elementor-heading-title elementor-size-default'><strong><i>Пересечение цены и скользящей средней</i></strong></p>
                  <p className='elementor-heading-title elementor-size-default'>Основной принцип анализа этого трендового индикатора — рассмотрение положения ценового графика относительно средней линии. В период, когда цена находится выше средней, текущая ситуация лучше ожиданий, а значит — на рынке преобладают бычьи настроения. И наоборот, если цена опускается ниже линии скользящей средней — это сигнал того, что ожидания рынка не оправдались и на рынке господствуют медведи. Таким образом, пересечение MA с ценой может стать сигналом к совершению сделки: пересечение цены снизу вверх дает сигнал на покупку, сверху вниз — на продажу.</p>
                  <img style={{height: '350px'}} src='https://cdn.bcs-express.ru/article-image/44070'></img>
                  <p></p>
                  <p className='elementor-heading-title elementor-size-default'><strong><i>Пересечение скользящих средних</i></strong></p>
                  <p className='elementor-heading-title elementor-size-default'>Если рынок демонстрирует сильную волатильность, то пересечение двух и более скользящих средних с разными периодами больше подходит для анализа. Принцип получения торгового сигнала от пересечения двух средних аналогичен принципу пересечения скользящей с ценовым графиком, с той лишь разницей, что вместо ценового графика в данном случае выступает вторая скользящая средняя с меньшим параметром N.</p>
                  <p className='elementor-heading-title elementor-size-default'>Таким образом, сигналом на покупку будет являться пересечение медленной скользящей средней, то есть с большим N, снизу вверх быстрой скользящей, а на продажу — пересечение сверху вниз. И в этом случае не стоит забывать, что цена должна находиться в тренде.</p>
                  <img style={{height: '400px'}} src='https://kbrobots.ru/wp-content/uploads/2019/10/Signal_MA_03.jpg'></img>
                  <p></p>

                  <h3 className='elementor-heading-title elementor-size-default'>Exponential Moving Average (EMA)</h3>
									<p className='elementor-heading-title elementor-size-default'>Экспоненциальное скользящее среднее – вычисление скользящего среднего по формуле, учитывающей предыдущее значение индикатора и текущую цену. Влияние каждой предыдущей цены убывает экспоненциально с его отдаленностью от текущей цены, что и отражено в термине «экспоненциальное скользящее среднее».</p>
                  <p className='elementor-heading-title elementor-size-default'>Экспоненциальные скользящие придают большее значение свежим ценам, которые важнее для дальнейшего развития тренда. Из‑за этого экспоненциальные средние быстрее реагируют на падения и взлеты цены и чаще используются инвесторами.</p>
                  <p className='elementor-heading-title elementor-size-default'><strong><i>Расчет экспоненциальной скользящей средней (EMA)</i></strong></p>
                  <p className='elementor-heading-title elementor-size-default'>EMA в конкретной точке графика = (цена × вес цены) + (EMA в предыдущей точке графика × (1 − вес цены)). Вес цены = 2 / (N + 1), где N — это количество временных отрезков, за который считается средняя, например, дней или часов.</p>
                  <p className='elementor-heading-title elementor-size-default'>Например, рассчитаем значение EMA для той же акции X. Временной отрезок составляет три дня, поэтому вес цены будет равен: 2 / (3 + 1) = 0,5.</p>
                  <p className='elementor-heading-title elementor-size-default'>Вес цены позволяет снизить влияние предыдущих цен на последние значения EMA. Из‑за этого при одинаковых ценах простая скользящая средняя на сегодня составила 12 ₽, а экспоненциальная — 11,5 ₽, что ближе к последней цене актива.</p>
                  <p className='elementor-heading-title elementor-size-default'><strong><i>Главные особенности ЕМА</i></strong>
                    <ul>
                      <li>Настройка обеспечивает большее число сигналов, повышается чувствительность к изменениям цены.</li>
                      <li>Все сигналы нужно фильтровать, так как повышается и число ложных срабатываний.</li>
                      <li>У ЕМА точность все равно выше в сравнении с SMA с меньшим периодом, к примеру (даже при условии уменьшения значения параметра и повышения чувствительности и числа сигналов, SMA не дает такого результата, как ЕМА).</li>
                    </ul>
                  </p>

                  <h3 className='elementor-heading-title elementor-size-default'>Triangular Moving Average (TMA)</h3>
                  <p className='elementor-heading-title elementor-size-default'>Треугольная скользящая средняя. TMA является трендовым индикатором. Он получил всеобщее признание трейдов по всему миру и его стали широко применять во многих стратегиях, которые популярны и по сей день. Наиболее часто используется в виде канала TMA.</p>
                  <p className='elementor-heading-title elementor-size-default'>Это индикатор, следующий за ценой. Он является запаздывающим, так как всегда несколько отстает от текущей цены. Расчет TMA похож на расчет простой скользящей средней, но с дополнительным сглаживанием.</p>
                  <p className='elementor-heading-title elementor-size-default'>Рассмотрим рисунок валютной пары USDJPY таймфрейма m5, на котором представлены для сравнения сразу два индикатора Triangular Moving Average(TMA) и Simple Moving Average(SMA) с одинаковыми периодами 21. На графике SMA обозначена цифрой 1, а TMA обозначена цифрой 2. Хорошо видно, что SMA при движении бывает довольно угловата, в то время как TMA выглядит гладкой и плавной линией. Треугольная MA похожа на экспоненциальное и взвешенное MA, отличаясь лишь способом взвешивания. Использовать TMA можно точно так же, как и простое скользящее среднее, например, определять с его помощью тренд.</p>
                  <img style={{height: '400px'}} src='https://fx-wiki.ru/images/6/6e/04.02.02.png'></img>
                  <p className='elementor-heading-title elementor-size-default'>Рассмотрим следующий рисунок. На нем изображена Triangular MA на графике валютной пары USDJPY M5. Как мы видим, цена сначала пересекла мувинг снизу вверх и стала двигаться значительно выше него - это явный признак восходящего тренда. Также стоит обратить внимание на направление мувинга, которое явно направлено вверх, это еще один фактор подтверждающий восходящий тренд. Причем, чем больше наклонен мувинг вверх или вниз, тем о большей силе тренда это говорит. Далее на графике TMA стала двигаться значительно с меньшим наклоном, что говорит нам о том, что силы тренда, вероятно, на исходе.</p>
                  <img style={{height: '400px'}} src='https://fx-wiki.ru/images/b/b0/04.02.03.png'></img>
                  <p className='elementor-heading-title elementor-size-default'>Индикатор можно применять на любых таймфреймах и инструментах. На более старших таймфреймах такой сигнал на вход, как подход к уровню поддержки или сопротивления канала имеет большую силу, чем, например, на М1 или М5. С другой стороны, данный индикатор очень популярен в скальперских стратегиях, направленных на работу как раз на таймфреймах м1 или м5. Обусловлено это тем, что на рынке по статистике 70% времени флэт и лишь 30% времени длится тренд. Поэтому цена часто на маленьких таймфреймах во время флэта бьется об уровни, рисуемые данным индикатором, и отскакивает.</p>
                  <p className='elementor-heading-title elementor-size-default'><strong><i>Расчет треугольной скользящей средней (TMA)</i></strong></p>
                  <img src='https://fx-wiki.ru/images/d/df/04.02.05.png'></img>
                  <p className='elementor-heading-title elementor-size-default'>SMA— простое скользящее среднее</p>
                  <p className='elementor-heading-title elementor-size-default'>N — число периодов для скользящего среднего</p>
                  <p className='elementor-heading-title elementor-size-default'>Что касается расчета индикатора, то сравним его с расчетом простой, экспоненциальной и взвешенной МА. Экспоненциальная и взвешенная МА присваивают больший вес самым крайним значениям соответствующего ряда. У обычной МА все значения ряда имеют одинаковый авторитет. У Треугольного МА больший вес присваивается данным из средней части ценового ряда.</p>
                  <p className='elementor-heading-title elementor-size-default'><strong><i>Особенности TMA</i></strong>
                    <ul>
                      <li>Давно известный факт, что обычная SMA, довольно сильно уступает TMA. Преимущество треугольной скользящей средней заключается в том, что линия, отображаемая на графике, выглядит более сглаженной и волнообразной, а скорость реакции по сравнению с обычной скользящей средней значительно выше.</li>
                      <li>Главный её недостаток, как и у других MA, заключается в том что, он двигается по инерции ценового движения, всвязи с чем сигалы TMA запаздывающие.</li>
                    </ul>
                  </p>

                  <h3 className='elementor-heading-title elementor-size-default'>Linear Weighted Moving Average (WMA)</h3>
                  <p className='elementor-heading-title elementor-size-default'>Чтобы иметь объективное представление о динамике рынка, была разработана линейно-взвешенная скользящая. С ее помощью удалось придать значимости более поздним ценам и снизить влияние ранних. Принцип в том, что суммируются цены за промежуток, установленный трейдером, и последнему значению стоимости дают больший удельный вес, а ранней цене — меньший. Делится полученное число на сумму значимостей.</p>
                  <p className='elementor-heading-title elementor-size-default'><strong><i>Расчет взвешенной скользящей средней (WMA)</i></strong></p>
                  <p className='elementor-heading-title elementor-size-default'>Если за основу были взяты 5 значений, то формула будет выглядеть следующим образом: (5Р1+4Р2+3Р3+2Р4+1Р5)/(5+4+3+2+1), где Р обозначает цену, цифра перед буквой — удельный вес, число после Р — порядковый номер стоимости от конца к началу периода.</p>
                  <p className='elementor-heading-title elementor-size-default'><strong><i>Особенности WMA</i></strong>
                    <ul>
                      <li>Запаздывание сигнала меньше, чем в простой скользящей;</li>
                      <li>Все сигналы нужно фильтровать, так как повышается и число ложных срабатываний.</li>
                      <li>Показывает трейдеру более выгодные моменты для входа на рынок, по сравнению с простой скользящей, но менее выгодные по сравнению с экспоненциальной.</li>
                      <iframe width="720" height="405" src="https://rutube.ru/play/embed/301b6a56eb7c0c37193d20d778053e46" frameBorder="0" allow="clipboard-write; autoplay" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
                    </ul>
                  </p>
								</div>
							</div>
							<section className='elementor-section elementor-inner-section elementor-element elementor-element-63ebbcf elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="63ebbcf" data-element_type="section">
								<div className='elementor-container elementor-column-gap-default'>
									<div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-338231e' data-id="338231e" data-element_type="column">
										<div className='elementor-widget-wrap elementor-element-populated'>
											<div className='elementor-element elementor-element-6893467 elementor-align-left elementor-mobile-align-right elementor-widget elementor-widget-button' data-id="6893467" data-element_type="widget" data-widget_type="button.default">
												<div className='elementor-widget-container'>
													<div className='elementor-button-wrapper'>
                            <span className='elementor-button elementor-button-link elementor-size-sm'>
															<button style={{}} onClick={nextInd(5)} className='elementor-button-text'>Следующий</button>
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</section>
						</div>
					</div>
				</div>
			</section>

      <section id='6' style={{display: 'none'}} className='elementor-section elementor-top-section elementor-element elementor-element-1ef182e elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="1ef182e" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
				<div className='elementor-container elementor-column-gap-default'>
					<div className='elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-b84c016' data-id="b84c016" data-element_type="column">
						<div className='elementor-widget-wrap elementor-element-populated'>
							<div className='elementor-element elementor-element-219cfef elementor-widget elementor-widget-image' data-id="219cfef" data-element_type="widget" data-widget_type="image.default">
								<div className='elementor-widget-container'>
									<img loading="lazy" decoding="async" width="732" height="588" src="img/Group-93.png" className='attachment-full size-full wp-image-177' alt="" srcset="img/Group-93.png 732w, img/Group-93-300x241.png 300w" sizes="(max-width: 732px) 100vw, 732px" />
								</div>
							</div>
						</div>
					</div>
					<div className='elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-54f6cc4' data-id="54f6cc4" data-element_type="column">
						<div className='elementor-widget-wrap elementor-element-populated'>
							<div className='elementor-element elementor-element-bbb914e elementor-widget elementor-widget-heading' data-id="bbb914e" data-element_type="widget" data-widget_type="heading.default">
								<div className='elementor-widget-container'>
									<h2 className='elementor-heading-title elementor-size-default'>Индикатор Stochastic Oscillator</h2>
								</div>
							</div>
							<div className='elementor-element elementor-element-65eead9 elementor-widget elementor-widget-heading' data-id="65eead9" data-element_type="widget" data-widget_type="heading.default">
								<div className='elementor-widget-container'>
                  <iframe width="720" height="405" src="https://rutube.ru/play/embed/12ba0ef860251bcbca90ac4c25038473" frameBorder="0" allow="clipboard-write; autoplay" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
									<p className='elementor-heading-title elementor-size-default'>Стохастический осциллятор, также известный как Стохастик, был разработан трейдером и президентом компании Investment Educators Inc Джорджем Лейном для торговли на фьючерсных рынках и подробно описан в его книге «Self-Managed Trading with Stochastics».</p>
                  <p className='elementor-heading-title elementor-size-default'>Индикатор предназначен для определения импульса (momentum) цены. Сам Лейн для иллюстрации примера работы своего индикатора приводит пример запущенной в воздух ракеты, скорость которой непременно начинает уменьшаться перед тем, как ракета упадет вниз. Таким образом, импульс всегда изменяет своё направление до изменения цены. Автор отмечает, что овладеть таким методом определения направления цены достаточно сложно, но возможно при наличии желания и сил.</p>
                  <p className='elementor-heading-title elementor-size-default'>Математически стохастик выражает отношение между ценой закрытия и диапазоном «максимум-минимум» за определенный период в виде процентной величины от 0 до 100. Значение стохастического осциллятора равное 80 и выше показывает, что цена закрытия находится вблизи верхней границы диапазона; стохастик равный 20 и ниже означает, что цена закрытия находится вблизи нижней границы диапазона. Соответственно, если на рынке прослеживается тенденция к закрытию в верхней части дневного диапазона, то он — бычий, если в нижней, то он — медвежий.</p>
                  <p className='elementor-heading-title elementor-size-default'>Формула стохастика:</p>
                  <img src='https://cdn.bcs-express.ru/article-image/47042'></img>
                  <p className='elementor-heading-title elementor-size-default'>где max(Hn) — максимум за N периодов, min(Ln) — минимум за N периодов, С0 — цена закрытия текущего периода.</p>
                  <p className='elementor-heading-title elementor-size-default'>На графике осциллятор представлен в виде двух линий. Сплошная линия %K строится вышеописанным способом и сглаживается скользящей средней. Вторая линия представляет из себя сглаженную 3-периодной скользящей средней линию %K, и называется %D. На графике %D обрисовывается пунктирной линией.</p>
                  <img style={{height: '300px'}} src='https://cdn.bcs-express.ru/article-image/47043'></img>
                  <p className='elementor-heading-title elementor-size-default'>В литературе иногда линию %К называют быстрой или основной, а %D — медленной или сигнальной.</p>
                  <p className='elementor-heading-title elementor-size-default'><span style={{color: 'blue'}}><strong><i>Примечательный факт!</i></strong></span> Формула осциллятора представляет собой адаптированную формулу для расчета количества известняка, которое нужно добавить при плавке в железную руду, чтобы получить сталь. Автор использовал несколько различных вариаций этой формулы для анализа биржевых рынков. Самые лучшие результаты показала формула стохастика.</p>
                  <h3 className='elementor-heading-title elementor-size-default'>Настройка и использование индикатора</h3>
                  <p className='elementor-heading-title elementor-size-default'>Джордж Лейн рекомендовал период от 9 до 21, при этом по умолчанию в торговых терминалах обычно используются параметры 5,3 и 3.</p>
                  <p className='elementor-heading-title elementor-size-default'>Графики автор индикатора рекомендует использовать дневные и недельные, так как именно на них стохастик генерирует наиболее надежные сигналы. Тем не менее, известно, что сам он имел обыкновение использовать его с 3-минутными барами при торговле фьючерсами на индексе S&P 500.</p>
                  <p className='elementor-heading-title elementor-size-default'>Как и любой осциллятор, стохастик рекомендуется использовать во время фазы бокового движения вместе с другими индикаторами. Однако, некоторые сигналы стохастика хорошо зарекомендовали себя и на трендовом рынке.</p>
                  <h3 className='elementor-heading-title elementor-size-default'>Зоны перекупленности и перепроданности</h3>
                  <p className='elementor-heading-title elementor-size-default'>Зоны осциллятора выше 80% и ниже 20% называются зонами перекупленности и перепроданности соответственно. Когда бумага входит в зону перекупленности (то есть, торгуется вблизи верхней границы диапазона) если она отскочит вниз, то можно рассчитывать на дальнейшее падение. Сигналом на вход в короткую позицию является пересечение уровня 80% линией %K сверху вниз. Аналогичным образом, сигналом на покупку является пересечение уровня 20% линией K% снизу вверх.</p>
                  <p className='elementor-heading-title elementor-size-default'>Теоретически такой сигнал может использоваться в боковике, но в такой фазе рынка лучше комбинировать его с другими индикаторами. Самостоятельно хорошо использовать зоны перекупленности/перепроданности в тренде, открывая позиции в направлении тренда.</p>
                  <img style={{height: '300px'}} src='https://cdn.bcs-express.ru/article-image/47045'></img>
                  <p className='elementor-heading-title elementor-size-default'>Значения 20% и 80% не являются строго фиксированными. Можно использовать значения 25% и 75% или 30% и 70%, если они доказывают свою эффективность на истории.</p>
                  <h3 className='elementor-heading-title elementor-size-default'>Дивергенция</h3>
                  <p className='elementor-heading-title elementor-size-default'>Медвежья дивергенция возникает, когда цена достигает нового минимума, а локальный минимум на осцилляторе находится выше предыдущего. И наоборот, бычья дивергенция возникает, когда цена достигает нового максимума, а максимум на осцилляторе ниже предыдущего. Такой сигнал говорит о том, что импульс затухает и скоро цену ожидает разворот. При этом хорошо если дивергенция возникает в зонах перекупленности/перепроданности.</p>
                  <img style={{height: '300px'}} src='https://cdn.bcs-express.ru/article-image/47046'></img>
                  <h3 className='elementor-heading-title elementor-size-default'>Пересечение линии 50%.</h3>
                  <p className='elementor-heading-title elementor-size-default'>Довольно спорный сигнал, но на некоторых инструментах вполне может найти применение. На графике осциллятора проводится линия 50%. Пересечение её линией %K вниз дает сигнал на продажу, вверх — на покупку. Рекомендуется тщательно анализировать применимость этой тактики для каждого торгуемого инструмента и использовать вместе с другими инструментами анализа.</p>
                  <h3 className='elementor-heading-title elementor-size-default'>Пересечение линий %K и %D</h3>
                  <p className='elementor-heading-title elementor-size-default'>Сигнал на покупку, если %K пересекает %D снизу-вверх и сигнал на продажу, когда %K пересекает %D сверху вниз. Такая система похожа на систему пересечения скользящих средних (поскольку %D и есть скользящая от %K). Как и в случае с дивергенцией, сигнал более надежен, если возникает в зонах перекупленности/перепроданности. В таком случае последующий выход линий из зоны будет подтверждающим сигналом, где можно будет нарастить позицию.</p>
                  <img style={{height: '300px'}} src='https://cdn.bcs-express.ru/article-image/47047'></img>
                  <p className='elementor-heading-title elementor-size-default'>Считается, что это самый быстрый тип сигнала, однако это и его основной недостаток — сигнал может выдавать большое количество ложных входов, если амплитуда колебаний цены недостаточна.</p>
                  <p className='elementor-heading-title elementor-size-default'>Стохастик несомненно один из самых популярных индикаторов, но, как и все остальные индикаторы, его нужно уметь правильно использовать. Неплохую торговую систему можно организовать, комбинирую индикаторы стохастик и RSI. Стохастик подает сигналы быстрее, но они считаются менее надежными. Фильтруя его сигналы индикатором RSI можно получить сигналы с высокой вероятностью успеха. Также стохастик рекомендуют использовать с трендовыми и канальными индикаторами.</p>
                  <iframe width="720" height="405" src="https://rutube.ru/play/embed/b131bfbfd44607deb2ec8873948aba29" frameBorder="0" allow="clipboard-write; autoplay" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
								</div>
							</div>
							<section className='elementor-section elementor-inner-section elementor-element elementor-element-63ebbcf elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="63ebbcf" data-element_type="section">
								<div className='elementor-container elementor-column-gap-default'>
									<div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-338231e' data-id="338231e" data-element_type="column">
										<div className='elementor-widget-wrap elementor-element-populated'>
											<div className='elementor-element elementor-element-6893467 elementor-align-left elementor-mobile-align-right elementor-widget elementor-widget-button' data-id="6893467" data-element_type="widget" data-widget_type="button.default">
												<div className='elementor-widget-container'>
													<div className='elementor-button-wrapper'>
                            <span className='elementor-button elementor-button-link elementor-size-sm'>
															<button style={{}} onClick={nextInd(6)} className='elementor-button-text'>Следующий</button>
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</section>
						</div>
					</div>
				</div>
			</section>

      <section id='7' style={{display: 'none'}} className='elementor-section elementor-top-section elementor-element elementor-element-1ef182e elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="1ef182e" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
				<div className='elementor-container elementor-column-gap-default'>
					<div className='elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-b84c016' data-id="b84c016" data-element_type="column">
						<div className='elementor-widget-wrap elementor-element-populated'>
							<div className='elementor-element elementor-element-219cfef elementor-widget elementor-widget-image' data-id="219cfef" data-element_type="widget" data-widget_type="image.default">
								<div className='elementor-widget-container'>
									<img loading="lazy" decoding="async" width="732" height="588" src="img/Group-93.png" className='attachment-full size-full wp-image-177' alt="" srcset="img/Group-93.png 732w, img/Group-93-300x241.png 300w" sizes="(max-width: 732px) 100vw, 732px" />
								</div>
							</div>
						</div>
					</div>
					<div className='elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-54f6cc4' data-id="54f6cc4" data-element_type="column">
						<div className='elementor-widget-wrap elementor-element-populated'>
							<div className='elementor-element elementor-element-bbb914e elementor-widget elementor-widget-heading' data-id="bbb914e" data-element_type="widget" data-widget_type="heading.default">
								<div className='elementor-widget-container'>
									<h2 className='elementor-heading-title elementor-size-default'>Скользящие средние (Moving Average)</h2>
								</div>
							</div>
							<div className='elementor-element elementor-element-65eead9 elementor-widget elementor-widget-heading' data-id="65eead9" data-element_type="widget" data-widget_type="heading.default">
								<div className='elementor-widget-container'>
                  <iframe width="720" height="405" src="https://rutube.ru/play/embed/51856155161259d9e2a71b0d1e302cba" frameBorder="0" allow="clipboard-write; autoplay" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
                  <p className='elementor-heading-title elementor-size-default'>Скользящие средние (Moving Average, MA) — это дополнительные линии на графике цены актива. Внешне они повторяют график цены, но с небольшим запозданием и более гладко, без колебаний. Традиционная трактовка скользящих средних говорит, что, если цена выше скользящей средней, то рынок находится в фазе роста. Если цена ниже скользящей средней, то тенденция считается падающей.</p>
                  <h3 className='elementor-heading-title elementor-size-default'>Simple Moving Average (SMA)</h3>
                  <p className='elementor-heading-title elementor-size-default'>Скользящие средние показывают среднюю цену актива за установленный вами период. Например, скользящая средняя с длиной 20 показывает среднюю цену актива за предыдущие 20 свечей.</p>
                  <p className='elementor-heading-title elementor-size-default'><strong><i>Расчет простой скользящей средней (SMA)</i></strong></p>
                  <p className='elementor-heading-title elementor-size-default'>SMA = (цена 1 + цена 2 +… + цена N) / N, где N — это количество временных отрезков, за который считается средняя, например, дней или часов. Цена 1 берется за текущий временной отрезок, а последующие — за прошлые временные отрезки.</p>
                  <p className='elementor-heading-title elementor-size-default'>Например, рассчитаем значение SMA на определенную дату. Возьмем временной отрезок в три дня: сегодня акция X стоит 10 ₽, вчера — 11 ₽, позавчера — 15 ₽. Тогда сегодня SMA составит: (10 + 11 + 15) / 3 = 12 ₽.</p>
                  <p className='elementor-heading-title elementor-size-default'><strong><i>Применение скользящей средней в трейдинге</i></strong>
                    <ul>
                      <li>В торговых стратегиях – комбинируя с другими индикаторами.</li>
                      <li>С целью определения текущего тренда.</li>
                      <li>Для нахождения мест установки Stop-Loss.</li>
                      <li>Для получения торговых сигналов после пересечения линий скользящих средних.</li>
                      <li>В качестве зон поддержки/сопротивления.</li>
                    </ul>
                  </p>
                  <p className='elementor-heading-title elementor-size-default'>Уровни поддержки и сопротивления — это цены, при достижении которых актив с высокой вероятностью временно изменяет направление движения цены на противоположное. Линия скользящей средней — как раз такой уровень.
                    <ul>
                      <li>Когда цена актива находится над скользящей средней и опускается к ней, вероятность возобновления роста выше, чем вероятность пробития линии вниз. Значит, актив можно покупать.</li>
                      <li>Когда цена актива находится под скользящей средней и поднимается к ней, вероятность возобновления падения выше, чем вероятность пробития линии вверх. Значит, актив можно продавать.</li>
                    </ul>
                  </p>
                  <img style={{height: '400px'}} src='https://cdn.bcs-express.ru/article-image/44066'></img>
                  <p></p>
                  <p className='elementor-heading-title elementor-size-default'><strong><i>Пересечение цены и скользящей средней</i></strong></p>
                  <p className='elementor-heading-title elementor-size-default'>Основной принцип анализа этого трендового индикатора — рассмотрение положения ценового графика относительно средней линии. В период, когда цена находится выше средней, текущая ситуация лучше ожиданий, а значит — на рынке преобладают бычьи настроения. И наоборот, если цена опускается ниже линии скользящей средней — это сигнал того, что ожидания рынка не оправдались и на рынке господствуют медведи. Таким образом, пересечение MA с ценой может стать сигналом к совершению сделки: пересечение цены снизу вверх дает сигнал на покупку, сверху вниз — на продажу.</p>
                  <img style={{height: '350px'}} src='https://cdn.bcs-express.ru/article-image/44070'></img>
                  <p></p>
                  <p className='elementor-heading-title elementor-size-default'><strong><i>Пересечение скользящих средних</i></strong></p>
                  <p className='elementor-heading-title elementor-size-default'>Если рынок демонстрирует сильную волатильность, то пересечение двух и более скользящих средних с разными периодами больше подходит для анализа. Принцип получения торгового сигнала от пересечения двух средних аналогичен принципу пересечения скользящей с ценовым графиком, с той лишь разницей, что вместо ценового графика в данном случае выступает вторая скользящая средняя с меньшим параметром N.</p>
                  <p className='elementor-heading-title elementor-size-default'>Таким образом, сигналом на покупку будет являться пересечение медленной скользящей средней, то есть с большим N, снизу вверх быстрой скользящей, а на продажу — пересечение сверху вниз. И в этом случае не стоит забывать, что цена должна находиться в тренде.</p>
                  <img style={{height: '400px'}} src='https://kbrobots.ru/wp-content/uploads/2019/10/Signal_MA_03.jpg'></img>
                  <p></p>

                  <h3 className='elementor-heading-title elementor-size-default'>Exponential Moving Average (EMA)</h3>
									<p className='elementor-heading-title elementor-size-default'>Экспоненциальное скользящее среднее – вычисление скользящего среднего по формуле, учитывающей предыдущее значение индикатора и текущую цену. Влияние каждой предыдущей цены убывает экспоненциально с его отдаленностью от текущей цены, что и отражено в термине «экспоненциальное скользящее среднее».</p>
                  <p className='elementor-heading-title elementor-size-default'>Экспоненциальные скользящие придают большее значение свежим ценам, которые важнее для дальнейшего развития тренда. Из‑за этого экспоненциальные средние быстрее реагируют на падения и взлеты цены и чаще используются инвесторами.</p>
                  <p className='elementor-heading-title elementor-size-default'><strong><i>Расчет экспоненциальной скользящей средней (EMA)</i></strong></p>
                  <p className='elementor-heading-title elementor-size-default'>EMA в конкретной точке графика = (цена × вес цены) + (EMA в предыдущей точке графика × (1 − вес цены)). Вес цены = 2 / (N + 1), где N — это количество временных отрезков, за который считается средняя, например, дней или часов.</p>
                  <p className='elementor-heading-title elementor-size-default'>Например, рассчитаем значение EMA для той же акции X. Временной отрезок составляет три дня, поэтому вес цены будет равен: 2 / (3 + 1) = 0,5.</p>
                  <p className='elementor-heading-title elementor-size-default'>Вес цены позволяет снизить влияние предыдущих цен на последние значения EMA. Из‑за этого при одинаковых ценах простая скользящая средняя на сегодня составила 12 ₽, а экспоненциальная — 11,5 ₽, что ближе к последней цене актива.</p>
                  <p className='elementor-heading-title elementor-size-default'><strong><i>Главные особенности ЕМА</i></strong>
                    <ul>
                      <li>Настройка обеспечивает большее число сигналов, повышается чувствительность к изменениям цены.</li>
                      <li>Все сигналы нужно фильтровать, так как повышается и число ложных срабатываний.</li>
                      <li>У ЕМА точность все равно выше в сравнении с SMA с меньшим периодом, к примеру (даже при условии уменьшения значения параметра и повышения чувствительности и числа сигналов, SMA не дает такого результата, как ЕМА).</li>
                    </ul>
                  </p>

                  <h3 className='elementor-heading-title elementor-size-default'>Triangular Moving Average (TMA)</h3>
                  <p className='elementor-heading-title elementor-size-default'>Треугольная скользящая средняя. TMA является трендовым индикатором. Он получил всеобщее признание трейдов по всему миру и его стали широко применять во многих стратегиях, которые популярны и по сей день. Наиболее часто используется в виде канала TMA.</p>
                  <p className='elementor-heading-title elementor-size-default'>Это индикатор, следующий за ценой. Он является запаздывающим, так как всегда несколько отстает от текущей цены. Расчет TMA похож на расчет простой скользящей средней, но с дополнительным сглаживанием.</p>
                  <p className='elementor-heading-title elementor-size-default'>Рассмотрим рисунок валютной пары USDJPY таймфрейма m5, на котором представлены для сравнения сразу два индикатора Triangular Moving Average(TMA) и Simple Moving Average(SMA) с одинаковыми периодами 21. На графике SMA обозначена цифрой 1, а TMA обозначена цифрой 2. Хорошо видно, что SMA при движении бывает довольно угловата, в то время как TMA выглядит гладкой и плавной линией. Треугольная MA похожа на экспоненциальное и взвешенное MA, отличаясь лишь способом взвешивания. Использовать TMA можно точно так же, как и простое скользящее среднее, например, определять с его помощью тренд.</p>
                  <img style={{height: '400px'}} src='https://fx-wiki.ru/images/6/6e/04.02.02.png'></img>
                  <p className='elementor-heading-title elementor-size-default'>Рассмотрим следующий рисунок. На нем изображена Triangular MA на графике валютной пары USDJPY M5. Как мы видим, цена сначала пересекла мувинг снизу вверх и стала двигаться значительно выше него - это явный признак восходящего тренда. Также стоит обратить внимание на направление мувинга, которое явно направлено вверх, это еще один фактор подтверждающий восходящий тренд. Причем, чем больше наклонен мувинг вверх или вниз, тем о большей силе тренда это говорит. Далее на графике TMA стала двигаться значительно с меньшим наклоном, что говорит нам о том, что силы тренда, вероятно, на исходе.</p>
                  <img style={{height: '400px'}} src='https://fx-wiki.ru/images/b/b0/04.02.03.png'></img>
                  <p className='elementor-heading-title elementor-size-default'>Индикатор можно применять на любых таймфреймах и инструментах. На более старших таймфреймах такой сигнал на вход, как подход к уровню поддержки или сопротивления канала имеет большую силу, чем, например, на М1 или М5. С другой стороны, данный индикатор очень популярен в скальперских стратегиях, направленных на работу как раз на таймфреймах м1 или м5. Обусловлено это тем, что на рынке по статистике 70% времени флэт и лишь 30% времени длится тренд. Поэтому цена часто на маленьких таймфреймах во время флэта бьется об уровни, рисуемые данным индикатором, и отскакивает.</p>
                  <p className='elementor-heading-title elementor-size-default'><strong><i>Расчет треугольной скользящей средней (TMA)</i></strong></p>
                  <img src='https://fx-wiki.ru/images/d/df/04.02.05.png'></img>
                  <p className='elementor-heading-title elementor-size-default'>SMA— простое скользящее среднее</p>
                  <p className='elementor-heading-title elementor-size-default'>N — число периодов для скользящего среднего</p>
                  <p className='elementor-heading-title elementor-size-default'>Что касается расчета индикатора, то сравним его с расчетом простой, экспоненциальной и взвешенной МА. Экспоненциальная и взвешенная МА присваивают больший вес самым крайним значениям соответствующего ряда. У обычной МА все значения ряда имеют одинаковый авторитет. У Треугольного МА больший вес присваивается данным из средней части ценового ряда.</p>
                  <p className='elementor-heading-title elementor-size-default'><strong><i>Особенности TMA</i></strong>
                    <ul>
                      <li>Давно известный факт, что обычная SMA, довольно сильно уступает TMA. Преимущество треугольной скользящей средней заключается в том, что линия, отображаемая на графике, выглядит более сглаженной и волнообразной, а скорость реакции по сравнению с обычной скользящей средней значительно выше.</li>
                      <li>Главный её недостаток, как и у других MA, заключается в том что, он двигается по инерции ценового движения, всвязи с чем сигалы TMA запаздывающие.</li>
                    </ul>
                  </p>

                  <h3 className='elementor-heading-title elementor-size-default'>Linear Weighted Moving Average (WMA)</h3>
                  <p className='elementor-heading-title elementor-size-default'>Чтобы иметь объективное представление о динамике рынка, была разработана линейно-взвешенная скользящая. С ее помощью удалось придать значимости более поздним ценам и снизить влияние ранних. Принцип в том, что суммируются цены за промежуток, установленный трейдером, и последнему значению стоимости дают больший удельный вес, а ранней цене — меньший. Делится полученное число на сумму значимостей.</p>
                  <p className='elementor-heading-title elementor-size-default'><strong><i>Расчет взвешенной скользящей средней (WMA)</i></strong></p>
                  <p className='elementor-heading-title elementor-size-default'>Если за основу были взяты 5 значений, то формула будет выглядеть следующим образом: (5Р1+4Р2+3Р3+2Р4+1Р5)/(5+4+3+2+1), где Р обозначает цену, цифра перед буквой — удельный вес, число после Р — порядковый номер стоимости от конца к началу периода.</p>
                  <p className='elementor-heading-title elementor-size-default'><strong><i>Особенности WMA</i></strong>
                    <ul>
                      <li>Запаздывание сигнала меньше, чем в простой скользящей;</li>
                      <li>Все сигналы нужно фильтровать, так как повышается и число ложных срабатываний.</li>
                      <li>Показывает трейдеру более выгодные моменты для входа на рынок, по сравнению с простой скользящей, но менее выгодные по сравнению с экспоненциальной.</li>
                      <iframe width="720" height="405" src="https://rutube.ru/play/embed/301b6a56eb7c0c37193d20d778053e46" frameBorder="0" allow="clipboard-write; autoplay" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
                    </ul>
                  </p>
								</div>
							</div>
							<section className='elementor-section elementor-inner-section elementor-element elementor-element-63ebbcf elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="63ebbcf" data-element_type="section">
								<div className='elementor-container elementor-column-gap-default'>
									<div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-338231e' data-id="338231e" data-element_type="column">
										<div className='elementor-widget-wrap elementor-element-populated'>
											<div className='elementor-element elementor-element-6893467 elementor-align-left elementor-mobile-align-right elementor-widget elementor-widget-button' data-id="6893467" data-element_type="widget" data-widget_type="button.default">
												<div className='elementor-widget-container'>
													<div className='elementor-button-wrapper'>
                            <span className='elementor-button elementor-button-link elementor-size-sm'>
															<button style={{}} onClick={nextInd(7)} className='elementor-button-text'>Следующий</button>
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</section>
						</div>
					</div>
				</div>
			</section>

      <section id='8' style={{display: 'none'}} className='elementor-section elementor-top-section elementor-element elementor-element-1ef182e elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="1ef182e" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
				<div className='elementor-container elementor-column-gap-default'>
					<div className='elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-b84c016' data-id="b84c016" data-element_type="column">
						<div className='elementor-widget-wrap elementor-element-populated'>
							<div className='elementor-element elementor-element-219cfef elementor-widget elementor-widget-image' data-id="219cfef" data-element_type="widget" data-widget_type="image.default">
								<div className='elementor-widget-container'>
									<img loading="lazy" decoding="async" width="732" height="588" src="img/Group-93.png" className='attachment-full size-full wp-image-177' alt="" srcset="img/Group-93.png 732w, img/Group-93-300x241.png 300w" sizes="(max-width: 732px) 100vw, 732px" />
								</div>
							</div>
						</div>
					</div>
					<div className='elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-54f6cc4' data-id="54f6cc4" data-element_type="column">
						<div className='elementor-widget-wrap elementor-element-populated'>
							<div className='elementor-element elementor-element-bbb914e elementor-widget elementor-widget-heading' data-id="bbb914e" data-element_type="widget" data-widget_type="heading.default">
								<div className='elementor-widget-container'>
									<h2 className='elementor-heading-title elementor-size-default'>Скользящие средние (Moving Average)</h2>
								</div>
							</div>
							<div className='elementor-element elementor-element-65eead9 elementor-widget elementor-widget-heading' data-id="65eead9" data-element_type="widget" data-widget_type="heading.default">
								<div className='elementor-widget-container'>
                  <p className='elementor-heading-title elementor-size-default'>Скользящие средние (Moving Average, MA) — это дополнительные линии на графике цены актива. Внешне они повторяют график цены, но с небольшим запозданием и более гладко, без колебаний. Традиционная трактовка скользящих средних говорит, что, если цена выше скользящей средней, то рынок находится в фазе роста. Если цена ниже скользящей средней, то тенденция считается падающей.</p>
                  <h3 className='elementor-heading-title elementor-size-default'>Simple Moving Average (SMA)</h3>
                  <p className='elementor-heading-title elementor-size-default'>Скользящие средние показывают среднюю цену актива за установленный вами период. Например, скользящая средняя с длиной 20 показывает среднюю цену актива за предыдущие 20 свечей.</p>
                  <p className='elementor-heading-title elementor-size-default'><strong><i>Расчет простой скользящей средней (SMA)</i></strong></p>
                  <p className='elementor-heading-title elementor-size-default'>SMA = (цена 1 + цена 2 +… + цена N) / N, где N — это количество временных отрезков, за который считается средняя, например, дней или часов. Цена 1 берется за текущий временной отрезок, а последующие — за прошлые временные отрезки.</p>
                  <p className='elementor-heading-title elementor-size-default'>Например, рассчитаем значение SMA на определенную дату. Возьмем временной отрезок в три дня: сегодня акция X стоит 10 ₽, вчера — 11 ₽, позавчера — 15 ₽. Тогда сегодня SMA составит: (10 + 11 + 15) / 3 = 12 ₽.</p>
                  <p className='elementor-heading-title elementor-size-default'><strong><i>Применение скользящей средней в трейдинге</i></strong>
                    <ul>
                      <li>В торговых стратегиях – комбинируя с другими индикаторами.</li>
                      <li>С целью определения текущего тренда.</li>
                      <li>Для нахождения мест установки Stop-Loss.</li>
                      <li>Для получения торговых сигналов после пересечения линий скользящих средних.</li>
                      <li>В качестве зон поддержки/сопротивления.</li>
                    </ul>
                  </p>
                  <p className='elementor-heading-title elementor-size-default'>Уровни поддержки и сопротивления — это цены, при достижении которых актив с высокой вероятностью временно изменяет направление движения цены на противоположное. Линия скользящей средней — как раз такой уровень.
                    <ul>
                      <li>Когда цена актива находится над скользящей средней и опускается к ней, вероятность возобновления роста выше, чем вероятность пробития линии вниз. Значит, актив можно покупать.</li>
                      <li>Когда цена актива находится под скользящей средней и поднимается к ней, вероятность возобновления падения выше, чем вероятность пробития линии вверх. Значит, актив можно продавать.</li>
                    </ul>
                  </p>
                  <img style={{height: '400px'}} src='https://cdn.bcs-express.ru/article-image/44066'></img>
                  <p></p>
                  <p className='elementor-heading-title elementor-size-default'><strong><i>Пересечение цены и скользящей средней</i></strong></p>
                  <p className='elementor-heading-title elementor-size-default'>Основной принцип анализа этого трендового индикатора — рассмотрение положения ценового графика относительно средней линии. В период, когда цена находится выше средней, текущая ситуация лучше ожиданий, а значит — на рынке преобладают бычьи настроения. И наоборот, если цена опускается ниже линии скользящей средней — это сигнал того, что ожидания рынка не оправдались и на рынке господствуют медведи. Таким образом, пересечение MA с ценой может стать сигналом к совершению сделки: пересечение цены снизу вверх дает сигнал на покупку, сверху вниз — на продажу.</p>
                  <img style={{height: '350px'}} src='https://cdn.bcs-express.ru/article-image/44070'></img>
                  <p></p>
                  <p className='elementor-heading-title elementor-size-default'><strong><i>Пересечение скользящих средних</i></strong></p>
                  <p className='elementor-heading-title elementor-size-default'>Если рынок демонстрирует сильную волатильность, то пересечение двух и более скользящих средних с разными периодами больше подходит для анализа. Принцип получения торгового сигнала от пересечения двух средних аналогичен принципу пересечения скользящей с ценовым графиком, с той лишь разницей, что вместо ценового графика в данном случае выступает вторая скользящая средняя с меньшим параметром N.</p>
                  <p className='elementor-heading-title elementor-size-default'>Таким образом, сигналом на покупку будет являться пересечение медленной скользящей средней, то есть с большим N, снизу вверх быстрой скользящей, а на продажу — пересечение сверху вниз. И в этом случае не стоит забывать, что цена должна находиться в тренде.</p>
                  <img style={{height: '400px'}} src='https://kbrobots.ru/wp-content/uploads/2019/10/Signal_MA_03.jpg'></img>
                  <p></p>

                  <h3 className='elementor-heading-title elementor-size-default'>Exponential Moving Average (EMA)</h3>
									<p className='elementor-heading-title elementor-size-default'>Экспоненциальное скользящее среднее – вычисление скользящего среднего по формуле, учитывающей предыдущее значение индикатора и текущую цену. Влияние каждой предыдущей цены убывает экспоненциально с его отдаленностью от текущей цены, что и отражено в термине «экспоненциальное скользящее среднее».</p>
                  <p className='elementor-heading-title elementor-size-default'>Экспоненциальные скользящие придают большее значение свежим ценам, которые важнее для дальнейшего развития тренда. Из‑за этого экспоненциальные средние быстрее реагируют на падения и взлеты цены и чаще используются инвесторами.</p>
                  <p className='elementor-heading-title elementor-size-default'><strong><i>Расчет экспоненциальной скользящей средней (EMA)</i></strong></p>
                  <p className='elementor-heading-title elementor-size-default'>EMA в конкретной точке графика = (цена × вес цены) + (EMA в предыдущей точке графика × (1 − вес цены)). Вес цены = 2 / (N + 1), где N — это количество временных отрезков, за который считается средняя, например, дней или часов.</p>
                  <p className='elementor-heading-title elementor-size-default'>Например, рассчитаем значение EMA для той же акции X. Временной отрезок составляет три дня, поэтому вес цены будет равен: 2 / (3 + 1) = 0,5.</p>
                  <p className='elementor-heading-title elementor-size-default'>Вес цены позволяет снизить влияние предыдущих цен на последние значения EMA. Из‑за этого при одинаковых ценах простая скользящая средняя на сегодня составила 12 ₽, а экспоненциальная — 11,5 ₽, что ближе к последней цене актива.</p>
                  <p className='elementor-heading-title elementor-size-default'><strong><i>Главные особенности ЕМА</i></strong>
                    <ul>
                      <li>Настройка обеспечивает большее число сигналов, повышается чувствительность к изменениям цены.</li>
                      <li>Все сигналы нужно фильтровать, так как повышается и число ложных срабатываний.</li>
                      <li>У ЕМА точность все равно выше в сравнении с SMA с меньшим периодом, к примеру (даже при условии уменьшения значения параметра и повышения чувствительности и числа сигналов, SMA не дает такого результата, как ЕМА).</li>
                    </ul>
                  </p>

                  <h3 className='elementor-heading-title elementor-size-default'>Triangular Moving Average (TMA)</h3>
                  <p className='elementor-heading-title elementor-size-default'>Треугольная скользящая средняя. TMA является трендовым индикатором. Он получил всеобщее признание трейдов по всему миру и его стали широко применять во многих стратегиях, которые популярны и по сей день. Наиболее часто используется в виде канала TMA.</p>
                  <p className='elementor-heading-title elementor-size-default'>Это индикатор, следующий за ценой. Он является запаздывающим, так как всегда несколько отстает от текущей цены. Расчет TMA похож на расчет простой скользящей средней, но с дополнительным сглаживанием.</p>
                  <p className='elementor-heading-title elementor-size-default'>Рассмотрим рисунок валютной пары USDJPY таймфрейма m5, на котором представлены для сравнения сразу два индикатора Triangular Moving Average(TMA) и Simple Moving Average(SMA) с одинаковыми периодами 21. На графике SMA обозначена цифрой 1, а TMA обозначена цифрой 2. Хорошо видно, что SMA при движении бывает довольно угловата, в то время как TMA выглядит гладкой и плавной линией. Треугольная MA похожа на экспоненциальное и взвешенное MA, отличаясь лишь способом взвешивания. Использовать TMA можно точно так же, как и простое скользящее среднее, например, определять с его помощью тренд.</p>
                  <img style={{height: '400px'}} src='https://fx-wiki.ru/images/6/6e/04.02.02.png'></img>
                  <p className='elementor-heading-title elementor-size-default'>Рассмотрим следующий рисунок. На нем изображена Triangular MA на графике валютной пары USDJPY M5. Как мы видим, цена сначала пересекла мувинг снизу вверх и стала двигаться значительно выше него - это явный признак восходящего тренда. Также стоит обратить внимание на направление мувинга, которое явно направлено вверх, это еще один фактор подтверждающий восходящий тренд. Причем, чем больше наклонен мувинг вверх или вниз, тем о большей силе тренда это говорит. Далее на графике TMA стала двигаться значительно с меньшим наклоном, что говорит нам о том, что силы тренда, вероятно, на исходе.</p>
                  <img style={{height: '400px'}} src='https://fx-wiki.ru/images/b/b0/04.02.03.png'></img>
                  <p className='elementor-heading-title elementor-size-default'>Индикатор можно применять на любых таймфреймах и инструментах. На более старших таймфреймах такой сигнал на вход, как подход к уровню поддержки или сопротивления канала имеет большую силу, чем, например, на М1 или М5. С другой стороны, данный индикатор очень популярен в скальперских стратегиях, направленных на работу как раз на таймфреймах м1 или м5. Обусловлено это тем, что на рынке по статистике 70% времени флэт и лишь 30% времени длится тренд. Поэтому цена часто на маленьких таймфреймах во время флэта бьется об уровни, рисуемые данным индикатором, и отскакивает.</p>
                  <p className='elementor-heading-title elementor-size-default'><strong><i>Расчет треугольной скользящей средней (TMA)</i></strong></p>
                  <img src='https://fx-wiki.ru/images/d/df/04.02.05.png'></img>
                  <p className='elementor-heading-title elementor-size-default'>SMA— простое скользящее среднее</p>
                  <p className='elementor-heading-title elementor-size-default'>N — число периодов для скользящего среднего</p>
                  <p className='elementor-heading-title elementor-size-default'>Что касается расчета индикатора, то сравним его с расчетом простой, экспоненциальной и взвешенной МА. Экспоненциальная и взвешенная МА присваивают больший вес самым крайним значениям соответствующего ряда. У обычной МА все значения ряда имеют одинаковый авторитет. У Треугольного МА больший вес присваивается данным из средней части ценового ряда.</p>
                  <p className='elementor-heading-title elementor-size-default'><strong><i>Особенности TMA</i></strong>
                    <ul>
                      <li>Давно известный факт, что обычная SMA, довольно сильно уступает TMA. Преимущество треугольной скользящей средней заключается в том, что линия, отображаемая на графике, выглядит более сглаженной и волнообразной, а скорость реакции по сравнению с обычной скользящей средней значительно выше.</li>
                      <li>Главный её недостаток, как и у других MA, заключается в том что, он двигается по инерции ценового движения, всвязи с чем сигалы TMA запаздывающие.</li>
                    </ul>
                  </p>

                  <h3 className='elementor-heading-title elementor-size-default'>Linear Weighted Moving Average (WMA)</h3>
                  <p className='elementor-heading-title elementor-size-default'>Чтобы иметь объективное представление о динамике рынка, была разработана линейно-взвешенная скользящая. С ее помощью удалось придать значимости более поздним ценам и снизить влияние ранних. Принцип в том, что суммируются цены за промежуток, установленный трейдером, и последнему значению стоимости дают больший удельный вес, а ранней цене — меньший. Делится полученное число на сумму значимостей.</p>
                  <p className='elementor-heading-title elementor-size-default'><strong><i>Расчет взвешенной скользящей средней (WMA)</i></strong></p>
                  <p className='elementor-heading-title elementor-size-default'>Если за основу были взяты 5 значений, то формула будет выглядеть следующим образом: (5Р1+4Р2+3Р3+2Р4+1Р5)/(5+4+3+2+1), где Р обозначает цену, цифра перед буквой — удельный вес, число после Р — порядковый номер стоимости от конца к началу периода.</p>
                  <p className='elementor-heading-title elementor-size-default'><strong><i>Особенности WMA</i></strong>
                    <ul>
                      <li>Запаздывание сигнала меньше, чем в простой скользящей;</li>
                      <li>Все сигналы нужно фильтровать, так как повышается и число ложных срабатываний.</li>
                      <li>Показывает трейдеру более выгодные моменты для входа на рынок, по сравнению с простой скользящей, но менее выгодные по сравнению с экспоненциальной.</li>
                      <iframe width="720" height="405" src="https://rutube.ru/play/embed/301b6a56eb7c0c37193d20d778053e46" frameBorder="0" allow="clipboard-write; autoplay" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
                    </ul>
                  </p>
								</div>
							</div>
							<section className='elementor-section elementor-inner-section elementor-element elementor-element-63ebbcf elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="63ebbcf" data-element_type="section">
								<div className='elementor-container elementor-column-gap-default'>
									<div className='elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-338231e' data-id="338231e" data-element_type="column">
										<div className='elementor-widget-wrap elementor-element-populated'>
											<div className='elementor-element elementor-element-6893467 elementor-align-left elementor-mobile-align-right elementor-widget elementor-widget-button' data-id="6893467" data-element_type="widget" data-widget_type="button.default">
												<div className='elementor-widget-container'>
													<div className='elementor-button-wrapper'>
                            <span className='elementor-button elementor-button-link elementor-size-sm'>
															<button style={{}} onClick={nextInd(8)} className='elementor-button-text'>Следующий</button>
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</section>
						</div>
					</div>
				</div>
			</section>

      <section id='9' style={{display: 'none'}} className='elementor-section elementor-top-section elementor-element elementor-element-1ef182e elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="1ef182e" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
				<div className='elementor-container elementor-column-gap-default'>
					<div className='elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-b84c016' data-id="b84c016" data-element_type="column">
						<div className='elementor-widget-wrap elementor-element-populated'>
							<div className='elementor-element elementor-element-219cfef elementor-widget elementor-widget-image' data-id="219cfef" data-element_type="widget" data-widget_type="image.default">
								<div className='elementor-widget-container'>
									<img loading="lazy" decoding="async" width="732" height="588" src="img/Group-93.png" className='attachment-full size-full wp-image-177' alt="" srcset="img/Group-93.png 732w, img/Group-93-300x241.png 300w" sizes="(max-width: 732px) 100vw, 732px" />
								</div>
							</div>
						</div>
					</div>
					<div className='elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-54f6cc4' data-id="54f6cc4" data-element_type="column">
						<div className='elementor-widget-wrap elementor-element-populated'>
							<div className='elementor-element elementor-element-bbb914e elementor-widget elementor-widget-heading' data-id="bbb914e" data-element_type="widget" data-widget_type="heading.default">
								<div className='elementor-widget-container'>
									<h2 className='elementor-heading-title elementor-size-default'>Скрипты без использования индикаторов</h2>
								</div>
							</div>
							<div className='elementor-element elementor-element-65eead9 elementor-widget elementor-widget-heading' data-id="65eead9" data-element_type="widget" data-widget_type="heading.default">
								<div className='elementor-widget-container'>
									<p className='elementor-heading-title elementor-size-default'>На нашей платформе есть несколько скриптов покупки и продажи, которые работают без использования индикаторов. Это покупка при закрытии свечи ниже или выше заданной цены актива. А так же, продажа при закрытии свечи выше цены покупки, выше цены покупки плюс заданное значение и выше цены покупки умноженной на заданное значение.</p>
                  <iframe width="720" height="405" src="https://rutube.ru/play/embed/867cf67a13b206a4c6197452b858a7d8" frameBorder="0" allow="clipboard-write; autoplay" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>



				</div>
    		</div>
		</div>
	</section>

</>
)}
export default Master




const scrollBehavior = (instructions) => {
  const [{ el, left }] = instructions;
  const styler = Styler(el);

  animate({
    from: el.scrollLeft,
    to: left,
    type: "spring",
    onUpdate: (left) => styler.set("scrollLeft", left)
  });
};

const easingFunctions = {
  noEasing: undefined,
  // no easing, no acceleration
  linear: (t) => t,
  // accelerating from zero velocity
  easeInQuad: (t) => t * t,
  // decelerating to zero velocity
  easeOutQuad: (t) => t * (2 - t),
  // acceleration until halfway, then deceleration
  easeInOutQuad: (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t),
  // accelerating from zero velocity
  easeInCubic: (t) => t * t * t,
  // decelerating to zero velocity
  easeOutCubic: (t) => --t * t * t + 1,
  // acceleration until halfway, then deceleration
  easeInOutCubic: (t) =>
    t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1,
  // accelerating from zero velocity
  easeInQuart: (t) => t * t * t * t,
  // decelerating to zero velocity
  easeOutQuart: (t) => 1 - --t * t * t * t,
  // acceleration until halfway, then deceleration
  easeInOutQuart: (t) =>
    t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t,
  // accelerating from zero velocity
  easeInQuint: (t) => t * t * t * t * t,
  // decelerating to zero velocity
  easeOutQuint: (t) => 1 + --t * t * t * t * t,
  // acceleration until halfway, then deceleration
  easeInOutQuint: (t) =>
    t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t
  // Source https://gist.github.com/gre/1650294#file-easing-js
};