import axios from 'axios';
import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { useState, useEffect } from 'react'

import './css/post-2.css'
import './css/frontend-lite.min.css'
import './css/global.css'

function Create() {
    const [data, setData] = useState();
    useEffect(()=> {
        axios.get('http://smartex.site/api/users/')
            .then(res => setData(res.data))
            .catch(err => console.log(err))
            }, [])
    const [inputData, setInputData] = useState({
        name: '',
        email: '',
        ref: 0
        })
    const handleSubmit = (event) => {
        event.preventDefault();
//                console.log(inputData);
        let proverka = 0;
                axios.get('http://smartex.site/api/users/')
                    .then((res) => (setData(res.data)))
                    .then(data.map((d, i)=> (d.name == inputData.name ? (proverka = 1, alert("Такой пользователь уже существует, введите другое имя!")) : null)))
                    .catch(err => console.log(err))
        if (proverka == 0) {
            axios.post('http://smartex.site/api/user/save', inputData).then(window.location.replace('http://smartex.site'))
        }
    }
                                    const [isCaptchaSuccessful, setIsCaptchaSuccess] = React.useState(false)

  function onChange(value) {
    setIsCaptchaSuccess(true)
//    console.log("captcha value: ", value);
  }
  return (
<div data-elementor-type="wp-page" data-elementor-id="2" className='elementor elementor-2' data-elementor-post-type="page">
    <div className='elementor-element elementor-element-a3c9e3a elementor-widget elementor-widget-image' data-id="a3c9e3a" data-element_type="widget" data-widget_type="image.default">
		<div style={{textAlign: 'center'}} className='elementor-widget-container'>
			<img loading="lazy" decoding="async" width="657" height="105" src="img/Frame-7.png" className='attachment-full size-full wp-image-532' alt="" srcSet='img/Frame-7.png 657w, img/Frame-7-300x48.png 300w' sizes="(max-width: 657px) 100vw, 657px" />
		</div>
	</div>
<section className='elementor-section elementor-inner-section elementor-element elementor-element-83d8ca5 elementor-section-boxed elementor-section-height-default elementor-section-height-default' data-id="83d8ca5" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
							<div className='elementor-container elementor-column-gap-default'>
								<div className='elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-35bb662' data-id="35bb662" data-element_type="column">
									<div className='elementor-widget-wrap elementor-element-populated'>
										<div className='elementor-element elementor-element-6d81ddb elementor-widget elementor-widget-text-editor' data-id="6d81ddb" data-element_type="widget" data-widget_type="text-editor.default">
											<div className='elementor-widget-container'>
												<p>Готовы ли Вы вывести свой опыт криптовалютной торговли на новый уровень? Зарегистрируйтесь, чтобы начать оптимизировать свои инвестиции с помощью алгоритмических решений Smartex.</p>
											</div>
										</div>
										<div className='elementor-element elementor-element-9e52bf8 elementor-button-align-center elementor-widget elementor-widget-form' data-id="9e52bf8" data-element_type="widget" data-settings="{&quot;step_next_label&quot;:&quot;Next&quot;,&quot;step_previous_label&quot;:&quot;Previous&quot;,&quot;button_width&quot;:&quot;100&quot;,&quot;step_type&quot;:&quot;number_text&quot;,&quot;step_icon_shape&quot;:&quot;circle&quot;}" data-widget_type="form.default">
											<div className='elementor-widget-container'>
												<form  onSubmit={handleSubmit} className='elementor-form'>
													<div className='elementor-form-fields-wrapper elementor-labels-'>
														<div className='elementor-field-type-text elementor-field-group elementor-column elementor-field-group-name elementor-col-25'>
															<label for="form-field-name" className='elementor-field-label elementor-screen-only'>
						First Name							</label>
															<input size="1" type="text" name="form_fields[name]" id="form-field-name" className='elementor-field elementor-size-md  elementor-field-textual' placeholder="Имя" required="required" onChange={e => setInputData({...inputData, name: e.target.value})}></input>
														</div>
														<div className='elementor-field-type-email elementor-field-group elementor-column elementor-field-group-email elementor-col-25 elementor-field-required'>
															<label for="form-field-email" className='elementor-field-label elementor-screen-only'>
						Email Address						</label>
															<input size="1" type="email" name="form_fields[email]" id="form-field-email" className='elementor-field elementor-size-md  elementor-field-textual' placeholder="Email" required="required" aria-required="true" onChange={e => setInputData({...inputData, email: e.target.value, coin: 0, time: 0})}></input>
														</div>
														<div className='elementor-field-type-tel elementor-field-group elementor-column elementor-field-group-field_15fc807 elementor-col-25'>
															<label for="form-field-field_15fc807" className='elementor-field-label elementor-screen-only'>
						Mobile/Text							</label>
															<input size="1" type="password" name="form_fields[field_15fc807]" id="form-field-field_15fc807" className='elementor-field elementor-size-md  elementor-field-textual' placeholder="Пароль" required="required" onChange={e => setInputData({...inputData, password: e.target.value, payment: ""})}></input>
														</div>
                                                        <div className='elementor-field-type-tel elementor-field-group elementor-column elementor-field-group-field elementor-col-25'>
															<label for="form-field-ref" className='elementor-field-label elementor-screen-only'>
						Mobile/Text							</label>
															<input size="1" type="text" name="form_fields[ref]" id="form-field-ref" className='elementor-field elementor-size-md  elementor-field-textual' placeholder="Реферал" onChange={e => setInputData({...inputData, ref: e.target.value})}></input>
														</div>
														<div className='elementor-field-group elementor-column elementor-field-type-submit elementor-col-100 e-form__buttons'>
{/*                                                        <ReCAPTCHA
          sitekey={"6LfgujIpAAAAAFxt3OIp0l_k18Y4FoiRrVR1bquU"}
          onChange={onChange}
          style={{display: 'block'}} className='elementor-size-md '/>
           */}
                                                        </div>
                                                        <div className='elementor-field-group elementor-column elementor-field-type-submit elementor-col-100 e-form__buttons'>
                                                            <button type="submit" className='elementor-button elementor-size-md'
                                                            // disabled={!isCaptchaSuccessful}
                                                            >
																<span >
																	<span className=' elementor-button-icon'>
																	</span>
																	<span className='elementor-button-text'>Зарегистрироваться</span>
																</span>
															</button>
														</div>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>



{/* 
    <div className='d-flex w-100 vh-100 justify-content-center align-items-center'>
        <div className='w-50 border bg-secondary text-white p-5'>
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="name">Name:</label>
                          <input type="text" name='name' className='form-control'     onChange={e => setInputData({...inputData, name: e.target.value})}/>
                          </div> 
                          <div> 
                             <label htmlFor="email">Email:</label>
                             <input type="email" name='email' className='form-control'  onChange={e => setInputData({...inputData, email: e.target.value})}/>
                             </div>
                             <div>
                                <label htmlFor="password">Password:</label>
                                  <input type="password" name='password' className='form-control'     onChange={e => setInputData({...inputData, password: e.target.value})}/>
                              </div> 
                             <br />
                             <ReCAPTCHA
          sitekey={"6LfgujIpAAAAAFxt3OIp0l_k18Y4FoiRrVR1bquU"}
//          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          onChange={onChange}
          />
          <button className='btn btn-info'
          disabled={!isCaptchaSuccessful}
          >Submit</button>
                             </form>
                             </div>
                             </div>
          */}

                             </div>
                               )}
export default Create