import axios from 'axios'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'

//import { Link, useNavigate } from 'react-router-dom'

function Home() {
    const [data, setData] = useState([]);
//    const navigate = useNavigate();
    useEffect(()=> {
        axios.get('http://localhost:3001/users')
//            axios.get('http://100.100.235.148:3000/')
            .then(res => setData(res.data))
            .catch(err => console.log(err))
            }, [])
  return (
    <div className='container '>
        <h2>Crud App with JSON Server</h2>
        <div className='d-flex flex-column justify-content-center align-items-center bg-light vh100'>
            <h1>List of Users</h1>
        </div>
           <Link to="/create" className='btn btn-success my-3'>Create +</Link>
                   <table className='table'>
                                <thead>
                                     <tr>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Password</th>
                                        <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((d, i)=> (
                                                <tr key={i}>
                                                    <td>{d.id}</td>
                                                    <td>{d.name}</td>
                                                    <td>{d.email}</td>
                                                    <td>{d.password}</td>
                                                    <td>
                                                        <Link className='text-decoration-none btn btn-sm btn-success' to={`/update/${d.id}`}>Update</Link>
                                                        <button className='text-decoration-none btn btn-sm btn-danger mx-1'                                 onClick={e => handleDelete(d.id)}>Delete</button>
                                                        <Link className='text-decoration-none btn btn-sm btn-primary'                                 to={`/read/${d.id}`}>Read</Link>
                                                        </td>
                                                          </tr>                
                                                          ))}
                                                          </tbody>
                                                          </table>
    </div>  )
  function handleDelete(id) {
        const confirm = window.confirm("Do you like to Delete?");
            if(confirm) {
                        axios.delete('http://localhost:3001/users/'+id)
                                .then(res => {
                                    alert("Record Deleted");
//                                    location.reload();
                                })
                                }
                              }}
export default Home