import React from 'react';
import { render } from 'react-dom';
import App from "./App";
/*import { createRoot } from "react-dom";

import App from "./App";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
*/

render(
	<App />,
	document.getElementById("root")
);
